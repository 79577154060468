import { Fragment } from "react"
import { ButtonWaiting, getDeviceType } from "../../../../functions/PageAnimation";
import WaitingRoundSnippers from "../../../../common/smallComponents.js/WaitingRoundSnippers";
import { hasRole } from "../../../../functions/StrFunctions";

const Tab = (p) => {

    return <Fragment>
        {
            getDeviceType() === "desktop" &&
            <ul className="nav nav-tabs no-print">
                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (p.tab === 0 ? "active" : "")}
                        href="#" onClick={() => p.setTab(0)}>
                        {!p.machineInfo && <ButtonWaiting />}
                        Fiche machine
                    </a>
                </li>

                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (p.tab === 5 ? "active" : "")}
                        href="#" onClick={() => p.setTab(5)}>
                        {!p.machineInfo && <ButtonWaiting />}
                        Compléments & outils
                    </a>
                </li>

                {
                    !p.readOnly &&
                    <Fragment>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 2 ? "active" : "")}
                                href="#" onClick={() => p.setTab(2)}>
                                {!p.machineInfo && <ButtonWaiting />}
                                Exporter
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 1 ? "active" : "")}
                                href="#" onClick={() => p.setTab(1)}>
                                {!p.dp && <ButtonWaiting />}
                                Menu déroulant
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 8 ? "active" : "")}
                                href="#" onClick={() => p.setTab(8)}>
                                {!p.dp && <ButtonWaiting />}
                                Options
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 6 ? "active" : "")}
                                href="#" onClick={() => p.setTab(6)}>
                                {!p.machCat && <ButtonWaiting />}
                                Types de machines
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 4 ? "active" : "")}
                                href="#" onClick={() => p.setTab(4)}>
                                {!p.machineInfo && <ButtonWaiting />}
                                Cartonnier
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 3 ? "active" : "")}
                                href="#" onClick={() => p.setTab(3)}>Informations</a>
                        </li>
                    </Fragment>

                }

                {(p.readOnly && hasRole(["ROLE_PURCHASE"])) &&
                    <Fragment>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 6 ? "active" : "")}
                                href="#" onClick={() => p.setTab(6)}>
                                {!p.machCat && <ButtonWaiting />}
                                Types de machines
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (p.tab === 8 ? "active" : "")}
                                href="#" onClick={() => p.setTab(8)}>
                                {!p.dp && <ButtonWaiting />}
                                Options
                            </a>
                        </li>
                    </Fragment>}

                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (p.tab === 7 ? "active" : "")}
                        href="#" onClick={() => p.setTab(7)}>
                        {!p.machineInfo && <ButtonWaiting />}
                        Historique
                    </a>
                </li>
            </ul>
        }



        {
            getDeviceType() !== "desktop" &&
            <div className="mb-3  no-print">
                <select className="form-select" onChange={(e) => p.setTab(parseInt(e.target.value))}>
                    <option value="0">Fiche machine</option>
                    <option value="5">Compléments & outils</option>
                    {
                        !p.readOnly &&
                        <Fragment>
                            <option value="2">Exporter</option>
                            <option value="1">Menu déroulant</option>
                            <option value="6">Types de machines</option>
                            <option value="4">Cartonnier</option>
                            <option value="3">Informations</option>
                        </Fragment>
                    }
                </select>
                {!p.machineInfo && <WaitingRoundSnippers />}
            </div>
        }

    </Fragment>

}

export default Tab;