import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "test";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getMessageObj = () => {
    return axios
        .post(API_URL + "/get-pojo", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const sendMessage = (msg) => {
    return axios
        .post(API_URL + "/send", {
            fake_header: authHeader(),
            message: user.first_name + ": " + msg
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getMessageObj,
    sendMessage
}