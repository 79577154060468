import { useEffect, useState } from "react"
import checkList from "../../services/workshop/checkList";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import errorManagement from "../../services/errorManagement";
import { createColumnHelper } from "@tanstack/react-table";
import { getFrFormat } from "../../functions/StrFunctions";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const CheckListSummary = (props) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);


    useEffect(() => {
        checkList.getAllDoneForms().then(
            (res) => {
                setLoading(false);
                setList(res);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            })
    }, []);

    const getLink = ( v ) => {
        if( v.form === "Montage" ) return "/workshop/check-list/assembly?machine=" + v.mach_nb.trim();
        if( v.form === "Intégration méca" ) return "/workshop/check-list/integration?machine=" + v.mach_nb.trim();
        if( v.form === "Intégration élec" ) return "/workshop/check-list/electrical?machine=" + v.mach_nb.trim();
        
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('affaire', {
            header: 'Affaire',
            cell: i => <div className="fw-bold text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('mach_nb', {
            header: 'Machine',
            cell: i => <div className="fw-bold text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('typ_config', {
            header: 'Type de machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('form', {
            header: 'Type de contrôle',
            cell: i => <div className="text-center">{i.getValue().trim()}</div>
        }));

        arr.push(columnHelper.accessor('creation_date', {
            header: 'Date de création',
            cell: i => <div className="text-center">{ getFrFormat( i.getValue(), true )}</div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: '',
            cell: i => <div className="text-center">
                <a className="btn btn-success"
                    href={getLink(i.row.original)}>Entrer</a>
            </div>
        }));

        return arr;
    }

    const columns = getColumns();

    return <div>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}

        <ReactTable columns={columns} data={list} origin="workshopCtrlList" classes={"no-padding"} top0={true} />

    </div>
}


export default CheckListSummary;