import { useTranslation } from "react-i18next";

const SupplyChain = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('supply chain')}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

                <li>
                    <a href={"/supply-chain/tools/expedition"} className="dropdown-item">
                        Outils d'expédition
                    </a>
                </li>

                { (props.roles.includes("ROLE_SCREW") || props.roles.includes("ROLE_ADMIN") ) && 
                <li>
                    <a href={"/purchase/screws/order"} className="dropdown-item">
                        Tableau de visserie
                    </a>
                </li>}
                
            </ul>
        </li>
    )

}

export default SupplyChain;