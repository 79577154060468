import { useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import FormMgtDao from "../../services/settings/FormMgtDao";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const NightAlertsList = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState();
    const [msg, setMsg] = useState();

    useEffect(() => {

        FormMgtDao.getAllNightAlert().then(
            (response) => {
                setData(response);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
            }
        )

    }, []);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id',
            { header: '#', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('enabled',
            {
                header: 'Actif',
                cell: (i) => <div className={"text-center " + ( !i.getValue() && "bg-danger")} style={{height: "100%"}}>
                    <input type="checkbox" defaultChecked={i.getValue()} disabled /></div>
            }));

        arr.push(columnHelper.accessor('description', {
            header: 'Description',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('contacts', {
            header: 'Contacts',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('query', {
            header: 'Query',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('timing', {
            header: 'Query',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('xxx', {
            header: '',
            enableColumnFilter: false,
            cell: (i) => <div className="text-center">
                <a href={"/night-alerts/form?id=" + i.row.original.id} className="btn btn-success">Entrer</a>
            </div>
        }));

        arr.push(columnHelper.accessor('xxxx', {
            header: '',
            enableColumnFilter: false,
            cell: (i) => <div className="text-center">
                <button className="btn btn-secondary" disabled={loading} onClick={() => testAlert(i.row.original.id)}>
                    {loading && <ButtonWaiting />}
                    Tester</button>
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    const testAlert = (id) => {
        setLoading(true);

        FormMgtDao.testNightAlert(id).then(
            (response) => {
                setMsg(response);
                setLoading(false);
                scrollToTop();
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    return <div>
        <h6 className="text-center">Alertes de nuit</h6>
        {loading && <WaitingRoundSnippers />}
        {err && <ErrorMessage error={err} errorState={setErr} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <div className="text-center">
            <a href="/night-alerts/form">Nouvelle demande</a>
        </div>
        <ReactTable columns={columns} data={data} top0={true} origin="night_alert_list" />
    </div>

}

export default NightAlertsList;