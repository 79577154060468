import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../../actions/auth";
import { useTranslation } from "react-i18next";
import { SET_MESSAGE, REGISTER_FAIL } from "../../actions/types";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const validReferent = (value) => {
  if( !value.endsWith("@sealedair.com") ){
    return (
      <div className="alert alert-danger" role="alert">
        The referent must be from Sealed Air corp.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};


const Register = () => {
  const form = useRef();
  const checkBtn = useRef();

  const {t} = useTranslation();

  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [managerMail, setManagerMail] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onCompanyChange = (e) => {
    const company = e.target.value;
    setCompany(company);
  };

  const onManagerMailChange = (e) => {
    const manager_mail = e.target.value;
    setManagerMail(manager_mail);
  };

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  }

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  }

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);
    setLoading(true);

    form.current.validateAll();
    if( email.trim() === "" || username.trim() === "" 
        || lastName.trim() === "" || firstName.trim() === "" 
        || password.trim() === "" || company.trim() === "" 
        || managerMail.trim() === ""  ){
          dispatch({
            type: REGISTER_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: "Please fill in all fields",
          });

          setLoading(false);
          return;
        }

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(username, email, password, company, managerMail, firstName, lastName))
        .then(() => {
          setSuccessful(true);
          setLoading(false);
        })
        .catch(() => {
          setSuccessful(false);
          setLoading(false);
        });
    }else{
      setLoading(false);
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>

              <div className="form-group">
                <label htmlFor="username">{t('username')}</label>
                <div className="input-group mb-3">
                <input type="text" className="form-control"
                    name="username"
                    value={username}
                    onChange={onChangeUsername}
                    validations={[required, vusername]}
                  />
                  <img src={"/common/question-mark.png"} title={t('username help')}
                            style={{width: "30px", height: "30px", margin: "auto"}}></img>
                </div>
                
              </div>

              <div className="form-group">
                <label htmlFor="last_name">{t('last name')}</label>
                <div className="input-group mb-3">
                <input type="text" className="form-control"
                    name="last_name"
                    value={lastName}
                    onChange={onLastNameChange}
                    validations={[required]}/>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="first_name">{t('first name')}</label>
                <div className="input-group mb-3">
                <input type="text" className="form-control"
                    name="first_name"
                    value={firstName}
                    onChange={onFirstNameChange}
                    validations={[required]}/>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <div className="input-group mb-3">
                <input type="text" className="form-control"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[required, validEmail]}/>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="password">{t('password')}</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="company">{t('company')}</label>
                <Input
                  type="text"
                  className="form-control"
                  name="company"
                  value={company}
                  onChange={onCompanyChange}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="manager_mail">{t('referent email')}</label>
                <div className="input-group mb-3">
                <input type="text" className="form-control"
                    name="manager_mail"
                    value={managerMail}
                    onChange={onManagerMailChange}
                    validations={[required, validEmail, validReferent]}
                  />
                  <img src={"/common/question-mark.png"} title={t('referent help')}
                              style={{width: "30px", height: "30px", margin: "auto"}}></img>
                </div>
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>{t('sign up')}</span>
                  </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
