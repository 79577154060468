import { useEffect, useState } from "react";
import contactsDao from "../../services/settings/contactsDao";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import Select from "react-select";
import FormMgtDao from "../../services/settings/FormMgtDao";
import getUrlParam from "../../functions/StrFunctions";
import { removeAndReturnArray } from "../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../functions/PageAnimation";

const NightAlerts = (props) => {
    const [contacts, setContacts] = useState([]);
    const [form, setForm] = useState({
        enabled: 1, query: "", timing: "", contacts: "", intro: "",
        description: "", db_name: "", object: "", sender: ""
    });
    const [err, setErr] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        FormMgtDao.getNightAlertById(getUrlParam(props, "id")).then(
            (response) => {
                var form = response.form;
                form.contacts = form.contacts.split(",");
                setForm(response.form);

                setContacts(response.contacts);
                setLoading(false);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )

    }, []);


    const onTxtChange = (e, field) => {
        var o = { ...form };
        o[field] = e.target.value;
        setForm(o);
    }

    const onTimingChangeWeeks = (e) => {
        var o = { ...form };

        var str = "EACH_WEEKS:";

        const checkboxes = document.querySelectorAll('input[name="days"]:checked');
        checkboxes.forEach((checkbox) => { str += checkbox.value + ";" });

        o.timing = str;
        setForm(o);
    }

    const onTimingChangeMonth = (e) => {
        var o = { ...form };
        var str = "EACH_MONTH:" + e.target.value;
        o.timing = str;
        setForm(o);
    }

    const onContactChange = (e, k) => {
        var o = { ...form };
        o.contacts[k] = e.target.value;
        setForm(o)
    }

    const addContact = () => {
        var o = { ...form };
        o.contacts.push("");
        setForm(o)
    }

    const removeContact = (k) => {
        var o = { ...form };
        o.contacts = removeAndReturnArray(o.contacts, k);
        setForm(o)
    }

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        FormMgtDao.saveNightAlert(form).then(
            (response) => {
                window.location.href = "/night-alerts";
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    const enableAlert = () => {
        var o = { ...form };
        o.enabled = o.enabled === 0 ? 1 : 0;
        setForm(o);
    }

    return <div>
        <h5 className="text-center">Création d'alerte</h5>

        {err && <div className="alert alert-danger" role="alert">{err}</div>}
        {loading && <WaitingRoundSnippers />}

        <form className="card ms-auto me-auto" onSubmit={onSubmit} style={{ width: "800px" }}>

            <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" checked={form.enabled}
                    onChange={enableAlert} />
                <label className="form-check-label">Actif</label>
            </div>


            <div className="input-group mb-3">
                <span className="input-group-text" name="">Requête</span>
                <textarea className="form-control" value={form.query}
                    onChange={(e) => onTxtChange(e, 'query')} required />
            </div>

            <div className="mb-3">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="db_name" value="sybase"
                        onChange={(e) => onTxtChange(e, 'db_name')} checked={form.db_name === "sybase"} />
                    <label class="form-check-label">Sybase</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="db_name" value="msSql"
                        onChange={(e) => onTxtChange(e, 'db_name')} checked={form.db_name === "msSql"} />
                    <label class="form-check-label">Sql server</label>
                </div>
            </div>

            <div className="input-group mb-3">
                <label className="input-group-text">Récurrence</label>
                <select className="form-select" id="timing"
                    onChange={(e) => onTxtChange(e, 'timing')} required>
                    <option value="">Choose...</option>
                    <option value="ALL_DAYS" selected={form.timing.startsWith("ALL_DAYS")}>Tous les jours</option>
                    <option value="EACH_WEEKS" selected={form.timing.startsWith("EACH_WEEKS")}>Chaque semaine</option>
                    <option value="EACH_MONTH" selected={form.timing.startsWith("EACH_MONTH")}>Chaque mois</option>
                </select>
            </div>

            {form.timing.indexOf("EACH_WEEKS") !== -1 && <div className="me-auto ms-auto" style={{ width: "100px" }}>
                <div className="form-check">
                    <input className="form-check-input" name="days" type="checkbox" value="MONDAY"
                        onChange={onTimingChangeWeeks} checked={ form.timing.indexOf("MONDAY") !== -1 }/>
                    <label className="form-check-label">Lundi</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" name="days" type="checkbox" value="TUESDAY"
                        onChange={onTimingChangeWeeks} checked={ form.timing.indexOf("TUESDAY") !== -1 } />
                    <label className="form-check-label">Mardi</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" name="days" type="checkbox" value="WEDNESDAY"
                        onChange={onTimingChangeWeeks} checked={ form.timing.indexOf("WEDNESDAY") !== -1 } />
                    <label className="form-check-label">Mercredi</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" name="days" type="checkbox" value="THURSDAY"
                        onChange={onTimingChangeWeeks} checked={ form.timing.indexOf("THURSDAY") !== -1 } />
                    <label className="form-check-label">Jeudi</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" name="days" type="checkbox" value="FRIDAY"
                        onChange={onTimingChangeWeeks} checked={ form.timing.indexOf("FRIDAY") !== -1 } />
                    <label className="form-check-label">Vendredi</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" name="days" type="checkbox" value="SATURDAY"
                        onChange={onTimingChangeWeeks} checked={ form.timing.indexOf("SATURDAY") !== -1 } />
                    <label className="form-check-label">Samedi</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" name="days" type="checkbox" value="SUNDAY"
                        onChange={onTimingChangeWeeks} checked={ form.timing.indexOf("SUNDAY") !== -1 } />
                    <label className="form-check-label">Dimanche</label>
                </div>
            </div>
            }

            {form.timing.indexOf("EACH_MONTH") !== -1 && <div className="me-auto ms-auto">
                <div className="input-group mb-3">
                    <span className="input-group-text">Jours</span>
                    <input className="form-control" name="" placeholder="Ex: 1;15;30"
                        onChange={onTimingChangeMonth} 
                        defaultValue={ form.timing && form.timing.indexOf(":") !== -1 && form.timing.split(":")[1] }/>
                </div>
            </div>
            }

            {form.contacts && form.contacts.map((v, k) => {
                return < div className="input-group mb-3">
                    <span className="input-group-text">Contact {k + 1}</span>
                    <input className="form-control" list="emails" onChange={(e) => onContactChange(e, k)}
                        value={v} />
                    {k > 0 && <button className="btn btn-outline-danger" type="button"
                        onClick={() => removeContact(k)}>Supprimer</button>}
                </div>
            })}

            <datalist id="emails">
                {contacts.map((v, k) => { return <option value={v.email} /> })}
            </datalist>

            <div className="mb-3">
                <button type="button" className="btn btn-outline-secondary" onClick={addContact}>Ajouter</button>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" name="">Expéditeur</span>
                <input className="form-control" value={form.sender}
                    onChange={(e) => onTxtChange(e, 'sender')} required />
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" name="">Objet du mail</span>
                <input className="form-control" value={form.object}
                    onChange={(e) => onTxtChange(e, 'object')} required />
            </div>


            <div className="alert alert-info">
                <h6>Info</h6>
                <div>&#123; len() &#125; = nombre d'enregristrement</div>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" name="">Message d'introduction</span>
                <textarea className="form-control" value={form.intro}
                    onChange={(e) => onTxtChange(e, 'intro')} required />
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" name="">Description de l'alerte</span>
                <textarea className="form-control" value={form.description}
                    onChange={(e) => onTxtChange(e, 'description')} required />
            </div>

            <div className="text-center">
                <button className="btn btn-success" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Enregistrer</button>
            </div>
        </form>
    </div>
}

export default NightAlerts;