import { Fragment, useEffect, useState } from "react";
import Moment from "moment";

const getDay = (p) => {
    if (p === 0) return "monday_";
    if (p === 1) return "tuesday_";
    if (p === 2) return "wednesday_";
    if (p === 3) return "thursday_";
    if (p === 4) return "friday_";
    if (p === 5) return "saturday_";
    if (p === 6) return "sunday_";
    if (p === 7) return "total_";
}

/**
 * ROWS TO FILL BY USERS
 * 
 * @returns 
 */
const InstallerDay = (p) => {
    const [dNone, setDNone] = useState("");

    useEffect(() => {
        setDNone(p.minimalDisplay ? "d-none" : "")
    }, [p.minimalDisplay])

    var holiday = p.weekDays && p.weekDays[p.col] && p.weekDays[p.col].holiday !== "";

    return <Fragment>
        <td className={(p.col === 7 ? " no-change-bg " : holiday ? "holiday " : "") + (dNone ? "big-separator" : "")}>
            <div className="display-flex">
                <div className="form-floating">
                    <input type="number" className={"form-control" + (p.col === 7 ? " no-change-bg " : "")}
                        id={getDay(p.col) + "d_tee"} placeholder="xxx"
                        style={{ minWidth: "60px" }} autoComplete="off" onChange={p.onElementChange} data-key={p.row}
                        value={p.hours[p.row][getDay(p.col) + "d_tee"] || ""} readOnly={getDay(p.col).startsWith("total")}
                        min={0} />
                    <label>Jour</label>
                </div>

                {
                    (p.col === 7 && dNone) &&
                    <img className="cursor-pointer" src="/common/remove_icon.png" data-row={p.row}
                        style={{ width: "30px", height: "30px", margin: "auto" }} onClick={p.removeRow}></img>
                }
            </div>
        </td>
        <td className={(p.col === 7 ? " no-change-bg " : holiday ? "holiday " : "") + dNone}>
            <div className="form-floating">
                <input type="number" className={"form-control" + (p.col === 7 ? " no-change-bg " : "")}
                    id={getDay(p.col) + "d_tsc"} placeholder="xxx"
                    style={{ minWidth: "60px" }} autoComplete="off" onChange={p.onElementChange} data-key={p.row}
                    value={p.hours[p.row][getDay(p.col) + "d_tsc"] || ""} readOnly={getDay(p.col).startsWith("total")}
                    min={0} />
                <label>Jour</label>
            </div>
        </td>
        <td className={(p.col === 7 ? " no-change-bg " : holiday ? "holiday " : "") + dNone}>
            <div className="form-floating">
                <input type="number" className={"form-control" + (p.col === 7 ? " no-change-bg " : "")}
                    id={getDay(p.col) + "n_tsc"} placeholder="xxx"
                    style={{ minWidth: "60px" }} autoComplete="off" onChange={p.onElementChange} data-key={p.row}
                    value={p.hours[p.row][getDay(p.col) + "n_tsc"] || ""} readOnly={getDay(p.col).startsWith("total")}
                    min={0} />
                <label>Nuit</label>
            </div>
        </td>
        <td className={(p.col === 7 ? " no-change-bg " : holiday ? "holiday " : "") + dNone}>
            <div className="form-floating">
                <input type="number" className={"form-control" + (p.col === 7 ? " no-change-bg " : "")}
                    id={getDay(p.col) + "dat"} placeholder="xxx"
                    style={{ minWidth: "60px" }} autoComplete="off" onChange={p.onElementChange} data-key={p.row}
                    value={p.hours[p.row][getDay(p.col) + "dat"] || ""} readOnly={getDay(p.col).startsWith("total")}
                    min={0} />
                <label>Jour</label>
            </div>
        </td>
        <td className={(p.col === 7 ? " no-change-bg " : holiday ? "holiday " : "") + dNone}>
            <div className="form-floating">
                <input type="number" className={"form-control" + (p.col === 7 ? " no-change-bg " : "")}
                    id={getDay(p.col) + "dat_n"} placeholder="xxx"
                    style={{ minWidth: "60px" }} autoComplete="off" onChange={p.onElementChange} data-key={p.row}
                    value={p.hours[p.row][getDay(p.col) + "dat_n"] || ""} readOnly={getDay(p.col).startsWith("total")}
                    min={0} />
                <label>Nuit</label>
            </div>
        </td>
        <td className={(p.col === 7 ? " no-change-bg " : holiday ? "holiday " : "") + dNone}>
            <div className="form-floating">
                <input type="number" className={"form-control" + (p.col === 7 ? " no-change-bg " : "")}
                    id={getDay(p.col) + "dnt"} placeholder="xxx"
                    style={{ minWidth: "60px" }} autoComplete="off" onChange={p.onElementChange} data-key={p.row}
                    value={p.hours[p.row][getDay(p.col) + "dnt"] || ""} readOnly={getDay(p.col).startsWith("total")}
                    min={0} />
                <label>Jour</label>
            </div>
        </td>
        <td className={"big-separator " + (p.col === 7 ? " no-change-bg " : "") + (holiday ? " holiday " : "") + dNone}>
            <div className="display-flex">
                <div className="form-floating">
                    <input type="number" className={"form-control" + (p.col === 7 ? " no-change-bg " : "")}
                        id={getDay(p.col) + "dnt_n"} placeholder="xxx"
                        style={{ minWidth: "60px" }} autoComplete="off" onChange={p.onElementChange} data-key={p.row}
                        value={p.hours[p.row][getDay(p.col) + "dnt_n"] || ""} readOnly={getDay(p.col).startsWith("total")}
                        min={0} />
                    <label>Nuit</label>
                </div>

                {
                    (p.col === 7 ) &&
                    <img className="cursor-pointer" src="/common/remove_icon.png" data-row={p.row}
                        style={{ width: "30px", height: "30px", margin: "auto" }} onClick={p.removeRow}></img>
                }
            </div>
        </td>


    </Fragment>
}

/**
 * CALCULATION OF Sous-total heures pointées
 * 
 * @param {*} p 
 * @param {*} field 
 * @returns 
 */
const calculateSubset = (p, field) => {
    var res = 0;
    p.hours.map(v => { if (!isNaN(parseFloat(v[field]))) res += (parseFloat(v[field])); })

    return res;
}

/**
 * RENDERING OF Sous-total heures pointées
 * 
 * @param {*} p 
 * @returns 
 */
const SubTotal = (p) => {
    return <Fragment>
        <td className={p.minimalDisplay ? "big-separator" : ""}>{calculateSubset(p, getDay(p.col) + "d_tee").toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{calculateSubset(p, getDay(p.col) + "d_tsc").toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{calculateSubset(p, getDay(p.col) + "n_tsc").toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{calculateSubset(p, getDay(p.col) + "dat").toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{calculateSubset(p, getDay(p.col) + "dat_n").toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{calculateSubset(p, getDay(p.col) + "dnt").toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : "big-separator"}>{calculateSubset(p, getDay(p.col) + "dnt_n").toFixed(1)}</td>
    </Fragment>
}

/**
 * RENDERING OF Total heures pointées / jour
 * 
 * @param {*} p 
 * @returns 
 */
const TotHoursClockedInDay = (p) => {
    const calculate = () => {
        return calculateSubset(p, getDay(p.col) + "d_tee")
            + calculateSubset(p, getDay(p.col) + "d_tsc") + calculateSubset(p, getDay(p.col) + "n_tsc")
            + calculateSubset(p, getDay(p.col) + "dat") + calculateSubset(p, getDay(p.col) + "dat_n")
            + calculateSubset(p, getDay(p.col) + "dnt") + calculateSubset(p, getDay(p.col) + "dnt_n")
    }

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator fw-bold">{calculate().toFixed(1)} h</td>
    </Fragment>
}

/**
 * RENDERING FOR Total heures travaillées / jour
 * 
 * @param {*} p 
 * @returns 
 */
const TotHoursWorkdInDay = (p) => {
    const calculate = () => {
        return calculateSubset(p, getDay(p.col) + "d_tee")
            + calculateSubset(p, getDay(p.col) + "d_tsc") + calculateSubset(p, getDay(p.col) + "n_tsc")
    }

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator fw-bold">{calculate().toFixed(1)} h</td>
    </Fragment>
}

/**
 * RENDERING FOR Total heures travaillées ou assimilées / jour
 * 
 * @param {*} p 
 * @returns 
 */
const TotHoursWorkdOrAssimilatedInDay = (p) => {
    const calculate = () => {
        return calculateSubset(p, getDay(p.col) + "d_tee")
            + calculateSubset(p, getDay(p.col) + "d_tsc") + calculateSubset(p, getDay(p.col) + "n_tsc")
            + calculateSubset(p, getDay(p.col) + "dat") + calculateSubset(p, getDay(p.col) + "dat_n");
    }

    const getAddClass = () => {
        if (p.err["wrk_time_" + getDay(p.col)] !== undefined) return p.err["wrk_time_" + getDay(p.col)];
    }

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className={"big-separator fw-bold " + getAddClass()}>{calculate().toFixed(1)} h</td>
    </Fragment>
}

/**
 * RENDERING FOR Total majorations dimanches et fériés
 * 
 * @param {*} p 
 * @returns 
 */
const TotalSurcharges = (p) => {
    if (p.weekDays[p.col] === undefined) return;
    var o = surchargesObject(p);

    return <Fragment>
        <td className={p.minimalDisplay ? "big-separator" : ""}>{o[getDay(p.col) + "d_tee"].toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{o[getDay(p.col) + "d_tsc"].toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{o[getDay(p.col) + "n_tsc"].toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{o[getDay(p.col) + "dat"].toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{o[getDay(p.col) + "dat_n"].toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{o[getDay(p.col) + "dnt"].toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : "big-separator"}>{o[getDay(p.col) + "dnt_n"].toFixed(1)}</td>
    </Fragment>
}

const TotalTotalSurcharges = (p) => {
    var o = surchargesObject({ col: 0, ...p });
    Object.assign(
        o, surchargesObject({ col: 1, ...p }), surchargesObject({ col: 2, ...p }),
        surchargesObject({ col: 3, ...p }), surchargesObject({ col: 4, ...p }),
        surchargesObject({ col: 5, ...p }), surchargesObject({ col: 6, ...p })
    );

    return <Fragment>
        <td className={p.minimalDisplay ? "big-separator" : ""}>{(o.monday_d_tee + o.tuesday_d_tee + o.wednesday_d_tee + o.thursday_d_tee + o.friday_d_tee + o.saturday_d_tee + o.sunday_d_tee).toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{(o.monday_d_tsc + o.tuesday_d_tsc + o.wednesday_d_tsc + o.thursday_d_tsc + o.friday_d_tsc + o.saturday_d_tsc + o.sunday_d_tsc).toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{(o.monday_n_tsc + o.tuesday_n_tsc + o.wednesday_n_tsc + o.thursday_n_tsc + o.friday_n_tsc + o.saturday_n_tsc + o.sunday_n_tsc).toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{(o.monday_dat + o.tuesday_dat + o.wednesday_dat + o.thursday_dat + o.friday_dat + o.saturday_dat + o.sunday_dat).toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{(o.monday_dat_n + o.tuesday_dat_n + o.wednesday_dat_n + o.thursday_dat_n + o.friday_dat_n + o.saturday_dat_n + o.sunday_dat_n).toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : ""}>{(o.monday_dnt + o.tuesday_dnt + o.wednesday_dnt + o.thursday_dnt + o.friday_dnt + o.saturday_dnt + o.sunday_dnt).toFixed(1)}</td>
        <td className={p.minimalDisplay ? "d-none" : "big-separator"}>{(o.monday_dnt_n + o.tuesday_dnt_n + o.wednesday_dnt_n + o.thursday_dnt_n + o.friday_dnt_n + o.saturday_dnt_n + o.sunday_dnt_n).toFixed(1)}</td>
    </Fragment>
}

function surchargesObject(p) {
    var isHoliday = p.weekDays[p.col].holiday !== "" || p.col === 6;
    var o = new Object();
    o[getDay(p.col) + "d_tee"] = !isHoliday ? 0 : calculateSubset(p, getDay(p.col) + "d_tee");
    o[getDay(p.col) + "d_tsc"] = !isHoliday ? 0 : calculateSubset(p, getDay(p.col) + "d_tsc");
    o[getDay(p.col) + "n_tsc"] = !isHoliday ? 0 : calculateSubset(p, getDay(p.col) + "n_tsc");
    o[getDay(p.col) + "dat"] = !isHoliday ? 0 : calculateSubset(p, getDay(p.col) + "dat");
    o[getDay(p.col) + "dat_n"] = !isHoliday ? 0 : calculateSubset(p, getDay(p.col) + "dat_n");
    o[getDay(p.col) + "dnt"] = !isHoliday ? 0 : calculateSubset(p, getDay(p.col) + "dnt");
    o[getDay(p.col) + "dnt_n"] = !isHoliday ? 0 : calculateSubset(p, getDay(p.col) + "dnt_n");
    return o;
}

const getDayAbs = (p) => {
    if (p === 0) return "l";
    if (p === 1) return "m";
    if (p === 2) return "mm";
    if (p === 3) return "j";
    if (p === 4) return "v";
    if (p === 5) return "s";
    if (p === 6) return "d";
}

/**
 * RENDERING FOR Motif absence
 * @param {*} p 
 */
const Absences = (p) => {
    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator">
            {
                p.col <= 4 &&
                <select className="form-select text-center" value={p.abs["abs_" + getDayAbs(p.col)]}
                    id={"abs_" + getDayAbs(p.col)} onChange={p.onAbsChange}>
                    <option value="none"></option>
                    <option value="cp">Congé payé</option>
                    <option value="cp_half">1/2 Congé payé</option>
                    <option value="rttEmpr">RTT employeur</option>
                    <option value="rttEmpr_half">1/2 RTT employeur</option>
                    <option value="rttEmpe">RTT employé</option>
                    <option value="rttEmpe_half">1/2 RTT employé</option>
                    <option value="ss">Congés sans solde</option>
                    <option value="sp">Congés spéciaux</option>
                    <option value="c2">Récup. C2</option>
                    <option value="maladie">Arrêt maladie</option>
                    <option value="ct_end">Fin de contrat</option>
                    <option value="delay">Retard</option>
                    <option value="not_allowed_abs">Abs. injustifiée</option>
                    <option value="allowed_abs">Abs. autorisée</option>
                    <option value="other">Autre</option>
                </select>
            }
        </td>
    </Fragment>
}

/**
 * RENDERING FOR Lieux de travail
 * @param {*} p 
 */
const Location = (p) => {

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator">
            {
                p.col <= 6 &&
                <select className="form-select text-center" value={p.abs["loc_" + getDayAbs(p.col)]}
                    id={"loc_" + getDayAbs(p.col)} onChange={p.onAbsChange}>
                    <option value=""></option>
                    <option value="gemenos">Gémenos</option>
                    <option value="training">Formation</option>
                    <option value="home_w">Télétravail</option>
                    <option value="yard">Chantier</option>
                    {
                        p.col >= 5 && <option value="moving">WE déplacement non travaillé</option>
                    }
                </select>
            }
        </td>
    </Fragment>
}

/**
 * RENDERING FOR Chantier France / Etranger ?	
 * @param {*} p 
 */
const Yard = (p) => {

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator">
            {
                p.col <= 6 &&
                <select className="form-select text-center" id={"yard_" + getDayAbs(p.col)}
                    onChange={p.onAbsChange} value={p.abs["yard_" + getDayAbs(p.col)]}>
                    <option value="none"></option>
                    <option value="france">Chantier France</option>
                    <option value="foreign">Chantier étranger</option>
                </select>
            }
        </td>
    </Fragment>
}

/**
 * RENDERING FOR Prime Eloignement ?	
 * @param {*} p 
 */
const Distance = (p) => {

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator">
            {
                p.col <= 6 &&
                <select className="form-select text-center" id={"dist_" + getDayAbs(p.col)}
                    onChange={p.onAbsChange} value={p.abs["dist_" + getDayAbs(p.col)]}>
                    <option value="none"></option>
                    <option value="distance">Éloignement</option>
                </select>
            }
        </td>
    </Fragment>
}

/**
 * RENDERING FOR Total Primes Chantier
 *  
 * @param {*} p 
 * @returns 
 */
var totYard = 0;
const YardBonus = (p) => {
    /*var yard = p.col <= 6 && p.abs["yard_" + getDayAbs(p.col)] !== null && p.abs["yard_" + getDayAbs(p.col)] !== "none";
    var distance = p.col <= 6 && p.abs["dist_" + getDayAbs(p.col)] !== null && p.abs["dist_" + getDayAbs(p.col)] !== "none";
    var total = (yard && p.abs["yard_" + getDayAbs(p.col)] === "foreign") ? 21.03
        : (yard && p.abs["yard_" + getDayAbs(p.col)] === "france") ? 18.03 : 0;
    total += (distance && p.abs["dist_" + getDayAbs(p.col)] === "distance") ? 18.03 : 0;*/

    /** New rules 2024-01-25: 
     *  -	Chantier =70€ / j
        -	Chantier WE ou férié = 120€ /S ou D ou Fé
        -	WE non travaillé en déplacement (si renseigné le samedi et le dimanche = 200€ le dimanche)
          -> si un des 2 jours travaillé seul -> 120€
          -> si les 2 jour travaillés ou 
    */

    if (p.col === 0) totYard = 0;
    function getBonus() {
        var isYard = p.abs["loc_" + getDayAbs(p.col)] === "yard";
        var isMoving = p.abs["loc_" + getDayAbs(p.col)] === "moving";
        var yard = 0;

        if (p.col < 5 && p.weekDays[p.col].holiday !== 'Férié' ) {
            yard = isYard ? 70 : 0;
        } else {
            if (isMoving) {
                yard = 100;
            } else if (isYard) {
                yard = 120;
            } else {
                return 0;
            }
        }

        totYard += yard;
        return yard;
    }

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator yard">
            {p.col <= 6 && <span>{getBonus()} €</span>}
            {p.col > 6 && <span>{totYard} €</span>}
        </td>
    </Fragment>
}

{/** RENDERING FOR Droit Nb de Ticket restaurant */ }
var totTr = 0;
const RestaurantTicket = (p) => {
    const calculate = () => {
        return calculateSubset(p, getDay(p.col) + "d_tee")
            + calculateSubset(p, getDay(p.col) + "d_tsc") + calculateSubset(p, getDay(p.col) + "n_tsc")
            + calculateSubset(p, getDay(p.col) + "dat") + calculateSubset(p, getDay(p.col) + "dat_n");
    }

    const getRight = () => {
        if (p.col === 0) totTr = 0;

        var lunch = p.abs["lunch_" + getDayAbs(p.col) ];
        if( lunch !== null && lunch !== "" && parseFloat(lunch) > 0 ) return 0;

        if (p.abs["loc_" + getDayAbs(p.col)] === "gemenos"
            || p.abs["loc_" + getDayAbs(p.col)] === "home_w") {
            var tr = calculate() > 4 ? 1 : 0;
            totTr += tr;
            return tr;
        } else {
            return 0;
        }
    }

    return <Fragment>
        <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator ticket">
            {p.col < 7 && getRight()}
            {p.col === 7 && totTr}
        </td>
    </Fragment>
}

{/** RENDERING FOR Droit Nb de Ticket restaurant */ }
const InterimCharges = (p) => {

    function calculateTotal(field) {
        var tot = 0;
        var cur;
        for (let i = 0; i < 7; i++) {
            cur = p.abs[field + "_" + getDayAbs(i)];
            if (cur !== null && cur !== "") tot += parseFloat(p.abs[field + "_" + getDayAbs(i)]);
        }


        return tot;
    }

    return <Fragment>
        {/** Frais repas petit-déjeuner */}
        <tr className={p.minimalDisplay ? "d-none" : ""}>
            <td className="text-end interim-charges">Frais repas petit-déjeuner</td>
            {[...Array(7)].map((v, k) => {
                return <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges">
                    <input type="number" className="form-control text-center"
                        id={"break_fast_" + getDayAbs(k)} onChange={p.onAbsChange}
                        value={p.abs["break_fast_" + getDayAbs(k)]}
                        style={{ maxWidth: "100px", margin: "auto" }}></input>
                </td>
            })}
            <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges text-center fw-bold">
                {calculateTotal("break_fast")}</td>
        </tr>

        {/** Frais repas déjeuner */}
        <tr className={p.minimalDisplay ? "d-none" : ""}>
            <td className="text-end interim-charges">Frais repas déjeuner</td>
            {[...Array(7)].map((v, k) => {
                return <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges">
                    <input type="number" className="form-control text-center"
                        id={"lunch_" + getDayAbs(k)} onChange={p.onAbsChange}
                        value={p.abs["lunch_" + getDayAbs(k)]}
                        style={{ maxWidth: "100px", margin: "auto" }}></input>
                </td>
            })}
            <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges text-center fw-bold">
                {calculateTotal("lunch")}</td>
        </tr>

        {/** Frais repas diner */}
        <tr className={p.minimalDisplay ? "d-none" : ""}>
            <td className="text-end interim-charges">Frais repas diner</td>
            {[...Array(7)].map((v, k) => {
                return <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges">
                    <input type="number" className="form-control text-center"
                        id={"dinner_" + getDayAbs(k)} onChange={p.onAbsChange}
                        value={p.abs["dinner_" + getDayAbs(k)]}
                        style={{ maxWidth: "100px", margin: "auto" }}></input>
                </td>
            })}
            <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges text-center fw-bold">
                {calculateTotal("dinner")}</td>
        </tr>

        {/** Frais transport */}
        <tr className={p.minimalDisplay ? "d-none" : ""}>
            <td className="text-end interim-charges">Frais transport</td>
            {[...Array(7)].map((v, k) => {
                return <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges">
                    <input type="number" className="form-control text-center"
                        id={"transport_" + getDayAbs(k)} onChange={p.onAbsChange}
                        value={p.abs["transport_" + getDayAbs(k)]}
                        style={{ maxWidth: "100px", margin: "auto" }}></input>
                </td>
            })}
            <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges text-center fw-bold">
                {calculateTotal("transport")}</td>
        </tr>

        {/** Frais hôtel */}
        <tr className={p.minimalDisplay ? "d-none" : ""}>
            <td className="text-end interim-charges">Frais hôtel</td>
            {[...Array(7)].map((v, k) => {
                return <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges">
                    <input type="number" className="form-control text-center"
                        id={"hostel_" + getDayAbs(k)} onChange={p.onAbsChange}
                        value={p.abs["hostel_" + getDayAbs(k)]}
                        style={{ maxWidth: "100px", margin: "auto" }}></input>
                </td>
            })}
            <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges text-center fw-bold">
                {calculateTotal("hostel")}</td>
        </tr>

        {/** Autres Frais */}
        <tr className={p.minimalDisplay ? "d-none" : ""}>
            <td className="text-end interim-charges">Autres Frais</td>
            {[...Array(7)].map((v, k) => {
                return <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges">
                    <input type="number" className="form-control text-center"
                        id={"others_charges_" + getDayAbs(k)} onChange={p.onAbsChange}
                        value={p.abs["others_charges_" + getDayAbs(k)]}
                        style={{ maxWidth: "100px", margin: "auto" }}></input>
                </td>
            })}
            <td colSpan={p.minimalDisplay ? 1 : 7} className="big-separator interim-charges text-center fw-bold">
                {calculateTotal("others_charges")}</td>
        </tr>

    </Fragment>
}

/**
 * RENDERING FOR Prime Exeptionnelle
 *  
 * @param {*} p 
 * @returns 
 */
const ExceptionalBonus = (p) => {

    function calculateTotal() {
        var total = 0;
        if (p.abs.emergency_move === "on") total = p.abs.emergency_move_loc === "france" ? 31.68 : 34.32;
        if (p.abs.awake_early === "on") total += 30;
        if (p.abs.come_later === "on") total += 30;

        //Add yard bonus
        for (let i = 0; i < 7; i++) {
            var yard = p.abs["yard_" + getDayAbs(i)] !== "none";
            var distance = p.abs["dist_" + getDayAbs(i)] !== "none";
            total += (yard && p.abs["yard_" + getDayAbs(i)] === "foreign") ? 21.03
                : (yard && p.abs["yard_" + getDayAbs(i)] === "france") ? 18.03 : 0;
            total += (distance && p.abs["dist_" + getDayAbs(i)] === "distance") ? 18.03 : 0;
        }

        return total;
    }


    return <Fragment>
        {/** Prime déplacement urgent */}
        <td colSpan={p.minimalDisplay ? 2 : 14} className="big-separator">
            <div className="input-group mb-3">
                <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox"
                        value={p.abs.emergency_move === "on" ? "none" : "on"}
                        id="emergency_move" onChange={p.onAbsChange} checked={p.abs.emergency_move === "on"} />
                </div>
                <input type="text" className="form-control" value="Prime déplacement urgent" readOnly />
                {
                    p.abs.emergency_move === "on" && <Fragment>
                        <select className="form-select" id="emergency_move_loc" defaultValue={p.abs.emergency_move_loc}
                            onChange={p.onAbsChange}>
                            <option value="">Choose...</option>
                            <option value="france">France</option>
                            <option value="foreign">Etranger</option>
                        </select>
                        <input type="text" className="form-control fw-bold text-center" value={p.abs.emergency_move_loc === "france" ? "31.68€" :
                            p.abs.emergency_move_loc === "foreign" ? "34.32€" : "0€"} readOnly />
                    </Fragment>
                }
            </div>
        </td>

        {/** Prime levée tôt */}
        <td colSpan={p.minimalDisplay ? 2 : 14} className="big-separator">
            <div className="input-group mb-3">
                <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox"
                        value={p.abs.awake_early === "on" ? "none" : "on"}
                        id="awake_early" onChange={p.onAbsChange} checked={p.abs.awake_early === "on"} />
                </div>
                <input type="text" className="form-control" value="Prime levée tôt" readOnly />
                {
                    p.abs.awake_early === "on" &&
                    <input type="text" className="form-control fw-bold text-center" value="30€" readOnly />
                }

            </div>
        </td>

        {/** Prime de rentrée tard de mission */}
        <td colSpan={p.minimalDisplay ? 3 : 14} className="big-separator">
            <div className="input-group mb-3">
                <div className="input-group-text">
                    <input className="form-check-input mt-0" type="checkbox"
                        value={p.abs.come_later === "on" ? "none" : "on"}
                        id="come_later" onChange={p.onAbsChange} checked={p.abs.come_later === "on"} />
                </div>
                <input type="text" className="form-control" value="Prime de rentrée tard de mission" readOnly />
                {
                    p.abs.come_later === "on" &&
                    <input type="text" className="form-control fw-bold text-center" value="30€" readOnly />
                }

            </div>
        </td>

        <td colSpan={14} className="big-separator text-center fw-bold">
            Total prime à payer: {calculateTotal()} €
        </td>
    </Fragment>
}

const calculateTotalDayClocked = (p) => {
    return calculateSubset(p, getDay(p.col) + "d_tee")
        + calculateSubset(p, getDay(p.col) + "d_tsc") + calculateSubset(p, getDay(p.col) + "n_tsc")
        + calculateSubset(p, getDay(p.col) + "dat") + calculateSubset(p, getDay(p.col) + "dat_n")
        + calculateSubset(p, getDay(p.col) + "dnt") + calculateSubset(p, getDay(p.col) + "dnt_n")
}

const calculateSumarry = (abs, hours, summary, setSumarry, weekDays) => {

    var o = { ...summary };
    var p = { hours: hours };

    // Temps de travail effectif
    var work_time = calculateSubset(p, "total_d_tee")
        + calculateSubset(p, "total_d_tsc") + calculateSubset(p, "total_n_tsc")
    o.work_time = work_time;

    // Temps assimilé
    var wrk_ass = 0.0;
    var tot_dat = calculateSubset(p, "total_dat") + calculateSubset(p, "total_dat_n");
    var tot_dnt = calculateSubset(p, "total_dnt") + calculateSubset(p, "total_dnt_n");
    var tot_schedule = 0;
    weekDays && weekDays.map(v => tot_schedule += v.schedule);
    o.tot_schedule = tot_schedule;
    var complement_move = 0;

    if (tot_dat === 0) {
        if (work_time < tot_schedule) {
            if (tot_dnt > (tot_schedule - work_time)) {
                wrk_ass = tot_schedule - work_time;
            } else {
                wrk_ass = tot_dnt;
            }
        } else {
            wrk_ass = 0;
        }
    } else {
        if ((tot_dat + work_time) < tot_schedule) {
            if (tot_dnt > (tot_schedule - work_time - tot_dat)) {
                complement_move = tot_schedule - work_time - tot_dat;
            } else {
                complement_move = tot_dnt;
            }
        }

        wrk_ass = tot_dat + complement_move;
    }
    o.wrk_or_ass = wrk_ass;

    // Temps de travail effectif ou considéré assimilé
    var tot_hours_clocked_day = work_time + tot_dat + tot_dnt;
    var cons_wrk_or_ass = (work_time + wrk_ass) >= tot_schedule ? (work_time + wrk_ass)
        : Math.min(tot_schedule, tot_hours_clocked_day);
    o.cons_wrk_or_ass = cons_wrk_or_ass;

    // Temps considéré comme du trajet
    o.move = tot_hours_clocked_day - cons_wrk_or_ass;

    //Heures excédentaires (***) 
    o.exc_hours = Math.max((cons_wrk_or_ass.toFixed(1) - tot_schedule.toFixed(1)), 0);

    // Total congés - days
    var abs_tot_d = 0.0;
    var abs_c1 = 0, abs_c2 = 0, abs_disease = 0;
    var tot_day_clocked;
    weekDays && weekDays.map((v, k) => {
        tot_day_clocked = calculateTotalDayClocked({ col: k, hours: hours });

        if (abs["abs_" + getDayAbs(k)] === "maladie") abs_disease++; //Special case
        if (v.schedule > 0) {
            abs_tot_d += (v.schedule - tot_day_clocked) / v.schedule;
            if (abs["abs_" + getDayAbs(k)] === "c1") abs_c1 += v.schedule - tot_day_clocked;
            if (abs["abs_" + getDayAbs(k)] === "c2") abs_c2 += v.schedule - tot_day_clocked;
        }

    });
    o.abs_tot_d = Math.max(abs_tot_d, 0);
    o.abs_c1 = abs_c1;
    o.abs_c2 = abs_c2;
    o.abs_disease = abs_disease;

    // Total congés - hours
    o.abs_tot_h = Math.max((tot_schedule - tot_hours_clocked_day), 0);

    var p = { hours: hours, weekDays: weekDays };
    var s = surchargesObject({ col: 0, ...p });
    Object.assign(
        s, surchargesObject({ col: 1, ...p }), surchargesObject({ col: 2, ...p }),
        surchargesObject({ col: 3, ...p }), surchargesObject({ col: 4, ...p }),
        surchargesObject({ col: 5, ...p }), surchargesObject({ col: 6, ...p })
    );

    /********* TO PAY CALCULATION */
    // Heures excédentaires sur chantier à payer (à 125%)
    var tot_tsc = calculateSubset(p, "total_d_tsc") + calculateSubset(p, "total_n_tsc");
    var p125 = Math.min(Math.max(tot_tsc - 38.0, 0), 5);

    //Heures excédentaires sur chantier à payer (à 150%)
    var p150 = Math.min(Math.max(tot_tsc - 43, 0), 5);

    //Heures excédentaires sur chantier à payer (à 100%)
    var p100 = Math.max((tot_tsc - tot_schedule) - p125 - p150, 0);

    //Majorations heures de D et fériés sur chantier à payer (à 100%)
    var p_d_100 = s.monday_d_tsc + s.tuesday_d_tsc + s.wednesday_d_tsc + s.thursday_d_tsc
        + s.friday_d_tsc + s.saturday_d_tsc + s.sunday_d_tsc
        + s.monday_n_tsc + s.tuesday_n_tsc + s.wednesday_n_tsc + s.thursday_n_tsc
        + s.friday_n_tsc + s.saturday_n_tsc + s.sunday_n_tsc;
    o.p_d_100 = p_d_100;

    /*************** C1 add to pay *************************/
    // Heures supplémentaires en entreprise à passer en récup. compteur C1 (à 125%)
    var c1_125 = Math.min(Math.max(cons_wrk_or_ass - 38 - p125 - p150 - p100, 0), 5);
    o.c1_125 = c1_125;

    // Heures supplémentaires en entreprise à passer en récup. compteur C1 (à 150%)
    var c1_150 = Math.min(Math.max(cons_wrk_or_ass - p100 - p125 - p150 - c1_125 - 38, 0), 5);
    o.c1_150 = c1_150;

    // Heures excédentaires en entreprise à passer en récup. compteur C1 (à 100%)
    var c1_100 = Math.max(o.exc_hours - p100 - p125 - p150 - c1_125 - c1_150, 0);
    o.c1_100 = c1_100;

    //Majorations heures de nuit sur chantier à payer (à 50%)
    var p50 = calculateSubset(p, "total_n_tsc") + calculateSubset(p, "total_dat_n");
    o.p50 = p50;

    // Majorations heures de D et fériés en entreprise à passer en récup. compteur C1 (à 100%)
    var majD = s.monday_d_tee + s.tuesday_d_tee + s.wednesday_d_tee + s.thursday_d_tee
        + s.friday_d_tee + s.saturday_d_tee + s.sunday_d_tee

        + s.monday_d_tsc + s.tuesday_d_tsc + s.wednesday_d_tsc + s.thursday_d_tsc
        + s.friday_d_tsc + s.saturday_d_tsc + s.sunday_d_tsc
        + s.monday_n_tsc + s.tuesday_n_tsc + s.wednesday_n_tsc + s.thursday_n_tsc
        + s.friday_n_tsc + s.saturday_n_tsc + s.sunday_n_tsc

        + s.monday_dat + s.tuesday_dat + s.wednesday_dat + s.thursday_dat
        + s.friday_dat + s.saturday_dat + s.sunday_dat
        + s.monday_dat_n + s.tuesday_dat_n + s.wednesday_dat_n + s.thursday_dat_n
        + s.friday_dat_n + s.saturday_dat_n + s.sunday_dat_n;
    o.majD = majD;

    // TOTAL A CREDITER SUR COMPTEUR C1
    var tot_c1 = c1_100 + (c1_125 * 1.25) + (c1_150 * 1.5) + majD;
    o.tot_c1 = tot_c1;

    /************* MERGED TO PAY */
    // Heures excédentaires sur chantier à payer (à 125%)
    o.p125 = p125 + c1_125;
    //Heures excédentaires sur chantier à payer (à 150%)
    o.p150 = p150 + c1_150;
    //Heures excédentaires sur chantier à payer (à 100%)
    o.p100 = p100 + c1_100;
    //Majorations heures de D et fériés sur chantier à payer (à 100%)
    o.p_d_100 = p_d_100 + majD
    //Total A PAYER	
    var tot_p = p100 + (p125 * 1.25) + (p150 * 1.5) + (p50 * 0.5) + p_d_100;
    o.tot_p = tot_p + tot_c1;


    // Heures de trajet à passer en récup. compteur C2 (à 100%)
    o.c2_100 = o.move;

    // Majorations heures de nuit en DnT à passer en récup. compteur C1 (à 25%)
    o.c2_25 = calculateSubset(p, "total_dnt_n");

    // Majorations heures de trajet de D et fériés à passer en récup. compteur C2 (à 50%)
    o.c2_50 = s.monday_dnt + s.tuesday_dnt + s.wednesday_dnt + s.thursday_dnt
        + s.friday_dnt + s.saturday_dnt + s.sunday_dnt
        
        +s.monday_dnt_n + s.tuesday_dnt_n + s.wednesday_dnt_n + s.thursday_dnt_n
        + s.friday_dnt_n + s.saturday_dnt_n + s.sunday_dnt_n;

    // TOTAL A CREDITER SUR COMPTEUR C2
    o.tot_c2 = o.c2_100 + (o.c2_50 * 0.5) + (o.c2_25 * 0.25);

    setSumarry(o);
}

/**
 * 
 * @param {*} err 
 * @param {*} setErr 
 * @param {*} weekDays 
 * @param {*} hours 
 * @param {*} summary 
 * @param {*} setError 
 * @param {*} abs 
 */
const checkConstrainsts = (err, setErr, weekDays, hours, summary, setError, abs) => {
    var arr = new Object();
    var strErr = "";

    // Plafond : 48h
    if (summary.work_time > 48) {
        arr.work_time = "Le plafond hebdomadaire est de 48h !";
        strErr += "Le plafond hebdomadaire est de 48h !<br>";
    }

    // Compare time worked with normal schedule
    var res;
    var p = { hours: hours };
    for (let i = 0; i < 7; i++) {
        res = calculateSubset(p, getDay(i) + "d_tee")
            + calculateSubset(p, getDay(i) + "d_tsc") + calculateSubset(p, getDay(i) + "n_tsc")
            + calculateSubset(p, getDay(i) + "dat") + calculateSubset(p, getDay(i) + "dat_n")

        if (res > 10) {
            arr["wrk_time_" + getDay(i)] = "danger";
            if (strErr.indexOf("Le plafond de travail quotidien est de 10h !") === -1)
                strErr += "Le plafond de travail quotidien est de 10h !<br>"
        } else if (weekDays[i].schedule.toFixed(1) !== res.toFixed(1)) {
            arr["wrk_time_" + getDay(i)] = "warning";
        }
    }

    // Check if there is hours of night and comments written
    res = calculateSubset(p, "total_n_tsc") + calculateSubset(p, "total_dat_n") + calculateSubset(p, "total_dnt_n");
    if (res > 0) {
        arr.hasNight = true;
        if (!abs.comment_night || abs.comment_night.trim() === "") {
            arr.night_justif = "danger";
            strErr += "Vous devez préciser les horaires des heures de nuit pour chaque jour concerné<br>"
        }
    }

    // Checks if there is exceed hours and comments written
    if (summary.exc_hours > 0) {
        arr.hasExc = true;
        if (!abs.comment_exceed || abs.comment_exceed.trim() === "") {
            arr.over_justif = "danger";
            strErr += "Vous devez préciser qui a donné l'accord d'heures sup et la date d'obtention de cet accord<br>"
        }
    }

    // Checks if there movement and comments written
    res = calculateSubset(p, "total_dat");
    if (res > 0) {
        arr.hasMove = true;
        if (!abs.comment_journey || abs.comment_journey.trim() === "") {
            arr.move_justif = "danger";
            strErr += "Vous devez justifier pourquoi le déplacement est assimilable à du travail effectif<br>"
        }
    }

    // Checks comments if others charges > 0
    function getChg(f){ return ( abs["others_charges_"+f] === null || abs["others_charges_"+f] === "" ) ?  0 : parseFloat(abs["others_charges_"+f])  }
    if( ( getChg("l") + getChg("m") + getChg("mm") + getChg("j") + getChg("v") + getChg("s") + getChg("d") ) > 0 ){
        arr.hasOthCharges = "danger";
        if(abs.comment_oth_charges === null || abs.comment_oth_charges === "" ){
            strErr += "Détaillez la nature des autres frais mentionnés en commentaires";
        }
    }
        

    setError(strErr !== "" ? strErr : undefined);

    setErr(arr);
}


/**
 * 
 * @param {*} weekDays 
 * @param {*} hours 
 * @param {*} summary 
 * @param {*} abs 
 * @returns 
 */
const checkBeforSubmit = (weekDays, hours, summary, abs) => {
    var err = "";

    var tot = 0;

    // If there is any hour set, check if business, activity and function are completed
    for( let i=0; i<hours.length; i++ ){
        var v = hours[i];
        
        tot = v.total_d_tee + v.total_d_tsc + v.total_n_tsc + v.total_dat + v.total_dat_n + v.total_dnt + v.total_dnt_n;
        
        //console.log( tot, ( v.business !== null ), (  !v.function || v.function === null || v.function === "" ) )
        if ( tot && tot > 0 && 
                ( v.business === null || v.activity === null || v.activity === "" || !v.function || v.function === null || v.function === "") ) {
            err = "Vous devez renseigner le code affaire, code activité et code fontion avant de valider.<br>";
            break;
        }else if( tot === 0 && ( v.business !== null ) && (  !v.function || v.function === null || v.function === "" ) ){
            //Avoid writing business code without machine
            err = "Il semble que vous ayez écrit le code affaire sans le code fonction ni heure. Effacez le code affaire ou remplissez le code fonction et les heures.<br>";
            break;
        }
    }

    // If absence check if justification is set
    var p;
    for (let i = 0; i < 5; i++) {
        p = { hours: hours, col: i };
        var worked = calculateSubset(p, getDay(p.col) + "d_tee")
            + calculateSubset(p, getDay(p.col) + "d_tsc") + calculateSubset(p, getDay(p.col) + "n_tsc")
            + calculateSubset(p, getDay(p.col) + "dat") + calculateSubset(p, getDay(p.col) + "dat_n")
            + calculateSubset(p, getDay(p.col) + "dnt") + calculateSubset(p, getDay(p.col) + "dnt_n");
        var absJust = abs["abs_" + getDayAbs(i)];

        if (weekDays[i].schedule > worked && (absJust === null || absJust === "none")) {
            err += "Pour chaque jour ayant un pointage inférieur à l'heure normale, justifiez le type d'absence.<br>";
            break;
        }
    }

    // Check if location is set
    for (let i = 0; i < 7; i++) {
        p = { hours: hours, col: i };
        var absJust = abs["abs_" + getDayAbs(i)];
        var location = abs["loc_" + getDayAbs(i)];

        if (weekDays[i].schedule > 0 && (absJust === null || absJust === "none") && (location === null || absJust === "")) {
            err += "Veuillez saisir soit votre lieu de travail, soit un motif d'absence.<br>";
            break;
        }
    }


    return err;
}

export {
    InstallerDay,
    SubTotal,
    TotHoursClockedInDay,
    TotHoursWorkdInDay,
    TotHoursWorkdOrAssimilatedInDay,
    TotalSurcharges,
    TotalTotalSurcharges,
    Absences,
    Location,
    Yard,
    YardBonus,
    Distance,
    ExceptionalBonus,
    calculateSumarry,
    checkConstrainsts,
    checkBeforSubmit,
    RestaurantTicket,
    InterimCharges
}