import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { cleanRef, currentFrDate } from "../../functions/StrFunctions";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import CategoriesGuide from "./smallComp/CategoriesGuide";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";
import { CustomToolTipInfo } from "../../common/smallComponents.js/CustomToolTip";
import { getUnitConversion } from "../../functions/Calculations";

const CreateArticle = (props) => {
    const { t } = useTranslation('article');
    const [id, setId] = useState();
    const [articleInfo, setArticleInfo] = useState();
    const [existing, setExisting] = useState();
    const [oldRef, setOldRef] = useState([]);
    const [oldRefResponse, setOldRefResponse] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [err, setErr] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(-1);
    const [saving, setSaving] = useState(false);
    const [currency, setCurrency] = useState();
    const [unit, setUnit] = useState();
    const [supplierSearch, setsupplierSearch] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [readonly] = useState(user && !user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_ART_CHANGE"));
    const [purchase] = useState(user && user.roles.includes("ROLE_PURCHASE"));
    const machUsing = { "in": 16, "wn": 0.2, "ln": 0.2, "ik": 1, "telp": 2, "ec": 5 }
    const mandatoriesField = ["zn02pa", "zn01pa", "des1l", "gebel", "tgesl", "zn03pa", "chapl", "typrl",
        "cfaml", "catrl", "cdunl", "pxrel", "zn17pa", "qreal", "stmil", "delrl", "stsel", "supplier", "price", "currency",
        "unit_supplier", "discount", "conversion", "in", "wn", "ln", "ik", "telp", "ec"];
    const [displayOldBlocked, setDisplayOldBlocked] = useState(false);
    const [drawing, setDrawing] = useState();
    const [searchDrawing, setSearchDrawing] = useState();
    const token = user && "Bearer " + user.accessToken;

    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    Moment.locale("fr");

    useEffect(() => {
        var id = getUrlParam(props, "id");
        setId(id);

        ArticleInfo.getArticleToCreate(id).then(
            (response) => {
                if (!purchase && id === null) {
                    response.article.supplier = "ADEFINIR";
                    response.article.supplier_name = "FRN à définir";
                    response.article.price = 0.01;
                }

                setArticleInfo(response.article);

                setCurrency(response.currency);
                setUnit(response.unit);

                checkIfRefExists(response.article);

                //Get old ref if exists
                var value = response.article.zn02pa || "not found ref";

                console.log(value)

                    ArticleInfo.getOldRef(value).then(
                        (response) => {
                            setOldRef(response);
                            setOldRefResponse(true);
                        },
                        (error) => {
                            /*setError(errorManagement.handleError(props, error));
                            scrollToTop();*/
                            setOldRef([]);
                        }
                    );
                


                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );


    }, []);

    const checkIfRefExists = (articleInfo) => {
        if (!articleInfo.zn02pa || articleInfo.zn02pa.trim() === "") return;
        setExisting();

        ArticleInfo.getAllArtInfoStrictSearch(articleInfo.zn02pa).then(
            (response) => {
                setExisting(response.article.CDARL);
                setError("Il existe au moins un article ayant cette référence: " + response.article.CDARL);
            }, (error) => {
                setError();
            }
        );
    }

    /**
     * Change tab
     * @param {*} e 
     */
    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    /**
     * Update basic info
     * @param {*} e 
     */
    const updateArticle = (e) => {
        var field = e.target.getAttribute("data-field");
        var value;

        if ((field === "visil" || field === "gebel")) {
            value = e.target.checked ? "O" : "N";
        } else {
            value = e.target.value;
        }

        var o = { ...articleInfo };
        o[field] = value;

        setAutomaticRules(o, field, value);
    }

    /**
     * Update old ref
     * @param {*} e 
     */
    const updateOldRef = (e) => {
        var field = e.target.getAttribute("data-field");
        var key = e.target.getAttribute("data-key");
        var value = e.target.value;

        var arr = [...oldRef];

        /*if (field === "com_ipt_user") {
            arr[key].com_ipt = getOldChangeCom(arr[key]) + value;
        } else {*/
            arr[key][field] = value;
            //arr[key].com_ipt = getOldChangeCom(arr[key]);
        //}

        var toLog = arr[key].toLog || [];
        if (!toLog.includes(field)) toLog.push(field);

        arr[key].toLog = toLog;

        setOldRef(arr);
    }

    /** Separate user comments and system comments */
    function getUserComment(val) {
        if (!val) return "";

        var idx = val.indexOf("----------");
        return idx !== -1 ? val.substring(idx + 11, val.length) : "";
    }

    const setAutomaticRules = (articleInfo, field, value) => {
        var o = { ...articleInfo };

        //If category change
        if (field === "zn15pa") {

            if (value !== "Std") {
                o.stmil = -9999;
                o.qreal = 0;
                o.empsl = 879;
            } else {
                //Set estimation calculation
                o.stmil = calculateMinSTock();
                o.qreal = null;
                o.empsl = 878;
            }

            if (value.toUpperCase().startsWith("CSM")) {
                o.gebel = "N";
                o.tgesl = "C";
            } else {
                o.gebel = "O";
                o.tgesl = "Q";
            }

            setArticleInfo(o);
        } else if (field === "maker") {

            if (value.startsWith("BPLUS/") || o.zn01pa.toUpperCase().startsWith("B+")) {
                o.zn17pa = "FR";
                o.price = 0.01;
            } else {
                o.zn17pa = "";
                o.price = null;
            }

            if (value.startsWith("TECMA/") || value.startsWith("LANTE/")) {
                o.catrl = "FAB";
                o.supplier = value.startsWith("TECMA/") ? "TECMA.P" : "LANTECH";
            } else if (value.startsWith("NORDS/") || value.startsWith("ROBAT/")) {
                o.catrl = "SCL";
                o.supplier = value.startsWith("ROBAT/") ? "ROBATECH" : "NORDSON";
            } else if (value.startsWith("BPLUS/") || o.zn01pa.toUpperCase().startsWith("B+")) {
                o.catrl = "BPLUS";
            } else {
                o.catrl = "COMP";
            }

            setArticleInfo(o);
        } else if (field === "price" || field === "unit_supplier") {
            var unit_label = unit.find( (a) => a.CODCS === o.unit_supplier ).LI1CS;
            var unit_conversion = getUnitConversion( unit_label );
            var price = field === "price" ? value : o.pxrel;

            o.conversion = unit_conversion;
                        
            o.pxrel = price * unit_conversion;


            setArticleInfo(o);
        } else {
            setArticleInfo(o);
        }

    }

    const refreshMinSTock = () => {
        var o = { ...articleInfo };

        if (articleInfo.zn15pa !== "Std") {
            o.stmil = -9999;
            o.qreal = 0;
        } else {
            o.stmil = calculateMinSTock().toFixed(0);
            o.qreal = o.stmil;
        }

        setArticleInfo(o);
    }

    function calculateMinSTock() {


        return (machUsing.in * (articleInfo.in || 0))
            + (machUsing.wn * (articleInfo.wn || 0))
            + (machUsing.ln * (articleInfo.ln || 0))
            + (machUsing.ik * (articleInfo.ik || 0))
            + (machUsing.telp * (articleInfo.telp || 0))
            + (machUsing.ec * (articleInfo.ec || 0));

    }

    /**
     *  Generate technical label from maker + ref
     */
    const onRefChange = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...articleInfo };
        o[field] = value;

        var tech_lab = "";

        if (articleInfo.typeArticle === "gen") {
            if (field === "zn02pa") o["des2l"] = value;
            if (field === "zn01pa" && value.toUpperCase().startsWith("B+")) o["zn17pa"] = "FR";
        } else {
            if (field === "zn01pa") {
                tech_lab = getMaker(value) + "/" + getRef(articleInfo.zn02pa || "");
                o["zn01pa"] = value;
            } else {
                if (value.indexOf("MASTEMB") !== -1 || value.indexOf("RULT") !== -1)
                    o["zn01pa"] = "B+ Equipment";

                if (value.toUpperCase().startsWith("PL-")) {
                    o.typrl = "QF";
                } else if (value.toUpperCase().startsWith("ST-")) {
                    o.typrl = "MS";
                } else {
                    o.typrl = "MK";
                }

                o["zn02pa"] = value;
                tech_lab = getMaker(o.zn01pa || "") + "/" + getRef(value);
            }

            o["des2l"] = tech_lab;
        }

        setAutomaticRules(o, "maker", tech_lab);
    }

    /**
     * Get maker trigram
     * @param {*} value 
     * @returns 
     */
    const getMaker = (value) => {
        if (value && value.toUpperCase().startsWith("B+")) {
            return "BPLUS";
        } else {
            return value.toUpperCase().substring(0, Math.min(5, value.length)).padEnd(5, "_");
        }
    }

    /**
     * Remove special char from ref
     * @param {*} ref 
     * @returns 
     */
    const getRef = (ref) => {
        var ref= cleanRef(ref);
        return ref.substring(0, Math.min( 19, ref.length ));
    }

    /**
     * Check if there is any old ref
     * @param {*} e 
     */
    const checkOldRef = (e) => {
        var value = e.target.value;

        checkIfRefExists(articleInfo);

        /*if (value.length < 16 || (!value.startsWith("PL-MASTEM")
            && !value.startsWith("PL-RULT") && !value.startsWith("PL-RIP")
            && !value.startsWith("PL-REPA") && !value.startsWith("PL-REMB"))) return;*/

        ArticleInfo.getOldRef(value).then(
            (response) => {
                setOldRef(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

    }

    /**
     * Change obs and merge between obs displaying and hidden in order
     * @param {*} e 
     */
    const changeComment = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;
        var com_ord, com_norder;

        if (field === "com_ord") {
            com_ord = e.target.value;
            com_norder = articleInfo.com_norder || "";
        } else {
            com_ord = articleInfo.com_ord || "";
            com_norder = e.target.value;
        }

        var valueToSave = com_ord
            + "\r[----- Les observations ci-dessous sont masquées dans les commandes--]\r"
            + com_norder;

        var o = { ...articleInfo };
        o[field] = value;
        o["obses"] = valueToSave;
        setArticleInfo(o);

    }

    /**
     * Check if supplier exists and set name
     * @param {*} e 
     */
    const checkSupplier = (e, name) => {
        var supplier = (e && e.target.value) || name;
        setsupplierSearch(true);

        setError();
        ArticleInfo.getSupplierInfo(supplier).then(
            (response) => {
                var o = { ...articleInfo };
                o.supplier = response.CTIES;
                o.supplier_name = response.RSC1S;
                setArticleInfo(o);
                setsupplierSearch(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setsupplierSearch(false);
            }
        );
    }

    const checkSupplierFromMaker = () => {
        if (articleInfo.des2l.startsWith("TECMA/") || articleInfo.des2l.startsWith("LANTE/")
            || articleInfo.des2l.startsWith("NORDS/") || articleInfo.des2l.startsWith("ROBAT/")) {
            checkSupplier(undefined, articleInfo.supplier);
        }
    }

    /**
     * Check if there is no error
     * @param {*} o 
     * @param {*} change 
     * @returns 
     */
    const control = () => {
        setErr();

        var err = "";

        if (existing) err = "Cette référence existe déjà sous le code: " + existing + "<br/>";

        //check all mandatories fields are filled in 
        for (let i = 0; i < mandatoriesField.length; i++) {
            if (articleInfo[mandatoriesField[i]] === null || articleInfo[mandatoriesField[i]].toString().trim() === "") {
                err += "Veuillez remplir tous les champs marqués par un astérisque (*)<br/>";
                break;
            }
        }

        //Check old ref
        if (oldRef && oldRef.length > 0) {
            for (let i = 0; i < oldRef.length; i++) {
                if (oldRef[i].BLOCL === 'N' && (oldRef[i].replace === null || oldRef[i].blocking === null
                    || oldRef[i].action === null)) {
                    err += "Veuillez compléter les informations manquantes dans l'onglet \"Anciens indices\"";
                    break;
                }
            }
        }

        //Generic must be specific
        if(articleInfo.typeArticle === "gen" && articleInfo.zn15pa !== "Spé" ){
            err+= "Un article générique doit être de catégorie \"Spé\"";
            setErr(err);
            scrollToTop();
            return;
        }

        //Check rules
        //Hst, Spé, Peint, old
        if (articleInfo.zn15pa === "Hst" || articleInfo.zn15pa === "Hst Pneu" || articleInfo.zn15pa === "Spé" || articleInfo.zn15pa === "Peint" || articleInfo.zn15pa === "OLD") {
            if (articleInfo.stmil === null || articleInfo.stmil === "" || parseInt(articleInfo.stmil) > -9999 ) 
                err += "Le stock mini doit être de -9999<br>"
            
            if (articleInfo.qreal === null || articleInfo.qreal === "" || parseInt(articleInfo.qreal) !== 0) 
                err += "La quantité de réappro doit être de 0<br>";
            
            if (articleInfo.gebel !== "O") 
            err += "Le besoin doit être coché à \"Oui\"<br>";
             
            //csm, cmp
        } else if (articleInfo.zn15pa && (articleInfo.zn15pa.startsWith("CSM") || articleInfo.zn15pa.startsWith("CMP"))) {
            if (articleInfo.stmil === null || articleInfo.stmil === "" || parseInt(articleInfo.stmil) > -9999) 
                err += "Le stock mini doit être de -9999<br>";
            
            if (articleInfo.qreal === null || articleInfo.qreal === "" || parseInt(articleInfo.qreal) !== 0 ) 
                err += "La quantité de réappro doit être de 0<br>";
            
            if (articleInfo.gebel !== "N") 
                err += "Le besoin doit être coché à \"Non\"<br>";
            
            // std
        } else if (articleInfo.zn15pa.toUpperCase().startsWith("STD")) {
            if (articleInfo.stmil === null || articleInfo.stmil === "" || parseInt(articleInfo.stmil) < 0)
                err += "Le stock mini doit être supérieur à 0<br>";
            

            if (articleInfo.qreal === null || articleInfo.qreal === "" || parseInt(articleInfo.qreal) <= 0) 
                err += "La quantité de réappro doit être supérieur à 0<br>";
            
            if (articleInfo.gebel !== "O") 
                err += "Le besoin doit être coché à \"Oui\"";
            
        }

        //If generic check ref format
        if (articleInfo.typeArticle === "gen") {
            const match = articleInfo.zn02pa.match(/[A-Z0-9]{3}-[A-Z0-9]{3}/g);
            if (match === null || articleInfo.zn02pa !== match[0])
                err += "Pour les articles génériques, la référence doit être au format \"XXX-XXX\", ex: RFO-088";
        }

        if (err !== "") {
            setErr(err);
            scrollToTop();
        } else {
            setSaving(true);
            ArticleInfo.createArticleInFocus(articleInfo, oldRef, true, true).then(
                (response) => {
                    setMsg("Article " + response + " créé !");
                    setSaving(false);
                    setOldRefResponse(false);
                    scrollToTop();
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setSaving(false);
                }
            );
        }

    }

    /**
     * Translate in english the label
     * 
     * @param {*} e 
     */
    const translate = async (e) => {
        e.preventDefault();
        setError();
        var lang = e.target.getAttribute("data-lang");
        var field = e.target.getAttribute("data-field");

        if(!articleInfo.zn03pa || articleInfo.zn03pa.trim() === "") return

        const translation = await CheckConnDao.translate(articleInfo.zn03pa.trim(), lang);

        if(translation !== null){
            var o = {...articleInfo};
            o[field] = translation;
            setArticleInfo(o);
        }else{
            var url = "https://translate.google.com/?hl=fr&sl=auto&tl=" + lang + "&text=" + articleInfo.zn03pa.replace(/ /g, '+') + "&op=translate";
            window.open(url, '_blank');
        }

    }

    /**
     * Save the changes
     */
    const save = () => {
        setError();
        setMsg();

        if (existing) {
            setError("Cette référence existe déjà sous le code: " + existing);
            scrollToTop();
            return;
        }

        if (!articleInfo.zn02pa || articleInfo.zn02pa.trim() === "") {
            setError("Saisissez au moins la référence de l'article !");
            scrollToTop();
            return;
        }

        var be = document.getElementsByClassName("form-control bg-warning").length
            + document.getElementsByClassName("form-select bg-warning").length;
        var purchase = document.getElementsByClassName("form-control bg-danger").length
            + document.getElementsByClassName("form-select bg-danger").length;

        var msg = "Il reste " + be + " champ(s) à remplir par le BE et " + purchase +
            " champ(s) à remplir par les achats. Confirmez-vous l'enregistrement des données ?";

        if (be > 0 || purchase > 0) {
            confirmAlert({
                title: "Confirmation",
                message: msg,
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => { saveInDb() }
                    },
                    {
                        label: "Non"
                    }
                ]
            });
        } else {
            saveInDb();
        }

    }

    /**
     * Save in database
     */
    function saveInDb() {
        if (existing) {
            setError("Cette référence existe déjà sous le code: " + existing);
            scrollToTop();
            return;
        }

        setSaving(true);

        ArticleInfo.saveCreationRequest(articleInfo, oldRef).then(
            (response) => {
                setSaving(false);
                setMsg("Demande enregistrée !");
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        );
    }

    /**
     * Send the color of field background
     * @param {*} value 
     * @param {*} type 
     * @returns 
     */
    const filledIn = (value, type) => {
        if (value !== undefined && value !== null && value.toString().trim() !== "") return "";

        return type === "meca" ? "bg-warning" : "bg-danger";
    }

    /**
     * Copy the data from the old ref
     * @param {*} e 
     */
    const copyData = (e) => {
        var ar = e.target.getAttribute("data-ar");

        confirmAlert({
            title: "Confirmation",
            message: "Vous allez dupliquer les données de l'article " + ar
                + ", merci de bien vouloir tout de même contrôler pour éviter de répéter d'éventuels erreurs.",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        duplicateData(e.target.getAttribute("data-key"));
                    }
                },
                {
                    label: "Non"
                }
            ]
        });

    }

    function duplicateData(key) {
        var old = oldRef[key];
        var o = { ...articleInfo };

        o.zn01pa = old.ZN01PA;
        o.des1l = old.DES1L;
        o.zn15pa = old.ZN15PA;
        o.gebel = old.GEBEL;
        o.tgesl = old.TGESL;
        o.zn03pa = old.ZN03PA;
        o.zn12pa = old.ZN12PA;
        o.matil = old.MATIL;
        o.chapl = old.CHAPL;
        o.typrl = old.TYPRL.trim();
        o.cfaml = old.CFAML.trim();
        o.catrl = old.CATRL;
        o.cdunl = old.CDUNL;
        if( old.ZN17PA ) o.zn17pa = old.ZN17PA;
        o.dim1l = old.DIM1L;
        o.dim2l = old.DIM2L;
        o.dim3l = old.DIM3L;
        o.poidl = old.POIDL;
        o.deu_lab = old.ZN14PA;

        setArticleInfo(o);
        setTab(0);
    }

    /**
     * Search drawing PDF
     */
    const searchPlan = () => {
        setSearchDrawing(true);

        ArticleInfo.searchDrawingUrl(articleInfo.zn02pa).then(
            (response) => {
                var url = response.replaceAll(String.fromCharCode(92), "_wso");
                setDrawing(url);
                setSearchDrawing(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSearchDrawing(false);
            }
        )
    }

    {/** */ }
    const deleteOld = (k) => {
        var arr = [...oldRef];
        arr = arrayRemoveElt(arr, k);
        setOldRef(arr);
    }

    const addArticle = (e) => {
        e.preventDefault();
        var ar = e.target.ar.value;

        setLoading(true);
        ArticleInfo.articlesInfo(ar).then(
            (response) => {
                if (response.length === 0) {
                    setError("Article inconnu");
                    scrollToTop();
                } else {
                    var arr = [...oldRef];
                    var o = { ...response[0] };
                    o.mode = "create";
                    arr.push(o);
                    setOldRef(arr);
                }
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    return (
        <div>
            <h4 className="text-center">Création d'article</h4>
            <div className="text-center">
                {id !== null && <a href={"/article/create"}>Nouvelle demande</a>}
                {id === null && <a href="#" onClick={() => window.location.reload()}>Nouvelle demande</a>}
            </div>

            {err && <ErrorMessage error={err} errorState={setErr} />}

            {loading && <WaitingRoundSnippers />}

            {error && <ErrorMessage error={error} errorState={setError} />}

            {msg && <SuccessMessage msg={msg} />}

            {
                articleInfo &&
                <Fragment>

                    <ul className="nav nav-tabs fixed-header" style={{ backgroundColor: "white" }}>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === -1 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="-1">Préambule</a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="0">Info de base</a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 2 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="2">Fournisseurs</a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 3 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="3">Anciens indices</a>
                        </li>



                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 5 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="5">Guide des catégories</a>
                        </li>

                    </ul>
                    <br></br>

                    {
                        <div className={"col-8 offset-2 table-responsive " + (tab !== -1 ? "d-none" : "")}>
                            <table className="table">
                                <thead>
                                    <tr className="text-center">
                                        <th style={{ minWidth: "350px" }}></th>
                                        <th scope="col">IN</th>
                                        <th scope="col">WN</th>
                                        <th scope="col">LN</th>
                                        <th scope="col">IK</th>
                                        <th scope="col">TELP</th>
                                        <th scope="col">EC</th>
                                    </tr>
                                    <tr>
                                        <td>Production prévue par mois des machine*</td>
                                        <td className="text-center">{machUsing.in}</td>
                                        <td className="text-center">{machUsing.wn}</td>
                                        <td className="text-center">{machUsing.ln}</td>
                                        <td className="text-center">{machUsing.ik}</td>
                                        <td className="text-center">{machUsing.telp}</td>
                                        <td className="text-center">{machUsing.ec}</td>
                                    </tr>
                                    <tr>
                                        <td>Utilisation moyenne par machine*</td>
                                        <td>
                                            <input className={"form-control " + filledIn(articleInfo.in, "meca")} type="number" style={{ minWidth: "70px" }}
                                                onChange={updateArticle} data-field="in" value={articleInfo.in}></input>
                                        </td>
                                        <td>
                                            <input className={"form-control " + filledIn(articleInfo.wn, "meca")} type="number" style={{ minWidth: "70px" }}
                                                onChange={updateArticle} data-field="wn" value={articleInfo.wn}></input>
                                        </td>
                                        <td>
                                            <input className={"form-control " + filledIn(articleInfo.ln, "meca")} type="number" style={{ minWidth: "70px" }}
                                                onChange={updateArticle} data-field="ln" value={articleInfo.ln}></input>
                                        </td>
                                        <td>
                                            <input className={"form-control " + filledIn(articleInfo.ik, "meca")} type="number" style={{ minWidth: "70px" }}
                                                onChange={updateArticle} data-field="ik" value={articleInfo.ik}></input>
                                        </td>
                                        <td>
                                            <input className={"form-control " + filledIn(articleInfo.telp, "meca")} type="number" style={{ minWidth: "70px" }}
                                                onChange={updateArticle} data-field="telp" value={articleInfo.telp}></input>
                                        </td>
                                        <td>
                                            <input className={"form-control " + filledIn(articleInfo.ec, "meca")} type="number" style={{ minWidth: "70px" }}
                                                onChange={updateArticle} data-field="ec" value={articleInfo.ec}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Demandeur*</td>
                                        <td colSpan={6}>
                                            <input className="form-control"
                                                value={articleInfo.author}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Degré d'urgence*</td>
                                        <td colSpan={6}>
                                            <select className="form-select" onChange={updateArticle}
                                                data-field="emergency" value={articleInfo.emergency}>
                                                <option value="2" className="bg-success">Non urgent</option>
                                                <option value="1" className="bg-warning">Urgent</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Type d'article à créer</td>
                                        <td colSpan={6}>
                                            <div className="btn-group" role="group">
                                                <input type="radio" className="btn-check" data-field="typeArticle" id="std" value="std"
                                                    checked={articleInfo.typeArticle === "std"} onClick={updateArticle} />
                                                <label className="btn btn-outline-secondary" for="std">Article standard</label>

                                                <input type="radio" className="btn-check" data-field="typeArticle" id="gen" value="gen"
                                                    checked={articleInfo.typeArticle === "gen"} onClick={updateArticle} />
                                                <label className="btn btn-outline-secondary" for="gen">Article générique</label>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    }

                    {
                        <div className={tab !== 0 ? "d-none" : ""}>
                            {/** PLAN REF - TECH LAB - MATTER */}
                            <div className="d-flex">

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.zn02pa, "meca")}
                                        onChange={onRefChange} onBlur={checkOldRef}
                                        data-table="fperart" data-field="zn02pa" value={articleInfo.zn02pa}
                                        id="zn02pa" readOnly={readonly} maxLength={100} />
                                    <label htmlFor="zn02pa">{t('plan')}*</label>
                                </div>

                                <div style={{ marginRight: "10px" }}>
                                    {
                                        (articleInfo.zn02pa.startsWith("PL-") && !drawing) &&
                                        <button className="btn btn-outline-secondary" onClick={searchPlan} disabled={searchDrawing}>
                                            {searchDrawing && <ButtonWaiting />}
                                            Chercher<br></br>PDF
                                        </button>
                                    }

                                    {drawing &&
                                        <a className="btn btn-success" style={{ marginLeft: "auto" }}
                                            target="_blank" href={url + drawing}>Ouvrir<br></br>Plan</a>
                                    }
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.zn01pa, "meca")}
                                        onChange={onRefChange} data-table="fperart" data-field="zn01pa" value={articleInfo.zn01pa}
                                        id="zn01pa" readOnly={readonly} list="maker_list" onBlur={checkSupplierFromMaker} />
                                    <label htmlFor="zn01pa">Fabricant*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.des2l, "meca")} onChange={updateArticle}
                                        data-table="farticl" data-field="des2l" value={articleInfo.des2l}
                                        id="des2l" readOnly maxLength={30} />
                                    <label htmlFor="des2l">{t('tec_lab')}*</label>
                                </div>

                            </div>

                            {/** LABEL - CSM - NEED */}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.des1l, "meca")} onChange={updateArticle}
                                        data-table="farticl" data-field="des1l" value={articleInfo.des1l}
                                        id="des1l" readOnly={readonly} maxLength={30} />
                                    <label htmlFor="des1l">{t('short_lab')}*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className={"form-select " + filledIn(articleInfo.zn15pa, "meca")} value={articleInfo.zn15pa} onChange={updateArticle}
                                        data-table="fperart" data-field="zn15pa" id="zn15pa" disabled={readonly} title={articleInfo.zn15pa}>
                                        <option value=""></option>
                                        <option value="Std">Std</option>
                                        <option value="Std Pneu">Std Pneu</option>
                                        <option value="Hst">Hst</option>
                                        <option value="Hst Pneu">Hst Pneu</option>
                                        <option value="Peint">Peint</option>
                                        <option value="Spé">Spé</option>
                                        <option value="CSM">CSM</option>
                                        <option value="CSM vis">CSM vis</option>
                                        <option value="CSM élec">CSM élec</option>
                                        <option value="CSM cablo">CSM cablo</option>
                                        <option value="OLD">OLD</option>
                                        <option value="CMP">CMP</option>
                                        <option value="Presta">Presta</option>
                                    </select>
                                    <label htmlFor="zn15pa">{t('category')}*</label>
                                </div>

                                <div style={{ width: "20%" }}>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" onChange={updateArticle}
                                            data-table="farticl" data-field="gebel" checked={articleInfo.gebel === "O"}
                                            readOnly={readonly} role="switch" id="gebel" />
                                        <label className="form-check-label" htmlFor="gebel">{t('need')}</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="tgesl" onClick={updateArticle}
                                            data-field="tgesl" value="C" checked={articleInfo.tgesl === "C"} />
                                        <label className="form-check-label" for="inlineRadio1">Consommable</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="tgesl" onClick={updateArticle}
                                            data-field="tgesl" value="Q" checked={articleInfo.tgesl === "Q"} />
                                        <label className="form-check-label" for="inlineRadio2">Quantitatif</label>
                                    </div>
                                </div>
                            </div>

                            {/** LABEL FR - LABEL EN - LABEL DEU */}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.zn03pa, "meca")}
                                        onChange={updateArticle} data-table="fperart" data-field="zn03pa" value={articleInfo.zn03pa}
                                        id="zn03pa" readOnly={readonly} />
                                    <label htmlFor="zn03pa">{t('com_lab')}*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.zn12pa, "meca")} onChange={updateArticle}
                                        data-table="fperart" data-field="zn12pa" value={articleInfo.zn12pa}
                                        id="zn12pa" readOnly={readonly} />
                                    <label htmlFor="zn12pa">{t('en_lab')}*</label>
                                </div>

                                <div>
                                    {
                                        <div style={{ marginRight: "10px", marginTop: "auto", marginBottom: "auto" }}>
                                            <button className="btn btn-secondary"
                                                data-lang="en" data-field="zn12pa" onClick={translate}>
                                                Traduire
                                            </button>
                                        </div>
                                    }

                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.deu_lab, "meca")} onChange={updateArticle}
                                        data-table="fperart" data-field="deu_lab" value={articleInfo.deu_lab}
                                        id="deu_lab" readOnly={readonly} />
                                    <label htmlFor="deu_lab">{t('de_lab')}*</label>
                                </div>


                                <div>
                                    {
                                        <div style={{ marginRight: "10px", marginTop: "auto", marginBottom: "auto" }}>
                                            <button className="btn btn-secondary"
                                                data-lang="de" data-field="deu_lab" onClick={translate}>
                                                Traduire
                                            </button>
                                        </div>
                                    }

                                </div>
                            </div>

                            {/** MATTER - CHAPTER - TYPE PROD */}
                            <div className="d-flex">
                                <div className="form-floating fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className="form-select" onChange={updateArticle}
                                        data-table="farticl" data-field="matil" id="matil" disabled={readonly}>
                                        <option value=""></option>
                                        <option value="Métal" selected={articleInfo.matil === "Métal"}>{t('metal')}</option>
                                        <option value="Plastique" selected={articleInfo.matil === "Plastique"}>{t('plastic')}</option>
                                    </select>
                                    <label htmlFor="matil">{t('matter')}</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className={"form-select " + filledIn(articleInfo.chapl, "achat")}
                                        onChange={updateArticle} value={articleInfo.chapl}
                                        data-table="farticl" data-field="chapl" id="chapl"
                                        disabled={readonly}>
                                        <option value=""></option>
                                        <option value="400">Composants &lt;=75€ (2,5)</option>
                                        <option value="500">Composants &lt;=750€ (2)</option>
                                        <option value="600">Equipments &lt;=1500€ (1,7)</option>
                                        <option value="700">Equipments &gt;1500€ (1,5)</option>
                                        <option value="800">Frais annexes</option>
                                        <option value="100">Heures générales</option>
                                        <option value="200">Heures internes</option>
                                        <option value="300">Heures sur site</option>
                                        <option value="410">Ne pas utiliser</option>
                                        <option value="610">Ne pas utiliser</option>
                                        <option value="999">Non Exploite</option>
                                    </select>
                                    <label htmlFor="chapl">{t('chapter')}*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank">
                                    <select className={"form-select " + filledIn(articleInfo.typrl, "achat")} onChange={updateArticle} value={articleInfo.typrl}
                                        data-table="farticl" data-field="typrl" id="typrl" disabled={readonly}>
                                        <option value=""></option>
                                        <option value="MK">Composant</option>
                                        <option value="QF">Fabrication sur plan</option>
                                        <option value="MS">Sous-ens. sur spec/plans</option>
                                    </select>
                                    <label htmlFor="chapl">Type de produit*</label>
                                </div>

                            </div>

                            {/** FAMILY - CUSTOM CAT - UNIT*/}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className={"form-select " + filledIn(articleInfo.cfaml, "achat")}
                                        onChange={updateArticle} value={articleInfo.cfaml}
                                        data-table="fperart" data-field="cfaml" id="cfaml" disabled={readonly}>
                                        <option value=""></option>
                                        <option value="MA" className="comp">Actionneur mecanique</option>
                                        <option value="PA" className="comp">Actionneur pneumatique</option>
                                        <option value="PV" className="comp">Composant pour le vide</option>
                                        <option value="MS" className="comp">Eléments standards</option>
                                        <option value="ML" className="comp">Guidage lineaire</option>
                                        <option value="MR" className="comp">Guidage rotatif (rlt  palier)</option>
                                        <option value="MM" className="comp">Matiere (plaque  plat  profile)</option>
                                        <option value="OI" className="fab">Piece d'injection  Mat.plastiq</option>
                                        <option value="OF" className="fab">Piece de fraisage</option>
                                        <option value="OS" className="fab">Piece de mecano-soudure</option>
                                        <option value="OT" className="fab">Piece de tôlerie</option>
                                        <option value="OO" className="fab">Piece de tournage</option>
                                        <option value="OG" className="fab">Piece forgee</option>
                                        <option value="PR" className="comp">Raccord</option>
                                        <option value="OE" className="fab">Tournage  Fraisage</option>
                                        <option value="PF" className="comp">Traitement de l'air</option>
                                        <option value="PT" className="comp">Tuyauterie</option>
                                        <option value="MV" className="comp">Visserie / Stock géré à vue</option>
                                    </select>
                                    <label htmlFor="cfaml">Famille d'article*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className={"form-select " + filledIn(articleInfo.catrl, "achat")}
                                        onChange={updateArticle} value={articleInfo.catrl}
                                        data-table="farticl" data-field="catrl" id="catrl"
                                        disabled={readonly}>
                                        <option value=""></option>
                                        <option value="BPLUS">Article B+</option>
                                        <option value="COMP">Composant</option>
                                        <option value="FAB">Autres constructeurs</option>
                                        <option value="SCL">Système de colle</option>
                                    </select>
                                    <label htmlFor="catrl">Catégorie personnalisée*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank">
                                    <select className={"form-select " + filledIn(articleInfo.cdunl, "meca")}
                                        onChange={updateArticle} value={articleInfo.cdunl}
                                        data-table="farticl" data-field="cdunl" id="cdunl" disabled={readonly}>
                                        <option value=""></option>
                                        <option value="UN">UC : 1</option>
                                        <option value="LI">UC : L</option>
                                        <option value="ML">UC : M</option>
                                    </select>
                                    <label htmlFor="cdunl">Unité de nomenclature*</label>
                                </div>

                            </div>

                            {/** COST -  HST CODE - COUNTRY */}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" className="form-control"
                                        onChange={updateArticle} data-table="farticl" data-field="pxrel"
                                        value={articleInfo.pxrel} id="pxrel" readOnly />
                                    <label htmlFor="pxrel">{t('cost_prc')}</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className="form-control" onChange={updateArticle}
                                        data-table="farticl" data-field="doua" value={articleInfo.doua}
                                        id="doua" readOnly={readonly} />
                                    <label htmlFor="doua">{t('cust_nom')}</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className="form-control" onChange={updateArticle}
                                        data-table="fperart" data-field="hs_code" value={articleInfo.hs_code}
                                        id="hs_code" readOnly={readonly} />
                                    <label htmlFor="hs_code">{t('hs-code')}</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="text" className={"form-control " + filledIn(articleInfo.zn17pa, "achat")}
                                        onChange={updateArticle} data-table="fperart" data-field="zn17pa" value={articleInfo.zn17pa}
                                        id="zn17pa" readOnly={readonly} />
                                    <label htmlFor="zn17pa">{t('country_origin')}*</label>
                                </div>
                            </div>

                            {/** STOCK MINI - REPLENISHMENT - SECU - LOCATION */}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className={"form-control " + filledIn(articleInfo.stmil, "achat")}
                                        onChange={updateArticle} data-table="farticl" data-field="stmil" value={articleInfo.stmil}
                                        id="stmil" readOnly={readonly} />
                                    <label htmlFor="stmil">{t('min_stck')}*</label>
                                </div>

                                <div style={{ marginRight: "10px" }}>
                                    <button className="btn btn-secondary" onClick={refreshMinSTock}>Calculer</button>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className={"form-control " + filledIn(articleInfo.qreal, "achat")}
                                        onChange={updateArticle} data-table="farticl" data-field="qreal" value={articleInfo.qreal}
                                        id="qreal" readOnly={readonly} />
                                    <label htmlFor="qreal">{t('reap_qty')}*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className={"form-control " + filledIn(articleInfo.delrl, "achat")}
                                        onChange={updateArticle} data-table="farticl" data-field="delrl" value={articleInfo.delrl}
                                        id="delrl" readOnly={readonly} />
                                    <label htmlFor="delrl">{t('reap_del')}*</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className="form-control" onChange={updateArticle}
                                        data-table="farticl" data-field="stsel" value={articleInfo.stsel}
                                        id="stsel" readOnly={readonly} />
                                    <label htmlFor="stsel">{t('sec_stck')}*</label>
                                </div>
                            </div>

                            {/** COMMENTS */}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <textarea className="form-control" data-table="farticl" data-field="com_ord" onChange={changeComment}
                                        id="com_order" value={articleInfo.com_order} style={{ minHeight: "100px" }}></textarea>
                                    <label htmlFor="com_order">Observations pour les commandes</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank">
                                    <textarea className="form-control" data-table="farticl" data-field="com_no_ord" onChange={changeComment}
                                        id="com_norder" value={articleInfo.com_no_ord} style={{ minHeight: "100px" }}></textarea>
                                    <label htmlFor="com_norder">Observations à masquer dans les commandes</label>
                                </div>
                            </div>

                            {/** DIMENSIONS - WEIGHT  */}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className="form-control" onChange={updateArticle}
                                        data-table="farticl" data-field="dim1l" value={articleInfo.dim1l}
                                        id="dim1l" readOnly={readonly} />
                                    <label htmlFor="dim1l">Dim 1</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className="form-control" onChange={updateArticle}
                                        data-table="farticl" data-field="dim2l" value={articleInfo.dim2l}
                                        id="dim2l" readOnly={readonly} />
                                    <label htmlFor="dim2l">Dim 2</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className="form-control" onChange={updateArticle}
                                        data-table="farticl" data-field="dim3l" value={articleInfo.dim3l}
                                        id="dim3l" readOnly={readonly} />
                                    <label htmlFor="dim3l">Dim 3</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <input type="number" step="any" className="form-control" onChange={updateArticle}
                                        data-table="farticl" data-field="poidl" value={articleInfo.poidl}
                                        id="poidl" readOnly={readonly} />
                                    <label htmlFor="poidl">{t('weight')}</label>
                                </div>

                            </div>

                            {/** SPARES - USURE - CRITICITY */}
                            <div className="d-flex">
                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className="form-select" onChange={updateArticle} value={articleInfo.zn05pa}
                                        data-table="fperart" data-field="zn05pa" id="zn05pa" disabled={readonly}>
                                        <option value=""></option>
                                        <option value="O">Oui</option>
                                        <option value="N">Non</option>
                                        <option value="Indéfini">Indéfini</option>
                                    </select>
                                    <label htmlFor="zn05pa">Sparespart</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className="form-select" onChange={updateArticle} value={articleInfo.zn13pa}
                                        data-table="fperart" data-field="zn13pa" id="zn13pa" disabled={readonly}>
                                        <option value=""></option>
                                        <option value="O">Oui</option>
                                        <option value="N">Non</option>
                                        <option value="Indéfini">Indéfini</option>
                                    </select>
                                    <label htmlFor="zn13pa">Pièce d'usure</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank" style={{ marginRight: "10px" }}>
                                    <select className="form-select" onChange={updateArticle} value={articleInfo.zn06pa}
                                        data-table="farticl" data-field="zn06pa" id="typrl" disabled={readonly}>
                                        <option value=""></option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="Indéfini">Indéfini</option>
                                    </select>
                                    <label htmlFor="zn06pa">Criticité</label>
                                </div>

                                <div className="form-floating mb-3 fillInBlank">
                                    <input type="text" className="form-control" onChange={updateArticle}
                                        data-table="farticl" data-field="zn07pa" value={articleInfo.zn07pa}
                                        id="zn07pa" readOnly={readonly} />
                                    <label htmlFor="zn07pa">Remplacer en SAV / sur site par</label>
                                </div>
                            </div>

                        </div>
                    }

                    {
                        <table className={"table " + (tab !== 2 ? "d-none" : "")}>
                            <thead>
                                <tr>
                                    <th scope="col">Fournisseur*</th>
                                    <th scope="col">Raison sociale*</th>
                                    <th scope="col">Ref. article</th>
                                    <th scope="col">Prix unitaire*</th>
                                    <th scope="col">Devise*</th>
                                    <th scope="col">Unité*</th>
                                    <th scope="col">Remise*</th>
                                    <th scope="col">Conversion*</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr>
                                    <td>
                                        <input type="text" className={"form-control " + filledIn(articleInfo.supplier, "achat")} onChange={updateArticle} onBlur={checkSupplier}
                                            data-field="supplier" value={articleInfo.supplier} />
                                    </td>
                                    <td>
                                        {supplierSearch && <ButtonWaiting />}
                                        {!supplierSearch && <input type="text" className="form-control" value={articleInfo.supplier_name} readOnly />}
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" onChange={updateArticle}
                                            data-table="fartfou" data-field="ref_supplier" value={articleInfo.ref_supplier} />
                                    </td>
                                    <td>
                                    <div className="input-group">
                                        <input type="number" step="any" className={"form-control " + filledIn(articleInfo.price, "achat")} onChange={updateArticle}
                                            data-table="fartfou" data-field="price" value={articleInfo.price} />
                                        <input className="form-control"
                                            value={ (articleInfo.price && articleInfo.conversion) 
                                                    && ( (articleInfo.price * articleInfo.conversion).toFixed(2) + " €/u") } disabled/>
                                                    </div>
                                    </td>
                                    <td>
                                        <select className={"form-select " + filledIn(articleInfo.currency, "achat")}
                                            onChange={updateArticle} value={articleInfo.currency} data-field="currency">
                                            <option value="">Choose...</option>
                                            {
                                                currency.map(
                                                    (v2, k2) =>
                                                        <option key={"currency_" + k2} value={v2.CDEV1}>{v2.LDEV1}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <select className={"form-select " + filledIn(articleInfo.unit_supplier, "achat")}
                                            onChange={updateArticle} value={articleInfo.unit_supplier} data-field="unit_supplier">
                                            <option value="">Choose...</option>
                                            {
                                                unit.map(
                                                    (v2, k2) =>
                                                        <option key={"unit_" + k2} value={v2.CODCS}>{v2.LI1CS}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" className={"form-control " + filledIn(articleInfo.discount, "achat")}
                                            onChange={updateArticle} data-table="fartfou" data-field="discount" value={articleInfo.discount}
                                            data-label="Remise"
                                            id="PCTRU" />
                                    </td>
                                    <td>
                                        <input type="text" dataf-field="conversion" className={"form-control " + filledIn(articleInfo.conversion, "achat")}
                                            value={articleInfo.conversion} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }

                    {
                        <div className={"table-responsive " + (tab !== 3 ? "d-none" : "")}>

                            <table className="table">
                                <thead>
                                    <tr className="table-info fw-bold text-center">
                                        <th scope="col">
                                            Article<br></br>
                                            {!displayOldBlocked &&
                                                <a href="#" onClick={() => setDisplayOldBlocked(true)}>Afficher les AR bloqués</a>}
                                            {displayOldBlocked &&
                                                <a href="#" onClick={() => setDisplayOldBlocked(false)}>Masquer les AR bloqués</a>}
                                        </th>
                                        <th scope="col" style={{ minWidth: "250px" }}>Référence</th>
                                        <th scope="col">Cat.</th>
                                        <th scope="col">Qté dispo. à terme</th>
                                        <th scope="col">Valeur de stock</th>
                                        <th scope="col" style={{ minWidth: "200px" }}>
                                            <div className="d-flex">
                                            <div>Remplacé en lieu et place par</div>
                                            <CustomToolTipInfo props={{ tooltipText: "Laissez vide si vous ne savez pas par quoi remplacer" }}/>
                                            </div>
                                        </th>
                                        <th scope="col" style={{ minWidth: "210px" }}>Type de blocage</th>
                                        <th scope="col" style={{ minWidth: "210px" }}>Gestion du stock</th>
                                        <th scope="col">Observation Focus</th>
                                        <th scope="col">Observation libre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        oldRef && oldRef.map((v, k) => {
                                            return (
                                                (v.BLOCL === 'N' || (v.BLOCL !== 'N' && displayOldBlocked)) &&
                                                <tr key={"old" + k}>
                                                    <th scope="row">
                                                        <a target="_blank" href={"/article/info/get?article=" + v.CDARPA}>{v.CDARPA}</a>
                                                        <button className="btn btn-outline-warning" data-ar={v.CDARPA}
                                                            onClick={copyData} data-key={k}>
                                                            Rapatrier ces données
                                                        </button>
                                                    </th>
                                                    <td>
                                                        {v.ZN02PA}
                                                        <button className="btn btn-outline-danger"
                                                            onClick={() => deleteOld(k)}>Effacer cette ligne</button>
                                                    </td>
                                                    <td>{v.ZN15PA}</td>
                                                    <td>{v.QTSTL - v.QTREL}</td>
                                                    <td>{v.QTSTL * v.PXMPL} €</td>
                                                    <td>
                                                        <input className={"form-control " + filledIn(v.replace, "meca")}
                                                            onChange={updateOldRef} data-key={k}
                                                            data-field="replace" value={v.replace} />
                                                    </td>
                                                    <td>
                                                        <select className={"form-control " + filledIn(v.blocking, "meca")} onChange={updateOldRef} data-key={k}
                                                            data-field="blocking" value={v.blocking}>
                                                            <option selected="">Choose...</option>
                                                            <option value="rep">En réapprovisionnement</option>
                                                            <option value="tot">Total</option>
                                                            <option value="no">Ne pas bloquer</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className={"form-control " + filledIn(v.action, "meca")} onChange={updateOldRef} data-key={k}
                                                            data-field="action" value={v.action}>
                                                            <option selected="">Choose...</option>
                                                            <option value="bom">Sortir en nomenclature</option>
                                                            <option value="sav">SAV (stock de sécu)</option>
                                                            <option value="retrofit">À modifier (retrofit)</option>
                                                            <option value="trash">À jeter</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        {v.OBSES}
                                                    </td>
                                                    <td>
                                                        <textarea className="form-control" onChange={updateOldRef} data-key={k}
                                                            data-field="com_ipt" value={v.com_ipt}></textarea>

                                                        {<textarea className="form-control" value={v.com_ipt}
                                                            style={{ height: (v.com_ipt ? "150px" : "0px") }} readOnly></textarea>}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>

                            <form onSubmit={addArticle} className="col-3">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Ajouter un article</span>
                                    <input type="text" className="form-control" name="ar" />
                                    <button className="btn btn-outline-secondary" disabled={loading}>
                                        {loading && <ButtonWaiting />}
                                        Rechercher
                                    </button>
                                </div>
                            </form>
                        </div>
                    }

                    {
                        <div className={tab !== 5 ? "d-none" : ""}>
                            <CategoriesGuide />
                            <br></br>
                        </div>
                    }

                    <div className="text-center">
                        <button className="btn btn-success" onClick={save} disabled={saving}>
                            {saving && <ButtonWaiting />}
                            Sauvegarder la saisie
                        </button>
                    </div>
                    <br></br>


                    <div className="alert alert-info col-6 offset-3">
                        <h4 className="text-center">Contrôler et créer l'article</h4>
                        <p className="text-center">
                            Vous allez créer l'article:&nbsp;
                            <b>
                                {articleInfo.typeArticle === "gen" ? "GE_" : "AR_"}{getMaker((articleInfo.zn01pa || ""))}_...
                            </b>
                        </p>
                        <br></br>
                        <div className="text-center">
                            {
                                oldRefResponse &&
                                <button className="btn btn-success"
                                    onClick={control} disabled={saving}>
                                    {saving && <ButtonWaiting />}
                                    Contrôler et créer l'article
                                </button>
                            }

                        </div>
                        <br></br>
                    </div>


                </Fragment>
            }



            {/** MAKERS LIST **/}
            <datalist id="maker_list">
                <option value="AIRFIT">AIRFIT</option>
                <option value="B+ Equipment">B+Equipment</option>
                <option value="BINDER">BINDER</option>
                <option value="BOSCH">BOSCH</option>
                <option value="BRAMPTON">BRAMPTON</option>
                <option value="BRIET">BRIET</option>
                <option value="CAMOZZI">CAMOZZI</option>
                <option value="CHAPELLET">CHAPELLET</option>
                <option value="COVAL">COVAL</option>
                <option value="CSR">CSR</option>
                <option value="DAVID">DAVID</option>
                <option value="DOM">DOM</option>
                <option value="ELESA">ELESA</option>
                <option value="FESTO">FESTO</option>
                <option value="HABASIT">HABASIT</option>
                <option value="HELLE">HELLE</option>
                <option value="HPC">HPC</option>
                <option value="IGUS">IGUS</option>
                <option value="INTERROLL">INTERROLL</option>
                <option value="Itoh Denki">Itoh Denki</option>
                <option value="LEDUC">LEDUC</option>
                <option value="LEGRIS">LEGRIS</option>
                <option value="MARBET">MARBET</option>
                <option value="MARBETT">MARBETT</option>
                <option value="NORDSON">NORDSON</option>
                <option value="NSK">NSK</option>
                <option value="PAULSTRA">PAULSTRA</option>
                <option value="PEINT">PEINT</option>
                <option value="RINGSPANN">RINGSPANN</option>
                <option value="SCHNEIDER">SCHNEIDER</option>
                <option value="SETON">SETON</option>
                <option value="SEW">SEW</option>
                <option value="SIGNA">SIGNA</option>
                <option value="SKF">SKF</option>
                <option value="SYSTEM PLAST">SYSTEM PLAST</option>
                <option value="VANEL">VANEL</option>
                <option value="XXXXX">XXXXX</option>
            </datalist>
        </div>
    )
}

export default CreateArticle