import { createColumnHelper } from "@tanstack/react-table";
import { CustomToolTipInfo } from "../../../common/smallComponents.js/CustomToolTip";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { currentEnDate, nbFormat } from "../../../functions/StrFunctions";
import Movement from "../../../services/storehouse/Movement";

const FocusTable = (p) => {
    
    const onFieldChange = (k, field, value) => {
        var arr = [...p.focusTable];
        
        arr[k][field] = value;

        Movement.updateFocusInventoryTable(arr[k].CDARL, field, value);

        if( field === "stock" ){
           Movement.updateFocusInventoryTable(arr[k].CDARL, "counter", p.user.trigram); 
           Movement.updateFocusInventoryTable(arr[k].CDARL, "count_date", currentEnDate()); 
           arr[k].delta = p.getDelta(arr[k]);
           
            p.setfocusTable(arr);
        } else if( field === "stock_2" ){
           Movement.updateFocusInventoryTable(arr[k].CDARL, "counter_2", p.user.trigram); 
           Movement.updateFocusInventoryTable(arr[k].CDARL, "count_date_2", currentEnDate()); 
           arr[k].delta = p.getDelta(arr[k]);
           
            p.setfocusTable(arr);
        } else{
            p.setfocusTable(arr);
        }


    }

    function getDeltaColor(delta) {
        return delta >= 2000 ? "bg-danger-subtle fw-bold" : "";
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        {p.firstCountValidated && 
            arr.push(columnHelper.accessor('second_count', {
                header: '2e comptage', 
                cell: (i) => <div className="text-center">
                    { i.row.original.stock !== null &&
                    <input type="checkbox" className="form-check-input big" 
                        defaultChecked={i.getValue() === 1} disabled
                        onChange={(e) => onFieldChange(i.row.id, "second_count",  e.target.checked ? 1 : 0 ) }/>}
                </div>
            }));
        }
                          
        arr.push(columnHelper.accessor('isCommercial', {
            header: 'Pce de com.', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('CDARH', 
            { header: 'Article', cell: (i) => <div className="text-center">
                <a target="_blank" href={"/article/info/get?article="+i.getValue()}>{i.getValue()}</a>
            </div> }));
                                 
        arr.push(columnHelper.accessor('ZN02PA', {
            header: 'Plan', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
                                 
        arr.push(columnHelper.accessor('ZN04PA', {
            header: 'Renvois', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
        
        if( !p.firstCountValidated ){
            arr.push(columnHelper.accessor('counter', {
                header: 'Compteur', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));
                                     
            arr.push(columnHelper.accessor('stock', {
                header: 'Qté comptée', cell: (i) => <div 
                    className="text-center full-width-div ">
                        {i.getValue()}
                    {/*i.row.original.ar != null &&
                     <input className={"form-control text-center " + ( i.getValue() !== null ? "bg-warning-subtle" : "" )} type="number"
                        onBlur={(e)=>onFieldChange(i.row.id, "stock", e.target.value)}
                        defaultValue={i.getValue()} />*/}
                </div>
            }));
        } 
        
        if( p.firstCountValidated ){
            arr.push(columnHelper.accessor('counter_2', {
                header: 'Compteur', 
                cell: (i) => <div className="text-center">
                    <div>{i.getValue()}</div>
                    <div className="fst-italic" style={{fontSize: "12px"}}>1er compteur: {i.row.original.counter}</div>
                </div>
            }));
                                     
            arr.push(columnHelper.accessor('stock_2', {
                header: 'Qté comptée', cell: (i) => <div 
                    className="text-center full-width-div ">
                    {/*i.row.original.ar != null &&
                     <input className={"form-control text-center " + ( i.getValue() !== null ? "bg-warning-subtle" : "" )} type="number"
                        onBlur={(e)=>onFieldChange(i.row.id, "stock_2", e.target.value)}
                        defaultValue={i.getValue()} />*/}
                    { i.getValue() !== null && 
                        <div className="fst-italic fw-bold" style={{fontSize: "12px"}}>2ème comptage: {i.getValue()}</div>
                    }
                    <div className="fst-italic" style={{fontSize: "12px"}}>1er comptage: {i.row.original.stock}</div>
                </div>
            }));
        } 
                                 
        arr.push(columnHelper.accessor('QTSTL', {
            header: 'Qté Focus', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
                                 
        arr.push(columnHelper.accessor('b2_qty', {
            header: 'Autre qté', cell: (i) => <div className="text-center">
                <div>B+2: {i.getValue() || 0}</div>
                <div>NC : {i.row.original.nc || 0}</div>
            </div>
        }));
                                 
        arr.push(columnHelper.accessor('delta', {
            header: <div>
            <div>Delta</div>
            <div>
                <CustomToolTipInfo props={{ tooltipText: "En cas de second comptage c'est ce dernier qui est pris en compte dans le calcul" }}/>
            </div>
        </div>, 
            cell: (i) => {
            var qty = i.getValue();
            return <div className="text-center full-width-div ">
                <div className="fw-bold">
                    {qty > 0 ? "+" : ""}{ qty }
                </div>    
            </div>}
        }));
                                 
        arr.push(columnHelper.accessor('delta_price', {
            header: <div>
                <div>Delta en $</div>
                <div>
                    <CustomToolTipInfo props={{ tooltipText: "Delta * prix de revient en valeur absolue. Ne tient pas compte du signe négatif pour plus de facilité lors du tri." }}/>
                </div>
            </div>, 
            cell: (i) => {
            return <div className={"text-center full-width-div " + getDeltaColor(i.getValue())}>
                <div>{ nbFormat( i.getValue(), "fr-FR", 2) } $</div> 
                               
            </div>}
        }));
                                 
        arr.push(columnHelper.accessor('alert', {
            header: 'Alerte', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
                                 
        arr.push(columnHelper.accessor('coms', {
            header: 'Commentaires', cell: (i) => <div className="text-center">
                <textarea className="form-control" defaultValue={i.getValue()}
                    onBlur={(e)=>onFieldChange(i.row.id, "coms", e.target.value)}/>
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();
    
    return <div>
        <h5 className="text-center">Tableau d'inventaire Focus</h5>

        
        {p.focusTable && <ReactTable columns={columns} data={p.focusTable} top0={true} 
                            tdStyle={{padding: "0"}}   origin="delta_scan_focus" />}

    </div>

}

export default FocusTable;