import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { getHeight, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { fileToBase64, forceString, getBase64, getLabelFromInput } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import BusinessDao from "../../services/machines/BusinessDao";
import DropDownMenu from "./smallCom/business/dropDownMenu";
import CReview from "./smallCom/contract-review/CReview";
import Context from "./smallCom/contract-review/Context";
import CrMachines from "./smallCom/contract-review/CrMachines";
import CustService from "./smallCom/contract-review/CustService";
import FinalCustomer from "./smallCom/contract-review/FinalCustomer";
import InvoicedCustomer from "./smallCom/contract-review/InvoicedCustomer";
import LimitOfServices from "./smallCom/contract-review/LimitOfServices";
import PayTerms from "./smallCom/contract-review/PayTerms";
import Schedule from "./smallCom/contract-review/Schedule";
import Logos from "./smallCom/contract-review/Logos";
import ShareFileDao from "../../services/common/ShareFileDao";
import ExportCr from "./smallCom/contract-review/ExportCr";
import Miscellaneous, { logObj } from "../../services/common/Miscellaneous";
import History from "../common/History";
import CustomSpeechRecognition, { markToListen } from "../../common/smallComponents.js/SpeechRecognition";

const ContractReview = (props) => {
    const [business, setBusiness] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);

    const [businessCard, setBusinessCard] = useState();
    const [focusInfo, setFocusInfo] = useState();
    const [terms, setTerms] = useState();
    const [dp, setDp] = useState();
    const [revisions, setRevisions] = useState();
    const [rcRecepients, setRcRecipients] = useState([]);
    const [path, setPath] = useState();
    const [history, setHistory] = useState([]);

    const [listen, setListen] = useState(false);
    const [elt, setElt] = useState();

    const user = JSON.parse(localStorage.getItem("user"));
    const readOnly = user && (!user.roles.includes("ROLE_SALESMAN") && !user.roles.includes("ROLE_ADMIN"));

    useEffect(() => {
        var business = getUrlParam(props, "business");

        if (business !== null) {
            setBusiness(business);
            setLoading(true);
            BusinessDao.getContractReview(business).then(
                (response) => {
                    if (forceString(response.focus_info.proj_man).trim() !==
                        forceString(response.business_card.chef_proj).trim()) {
                        response.business_card.chef_proj = response.focus_info.proj_man.trim();

                        BusinessDao.updateBusinessField(business, "chef_proj",
                            response.focus_info.proj_man.trim(), "text", false);
                    }

                    setBusinessCard(response.business_card);
                    setFocusInfo(response.focus_info);
                    setTerms(response.terms);
                    setDp(response.dp);
                    setRevisions(response.revisions);
                    setRcRecipients(response.rcRecepients);
                    setPath(response.path);

                    setLoading(false);
                    
                    markToListen(setListen, elt, setElt);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                });

            // History
            Miscellaneous.getLog({ id: business, origin: "rc" }).then(
                (response) => { setHistory(response) },
                (error) => { console.error(error) }
            )
        }
    }, []);

    const addLogo = async (e) => {

        var field = e.target.getAttribute("data-field");
        var o = { ...businessCard };

        const b64 = await fileToBase64( e.target.files[0] );

        o[field] = b64.fullBase64;
        setBusinessCard(o);
        e.target.value = "";
      

        var log = { ...logObj };
        log.origin = "rc";
        log.id = business.toUpperCase();
        log.log_text = "Mise à jour du logo client" + (field === "logo_client" ? "" : " final");;
        log.old_value = "";
        log.new_value = b64.name;
      
        BusinessDao.uploadFile(businessCard.id_aff, field, b64, "/upload-file/v2").then(
            (response) => { Miscellaneous.saveLog(log); },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
        
    }

    useEffect( () => { markToListen(setListen, elt, setElt); }, [tab])

    /**
     * Update business fields
     * @param {*} e 
     */
    const updateBusinessField = (e, value) => {
        var field = e.target.getAttribute("data-field");
        var translate = field.endsWith("_en");
        var value = value || e.target.value;
        var typeField = e.target.type;
        if (typeField === "textarea" || typeField === "select-one") typeField = "text";
        setError();

        var old_value = businessCard[field];

        if (forceString(old_value) === forceString(value) || old_value === null && value === "") return;
        
        var log = { ...logObj };
        log.origin = "rc";
        log.id = business;
        log.log_text = "Mise à jour du champ " + (getLabelFromInput(e.target, field) || field);
        log.old_value = old_value === null ? "" : old_value;
        log.new_value = value;

        BusinessDao.updateBusinessField(business, field, value, typeField, translate).then(
            () => { Miscellaneous.saveLog(log); },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    return <Fragment>
        {!business && <h5 className="text-center">Contract review</h5>}

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
        {loading && <WaitingRoundSnippers />}

        {!business && <form className="col-6 offset-3">
            <div className="input-group">
                <span className="input-group-text">Code affaire</span>
                <input className="form-control text-center fw-bold" name="business" required autoFocus></input>
                <button type="submit" className="btn btn-outline-success">Commencer</button>
            </div>
        </form>}

        {businessCard && <Fragment>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className={'nav-link ' + (tab === 0 ? "active fw-bold" : "")}
                        aria-current="page" href="#" onClick={() => setTab(0)}>Contract Review {business}</a>
                </li>

                <li className="nav-item">
                    <a className={'nav-link ' + (tab === 1 ? "active fw-bold" : "")}
                        href="#" onClick={() => setTab(1)}>Générer une fiche</a>
                </li>

                <li className="nav-item">
                    <a className={'nav-link ' + (tab === 2 ? "active fw-bold" : "")}
                        href="#" onClick={() => setTab(2)}>Historique</a>
                </li>

                <li className="nav-item">
                    <a className='nav-link ' href="/contract-review">Other contract</a>
                </li>
            </ul>

            {tab === 0 && <div>
                <InvoicedCustomer businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <FinalCustomer businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <CReview businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <Context businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <CrMachines businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <Schedule businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <LimitOfServices businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <CustService businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} readOnly={readOnly} />

                <PayTerms businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} terms={terms} readOnly={readOnly} />

                <Logos businessInfo={businessCard} focusInfo={focusInfo}
                    updateBusinessField={updateBusinessField} addLogo={addLogo} readOnly={readOnly} />
            </div>}

            {
                tab === 1 && <ExportCr businessInfo={businessCard} focusInfo={focusInfo}
                    revisions={revisions} terms={terms} rcRecepients={rcRecepients}
                    path={path} setMsg={setMsg} />
            }

            {tab === 2 && <History history={history} />}

        </Fragment>}


        {dp && <DropDownMenu list={dp} />}

        <CustomSpeechRecognition listen={listen} setListen={setListen}/>
    </Fragment>

}

export default ContractReview;