import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import Movement from "../../services/storehouse/Movement";
import { hasRole } from "../../functions/StrFunctions";

const ScanGetArtInfo = (props) => {
    const [error, setError] = useState();
    const [article, setArticle] = useState();
    const [articleInfo, setArticleInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [scanned, setScanned] = useState([]);
    const [locations, setLocations] = useState([]);
    let timer = null;

    const user = JSON.parse(localStorage.getItem("user"));
    const canWrite = hasRole(["ROLE_LOCATION_WRITE", "ROLE_ADMIN"]);
    const token = user && "Bearer " + user.accessToken;
    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "articles/info/get-drawing?Authorization=" + token + "&article=";

    useEffect( () => {
        Movement.getLocationsNames("").then(
            (response) => {
                setLocations(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );
    }, [])

    const onScan = (e) => {
        var scanned = document.getElementById("scanIpt").value;

        if (timer) clearTimeout(timer);

        if (scanned.length >= 14) {

            timer = setTimeout(() => {
                var scanned = document.getElementById("scanIpt").value;
                setArticle(scanned);
                document.getElementById("scanIpt").value = "";
            }, 100);

        }
    }

    useEffect(() => {
        if (article) {
            const found = scanned.find((a) => { return a.CDARL.trim() === article });

            if (found) {
                setArticleInfo(found);
            } else {
                setLoading(true);
                ArticleInfo.getAllArtInfo(article).then(
                    (res) => {
                        setScanned((state) => [...state, res.article]);
                        setArticleInfo(res.article);
                        setLoading(false);
                    }, (error) => {
                        setError(errorManagement.handleError(props, error));
                        setLoading(false);
                    }
                )
            }
        }
    }, [article])

    const onChangeLocation = (e) => {
        var o = {...articleInfo};
        o.ZN04PA = e.target.value;
        setArticleInfo(o);


        Movement.updateLocations("location", e.target.value, articleInfo.CDARL.trim()).then(
            (response) => { 
                var array = [...scanned];
                let index = array.findIndex(obj => obj.CDARL.trim() === articleInfo.CDARL.trim());
                if (index !== -1) array[index] = o;
                setScanned( array );
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        )
    }

    return <Fragment>
        <h4 className="text-center">Info article</h4>
        {article && <Fragment>
            <h5 className="text-center">{article}</h5>
            <div className="text-center">
                <a href="#" onClick={() => { setArticle(); setArticleInfo() }}>Scanner autre chose</a>
            </div>
        </Fragment>}
        {loading && <WaitingRoundSnippers />}

        {error && <ErrorMessage error={error} errorState={setError} />}

        {!article &&
            <div className="col-8 offset-2">
                <input className="form-control text-center mb-3" onChange={onScan}
                    placeholder="Cliquez ici pour commencer à scanner" id="scanIpt" autoFocus></input>
            </div>}

        {articleInfo && <div className="mt-3 ms-auto me-auto" style={{ width: "90%" }}>
            <div className="input-group mb-3">
                <span className="input-group-text" style={{ width: "120px" }}>Stock</span>
                <input type="text" className="form-control" value={articleInfo.QTSTL} readOnly />
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" style={{ width: "120px" }}>Renvoi</span>
                <select className="form-select" value={articleInfo.ZN04PA} disabled={!canWrite}
                    onChange={onChangeLocation}>
                    <option value="">Choisissez...</option>
                    {locations.map((v2, k2) => {
                        return <option key={"loc_" + k2} value={v2.value}>{v2.value}</option>
                    })}
                </select>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" style={{ width: "120px" }}>Emplacement</span>
                <input type="text" className="form-control" value={articleInfo.EMPSL === 878 ? "Stock mag." : "Sur affaire"} readOnly />
            </div>

            {
                (articleInfo.ZN09PA !== null && articleInfo.ZN09PA !== 'ignore') &&
                <div className="text-center mb-3">
                    <a className="btn btn-success" style={{ marginLeft: "auto" }}
                        target="_blank" href={url + articleInfo.CDARL.trim()}>Voir le plan</a>
                </div>
            }


            <div className="text-center fw-bold">
                <span>Nombre d'articles scannés: {scanned.length}</span>
            </div>
        </div>}

    </Fragment>

}

export default ScanGetArtInfo;