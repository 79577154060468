import { useState } from "react";
import { useEffect } from "react"
import { FileUploader } from "react-drag-drop-files";
import getUrlParam, { forceString, hasRole, updateDrawingUrl } from "../../functions/StrFunctions";
import DisposableArticles from "../../services/articles/DisposableArticles";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { setMessage } from "../../actions/message";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const ValidateThrow = (props) => {
    const [sent, setSent] = useState(false);
    const [error, setError] = useState();
    const [toDestroy, setToDestroy] = useState(false);
    const [inPalet, setInPalet] = useState(false);
    const [param, setParam] = useState();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState();
    const [addingFiles, setAddingFiles] = useState(false);
    const [destroyed, setDestroyed] = useState(false);
    const row = { dim1: null, dim2: null, dim3: null, weight: null, type: null };
    const [packing, setPacking] = useState([row]);

    const API_URL = process.env.REACT_APP_API_URL + "articles";
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user && "Bearer " + user.accessToken;

    useEffect(() => {
        var param = getUrlParam(props, "param");
        var statut = getUrlParam(props, "statut");
        //Check if there is status param else it's from CEO
        if (statut !== null) {
            if (statut === "in_palet") {
                setInPalet(true);
                setLoading(true);
                setParam(param);

                DisposableArticles.getById(param).then(
                    (response) => {
                        setList(response);
                        setLoading(false);
                        setDefaultPacking(response);
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        setLoading(false);
                    }
                );
            } else if (statut === "to_destroy") {
                setToDestroy(true);
                setParam(param);

                DisposableArticles.getById(param).then(
                    (response) => {
                        setList(response);
                        setLoading(false);
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        setLoading(false);
                    }
                );
            } else if (statut === "destroyed") {
                setDestroyed(true);
                setLoading(true);
                setParam(true);

                DisposableArticles.getById(param).then(
                    (response) => {
                        setList(response);
                        setLoading(false);
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        setLoading(false);
                    }
                );
            }
        } else {

            DisposableArticles.ceoValidation(param).then(
                () => {
                    setLoading(false);
                    setSent(true);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                }
            );
        }

    }, []);

    function setDefaultPacking(arr) {
        var hasSteel = false;
        for (let i = 0; i < arr.length; i++) {
            if (forceString(arr[i].MATIL) === "Métal") {
                hasSteel = true;
                break;
            }
        }

        var p = [];
        var o = { ...row }
        o.matter = "Autre"
        p.push(o);

        if (hasSteel) {
            o = { ...row }
            o.matter = "Métal";
            p.push(o);
        }

        setPacking(p);
    }

    /** Open drawing */
    var url2 = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    /** Create columns */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('article', {
            header: 'AR',
            cell: i => <div className={"text-center "}>
                <a href={"/article/info/get?article=" + i.getValue()} target="_blank">{i.getValue()}</a>
            </div>
        }));

        arr.push(columnHelper.accessor('DES2L', {
            header: 'Référence',
            cell: i => <div className={"text-center "}>
                {(i.row.original.pdf_plan && i.row.original.pdf_plan !== 'ignore') &&
                    <a target="_blank" href={url2 + updateDrawingUrl(i.row.original.pdf_plan)}>{i.getValue()}</a>}

                {(!i.row.original.pdf_plan || i.row.original.pdf_plan === 'ignore') &&
                    <span>{i.getValue()}</span>}
            </div>
        }));

        arr.push(columnHelper.accessor('qty_at_term', {
            header: 'Qté à jeter',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('dol_price', {
            header: 'Somme à jeter ($)',
            cell: i => <div className={"text-center "}>
                {parseFloat(i.getValue()) * parseFloat(i.row.original.qty_at_term)}
            </div>
        }));

        arr.push(columnHelper.accessor('ZN04PA', {
            header: 'Renvoi',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('MATIL', {
            header: 'Matière',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('com_ipt', {
            header: 'Commentaire',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('in_trash', {
            header: 'Statut',
            cell: i => <div className={"text-center "}>
                {i.getValue() && (i.getValue() === "E" ? "Jet validé par la hiérarchie" :
                    i.getValue() === "P" ? "Article en cours de palettisation" : "")}
            </div>
        }));



        return arr;
    }

    //Set columns
    const columns = getColumns();

    const onFileChange = (file) => {
        setAddingFiles(true);

        DisposableArticles.addDestroyFile(getUrlParam(props, "param"), file).then(
            (response) => {
                setSent(true);
                setAddingFiles(false);
                scrollToTop();
            },
            (error) => {
                setSent(true);
                setAddingFiles(false);
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    const downloadFile = (e) => {
        var url = e.target.getAttribute("data-url");
        url = url.substring(url.lastIndexOf("\\") + 1, url.length);
        window.open("", "_blank").location.href =
            API_URL + "/download-destroy-file?param=" + url + "&Authorization=" + token;
    }

    const onPackingChange = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var field = e.target.getAttribute("data-field");
        var arr = [...packing];
        arr[key][field] = e.target.value;
        setPacking(arr);
    }

    const addPacking = () => {
        var arr = [...packing];
        arr.push(row);
        setPacking(arr);
    }

    const deletePackingRow = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...packing];
        arr = arrayRemoveElt(arr, key);
        setPacking(arr);
    }

    const sendConfirmation = () => {
        setError();
        var err = "";
        var o;
        for (let i = 0; i < packing.length; i++) {
            o = packing[i];
            if (
                o.type === null || o.dim1.type === "" || o.dim1 === null || o.dim1.trim === "" ||
                o.dim2 === null || o.dim2.trim === "" || o.dim3 === null || o.dim3.trim === "" ||
                o.weight === null || o.dim3.weight === "") {
                err = "Remplissez tous les éléments des dimensions";
                setError(err);
                scrollToTop();
                break;
            }
        }


        if (err !== "") return;

        setLoading(true);
        DisposableArticles.setInPalet(param, packing).then(
            (response) => {
                setSent(true);
                setLoading(false);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    return (
        <div>
            {loading && <WaitingRoundSnippers />}

            {
                sent &&
                <div className="alert alert-success text-center fw-bold" role="alert">
                    Mail envoyé. Vous y serez en copie.
                </div>
            }

            {
                error &&
                <div className="alert alert-danger text-center fw-bold" role="alert">
                    {error}
                </div>
            }

            {
                toDestroy &&
                <div>
                    <h2 className="text-center">Articles à détruire</h2>
                </div>
            }

            {
                inPalet &&
                <div className="print-only mb-3">
                    <h2 className="text-center">Articles à jeter</h2>
                </div>
            }

            {
                (list && inPalet) &&
                <div className="alert alert-info col-10 offset-1 no-print">
                    <h4 className="text-center">Colisage</h4>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Matière</th>
                                <th scope="col">Type</th>
                                <th scope="col">Dim 1 (mm)</th>
                                <th scope="col">Dim 2 (mm)</th>
                                <th scope="col">Dim 3 (mm)</th>
                                <th scope="col">Poids (kg)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                packing.map((v, k) => {
                                    return <tr key={"r" + k}>
                                        <td>
                                            <select className="form-select" value={v.matter} data-key={k}
                                                onChange={onPackingChange} data-field="matter">
                                                <option value="">Choisissez...</option>
                                                <option value="Métal">Métal</option>
                                                <option value="Autre">Autre</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-select" value={v.type} data-key={k}
                                                onChange={onPackingChange} data-field="type">
                                                <option value="">Choisissez...</option>
                                                <option value="Palette">Palette</option>
                                                <option value="Carton">Carton</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="number" className="form-control" data-key={k}
                                                onChange={onPackingChange} data-field="dim1" value={v.dim1}></input>
                                        </td>
                                        <td>
                                            <input type="number" className="form-control" data-key={k}
                                                onChange={onPackingChange} data-field="dim2" value={v.dim2}></input>
                                        </td>
                                        <td>
                                            <input type="number" className="form-control" data-key={k}
                                                onChange={onPackingChange} data-field="dim3" value={v.dim3}></input>
                                        </td>
                                        <td>
                                            <input type="number" className="form-control" data-key={k}
                                                onChange={onPackingChange} data-field="weight" value={v.weight}></input>
                                        </td>
                                        {
                                            k > 0 &&
                                            <td>
                                                <img style={{ width: "30px" }} data-key={k} onClick={deletePackingRow}
                                                    src={"/common/remove_icon.png"}></img>
                                            </td>
                                        }
                                    </tr>
                                })

                            }
                        </tbody>
                    </table>

                    <img src={"/common/add-logo.png"} onClick={addPacking}></img>
                    <br></br><br></br>
                    <div className="text-center">
                        <button className="btn btn-success" onClick={sendConfirmation} disabled={loading}>
                            {loading && <ButtonWaiting />}
                            Valider
                        </button>
                    </div>
                </div>
            }

            {
                list &&
                <div>
                    {
                        list[0].destroy_file &&
                        <div className="text-center mb-2 no-print">
                            <button type="button" className="btn btn-secondary"
                                data-url={list[0].destroy_file}
                                onClick={downloadFile}>Télécharger le bordereau</button>
                        </div>
                    }

                    <ReactTable columns={columns} data={list}
                        origin="disposable_throw" classes={"no-padding fixFstCol"} top0={true} />
                    <br /><br />

                    {
                        (list.length > 0 && !list[0].destroy_file && hasRole(["ROLE_QHSE", "ROLE_ADMIN"])) &&
                        <div className="text-center">
                            <h2>Uploadez la fiche de detruction.</h2>
                            <p>Cette action basculera automatiquement le statut des pièces à "Détruit"</p>

                            <FileUploader handleChange={onFileChange} name="file" multiple={false}
                                disabled={addingFiles} classes="w-100"
                                children={
                                    !addingFiles ? (
                                        <div className="uploadFiles" style={{ overflow: "hidden", borderColor: "black" }}>
                                            <h4>Sélectionnez ou glissez votre fichier ici</h4>
                                            <img className="sideNavUploadImg" src="/common/upload_icon.png" style={{ width: "50px", marginTop: "2%" }}></img>
                                        </div>) :
                                        (<div className="text-center">
                                            En cours de traitement...
                                        </div>)
                                } />
                        </div>
                    }

                </div>
            }

        </div>
    );
}

export default ValidateThrow;