import { Fragment } from "react";
import Moment from "moment";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { getFrFormat } from "../../../functions/StrFunctions";

const Historic = (p) => {
    Moment.locale("fr");

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('date_', {
            header: 'Date',
            cell: i => <div className="text-center fw-bold">{ getFrFormat( i.getValue(), false)}</div>
        }));

        arr.push(columnHelper.accessor('old_value', {
            header: 'Ancienne valeur',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('new_value', {
            header: 'Nouvelle valeur',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Rep.',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('author', {
            header: 'Auteur',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('log', {
            header: 'Log',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));


        return arr;
    }
 
    //Set columns
    const columns = getColumns();

    return <Fragment>

        <ReactTable columns={columns} data={p.historic}
                origin="bom_content" classes={"no-padding"} top0={true} />

        
    </Fragment>

}

export default Historic;