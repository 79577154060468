import { Fragment, useState } from "react";
import { SmallFrame } from "../../../../common/smallComponents.js/CustomDivs";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../../common/smallComponents.js/ReactTable";
import { ArticleAutoComplete } from "../../../../common/smallComponents.js/DesignedIpt";
import { removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../../../functions/PageAnimation";
import { forceString } from "../../../../functions/StrFunctions";

const OtherPicking = (p) => {
    const [search, setSearch] = useState(false);
    const [tab, setTab] = useState("");
    const [data, setData] = useState();
    const [value, setValue] = useState();
    const [added, setAdded] = useState("");


    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('key',
            {
                header: '',
                cell: (i) => <div className="text-center">
                    {!hideButton(i.row.original) && <button className="btn btn-outline-success"
                        onClick={() => addArticle(i.row.id)}>Ajouter</button>}
                </div>
            }));

        arr.push(columnHelper.accessor('affaire',
            { header: 'Affaire', cell: (i) => <div className="text-center">{i.getValue()}</div> }));


        arr.push(columnHelper.accessor('ar',
            { header: 'Article', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('label',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            { header: 'Ref.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('fonction',
            { header: 'Machine', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        return arr;
    }

    function hideButton(v) {
        if (!v) return;

        const foundKey = p.picking.currentPicking.find((a) => { return a.source === v.source && a.id === v.id });

        return foundKey !== undefined;
    }

    const changeTab = (tab) => {
        setTab(tab);
        setData(p.picking.picking[tab.trim()])
    }

    const addArticle = (k) => {
        var picking = { ...p.picking };

        var o = {
            ...data[k],
            machine: -1,
            id: forceString( data[k].source ).trim() + "_" + data[k].id,
            ar: data[k].ar,
            av: null,
            display: 1,
            type: "Article"
        }
     
        picking.currentPicking.push(o);
        p.setPicking(picking);
    }

    const onChooseArt = (v) => {
        var picking = { ...p.picking };
        picking.currentPicking.push({
            source: "free",
            "ar": v.CDARL,
            "qty": 1,
            "ref": v.ZN02PA,
            "en_label": v.ZN14PA,
            "label": v.ZN03PA,
            "dim1": v.DIM1L,
            "dim2": v.DIM2L,
            "dim3": v.DIM3L,
            "weight": v.POIDL,
            "hst_code": v.doua,
            "hs_code": v.zn23pa
        })
        p.setPicking(picking);
        setValue();
        setAdded(v.CDARL)
    }

    const removeArticle = (k) => {
        var picking = { ...p.picking };
        picking.currentPicking = removeAndReturnArray(picking.currentPicking, k);
        p.setPicking(picking);
    }

    const columns = getColumns();

    return <Fragment>
        <h5 className="text-center">Liste de picking {p.pickingId ? "n°" + p.pickingId : ""}</h5>

        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Qté</th>
                    <th scope="col">Article</th>
                    <th scope="col">Désignation</th>
                    <th scope="col">Référence</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {p.picking && p.picking.currentPicking && p.picking.currentPicking.map((v, k) => {
                    return <tr key={k}>
                        <td>
                            <input className="form-control text-center" type="number" value={v.qty}
                                style={{ width: "80px" }} />
                        </td>
                        <td>{v.ar}</td>
                        <td>{v.label}</td>
                        <td>{v.ref}</td>
                        <td>
                            <img className="see-img w-30" src="/common/remove.png"
                                onClick={() => removeArticle(k)} />
                        </td>
                    </tr>
                })}
            </tbody>
        </table>

        <button className="btn btn-secondary mt-3" onClick={() => setSearch(true)}>Ajouter des éléments</button>

        <div className="text-center mt-3">
            <button className="btn btn-success" disabled={p.saving} onClick={() => p.saveOtherPicking(false)}>
                {p.saving && <ButtonWaiting />}
                Sauvegarder
            </button>

            <button className="btn btn-success ms-3" disabled={p.saving} onClick={() => p.saveOtherPicking(true)}>
                {p.saving && <ButtonWaiting />}
                Sauvegarder et envoyer un mail au SLOG
            </button>
        </div>

        {search && <SmallFrame close={() => { setSearch(false); setAdded() }} component={<div>
            <h5 className="text-center">Ajouter des articles</h5>

            <ul class="nav nav-tabs">
                {(p.picking && p.picking.picking.toDo.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "toDo" ? "active" : "")}
                            onClick={() => changeTab("toDo")}>Sauvegardé</a>
                    </li>}

                {(p.picking && p.picking.picking.sav.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "sav" ? "active" : "")}
                            onClick={() => changeTab("sav")}>Du picking SAV</a>
                    </li>}

                {(p.picking && p.picking.picking.mes.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "mes" ? "active" : "")}
                            onClick={() => changeTab("mes")}>Du picking MeS</a>
                    </li>}

                {(p.picking && p.picking.picking.counter.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "counter" ? "active" : "")}
                            onClick={() => changeTab("counter")}>De la sortie comptoir</a>
                    </li>}

                {(p.picking && p.picking.picking.kit.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "kit" ? "active" : "")}
                            onClick={() => changeTab("kit")}>De la demande de kit REPA</a>
                    </li>}

                <li class="nav-item">
                    <a class={"nav-link fw-bold text-dark " + (tab === "article" ? "active" : "")}
                        onClick={() => changeTab("article")}>Ajout libre</a>
                </li>
            </ul>

            {(data && data.length > 0) &&
                <ReactTable columns={columns} data={data} origin="picking_ship_other" classes={"no-padding fixFstCol "} top0={true} />
            }

            {tab === "article" && <div className="text-center mt-3">
                {added && <div className="alert alert-success text-center fw-bold mb-3">{added} ajouté !</div>}
                <ArticleAutoComplete onChooseArt={onChooseArt} value={value} setValue={setValue} searchDetail={true} />
            </div>}
        </div>} />
        }
    </Fragment>

}

export default OtherPicking;