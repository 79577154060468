import React, { useState, useRef } from "react";


import { useEffect } from "react";
import getUrlParam from "../../functions/StrFunctions";
import userService from "../../services/user.service";


const Confirmation = (props) => {
  const [content, setContent] = useState("");

  useEffect( () => {
          userService.getConfirmation(getUrlParam( props, "param" )).then(
        (response) => {
          setContent(response.message);
        },
        (error) => {
          setContent(error.response.data.message);
        }
      );
  }, [] );


  return (
    <div className="col-md-12">
      <div className="card text-center font-weight-bold">
        <pre>{ content }</pre>
      </div>
    </div>
  );
};

export default Confirmation;
