import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { Fragment } from "react";
import { arrayRemoveElt } from "../../../../functions/ArrayFunctions";
import Miscellaneous, { logObj } from "../../../../services/common/Miscellaneous";
const PartEight = (p) => {
    const [searching, setSearching] = useState(false);
    const [boxes, setBoxes] = useState();

    const duplicateMachine = (e) => {
        e.preventDefault();
        var toDuplicate = e.target.machine_to_duplicate.value;

        var log = { ...logObj };
        log.id = p.machineInfo.no_machine;
        log.origin = "machine";
        log.log_text = "Copie des données de la machine " + toDuplicate;
        log.old_value = "";
        log.new_value = "";


        confirmAlert({
            title: "Dupliquer une machine",
            message: "Etes-vous sûr de copier les infos de la machine " + toDuplicate
                + " dans la fiche machine " + p.machineInfo.no_machine + " ? Attention: les données déjà saisies seront écrasées.",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        MachineInfo.duplicateMachine(p.machineInfo.no_machine, toDuplicate).then(
                            () => {
                                Miscellaneous.saveLog(log)
                                window.location.reload();
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                            }
                        )

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const duplicateBox = (e) => {
        e.preventDefault();
        var m1 = e.target.boxMachine1.value;
        var m2 = e.target.boxMachine2.value;
        var m3 = e.target.boxMachine3.value;

        var arr = new Array();
        if (m1 !== "") arr.push(m1);
        if (m2 !== "") arr.push(m2);
        if (m3 !== "") arr.push(m3);
 
        setSearching(true);
        MachineInfo.getBoxes(arr).then(
            (response) => {
                setBoxes(response);
                setSearching(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setSearching(false);
            }
        )
    }

    const changeBoxToDuplicate = (e) => {
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var arr = [...boxes];
        arr[k][field] = value;

        setBoxes(arr);
    }

    const removeBoxRow = (e) => {
        var k = e.target.getAttribute("data-key");
        var arr = [...boxes];
        arr = arrayRemoveElt(arr, k);
        setBoxes(arr);
    }

    const duplicateBoxes = () => {
        if (boxes.length === 0) {
            p.setError("Aucune caisse définie !");
            scrollToTop();
            return;
        }

        var machines = "";
        for( let i = 0; i < boxes.length; i++)
            machines += "#" + boxes[i].no_machine + " piste " + boxes[i].no_piste
                + " caisse " + boxes[i].no_caisse + ","
        
        machines = machines.substring( 0, machines.lastIndexOf(",") );
        
        var log = { ...logObj };
        log.id = p.machineInfo.no_machine;
        log.origin = "machine";
        log.log_text = "Copie des caisses de machine(s)";
        log.old_value = "";
        log.new_value = machines;


        setSearching(true);
        MachineInfo.copyBoxes(p.machineInfo.no_machine, boxes).then(
            () => {
                Miscellaneous.saveLog(log)
                window.location.reload();
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setSearching(false);
            }
        )
    }

    const deleteMachine = () => {
        confirmAlert({
            title: "Supprimer une machine",
            message: "Etes-vous sûr de vouloir supprimer la fiche machine " + p.machineInfo.no_machine + " ainsi que la ligne Monday ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setSearching(true);
                        MachineInfo.deleteMachine(p.machineInfo.no_machine, p.machineInfo.id_monday).then(
                            () => {
                                window.location.href = "my-space";
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                                setSearching(false);
                            }
                        )

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return <div className="table-responsive">
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col" colSpan={3}>Informations complémentaires</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.no_machine_client} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="no_machine_client" data-type="text" readOnly={p.readOnly} />
                                <label>N° de machine client</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.no_serie_fab} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="no_serie_fab" data-type="text" readOnly={p.readOnly} />
                                <label>N° de série fabricant</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.no_embase_fugitive} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="no_embase_fugitive" data-type="text" readOnly={p.readOnly} />
                                <label>N° de série embase fugitive</label>
                            </div>
                        </td>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.poids_mach || p.machineInfo.weight} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="poids_mach" data-type="number" readOnly={p.readOnly} />
                                <label>Poids de la machine</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.no_bac_colle} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="no_bac_colle" data-type="text" readOnly={p.readOnly} />
                                <label>N° de série bac à colle</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.no_serie_fullfill} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="no_serie_fullfill" data-type="text" readOnly={p.readOnly} />
                                <label>N° de série fullfill</label>
                            </div>
                        </td>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.no_serie_embase} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="no_serie_embase" data-type="text" readOnly={p.readOnly} />
                                <label>N° de série embase</label>
                            </div>

                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo.no_bac_fugitive} placeholder="xxx"
                                    onBlur={p.updateMachineTable}
                                    data-field="no_bac_fugitive" data-type="text" readOnly={p.readOnly} />
                                <label>N° de série bac fugitive</label>
                            </div>

                        </td>

                    </tr>


                </tbody>
            </table>
            <br></br>

            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Les autres machines</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center">
                            <div className="display-flex">
                                {p.sibling &&
                                    p.sibling.map((v, k) => {
                                        return v.no_machine !== p.machineInfo.no_machine && <div className="card">
                                            <div className="card-body">
                                                <span>{v.no_machine} ({v.typ_config})</span>
                                                <br></br><br></br>
                                                <a href={"/machine?machine=" + v.no_machine} className="btn btn-outline-secondary">Ouvrir</a>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </td>
                    </tr>


                </tbody>
            </table>
            <br></br>
            {!p.readOnly &&
                <table className="table businessCardStyle">
                    <thead>
                        <tr className="bg-dark bg-gradient text-white text-center rounded">
                            <th scope="col">Outils</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <h5 className="text-center">Copier les infos d'une autre machine ici</h5>
                                <div className="alert alert-info">
                                    Depuis le 25/09/24, les options ayant été supprimés dans le menu déroulant, mais présents sur une ancienne machine, 
                                    ne seront pas copiés sur la nouvelle machine
                                </div>
                                <form onSubmit={duplicateMachine} className="col-8 offset-2">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1">Numéro de machine "modèle"</span>
                                        <input type="text" className="form-control" name="machine_to_duplicate" placeholder="Saisissez le préfixe également, ex: 22/1863" required />
                                        <button className="btn btn-success">Dupliquer</button>
                                    </div>
                                </form>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <h5 className="text-center">Copier les caisses d'autres machines ici</h5>
                                <p className="text-center">Il vous sera affiché les caisses des machines trouvées puis vous sélectionnerez la ou les caisses qui vous intéressent<br></br>
                                    Vous pouvez combiner les caisses de 3 machines différentes max.</p>
                                <form onSubmit={duplicateBox} className="col-8 offset-2">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1">Numéro de machine(s) "modèle(s)"</span>
                                        <input type="text" className="form-control" name="boxMachine1" placeholder="Saisissez le préfixe également, ex: 22/1863" required />
                                        <input type="text" className="form-control" name="boxMachine2" placeholder="Saisissez le préfixe également, ex: 22/1863" />
                                        <input type="text" className="form-control" name="boxMachine3" placeholder="Saisissez le préfixe également, ex: 22/1863" />
                                        <button className="btn btn-success" disabled={searching}>
                                            {searching && <ButtonWaiting />}
                                            Chercher
                                        </button>
                                    </div>
                                </form>

                                {
                                    boxes &&
                                    <Fragment>
                                        <table className="table businessCardStyle">
                                            <thead>
                                                <tr className="bg-dark bg-gradient text-white text-center rounded">
                                                    <th scope="col" className=" text-center">#caisse</th>
                                                    <th scope="col" className=" text-center">#piste</th>
                                                    <th scope="col" className=" text-center">Type de caisse</th>
                                                    <th scope="col" className=" text-center">Cannelure</th>
                                                    <th scope="col" className=" text-center">L (ext)</th>
                                                    <th scope="col" className=" text-center">W (ext)</th>
                                                    <th scope="col" className=" text-center">H (ext)</th>
                                                    <th scope="col" className=" text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    boxes.map((v, k) => {
                                                        return <tr key={"k" + k} className="text-center">
                                                            <td>
                                                                <input className="form-control text-center" onChange={changeBoxToDuplicate}
                                                                    data-key={k} data-field="no_caisse" value={v.no_caisse}></input>
                                                            </td>
                                                            <td>
                                                                <input className="form-control text-center" onChange={changeBoxToDuplicate}
                                                                    data-key={k} data-field="no_piste" value={v.no_piste}></input>
                                                            </td>
                                                            <td>{v.type_caisse}</td>
                                                            <td>{v.l_dim_ext}</td>
                                                            <td>{v.w_dim_ext}</td>
                                                            <td>{v.h_dim_ext}</td>
                                                            <td></td>
                                                            <td>
                                                                <button className="btn btn-outline-warning" data-key={k} onClick={removeBoxRow}>Ne pas copier</button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }


                                            </tbody>
                                        </table>

                                        <div className="text-center">
                                            <button className="btn btn-success" disabled={searching} onClick={duplicateBoxes}>
                                                {searching && <ButtonWaiting />}
                                                Lancer la copie
                                            </button>
                                        </div>
                                    </Fragment>
                                }
                            </td>
                        </tr>

                        <tr className="text-center">
                            <br></br>
                            <button className="btn btn-outline-danger"
                                onClick={deleteMachine} disabled={searching}>
                                {searching && <ButtonWaiting />}
                                Supprimer la machine
                            </button>
                        </tr>

                    </tbody>
                </table>

            }

            {
                (p.readOnly && p.revisions) &&
                <div className='alert alert-info col-8 offset-2'>
                    <h5>Révision(s)</h5>
                    {
                        p.revisions &&
                        <Fragment>
                            <ul>
                                {
                                    p.revisions.map((v, k) => {
                                        return <li key={"rev" + k}>{v.date_suivi}: {v.commentaire_suivi}</li>
                                    })
                                }
                            </ul>
                        </Fragment>
                    }
                </div>
            }



        </div>
    </div>
}

export default PartEight;