import { Fragment, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { getSubAssemblySheetContent, getXlsxSheetContent } from "../../functions/XlsxMgt";
import errorManagement from "../../services/errorManagement";
import BomDaoV2 from "../../services/machines/BomDaoV2";
import MachineInfo from "../../services/machines/MachineInfo";
import { categoryRowAnalyse, checkArWithoutGeneralBom, checkTypeOfBom, duplicateRowAnalyse, generalBomAnalyse, paintedArticleAnalyse, refSynthaxAnalyse, setMachineInfoAnalyse } from "./BomChecking";
import { controlBom } from "./BomReading";


const ReadBom = (props) => {
    const [analyse, setAnalyse] = useState();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [machine, setMachine] = useState();
    const [machineInfo, setMachineInfo] = useState();
    const [file, setFile] = useState();
    const [error, setError] = useState();
    const [xls, setXls] = useState();
    const [canSave, setCanSave] = useState();
    const [msg, setMsg] = useState();
    const user = JSON.parse(localStorage.getItem("user"));

    

    /**
     * Get machine info and read file
     * @param {*} e 
     * @returns 
     */
    const readFile = (e) => {
        e.preventDefault();
        setAnalyse();
        setError();
        setMsg();
        setCanSave();

        setLoading(true);
        MachineInfo.getOneMachineInfo(machine).then(
            (response) => {
                if (response === null || response === "") {
                    setError("N° de machine non trouvé");
                    setLoading(false);
                } else {
                    setMachineInfo(response);
                    setMachine(response.no_machine);
                    getXlsxSheetContent(file, 1, function (res) {
                        controlBom(file, res, setAnalyse, response, function (res2) {
                            setLoading(false);
                            setXls(res2.list);
                            document.getElementById("fileInput").value = "";
                            var flag = true;
                            res2.analyse.map(v => { if (v.status !== "ok") { flag = false } });
                            setCanSave(flag);

                        });
                    });
                }

            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    /**
     * 
     * 
     * @param {*} e 
     * @returns 
     */
    const readFiles = async (e) => {
        e.preventDefault();
        setAnalyse();
        setError();
        setMsg();
        setCanSave();
        setLoading(true);
        var save = true;

        var files = e.target.file.files;
        var hasEG = false;
        var subsets = new Array();
        for (let i = 0; i < files.length; i++) {
            if (files[i].name.substring(12, 16).indexOf("EG") !== -1) hasEG = true;
            subsets.push(files[i].name.substring(12, 16));
        }

        setFile(files[0]);

        //Check if general BOM is present
        if (!hasEG) {
            setError("Importez la nomenclature EG également");
            scrollToTop();
            return;
        }

        // Compile the BOM
        var compilation = new Array();
        for (let i = 0; i < files.length; i++) {
            try {
                const f = await getSubAssemblySheetContent(files[i]);
                compilation = compilation.concat(f);
            } catch (e) {
                setError(files[i].name + ": <br>" + e);
                setLoading(false);
                scrollToTop();
                return;
            }
        }

        //Start analyse
        var arr = new Array();

        //Get type of BOM
        const typeOfBom = await checkTypeOfBom(files);
        arr.push(typeOfBom);
        setAnalyse(arr);
        if (typeOfBom.status === "err" || typeOfBom.status === "error") save = false;

        //Get machine infos
        const machInfo = await MachineInfo.getOneMachineInfo(machine);
        if (machInfo === "") {
            setError("N° de machine inconnu");
            setLoading(false);
            return;
        }
        arr.push(setMachineInfoAnalyse(machInfo));
        setAnalyse(arr);
        setMachineInfo(machInfo);
        setMachine(machInfo.no_machine);
        if (machInfo.status === "err" || machInfo.status === "error") save = false;

        // Check if all categories row are filles
        const catCheck = await categoryRowAnalyse(compilation);
        arr.push(catCheck);
        setAnalyse(arr);
        if (catCheck.status === "err" || catCheck.status === "error") save = false;

        // Created painted rows
        const painted = paintedArticleAnalyse(compilation);
        if (painted.paintedArr.length > 0) compilation = compilation.concat(painted.paintedArr);
        arr.push(painted);
        setAnalyse(arr);
        if (painted.status === "err" || painted.status === "error") save = false;

        //Get already imported BOM if exists
        if (typeOfBom.value === null) {
            setError("Impossible de poursuivre car nous ne connaissons pas le type de nomenclature (MECA ou ELEC)");
            setLoading(false);
            return;
        }

        const importedBom = await BomDaoV2.getBomExceptSubsets(machInfo.no_machine, typeOfBom.value, subsets);
        arr.push({
            label: "Récupération de nomenclature déjà importée",
            status: "ok",
            comments: importedBom.length + " ligne(s) récupérés de la dernière nomenclature importée et validée"
        });
        setAnalyse(arr);

        if (importedBom.length > 0) compilation = compilation.concat(importedBom);

        //Check if there are doublon
        const doublon = await duplicateRowAnalyse(compilation);
        arr.push(doublon);
        setAnalyse(arr);
        if (doublon.status === "err" || doublon.status === "error") save = false;

        //Check general BOM
        const egCheck = await generalBomAnalyse(compilation, machInfo);
        arr.push(egCheck);
        setAnalyse(arr);
        if (egCheck.status === "err" || egCheck.status === "error") save = false;

        //Check if ref is well written
        const refCheck = await refSynthaxAnalyse(compilation);
        arr.push(refCheck);
        setAnalyse(arr);
        if (refCheck.status === "err" || refCheck.status === "error") save = false;

        //Check if all article has its EG BOM
        const egCheck2 = checkArWithoutGeneralBom(compilation);
        arr.push(egCheck2);
        setAnalyse(arr);
        if (egCheck2.status === "err" || egCheck2.status === "error") save = false;

        //If no problem set can save
        setCanSave(save);
        setXls(compilation);
        
        document.getElementById("fileInput2").value = "";

        setLoading(false);

    }

    /**
     * Set Achine number
     */
    const onMachineChange = (e) => {
        setMachine(e.target.value);
    }

    const onFileChange = (e) => {
        var file = e.target.files[0];

        if (file === undefined) {
            setFile();
        } else {
            setFile(file);
        }
    }

    /**
     * Save BOM
     */
    const saveBom = () => {
        setSaving(true);
        setError();

        setMsg("Enregistrement en cours...");

        BomDaoV2.saveBom(machineInfo, analyse, xls).then(
            (response) => {
                setSaving(false);
                setMsg("Redirection en cours...");
                window.location.href = "/bom/get?machine=" + machine 
                    + "&type=" + (file.name.startsWith("NM") ? "MECA" : "ELEC")
                    +"&status=En attente de validation BE"
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        )
    }

    return (
        <Fragment>
            <h4 className="text-center">Lecture de nomenclature compilée</h4>
            <br></br>

            {error && <ErrorMessage error={error} errorState={setError} />}

            <form onSubmit={readFile} className="col-6 offset-3">
                <div className="input-group mb-3">
                    <span className="input-group-text">N° de machine</span>
                    <input type="text" className="form-control text-center fw-bold" onChange={onMachineChange}
                        placeholder="Préfixe conseillé en cas de rétrofit" required />
                    <input type="file" id="fileInput" className="form-control" onChange={onFileChange} required />
                    <button type="submit" className="btn btn-success">Commencer</button>
                </div>
            </form>

            <div>
                <div className="divider div-transparent"></div>
                <h4 className="text-center">Lecture de nomenclatures NON compilée</h4>
                <h5 className="text-center">Sélectionnez ou glisser la NM EG + les S/E que vous voulez</h5>
                <form onSubmit={readFiles} className="col-6 offset-3">
                    <div className="input-group mb-3">
                        <span className="input-group-text">N° de machine</span>
                        <input type="text" className="form-control text-center fw-bold" onChange={onMachineChange}
                            placeholder="Préfixe conseillé en cas de rétrofit" required multiple />
                        <input type="file" id="fileInput2" name="file" className="form-control"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            required multiple />
                        <button type="submit" className="btn btn-success">Commencer</button>
                    </div>
                </form>
            </div>


            {
                loading && <Fragment>
                    <h5 className="text-center">
                        <ButtonWaiting />
                        Analyse en cours...
                    </h5>

                </Fragment>
            }

            {
                analyse &&
                <Fragment>
                    <br></br><br></br>

                    <div className="col-10 offset-1">
                        <table className="table" style={{ height: "25px" }}>
                            <tbody>
                                {
                                    analyse.map((v, k) => {
                                        return v !== undefined &&
                                            <tr key={"k" + k}>
                                                <th scope="row"
                                                    className={v.status === "ok" ?
                                                        "bg-success bg-gradient" :
                                                        v.status === "loading" ? "bg-warning bg-gradient" : "bg-danger bg-gradient"}></th>
                                                <td>{v.label}</td>
                                                <td>
                                                    <span dangerouslySetInnerHTML={{ __html: v.comments }}></span>
                                                </td>
                                            </tr>
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </Fragment>
            }

            {msg && <SuccessMessage msg={msg} />}

            {
                (canSave && xls) &&
                <div className="text-center">
                    <button className="btn btn-success" onClick={saveBom} disabled={saving}>
                        {saving && <ButtonWaiting />}
                        Enregistrer la nomenclature de {xls.length} articles
                    </button>
                </div>
            }


        </Fragment>)
}

export default ReadBom;