import { useState } from "react";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { scrollToTop } from "../../../../functions/PageAnimation";
import { forceString, getLabelFromInput } from "../../../../functions/StrFunctions";
import Miscellaneous, { logObj } from "../../../../services/common/Miscellaneous";
const PartFour = (p) => {
    const [dp] = useState(p.dp);

    const onSelectChange = (e) => {
        saveChange(e);
    }

    const onInputType = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...p.machineInfo };
        o[field] = value;
        p.setMachineInfo(o);
    }

    const onInputChange = (e) => {
        saveChange(e);
    }

    const saveChange = (e) => {
        if( p.readOnly ) return;
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var old_value = p.machineInfo[field];

        if (forceString(old_value) === forceString(value) || old_value === null && value === "") return;

        var log = { ...logObj };
        log.origin = "machine";
        log.id = p.machineInfo.no_machine;
        log.log_text = "Mise à jour du champ " + (getLabelFromInput(e.target, field) || field);
        log.old_value = old_value === null ? "" : old_value;
        log.new_value = value;

        var o = { ...p.machineInfo };
        o[field] = value;
        p.setMachineInfo(o);

        MachineInfo.updateGap(p.machineInfo.no_machine, field, value).then(
            () => { Miscellaneous.saveLog(log); },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }
 
    return <div className="table-responsive">
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col" colSpan={3}>Cale</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center" style={{ width: "50%" }}>
                            <div className="form-floating mb-3">
                                <select className="form-select" data-field="cannelure_cale" disabled={p.readOnly}
                                    value={p.machineInfo.cannelure_cale} onChange={onSelectChange}>
                                    <option value=""></option>
                                    {
                                        p.dp && p.dp.map((v, k2) => {
                                            if (v.field === "cannelure")
                                                return <option key={"box1Type" + k2} value={v.label}>{v.label}</option>
                                        })
                                    }
                                </select>
                                <label for="Fabricant">Cannelure</label>
                            </div>

                            <div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={p.machineInfo.hauteur_rabat_cale}
                                        placeholder="xxx" data-field="hauteur_rabat_cale" readOnly={p.readOnly}
                                        onBlur={onInputChange} />
                                    <label>Rabats</label>
                                </div>
                            </div>
                        </td>

                        <td className="border-end text-center" style={{ width: "50%" }}>
                            <div className="form-floating mb-3">
                                <textarea type="text" className="form-control" defaultValue={p.machineInfo.particularites_cale}
                                    placeholder="xxx" data-field="particularites_cale" readOnly={p.readOnly}
                                    onBlur={onInputChange} style={{ minHeight: "100px" }} />
                                <label>Particularités</label>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    </div >
}

export default PartFour;