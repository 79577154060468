import { Fragment, useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import { useMemo } from "react";
import { DefaultColumnFilter, SelectEmptyOrNot, filterMethods } from "../../functions/TableFuntions";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import NeedsMngt from "../../services/articles/NeedsMngt";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import Moment from "moment";
import { arrayRemoveElt, getKey } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { confirmAlert } from "react-confirm-alert";
import LabelsChoice from "../../common/smallComponents.js/LabelsChoice";
import Movement from "../../services/storehouse/Movement";
import { downloadSingleFile } from "../../services/functions/FilesProcess";

const DisplayKiNeeds = (props) => {
    const [id, setId] = useState();
    const [type, setType] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [header, setHeader] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [data, setData] = useState([]);
    const [moving, setMoving] = useState(false);
    const [sending, setSending] = useState(false);
    const [consumables, setConsumables] = useState();
    const [bodyMailStorehouse, setbodyMailStorehouse] = useState("Nous avons traité ce kit. Merci de passer le récupérer");
    const [typeLabel, setTypeLabel] = useState("available");
    const [customLabels, setCustomLabels] = useState([]);
    Moment.locale("fr");

    useEffect(() => {
        var id = getUrlParam(props, "id");
        setId(id);
        setType(getUrlParam(props, "type"));

        NeedsMngt.getKitNeedById(id).then(
            (response) => {
                setHeader(response.header);
                setData(response.detail);
                setConsumables(response.consumables);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

    }, []);
 
    //Set columns
    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "normm"
            },
            {
                Header: "Article",
                accessor: "cdarm"
            },
            {
                Header: "Référence",
                accessor: "zn02pa"
            },
            {
                Header: "Libellé",
                accessor: "zn03pa"
            },
            {
                Header: "Qte att.",
                accessor: "qtram"
            },
            {
                Header: "Qte réservée",
                accessor: "affected",
                Filter: SelectEmptyOrNot,
                filter: 'empty'
            },
            {
                Header: "Reste à préparer",
                accessor: "remain"
            },
            {
                Header: "Qté dispo en stock",
                accessor: "available"
            },
            {
                Header: "Cmd. stock",
                accessor: "cmd_stck",
                Filter: SelectEmptyOrNot,
                filter: 'empty'
            },
            {
                Header: "Délai demandé",
                accessor: "dtlim"
            },
            {
                Header: "Cmd. besoins",
                accessor: "cmd_need",
                Filter: SelectEmptyOrNot,
                filter: 'empty'
            },
            {
                Header: "Fournisseur",
                accessor: "cdfou"
            }
        ], []
    );

    //Set filters for table
    const filterTypes = useMemo(() => (filterMethods()), []);

    //Set default columns of table
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    //Create table
    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance


    /**
     * Create labels
     */
    const exportLabels = (start) => {
        var arr = new Array();

        if( typeLabel === "csm" ){
            arr = consumables;
        }else if( typeLabel === "custom" ){
            var arr = new Array();
            customLabels.map(v => arr.push(data[v]));
        }   
        else{
            data.map(v => { if (v.affected !== null){
                v.need = v.normm;
                arr.push(v);
            }});
        }
       
        setMoving(true);
        NeedsMngt.generateKitLabel(arr, start, typeLabel, header).then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setMoving(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setMoving(false);
            }
        )
    }

    /**
     * Send storehouse mail
     */
    const sendStorehouseMail = () => {
        if (bodyMailStorehouse.trim() === "") {
            setError("Saisissez le corps du mail avant d'envoyer.");
            scrollToTop();
            return;
        }

        setSending(true);
        NeedsMngt.storehouseSendKitMail(header, bodyMailStorehouse).then(
            (response) => {
                setMsg("Mail envoyé !");
                scrollToTop();
                setSending(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSending(false);
            }
        );
    }

    const toLab = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var arr = [...customLabels];
     
        if(e.target.checked){
            arr.push(key);
        }else{
            arr = arrayRemoveElt(arr, arr.indexOf(key) );
        }
        
        setCustomLabels(arr);
    }

    const checkAll = (e) => {
        var arr = new Array();
        if( e.target.checked )
            for( let i = 0; i<data.length; i++ ) arr.push(i);

        setCustomLabels(arr);
    }

    const hidden = (e) => {
        if( e.Header !== undefined ){
            if( e.id === "affected" || e.id === "remain"  || e.id === "cdfou" || e.id === "normm" ) return "no-print";
        }else{
            if( e.column.id === "affected" || e.column.id === "remain"  || e.column.id === "cdfou" || e.column.id === "normm" ) return "no-print";
        }
    }

    return <Fragment>
        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            data.length > 0 &&
            <Fragment>
                <div style={{ display: "flex" }}>

                    <div className="card" style={{ width: "30%", marginLeft: "0px", marginTop: "5px" }}>
                        <div className="card-body fw-bold">
                            Kit: <a target="_blank" href={"/article/kit?ref=" + header.kit}>{header.kit}</a><br></br>
                            Affaire: {header.business} - Machine: {header.machine}<br></br>
                            Demandeur: {header.author}<br></br>
                            Date de la demande: {Moment(header.date_request).format("DD/MM/yyyy")}<br></br>
                            N° de la demande: {id}
                        </div>
                    </div>


                    {
                        (user.roles.includes('ROLE_STOREHOUSE') || user.roles.includes('ROLE_ADMIN')) &&
                        <div className="accordion no-print" id="accordionExample" style={{ width: "50%", marginLeft: "0px", marginTop: "5px" }}>
                            <div className="accordion-item">
                                <h2 className="accordion-header text-center" id="headingOne">
                                    <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Outils magasiniers
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="text-end">
                                        <button className="btn btn-outline-secondary" onClick={()=>window.print()}>Imprimer le listing</button>
                                        </div>
                                        <br></br>
                                        <span className="fw-bold">Générer les étiquettes: </span> &nbsp;
                                        <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" onClick={()=>setTypeLabel("available")}
                                            name="inlineRadioOptions" checked={typeLabel === "available"}/>
                                        <label className="form-check-label" for="inlineRadio1">uniquement des disponibles</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" onClick={()=>setTypeLabel("csm")}
                                            name="inlineRadioOptions" checked={typeLabel === "csm"}/>
                                        <label className="form-check-label" for="inlineRadio2">des consommables</label>
                                        </div>
                                        {
                                            customLabels.length > 0 &&
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" onClick={()=>setTypeLabel("custom")}
                                                    name="inlineRadioOptions" checked={typeLabel === "custom"}/>
                                                <label className="form-check-label" for="inlineRadio2">de la sélection</label>
                                            </div>
                                        }
                                        <br></br><br></br>
                                        <LabelsChoice onChooseTag={exportLabels} printingLabel={moving} />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Envoyer un mail</span>
                                        <textarea className="form-control"
                                            onChange={(e) => { setbodyMailStorehouse(e.target.value) }}
                                            placeholder="Nous avons traité entièrement cette demande. Merci de la clôturer. Vous pourrez trouver votre colis..."
                                            value={bodyMailStorehouse}></textarea>

                                    </div>

                                    <div className="text-center mb-3">
                                        <button className="btn btn-success" disabled={sending}
                                            onClick={sendStorehouseMail}>
                                            {sending && <ButtonWaiting />}
                                            Envoyer
                                        </button>
                                    </div>
                                </div>


                            </div>
                            <br></br>
                        </div>

                    }
                </div>

                <table {...getTableProps()} className="table text-center" id="subcontracting_table">
                    <thead>
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr className="table-dark fixed-header" {...headerGroup.getHeaderGroupProps()}>
                                    <th>Etiquettes</th>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps(),)} 
                                                    className={hidden(column)}>
                                                {column.render('Header')}

                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>

                                            </th>

                                        ))}

                                </tr>



                            ))}
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                                // Apply the header row props
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    <th><input type="checkbox" onClick={checkAll}></input></th>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                            // Apply the header cell props
                                            <th {...column.getHeaderProps()} className={hidden(column)}>
                                                {/* Render the columns filter UI */}
                                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                                            </th>
                                        ))}

                                </tr>
                            ))}
                    </thead>
                    {/* Check si  available || qtram <= 0*/}
                    <tbody {...getTableBodyProps()}>
                        {// Loop over the table rows
                            rows.map((row, k) => {
                                // Prepare the row for display
                                prepareRow(row)
                                return (
                                    // Apply the row props
                                    <tr {...row.getRowProps()} className={row.cells[8].value === 0 ? "bg-success bg-gradient" : ""}>
                                        <td>
                                            <input type="checkbox" data-key={k} onChange={toLab} 
                                                checked={customLabels.indexOf(k)!==-1}></input>
                                        </td>
                                        {// Loop over the rows cells
                                            row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()} className={hidden(cell)}>
                                                        {cell.column.id === "qtram" ? Math.max(0, cell.value) : 
                                                        cell.column.id === "available" ? Math.max(0, cell.value) : 
                                                        cell.render('Cell')}
                                                    </td>
                                                )
                                            })}

                                    </tr>
                                )
                            })}
                    </tbody>
                </table>

                {
                    consumables &&
                    <div>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-info fw-bold">
                                Consommables & autres articles non gérés en besoins
                            </li>
                            {
                                consumables.map( (v, k) => {
                                    return <li className="list-group-item" key={"c"+k}>
                                        {v.qty} x {v.cdaov} ({v.des1l})
                                    </li>
                                })
                            }
                            
                        </ul>
                        <br></br>
                    </div>
                }
            </Fragment>
        }
    </Fragment>

}

export default DisplayKiNeeds;