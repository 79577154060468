import { useEffect, useRef, useState } from "react";
import SockJsClient from 'react-stomp';
import authHeader from "../../services/auth-header";
import { Client, Stomp } from "@stomp/stompjs";
import SockJS from 'sockjs-client';
import axios from "axios";
import textPlainHeader from "../../services/fake-header";
import Test from "../../services/common/Test";

const SocketTest = (props) => {
    const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user && "\"Bearer " + user.accessToken + "\"";

    const [message, setMessage] = useState([]);
    const [msgObj, setMsgObj] = useState();
    const clientRef = useRef(null); // Use a ref to store the WebSocket client

    const onConnected = (client) => {
        console.log("Connected!!")

        //Remark: do not write the top level URL prefix /api/test
        client.subscribe('/topic/message', function (msg) {
            if (msg.body) {
                var jsonBody = JSON.parse(msg.body);
                if (jsonBody.message) {
                    console.log(jsonBody.message)
                    setMessage((prevMessages) => [...prevMessages, jsonBody.message]);
                }
            }
        });
    }

    let onMessageReceived = (msg) => {
        setMessage(msg.message);
    }

    let onDisconnected = () => {
        console.log("Disconnected!!")
    }

    useEffect(() => {
        Test.getMessageObj().then(
            (r) => {
                setMsgObj(r)
                startSocket();
            }, (error) => {
                console.error(error);
            }
        )
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();

        var msg = e.target.message.value;
        document.getElementById("msg").value = "";


        Test.sendMessage(msg).then(
            (r) => {
                startSocket();
            }, (error) => {
                console.error(error);
            }
        )
    }

    const startSocket = () => {
        // Check if the client already exists and is connected to avoid duplication
        if (clientRef.current && clientRef.current.connected) return;


        const client = new Client({
            brokerURL: SOCKET_URL,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => onConnected(client),
            onDisconnect: onDisconnected
        });

        clientRef.current = client;
        client.activate();
    }

    return <div>
        <h5 className="text-center">Test socket</h5>

        <div className="card" style={{ width: "300px" }}>
            <h6 className="text-center">Messages</h6>
            <ul>
                {message.map((v, k) => {
                    return <li key={k}>{v}</li>
                })}
            </ul>
        </div>

        <form onSubmit={onSubmit} className="input-group mb-3 ms-auto me-auto" 
            style={{width: "300px", height:"50px"}}>
            <input name="message" id="msg" className="form-control" />
            <button type="submit" className="btn btn-secondary">Envoyer</button>
        </form>


    </div>

}

export default SocketTest;