
const PartThree = (props) => {

    function copyInClipBoard( _class ) {
        var elt = document.getElementsByClassName("copy-"+_class);
        var txt = "";

       for( let i=0; i<elt.length; i++ )
            if(elt[i].value !== "") txt += elt[i].value + "\n";
       
       txt = txt.substring(0, txt.lastIndexOf("\n"));

       navigator.clipboard.writeText(txt);

        document.getElementById( _class + "-copy-img").classList.add("d-none");
        document.getElementById( _class + "-copy-txt").classList.remove("d-none");

        setTimeout(()=>{
            document.getElementById( _class + "-copy-img").classList.remove("d-none");
            document.getElementById( _class + "-copy-txt").classList.add("d-none");
        }, 3000)
    }

    return (
        < div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th className="position-relative" scope="col">Adresse de livraison
                            
                            <img src="/common/copy.png" className="see-img w-30 fix-right cursor-pointer" 
                                id="address-copy-img"
                                title="Copier l'adresse dans le presse-papier"
                                onClick={()=>copyInClipBoard("address")}/>

                                <div id="address-copy-txt" className="w-100 position-absolute text-end text-success d-none"
                                    style={{marginTop: "-20px"}}>Copié !</div>    
                            </th>                            
                        <th className="position-relative" scope="col">Chef de projet
                            
                            <img src="/common/copy.png" className="see-img w-30 fix-right cursor-pointer" 
                                id="cdp-copy-img"
                                title="Copier ce contact dans le presse-papier"
                                onClick={()=>copyInClipBoard("cdp")}/>

                                <div id="cdp-copy-txt" className="w-100 position-absolute text-end text-success d-none"
                                    style={{marginTop: "-20px"}}>Copié !</div>    
                            </th>
                        <th className="position-relative" scope="col">Chef de chantier
                            
                            <img src="/common/copy.png" className="see-img w-30 fix-right cursor-pointer" 
                                id="cc-copy-img"
                                title="Copier ce contact dans le presse-papier"
                                onClick={()=>copyInClipBoard("cc")}/>

                                <div id="cc-copy-txt" className="w-100 position-absolute text-end text-success d-none"
                                    style={{marginTop: "-20px"}}>Copié !</div>    
                            </th>
                        <th className="position-relative" scope="col">Responsable maintenance
                            
                            <img src="/common/copy.png" className="see-img w-30 fix-right cursor-pointer" 
                                id="rm-copy-img"
                                title="Copier ce contact dans le presse-papier"
                                onClick={()=>copyInClipBoard("rm")}/>

                                <div id="rm-copy-txt" className="w-100 position-absolute text-end text-success d-none"
                                    style={{marginTop: "-20px"}}>Copié !</div>    
                            </th>
                        <th className="position-relative" scope="col">Acheteur
                            
                            <img src="/common/copy.png" className="see-img w-30 fix-right cursor-pointer" 
                                id="ac-copy-img"
                                title="Copier ce contact dans le presse-papier"
                                onClick={()=>copyInClipBoard("ac")}/>

                                <div id="ac-copy-txt" className="w-100 position-absolute text-end text-success d-none"
                                    style={{marginTop: "-20px"}}>Copié !</div>    
                            </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/** Adresse de livraison */}
                        <td style={{ width: "20%", minWidth: "300px" }} className="border-end">
                            <input type="text" className="form-control mb-3 copy-address" placeholder="Société"
                                onBlur={props.updateBusinessField} 
                                data-field="societe_livrais" defaultValue={props.businessInfo.societe_livrais}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-address" placeholder="Adresse"
                                onBlur={props.updateBusinessField} 
                                data-field="adress_livrais" defaultValue={props.businessInfo.adress_livrais}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-address" placeholder="Adresse comp."
                                onBlur={props.updateBusinessField} 
                                data-field="adress_comp_livrais" defaultValue={props.businessInfo.adress_comp_livrais}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-address" placeholder="Code postal"
                                onBlur={props.updateBusinessField} 
                                data-field="cp_livrais" defaultValue={props.businessInfo.cp_livrais}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-address" placeholder="Ville"
                                onBlur={props.updateBusinessField} 
                                data-field="ville_livrais" defaultValue={props.businessInfo.ville_livrais}
                                readOnly={props.readOnly} />

                            {/*<input type="text" className="form-control mb-3 copy-address" placeholder="Pays"
                                onBlur={props.updateBusinessField} 
                                data-field="pays_livrais" value={props.businessInfo.pays_livrais}
                                readOnly={props.readOnly} />*/}

                            <select className="form-select mb-3 copy-address" 
                                onChange={(e) => {props.updateBusinessField(e)} }
                                data-field="pays_livrais" defaultValue={props.businessInfo.pays_livrais} disabled={props.readOnly}>
                            <option value="">Choix du pays</option>
                            { props.dp && [...props.dp].filter( (a) => { return a.field === "pays" } ).map( (v, k ) => {
                                return <option value={v.label}>{v.label}</option>
                            } )}
                            </select>
                        </td>

                        {/** Chef de projet */}
                        <td style={{ width: "20%", minWidth: "300px" }} className="border-end">
                            <div className="input-group">
                            <input type="text" className="form-control mb-3 copy-cdp" placeholder="Nom"
                                onBlur={props.updateBusinessField} 
                                data-field="nom_chef_proj" defaultValue={props.businessInfo.nom_chef_proj}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cdp" placeholder="Prénom"
                                onBlur={props.updateBusinessField} 
                                data-field="prenom_chef_proj" defaultValue={props.businessInfo.prenom_chef_proj}
                                readOnly={props.readOnly} />
                            </div>

                            <input type="text" className="form-control mb-3 copy-cdp" placeholder="Statut"
                                onBlur={props.updateBusinessField} 
                                data-field="statut_chef_proj" defaultValue={props.businessInfo.statut_chef_proj}
                                    readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cdp" placeholder="Société"
                                onBlur={props.updateBusinessField} 
                                data-field="societe_chef_proj" defaultValue={props.businessInfo.societe_chef_proj}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cdp" placeholder="Mobile"
                                onBlur={props.updateBusinessField} 
                                data-field="mobile_chef_proj" defaultValue={props.businessInfo.mobile_chef_proj}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cdp" placeholder="Fixe"
                                onBlur={props.updateBusinessField} 
                                data-field="fixe_chef_proj" defaultValue={props.businessInfo.fixe_chef_proj}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cdp" placeholder="Mail"
                                onBlur={props.updateBusinessField} 
                                data-field="mail_chef_proj" defaultValue={props.businessInfo.mail_chef_proj}
                                readOnly={props.readOnly} />
                        </td>

                        {/** Chef de chantier */}
                        <td style={{ width: "20%", minWidth: "300px" }} className="border-end">
                            <input type="text" className="form-control mb-3 copy-cc" placeholder="Nom"
                                onBlur={props.updateBusinessField} 
                                data-field="nom_chef_chant" defaultValue={props.businessInfo.nom_chef_chant}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cc" placeholder="Statut"
                                onBlur={props.updateBusinessField} 
                                data-field="statut_chef_chant" defaultValue={props.businessInfo.statut_chef_chant}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cc" placeholder="Société"
                                onBlur={props.updateBusinessField} 
                                data-field="societe_chef_chant" defaultValue={props.businessInfo.societe_chef_chant}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cc" placeholder="Mobile"
                                onBlur={props.updateBusinessField} 
                                data-field="mobile_chef_chant" defaultValue={props.businessInfo.mobile_chef_chant}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cc" placeholder="Fixe"
                                onBlur={props.updateBusinessField} 
                                data-field="fixe_chef_chant" defaultValue={props.businessInfo.fixe_chef_chant}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-cc" placeholder="Mail"
                                onBlur={props.updateBusinessField} 
                                data-field="mail_chef_chant" defaultValue={props.businessInfo.mail_chef_chant}
                                readOnly={props.readOnly} />
                        </td>

                        {/** Responsable maintenance */}
                        <td style={{ width: "20%", minWidth: "300px" }} className="border-end">
                            <input type="text" className="form-control mb-3  copy-rm" placeholder="Nom"
                                onBlur={props.updateBusinessField} 
                                data-field="nom_resp_maint" defaultValue={props.businessInfo.nom_resp_maint}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3  copy-rm" placeholder="Statut"
                                onBlur={props.updateBusinessField} 
                                data-field="statut_resp_maint" defaultValue={props.businessInfo.statut_resp_maint}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3  copy-rm" placeholder="Société"
                                onBlur={props.updateBusinessField} 
                                data-field="societe_resp_maint" defaultValue={props.businessInfo.societe_resp_maint}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3  copy-rm" placeholder="Mobile"
                                onBlur={props.updateBusinessField} 
                                data-field="mobile_resp_maint" defaultValue={props.businessInfo.mobile_resp_maint}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3  copy-rm" placeholder="Fixe"
                                onBlur={props.updateBusinessField} 
                                data-field="fixe_resp_maint" defaultValue={props.businessInfo.fixe_resp_maint}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3  copy-rm" placeholder="Mail"
                                onBlur={props.updateBusinessField} 
                                data-field="mail_resp_maint" defaultValue={props.businessInfo.mail_resp_maint}
                                readOnly={props.readOnly} />
                        </td>

                        {/**Acheteur */}
                        <td style={{ width: "20%", minWidth: "300px" }}>
                            <input type="text" className="form-control mb-3 copy-ac" placeholder="Nom"
                                onBlur={props.updateBusinessField} 
                                data-field="nom_achet" defaultValue={props.businessInfo.nom_achet}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-ac" placeholder="Statut"
                                onBlur={props.updateBusinessField} 
                                data-field="statut_achet" defaultValue={props.businessInfo.statut_achet}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-ac" placeholder="Société"
                                onBlur={props.updateBusinessField} 
                                data-field="societe_achet" defaultValue={props.businessInfo.societe_achet}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-ac" placeholder="Mobile"
                                onBlur={props.updateBusinessField} 
                                data-field="mobile_achet" defaultValue={props.businessInfo.mobile_achet}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-ac" placeholder="Fixe"
                                onBlur={props.updateBusinessField} 
                                data-field="fixe_achet" defaultValue={props.businessInfo.fixe_achet}
                                readOnly={props.readOnly} />

                            <input type="text" className="form-control mb-3 copy-ac" placeholder="Mail"
                                onBlur={props.updateBusinessField} 
                                data-field="mail_achet" defaultValue={props.businessInfo.mail_achet}
                                readOnly={props.readOnly} />
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default PartThree;