import i18next from "i18next";

/**
 * That will be sent through POST request param named "fake_header"
 * 
 * @returns 
 */
export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    // For Spring Boot back-end
    return { 
      Authorization: "Bearer " + user.accessToken,
      Language: i18next.language
    };

    // for Node.js Express back-end
    //return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}

/**
 * To avoid preflight request, then OPTIONS HTTP request, send text/plain instead of json/application
 * HTTP OPTIONS request are denied by SEE policy
 * @returns 
 */
/*export default function header(){
  return {
    'Accept': 'application/json',
    'Content-Type': 'text/plain'
  }
}*/
