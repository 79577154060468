import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { ButtonWaiting } from "../../../functions/PageAnimation";
import { forceString } from "../../../functions/StrFunctions";

const BlockedTab = (p) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [working, setWorking] = useState(false);
    const [filtered, setFiltered] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (p.list && p.focusBom) filter(false)
    }, [p.list, p.focusBom]);

    function filter(getAll = false) {
        if (getAll) {
            setList(p.list);
            setFiltered(false);
            return;
        }

        var arr = []

        var ar = "", se = "", rep = "";
        for (let i = 0; i < p.list.length; i++) {
            ar = forceString(p.list[i].CDARL).trim();
            se = forceString(p.list[i].se).trim();
            rep = forceString(p.list[i].rep).trim();

            const result = p.focusBom.find(item =>
                forceString(item.ar).trim() === ar &&
                forceString(item.se).trim() === se &&
                forceString(item.rep).trim() === rep
            );


            if (!result) {
                arr.push(p.list[i]);
            } else {
                var satisfied = result.satisfied || 0;
                var need = result.qty;

                if (parseFloat(satisfied) < parseFloat(need)) arr.push(p.list[i]);
            }

        }

        setFiltered(true);
        setList(arr);
    }


    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat', {
            header: 'Catégorie',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table="N/A" data-field="cat" data-id={i.row.original.id}
                    style={{ minWidth: "150px" }}></input>
                {i.row.original.cat_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('se', {
            header: 'S/E',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('rep', {
            header: 'Repère',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'Qté',
            cell: i => <div className="display-flex">
                <input type="number" className="form-control text-center" defaultValue={i.getValue()}
                    //onChange={p.onFieldChange} 
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key}
                    data-table="" data-field="qty" data-id={i.row.original.id}
                    readOnly={i.row.original.qty_changing}></input>
                {i.row.original.qty_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Désignation FR',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Référence',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} style={{ minWidth: "250px" }} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table="" data-field="ref" data-id={i.row.original.id}></input>
                {i.row.original.ref_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('maker', {
            header: 'Fabricant',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table="" data-field="maker" data-id={i.row.original.id}></input>
                {i.row.original.maker_changing && <button className="btn"><ButtonWaiting /></button>}
            </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'Code article',
            cell: i => <div>
                <input className="form-control" defaultValue={i.getValue()} //onChange={p.onFieldChange}
                    onBlur={p.onFieldBlur} data-key={i.row.original.parent_key} title={i.getValue()}
                    data-table="" data-field="ar" data-id={i.row.original.id}
                    style={{ minWidth: "150px" }}></input>
                {i.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
                {
                    i.row.original.ZN02PA.startsWith("PL-") &&
                    <a target="_blank" href={"/article/info/get?article=" + i.getValue()}>Voir autres indices</a>
                }
            </div>
        }));

        arr.push(columnHelper.accessor('stock_at_term', {
            header: 'Stock',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('OBSES', {
            header: 'Coms',
            cell: i => (i.getValue() && i.getValue() !== null && i.getValue().trim() !== "") ?
                <img title={i.getValue()} src={"/common/tooltip.png"}></img> : ""
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();



    return <Fragment>
        <div className="alert alert-info text-center">
            {filtered && <span>
                N'apparaissent que les articles dont les besoins n'ont pas été satisfaits à 100% pour cette machine.
                Pour voir la liste complète cliquez <a href="#" className="fw-bold" onClick={() => filter(true)}>ici</a>.
            </span>}

            {!filtered && <span>
                Pour n'afficher que les articles dont les besoins n'ont pas été satisfaits à 100% pour cette machine 
                cliquez <a href="#" className="fw-bold" onClick={() => filter(false)}>ici</a>.
            </span>}
        </div>
        <ReactTable columns={columns} data={list}
            origin="bom_content" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default BlockedTab;