import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { confirmAlert } from "react-confirm-alert";
import { arrayRemoveElt, getKey } from "../../../../functions/ArrayFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../../common/smallComponents.js/ReactTable";
import { hasRole } from "../../../../functions/StrFunctions";
import { getExpander } from "../../../../common/smallComponents.js/Filter";
import Select from "react-select";

const Options = (p) => {
    var [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([])

    const [list, setList] = useState([]);

    const typeOfMachine = ["BA_TECMA_18_20_24", "BA_TECMA_30_40", "BA15P", "EC08",
        "EC16", "FORMEUSE_CAISSE_LANTEC", "IK", "IN", "LN", "POSE_CALE", "POSE_COIFFE", "WN"];

    const [selectList, setSelectList] = useState();

    useEffect(() => {
        setList(p.dpOptions)

        var arr = new Array();
        typeOfMachine.map( v => arr.push({ value: v, label: v}) )
        setSelectList( arr )
    }, []);

    const saveOptionsChange = (e) => {
            var field = e.target.getAttribute("data-field");
            var id = e.target.getAttribute("data-id");
            var value = e.target.value;
    
            if( value === "" ) return;
    
            MachineInfo.updateOptionDp(id, field, value).then(
                () => { },
                (error) => {
                    p.setError(errorManagement.handleError(p, error));
                    scrollToTop();
                }
            );
        }  

    const deleteOption = (e) => {
        var id = e.target.getAttribute("data-id");
        var k = e.target.getAttribute("data-key");
        var label_option = list[k].label_option;

        confirmAlert({
            title: "Suppression de l'option",
            message: "Etes-vous sûr de vouloir supprimer l'option' " + label_option + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [...list];

                        arr = arrayRemoveElt(arr, k);

                        setList(arr);

                        MachineInfo.deleteOptionDp(id).then(
                            () => {},
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                            }
                        );

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const addOption = (e) => {
        e.preventDefault();
        p.setError();

        var mach = "";
        if( selected.length === 0 ){
            p.setError("Choisissez les machines concernées par cette option");
            scrollToTop();
            return;
        }else{
            for( let i = 0; i < selected.length; i++ ) mach += selected[i].value + ";"
        }

        var o = {
            type: e.target.type_option.value,
            designation: e.target.designation.value,
            price: e.target.price.value,
            types_machine: mach
        }



        if (list.find(a => a.label_option === o.designation)) {
            p.setError("Cette option existe déjà !");
            scrollToTop();
            return
        }

        
        MachineInfo.addOptionDp(o).then(
            (response) => {

                var curSelect = [...list];
                var o2 = {
                    label_option: o.designation,
                    type_option:  o.type,
                    price: o.price,
                    types_machine: o.types_machine,
                    id: response
                };

                curSelect.push(o2);
                setList(curSelect);

                var completeList = [...p.dpOptions];
                completeList.push(o2);
                p.setDpOptions(completeList);

                e.target.type_option.value = "";
                e.target.designation.value = "";
                e.target.price.value = "";

                setLoading(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();

                setLoading(false);
            }
        );

    }

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
          '&:hover': {
            borderColor: '#007bff',
          },
          width: "350px",
          zIndex: 2000,
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? '#fff' : '#333',
          padding: 10,
          zIndex: 2000
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#007bff',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Maximum z-index for the dropdown
          }),
      };

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(getExpander(columnHelper));

        arr.push(columnHelper.accessor('id',
            { header: 'ID', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('type_option',
            { header: 'Type option', cell: (i) => <div className="text-center">{i.getValue() && i.getValue().toUpperCase()}</div> }));

        arr.push(columnHelper.accessor('label_option',
            {
                header: 'Désignation option', cell: (i) => <div className="text-center">
                    <input type="text" className="form-control" data-key={i.row.id} 
                        data-id={i.row.original.id} data-field="label_option"
                        defaultValue={i.getValue()} title={i.getValue()} onBlur={saveOptionsChange} />
                </div>
            }));


        arr.push(columnHelper.accessor('price', {
            header: 'Prix', cell: (i) => <div className="text-center">
                <input type="number" className="form-control" data-key={i.row.id} 
                        data-id={i.row.original.id} data-field="price"
                    defaultValue={i.getValue()} onBlur={saveOptionsChange} />
            </div>
        }));


        if (hasRole(["ROLE_ADMIN", "ROLE_WRITE_FM"])) {
            arr.push(columnHelper.accessor('xxx', {
                enableColumnFilter: false,
                header: '', cell: (i) => <div className="text-center">
                    <button className="btn btn-outline-danger" data-key={i.row.id}
                         data-id={i.row.original.id} onClick={deleteOption} style={{ width: "200px" }}>
                        Supprimer
                    </button>
                </div>
            }));
        }

        return arr;
    }

    //Set columns
    const columns = getColumns();

    const renderSubComponent = (row) => {
        var types_machine = row.original.types_machine;

        return <div>
            <h6 className="fw-bold mb-3">Machines concernées par cette option</h6>
            {typeOfMachine.map((v2, k2) => {
                    return <div className="form-check ms-5" key={"tm" + k2}>
                        <input className="form-check-input" type="checkbox"
                            value={v2} onChange={onMachineTypeChoose}
                            data-id={row.original.id} data-key={row.id}
                            checked={types_machine && types_machine.indexOf(v2) !== -1} 
                            />
                        <label className="form-check-label" for="flexCheckDefault">{v2}</label>
                    </div>
                })
            }

        </div>
    };

    const onMachineTypeChoose = (e) => {
            var k = e.target.getAttribute("data-key");
            var id = e.target.getAttribute("data-id");
            var value = e.target.value;
    
            var arr = [...list];
            var newVal;
            
            if( e.target.checked ){
                newVal = arr[k].types_machine + value + ";";
            }else{
                newVal = arr[k].types_machine.replace( value + ";", "")
            }
    
            arr[k].types_machine = newVal;
            setList(arr);
    
            var arr2 = [...p.dpOptions];
            arr2[getKey(arr2, "id", parseInt(id))].types_machine = newVal;
            p.setDpOptions(arr2);
    
            MachineInfo.updateOptionDp(id, "types_machine", newVal).then(
                () => { },
                (error) => {
                    p.setError(errorManagement.handleError(p, error));
                    scrollToTop();
                }
            );
        }

        const onSelectMachines = (e) => {
            setSelected(e)
        }

    return <Fragment>
        {
            (list.length > 0 && hasRole(["ROLE_ADMIN", "ROLE_WRITE_FM"])) && <form className="mt-3 mb-3" 
                onSubmit={addOption}>
                <div className="input-group">
                    <span className="input-group-text">Nouvel option</span>
                    
                    <select className="form-select" name="type_option" required>
                        <option value="">Choisissez le type d'option</option>
                        <option value="CC" key={"opt1"}>CC</option>
                        <option value="MECA" key={"opt2"}>MECA</option>
                        <option value="MECA_CC" key={"opt3"}>MECA_CC</option>
                    </select>

                    <input className="form-control" name="designation" placeholder="Désignation de l'option" required />
                    <input type="number" className="form-control" name="price" placeholder="Prix" required />
                    
                    <Select options={selectList} onChange={onSelectMachines} value={selected} 
                        styles={customStyles} className="text-center fw-bold" isMulti={true} 
                        placeholder="Choisissez les types de mach."/>
                    
                    <button className="btn btn-outline-success" disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Ajouter</button>
                </div>
            </form>
        }

        <ReactTable columns={columns} data={list} canExpand={true} renderSubComponent={renderSubComponent}
            origin="machine_config" classes={"no-padding fixFstCol "} sendMyself={true} top0={true} />

    </Fragment>

}

export default Options;