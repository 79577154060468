import React, { useState, useRef, useEffect, Fragment } from "react";


import checkList from "../../../services/workshop/checkList";


import "../../../css/workshop/checkList.css"
import "../../../css/workshop/integrationForms.scss"
import errorManagement from "../../../services/errorManagement.js";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage.js";
import { ButtonWaiting, getDeviceType, scrollToTop } from "../../../functions/PageAnimation";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import DownloadFiles from "../../common/DownloadFiles";
import { downloadFile } from "../../../functions/FormCtrl";
import { arrayRemoveElt, getKey } from "../../../functions/ArrayFunctions";
import ArticleInfo from "../../../services/articles/ArticleInfo";
import { confirmAlert } from "react-confirm-alert";
import getUrlParam from "../../../functions/StrFunctions.js";

const IntegrationChkList = (props) => {
    const [machine, setMachine] = useState();
    const [machineType, setMachineType] = useState();
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [machineInfo, setMachineInfo] = useState(false);
    const [assemblyNc, setAssemblyNc] = useState();
    const [assemblyUndone, setAssemblyUndone] = useState();
    const [curSubset, setCurSubset] = useState();

    const [subset, setSubset] = useState();
    const [list, setList] = useState();
    const [form, setForm] = useState();
    const [sense, setSense] = useState();
    const [response, setResponse] = useState();
    const [controller, setController] = useState();
    const [ctrlDate, setCtrlDate] = useState();
    const [status, setStatus] = useState();
    const [deviceType] = useState(getDeviceType());
    const [tab, setTab] = useState(0);
    const [missing, setMissing] = useState([]);
    const [multipleArt, setMultipleArt] = useState();
    const [searchArt, setSearchArt] = useState(false);
    const [changeDone, setChangeDone] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));


    useEffect(() => {
        var machine = getUrlParam(props, "machine");
        if( machine !== null ){
            setMachine(machine);
            getFormRequest(machine);
        }
    }, []);

    /**
     * Change state of machine input
     * @param {*} e 
     */
    const onChangeMachine = (e) => {
        setMachine(e.target.value);
    };

    /**
     * Set subset choosen
     * @param {*} e 
     */
    const onSubsetChoose = (e) => {
        if (changeDone) {
            confirmAlert({
                title: "Confirmation",
                message: "Des changements n'ont pas été sauvegardés, voulez-vous quitter sans enregistrer ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => { setChangeDone(false); changeSubset(e) }
                    },
                    {
                        label: "Non",
                        onClick: () => { document.getElementById("subsetChoice").value = curSubset; return; }
                    }
                ]
            });
        } else {
            changeSubset(e);
        }
    };

    const changeSubset = (e) => {
        var se = e.target.value;
        setCurSubset(se);

        if (se === "") {
            setForm();
            return;
        }

        var arr = new Array();
        var found = false;

        list.map(v => {
            if (v.chapter_label.trim() === se) {
                arr.push(v);
            } else if (found) {
                return;
            }
        });

        setController(arr[0].ctrl_name);
        setCtrlDate(arr[0].control_date);

        setForm(arr);
    }

    /**
     * get article infos
     */
    const getArticleInfo = (e) => {
        e.preventDefault();
        var key = e.target.getAttribute("data-key");
        var arr = [...missing];
        var article = e.target.article.value.trim();
        setSearchArt(true);

        ArticleInfo.articlesInfo(article).then(
            (response) => {
                if (response.length === 0) {
                    setError("Article non trouvé !");
                } else if (response.length > 1) {
                    var o = new Object;
                    o.key = key;
                    o.list = response;
                    setMultipleArt(o);
                } else {
                    arr.push(response[0].CDARL);
                    setMissing(arr);
                }
                setSearchArt(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    const cancelArticleChoose = () => {

    }

    const chooseArticle = (e) => {
        var key_list = e.target.getAttribute("data-key");
        var key_row = multipleArt.key;
        var arr = [...missing];

        arr.push(multipleArt.list[key_list].CDARL);
        setMissing(arr);
        setMultipleArt();
        setLoading(false);

    }

    /**
     * Search the machine number and get the form
     * @param {*} e 
     */
    const getForm = (e) => {
        e.preventDefault();

        if (changeDone) {
            confirmAlert({
                title: "Confirmation",
                message: "Des changements n'ont pas été sauvegardés, voulez-vous quitter sans enregistrer ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => { setChangeDone(false); getFormRequest() }
                    },
                    {
                        label: "Non",
                        onClick: () => { return; }
                    }
                ]
            });
        } else {
            getFormRequest();
        }

    };

    const getFormRequest = (machineParam) => {
        var mach;
        if( machineParam ){mach = machineParam; } 
        else{mach = machine} 

        //Remove list
        setList();
        setSubset();
        setMachineType();
        setSense();
        setError();
        setForm();
        setMsg();
        setAssemblyNc();
        setAssemblyUndone();

        //Set loading, so disable button search
        setLoading(true);

        checkList.getIntegrationChkList(mach).then(
            (response) => {
                setMachine(response.machine);
                setMachineType(response.machine_type);
                getDistinctSubset(response.form);
                setList(response.form);
                setSense(response.sense);
                setMachineInfo(response.machineInfo);
                setResponse(response);
                setLoading(false);
                setGlobalStatus(response.form);
                if (response.value1 !== "") setMissing(response.value1.split(";"));
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

        checkList.saveAssemblyNcForm(mach).then(
            (response) => {
                if (response.length > 0) setAssemblyNc(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

        checkList.saveAssemblyUndoneForm(mach).then(
            (response) => {
                if (response.length > 0) setAssemblyUndone(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const setGlobalStatus = (arr) => {
        var status = "Terminé";
        arr.map(v => {
            if (parseInt(v.conform) === 0) {
                status = "Non conforme";
                return;
            } else if (v.conform === null && status !== "Non conforme") {
                status = "En cours";
            }
        });
      
        setStatus(status);
    }

    function getDistinctSubset(se) {
        var arr = new Array();
        var o, key;

        se.map(v => {
            key = getKey(arr, "chapter", v.chapter);
            if (key === null) {
                o = {
                    "chapter": v.chapter,
                    "label": v.chapter_label,
                    "conform": v.conform === 1 ? 1 : 0,
                    "no_conform": v.conform === 0 ? 1 : 0,
                    "cpt": 1
                }
                arr.push(o);
            } else {
                arr[key].cpt++;
                if (v.conform === 1) arr[key].conform++;
                if (v.conform === 0) arr[key].no_conform++;
            }
        })

        setSubset(arr);
    }

    const downloadReport = (e) => {
        e.preventDefault();
        downloadFile(process.env.REACT_APP_API_URL + "workshop/check-list/public/download-checkList?mach_nb=" + machine);
    };

    const onClickConform = (e) => {
        var k = e.target.getAttribute("data-key");
        var conform = e.target.getAttribute("data-conform");
        var arr = [...form];
        arr[k].conform = !conform ? null : conform === "true" ? 1 : 0;
        setChangeDone(true);
        setForm(arr);
    }

    const onSubmit = (e) => {
        setLoading(true);

        checkList.saveIntegrationChkListForm(machine, form).then(
            (response) => {
                //setMsg("Sauvegardé !");
                setForm();
                document.getElementById("subsetChoice").value = "";
                setLoading(false);
                setChangeDone(false);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    const onBuilderChange = (e) => {
        var arr = [...form];
        arr[0].builder = e.target.value;
        setForm(arr);
    }

    const onAssemblyUpdate = (e) => {
        var k = e.target.getAttribute("data-key");
        var conform = e.target.getAttribute("data-conform");
        var arr = [...assemblyNc];
        arr[k].conform = !conform ? null : conform === "true" ? 1 : 0;
        setChangeDone(true);
        setAssemblyNc(arr);
    }

    const onAssemblyUndoneUpdate = (e) => {
        var k = e.target.getAttribute("data-key");
        var conform = e.target.getAttribute("data-conform");
        var arr = [...assemblyUndone];
        arr[k].conform = conform === "true" ? 1 : 0;
        setChangeDone(true);
        setAssemblyUndone(arr);
    }

    const updateAssemblyForm = (e) => {
        e.preventDefault();

        setLoading(true);
        checkList.saveAssemblyChkListForm(machine, assemblyNc).then(
            (response) => {
                //setMsg("Sauvegardé !");
                var arr = new Array();
                assemblyNc.map(v => { if (parseInt(v.conform) === 0) arr.push(v); })
                setAssemblyNc(arr.length === 0 ? undefined : arr)
                setLoading(false);
                setChangeDone(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )

    }

    const updateAssemblyUndoneForm = (e) => {
        e.preventDefault();

        setLoading(true);
        checkList.saveAssemblyChkListForm(machine, assemblyUndone).then(
            (response) => {
                //setMsg("Sauvegardé !");
                var arr = new Array();
                assemblyUndone.map(v => { if (v.conform === undefined || v.conform === null) arr.push(v); })
                setAssemblyUndone(arr.length === 0 ? undefined : arr)
                setLoading(false);
                setChangeDone(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )

    }

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    const deleteMissing = (e) => {
        var k = e.target.getAttribute("data-key");
        var arr = arrayRemoveElt([...missing], k);
        setMissing(arr);
    }

    const saveMissing = () => {
        setSearchArt(true);
        checkList.saveIntegrationMissing(machine, missing.join(";")).then(
            (reponse) => {
                setMsg("Articles manquants sauvegardés !");
                setSearchArt(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearchArt(false);
                scrollToTop();
            }
        );
    }

    return (
        <div>

            {error && <ErrorMessage error={error} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            <form onSubmit={getForm}>

                <div>
                    <div className="input-group mb-3">
                        {
                            status &&
                            <span style={{ maxWidth: "10px" }} className={"form-select text-center fw-bold "
                                + (status === "Non conforme" ? "bg-danger bg-gradient text-white" :
                                    status === "Terminé" ? "bg-success bg-gradient" : "bg-warning bg-gradient")}></span>
                        }
                        {machineInfo && <input type="text" className="form-control text-center fw-bold" value={machineInfo.affaire} readOnly />}
                        {!machineInfo && <span className="input-group-text">N° de machine</span>}
                        <input type="text" className="form-control text-center fw-bold"
                            name="mach_nb" value={machine} onChange={onChangeMachine} required />
                        {machineInfo && <input type="text" className="form-control text-center fw-bold" value={machineInfo.typ_config} readOnly />}
                        {sense && <input type="text" className="form-control text-center fw-bold" value={sense} readOnly />}
                        <button className="btn btn-success" disabled={loading}>
                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                            <span>Search</span>
                        </button>
                        {machineInfo && <button className="btn btn-outline-danger" onClick={(e) => { e.preventDefault(); window.location.reload() }}>Réinit.</button>}
                    </div>

                    {
                        subset &&
                        <Fragment>
                            <ul className="nav nav-tabs">
                                <li className="nav-item fw-bold">
                                    <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                        href="#" onClick={changeTab} data-tab="0">Contrôle des S/E</a>
                                </li>
                                <li className="nav-item fw-bold">
                                    <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                        href="#" onClick={changeTab} data-tab="1">Déclarer des manquants</a>
                                </li>
                            </ul>
                            <br></br>
                        </Fragment>
                    }

                    {
                        (subset && tab === 0) && <Fragment>
                            <div className="input-group mb-3">
                                <select className="form-select text-center fw-bold " id="subsetChoice" onChange={onSubsetChoose}>
                                    <option value="">Choisissez le sous-ensemble</option>
                                    {
                                        subset.map((v, k) =>
                                            <option key={"se" + k}
                                                className={v.no_conform > 0 ? "bg-danger bg-gradient text-white" :
                                                    v.conform === v.cpt ? "bg-success bg-gradient text-white" :
                                                        v.conform > 0 ? "bg-warning bg-gradient" : ""}
                                                value={v.label && v.label.trim()}>{v.label}</option>)
                                    }
                                </select>

                                <span className="input-group-text">Contrôle</span>
                                {
                                    response && <input type="date" className="form-control text-center fw-bold"
                                        value={ctrlDate} readOnly />
                                }



                                {
                                    controller &&
                                    <Fragment>
                                        <span className="input-group-text">Contrôleur</span>
                                        <input type="text" className="form-control text-center fw-bold"
                                            value={controller} readOnly />
                                    </Fragment>
                                }

                                {
                                    form &&
                                    <Fragment>
                                        <span className="input-group-text">Monteur</span>
                                        <input type="text" className="form-control text-center fw-bold"
                                            value={form[0].builder === null ? "" : form[0].builder.trim()} onChange={onBuilderChange} />
                                    </Fragment>
                                }

                                <select className="form-select text-center fw-bold">
                                    <option>Légende</option>
                                    <option className="bg-danger bg-gradient text-white">Au moins une non conformité signalée</option>
                                    <option className="bg-warning bg-gradient">En cours de contrôle sans non conformité</option>
                                    <option className="bg-success bg-gradient text-white">Tout est conforme</option>
                                    <option>Non commencé</option>
                                    <option style={{ color: "red" }}>Libellé contrôle en rouge: a été non-conforme autrefois</option>
                                </select>

                                {/*<button onClick={downloadReport} className="btn btn-info fw-bold">Télécharger le rapport</button>*/}
                            </div>


                        </Fragment>}
                </div>

            </form>

            {/** Non conformity of assembly */}
            {
                (assemblyNc && tab === 0) &&
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header text-center" id="flush-headingOne">
                            <button className="accordion-button collapsed bg-info bg-gradient text-white fw-bold text-center" type="button"
                                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                {assemblyNc.length} non-conformité(s) ont été signalées en montage.
                                Cliquez ici pour vérifier si elles ont été levées.
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse"
                            style={{ padding: "10px", borderStyle: "solid", borderColor: "rgb(12, 202,240)", borderWidth: "thin" }}
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <form onSubmit={updateAssemblyForm}>
                                <ul className="list-group" id="integrationsubForm">
                                    {
                                        assemblyNc.map(
                                            (v, k) => {
                                                return <Fragment>
                                                    <li key={"q" + k} className="list-group-item">
                                                        <h5>{v.chapter_label} {v.rubric_label !== "" ? " (" + v.rubric_label + ")" : ""}</h5>
                                                        <div className="question">
                                                            <div className="left-side" style={deviceType === "mobile" ? { display: "inline-flex" } : {}} >
                                                                {
                                                                    v.conform !== null &&
                                                                    <button type="button" className="btn-close" onClick={onAssemblyUpdate}
                                                                        data-key={k} aria-label="Close"></button>
                                                                }
                                                                <input type="radio" className="btn-check"
                                                                    data-key={k} data-conform="true"
                                                                    checked={v.conform !== null && v.conform}
                                                                    onClick={onAssemblyUpdate} />
                                                                <label className="btn btn-outline-success"
                                                                    htmlFor={"q_" + v.question + "_c"} data-conform="true"
                                                                    data-key={k} onClick={onAssemblyUpdate}>Conform</label>

                                                                <input type="radio" className="btn-check"
                                                                    data-key={k} data-conform="false"
                                                                    checked={v.conform !== null && !v.conform}
                                                                    onClick={onAssemblyUpdate} />
                                                                <label className="btn btn-outline-danger" data-conform="false"
                                                                    data-key={k} htmlFor={"q_" + v.question + "_nc"} onClick={onAssemblyUpdate}>Non conforme</label>
                                                            </div>

                                                            <div className="right-side">
                                                                <span>{v.question_label}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </Fragment>
                                            }
                                        )
                                    }
                                </ul>
                                <div className="text-center">
                                    <br></br>
                                    <button className="btn btn-success" disabled={loading}>
                                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                                        Enregistrer les modifications du montage
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                    <br></br>
                </div>
            }

            {/** Unchecked items of assembly */}
            {
                (assemblyUndone && tab === 0) &&
                <div className="accordion accordion-flush" id="accordionFlushExample2">
                    <div className="accordion-item">
                        <h2 className="accordion-header text-center" id="flush-headingOne">
                            <button className="accordion-button collapsed bg-info bg-gradient text-white fw-bold text-center" type="button"
                                data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                aria-controls="flush-collapseTwo">
                                {assemblyUndone.length} éléments n'ont pas été contrôlés en montage.
                                Cliquez ici pour vérifier.
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse"
                            style={{ padding: "10px", borderStyle: "solid", borderColor: "rgb(12, 202,240)", borderWidth: "thin" }}
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample2">
                            <form onSubmit={updateAssemblyUndoneForm}>
                                <ul className="list-group" id="integrationUndonesubForm">
                                    {
                                        assemblyUndone.map(
                                            (v, k) => {
                                                return <Fragment>

                                                    <li key={"qUndone" + k} className="list-group-item">
                                                        <h5>{v.chapter_label} {v.rubric_label !== "" ? " (" + v.rubric_label + ")" : ""}</h5>
                                                        <div className="question">
                                                            <div className="left-side" style={deviceType === "mobile" ? { display: "inline-flex" } : {}} >
                                                                {
                                                                    v.conform !== null &&
                                                                    <button type="button" className="btn-close" onClick={onClickConform}
                                                                        data-key={k} aria-label="Close"></button>
                                                                }
                                                                <input type="radio" className="btn-check"
                                                                    data-key={k} data-conform="true"
                                                                    checked={v.conform !== null && v.conform}
                                                                    onClick={onAssemblyUndoneUpdate} />
                                                                <label className="btn btn-outline-success"
                                                                    htmlFor={"q_" + v.question + "_c"} data-conform="true"
                                                                    data-key={k} onClick={onAssemblyUndoneUpdate}>Conform</label>

                                                                <input type="radio" className="btn-check"
                                                                    data-key={k} data-conform="false"
                                                                    checked={v.conform !== null && !v.conform}
                                                                    onClick={onAssemblyUndoneUpdate} />
                                                                <label className="btn btn-outline-danger" data-conform="false"
                                                                    data-key={k} htmlFor={"q_" + v.question + "_nc"} onClick={onAssemblyUndoneUpdate}>Non conforme</label>
                                                            </div>
                                                            <div className="right-side">
                                                                <span>{v.question_label}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </Fragment>
                                            }
                                        )
                                    }
                                </ul>
                                <div className="text-center">
                                    <br></br>
                                    <button className="btn btn-success" disabled={loading}>
                                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                                        Enregistrer les modifications du montage
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                    <br></br>
                </div>
            }


            {
                (form && tab === 0) &&
                <Fragment>
                    <ul className="list-group" id="integrationForm">
                        {
                            form.map(
                                (v, k) => {

                                    return <Fragment>
                                        {(k === 0 && v.rubric_label !== '') &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }

                                        {
                                            (k > 0 && v.rubric_label !== '' && form[k - 1].rubric_label !== v.rubric_label) &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }
                                        <li key={"q" + k} className="list-group-item">
                                            <div className="question">
                                                <div className="left-side" style={deviceType === "mobile" ? { display: "inline-flex" } : {}} >
                                                    {
                                                        v.conform !== null &&
                                                        <button type="button" className="btn-close" onClick={onClickConform}
                                                            data-key={k} aria-label="Close"></button>
                                                    }
                                                    <input type="radio" className="btn-check"
                                                        data-key={k} data-conform="true"
                                                        checked={v.conform !== null && v.conform === 1}
                                                        onClick={onClickConform} />
                                                    <label className="btn btn-outline-success"
                                                        htmlFor={"q_" + v.question + "_c"} data-conform="true"
                                                        data-key={k} onClick={onClickConform}>Conform</label>

                                                    <input type="radio" className="btn-check"
                                                        data-key={k} data-conform="false"
                                                        checked={v.conform !== null && v.conform === 0}
                                                        onClick={onClickConform} />
                                                    <label className="btn btn-outline-danger" data-conform="false"
                                                        data-key={k} htmlFor={"q_" + v.question + "_nc"} onClick={onClickConform}>Non conforme</label>

                                                </div>

                                                <div className="right-side">
                                                    <span style={{ color: (v.conform && v.conform === 1 && v.hasBeenNC === 1) ? "red" : "black" }}>{v.question_label}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </Fragment>
                                }
                            )
                        }
                    </ul>

                    <br></br>

                    <div className="text-center">
                        <button className="btn btn-success" onClick={onSubmit} disabled={loading}>
                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                            Enregistrer
                        </button>
                    </div>
                </Fragment>

            }

            {
                tab === 1 &&
                <Fragment>
                    <form onSubmit={getArticleInfo}>
                        <div className="col-8 offset-2">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Ajouter un article</span>
                                <input type="text" className="form-control" name="article" />
                                <button className="btn btn-secondary" disabled={searchArt}>
                                    {searchArt && <ButtonWaiting />}
                                    Chercher
                                </button>
                            </div>
                        </div>
                        <br></br>

                    </form>

                    {
                        missing.map((v, k) => {
                            return (
                                <div className="col-8 offset-2">
                                    <div className="input-group mb-3" key={"m" + k}>
                                        <span className="input-group-text" id="basic-addon1">Article {k + 1}</span>
                                        <input type="text" className="form-control" value={v} readOnly />
                                        <img src={"/common/remove_icon.png"} data-key={k} onClick={deleteMissing}
                                            style={{ height: "30px", margin: "auto" }}></img>
                                    </div>
                                </div>)
                        })
                    }
                    {
                        missing.length > 0 &&
                        <div className="text-center">
                            <button type="submit" onClick={saveMissing}
                                className="btn btn-success" disabled={searchArt}>
                                {searchArt && <ButtonWaiting />}
                                Sauvegarder
                            </button>
                        </div>
                    }

                </Fragment>
            }


            {
                multipleArt &&
                <div className="custom-tooltip text-center">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => cancelArticleChoose()}></button>
                    <h5>Plusieurs articles ont été retrouvés, veuillez en choisir un.</h5><br></br><br></br>
                    <div style={{ height: "250px", overflowY: "auto" }}>
                        <table className="table">
                            <tr>
                                <th>Article</th>
                                <th>Ref</th>
                                <th>Qté</th>
                                <td></td>
                            </tr>
                            {
                                multipleArt.list.map(
                                    (v, k) =>
                                        <tr key={"art" + k}>
                                            <td>{v.CDARL}</td>
                                            <td>{v.ZN02PA}</td>
                                            <td>{v.QTSTL}</td>
                                            <td>
                                                <button className="btn btn-success"
                                                    data-key={k} onClick={chooseArticle}>Choisir</button>
                                            </td>
                                        </tr>
                                )
                            }
                        </table>
                    </div>
                </div>

            }
            <br></br>
        </div>

    );

};

export default IntegrationChkList;