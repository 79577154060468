import { Fragment } from "react"
import Moment from "moment";
import CustomToolTip from "../../../common/smallComponents.js/CustomToolTip";
import { createColumnHelper } from "@tanstack/react-table";
import { getFrFormat, hasRole } from "../../../functions/StrFunctions";
import ReactTable from "../../../common/smallComponents.js/ReactTable";

const BbsoTable = (p) => {

    Moment.locale("fr");

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('bbso_id',
            { header: '#', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('incidentDate',
            { header: 'Date d\'incident', cell: (i) => <div className="text-center">{getFrFormat(i.getValue(), false)}</div> }));

        arr.push(columnHelper.accessor('last_name',
            {
                header: 'Auteur', cell: (i) => <div className="text-center">
                    {i.getValue()} {i.row.original.first_name}</div>
            }));

        arr.push(columnHelper.accessor('department',
            { header: 'Département', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('description',
            {
                header: 'Description', cell: (i) => <div className="text-center">
                    <CustomToolTip props={
                        { tooltipText: i.getValue(), originText: i.getValue().substring(0, 31) + (i.getValue().length > 31 ? "..." : "") }} />
                </div>
            }));

        arr.push(columnHelper.accessor('my_action',
            {
                header: 'Mes actions', cell: (i) => <div className="text-center">
                    <CustomToolTip props={
                        { tooltipText: i.getValue(), originText: i.getValue().substring(0, 31) + (i.getValue().length > 31 ? "..." : "") }} />
                </div>
            }));

        arr.push(columnHelper.accessor('type_obs',
            { header: 'Type d\'observation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('risk',
            { header: 'Risque', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('category',
            { header: 'Catégorie', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        if (hasRole(["ROLE_QHSE", "ROLE_ADMIN"])) {
            arr.push(columnHelper.accessor('who',
                { header: 'Qui ?', cell: (i) => <div className="text-center">
                    <input className="form-control" type="text" data-id={i.row.original.bbso_id} data-field="who"
                            onBlur={p.onFieldChange} defaultValue={i.getValue()}></input>
                </div> }));

            arr.push(columnHelper.accessor('when',
                { header: 'Quand ?', cell: (i) => <div className="text-center">
                    <input className="form-control" type="date" data-id={i.row.original.bbso_id} data-field="when"
                            onBlur={p.onFieldChange} defaultValue={i.getValue()}></input>
                </div> }));

            arr.push(columnHelper.accessor('what',
                { header: 'Quoi ?', cell: (i) => <div className="text-center">
                    <input className="form-control" type="text" data-id={i.row.original.bbso_id} data-field="what"
                            onBlur={p.onFieldChange} defaultValue={i.getValue()}></input>
                </div> }));
        }

        arr.push(columnHelper.accessor('status',
            { header: 'Statut', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('xx',
            { header: '', cell: (i) => <div className="text-center">
                <a className="btn btn-outline-info" href={"/qhse/bbso/form?id=" + i.row.original.bbso_id} target="_blank">Entrer</a>
            </div> }));


        return arr;
    }

    //Set columns
    const columns = getColumns();


    return <div className="mt-1">
        { p.list && <ReactTable columns={columns} data={p.list}
            origin="bbso_list" classes={"no-padding fixFstCol "} top0={true} />}
    </div>

}

export default BbsoTable;