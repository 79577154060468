import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "test";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const testFormData = (o) => {

    return axios
        .post(API_URL + "/upload-file", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });

}

export default {
    testFormData
}