import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { currentEnDate, getBase64, getDateTimeFromDb } from "../../functions/StrFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const Annoucement = (props) => {
    const [list, setList] = useState([]);
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [tab, setTab] = useState(0);
    const [base64Img, setBase64Img] = useState();

    const contentBlock = htmlToDraft("");
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    const contentBlockEn = htmlToDraft("");
    const contentStateEn = ContentState.createFromBlockArray(contentBlockEn.contentBlocks);
    const [editorStateEn, setEditorStateEn] = useState(() => EditorState.createWithContent(contentStateEn))

    Moment.locale('fr');

    useEffect(() => {
        setLoading(true);
        CheckConnDao.getAnnouncementsList().then(
            (r) => {
                setList(r);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }, []);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        arr.push(columnHelper.accessor('id', { header: '#', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('msg_fr', {
            header: 'Message français',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));
        arr.push(columnHelper.accessor('msg_en', {
            header: 'Message anglais',
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));

        arr.push(columnHelper.accessor('date_start',
            {
                header: 'Début d\'affichage',
                cell: (i) => <div className="text-center">{getDateTimeFromDb(i.getValue(), "fr", true)}</div>
            }));

        arr.push(columnHelper.accessor('date_end',
            {
                header: 'Fin d\'affichage',
                cell: (i) => <div className="text-center">{getDateTimeFromDb(i.getValue(), "fr", true)}</div>
            }));

            arr.push(columnHelper.accessor('url',
                {
                    header: 'Image',
                    cell: (i) => <div className="text-center">
                        <img src={i.getValue()} style={{maxWidth: "100px"}}/>
                        </div>
                }));

        arr.push(columnHelper.accessor('x', {
            header: '', cell: (i) => <div className="text-center">
                <button className="btn btn-outline-danger" onClick={() => deleteAnn(i.row.id)}>Supprimer</button>
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    const submit = (e) => {
        e.preventDefault();

        var msg_fr = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        var msg_en = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()));
        var o = {
            msg_fr: msg_fr,
            msg_en: msg_en,
            date_start: e.target.date_start.value,
            date_end: e.target.date_end.value,
            img: base64Img
        }

        setSaving(true);
        CheckConnDao.addAnnouncements(o).then(
            (id) => {
                var arr = [...list];

                arr.push({ id: id, msg_en: o.msg_en, msg_fr: o.msg_fr, date: currentEnDate() });
                setList(arr);
                setMsg("Sauvegardé !");
                setSaving(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        )
    }

    const deleteAnn = (k) => {
        CheckConnDao.deleteAnnouncement(list[k].id);

        var arr = [...list];
        arr = arrayRemoveElt(arr, k);
        setList(arr);

    }

    const onImgChange = (e) => {
        getBase64(e.target.files[0], (res) => { console.log(res); setBase64Img(res) })
    }

    return <Fragment>

        <h5 className="text-center">Annonces</h5>

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
                <a className={"nav-link " + (tab === 0 ? "active" : "")}
                    onClick={() => setTab(0)}>Nouveau</a>
            </li>
            <li className="nav-item">
                <a className={"nav-link " + (tab === 1 ? "active" : "")}
                    onClick={() => setTab(1)}>Historique</a>
            </li>
        </ul>

        {tab === 0 && <div>
            <form onSubmit={submit}>
                <div className="input-group mb-3" style={{ width: "500px" }}>
                    <label className="input-group-text">Image à afficher</label>
                    <input type="file" className="form-control" name="file"
                        onChange={onImgChange} accept="image/png, image/gif, image/jpeg" />
                </div>

                <div className="input-group mb-3" style={{ width: "500px" }}>
                    <span className="input-group-text" id="basic-addon1">Début d'affichage</span>
                    <input type="date" className="form-control" name="date_start" required />

                    <span className="input-group-text" id="basic-addon1">Fin</span>
                    <input type="date" className="form-control" name="date_end" required />
                </div>

                <div className="d-flex">

                    <div className="me-1">
                        <h6>Message français (facultatif)</h6>
                        <Editor editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="draftEditor-wrapper mb-3" editorClassName="draftEditor-editor" />
                    </div>
                    <div>
                        <h6>Message anglais (facultatif)</h6>
                        <Editor editorState={editorStateEn}
                            onEditorStateChange={setEditorStateEn}
                            wrapperClassName="draftEditor-wrapper mb-3" editorClassName="draftEditor-editor" />
                    </div>
                </div>




                <div className="text-center mb-3">
                    <button className="btn btn-outline-secondary" disabled={saving}>
                        {saving && <ButtonWaiting />}
                        Enregistrer
                    </button>
                </div>

            </form>
        </div>

        }


        {(list.length > 0 && tab === 1) &&
            <ReactTable columns={columns} data={list} top0={true} origin="annoucements" />
        }

    </Fragment>
}

export default Annoucement;