import React, { useState } from 'react';
import CheckConnDao from '../../../services/common/CheckConnDao';
import errorManagement from '../../../services/errorManagement';
import { ButtonWaiting } from '../../../functions/PageAnimation';

const SendMail = ({ props, setError, setMsg }) => {
    const [loading, setLoading] = useState(false);

    const testSendEmail = () => {
        setLoading(true);

        CheckConnDao.testSendMail().then(() => { setMsg("ok"); setLoading(false); },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            });
    }

    return <div>
        <h2 className='text-center'>Test envoi mail</h2>
        <div className='text-center'>
            <button className='btn btn-success' disabled={loading} onClick={testSendEmail}>
                {loading && <ButtonWaiting />}
                Envoyer</button>
        </div>
    </div>

}

export default SendMail;