import { Fragment, useEffect, useState } from "react";
import { ArticleAutoComplete } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ArticlesAutocompletion from "../../services/autocomplete/ArticlesAutocompletion";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import MachineInfo from "../../services/machines/MachineInfo";
import Movement from "../../services/storehouse/Movement";
import BusinessSearch from "./components/BusinessSearch";

const ArticleRequest = (props) => {
    const [row, setRow] = useState([]);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [multipleArt, setMultipleArt] = useState();
    const [loading, setLoading] = useState(false);
    const [autocompletion, setAutocompletion] = useState();
    const [closed, setClosed] = useState(false);
    const [businessSrch, setBusinessSrch] = useState();

    const user = JSON.parse(localStorage.getItem("user"));
    const hOnly = user && (!user.roles.includes("ROLE_STOREHOUSE") && !user.roles.includes("ROLE_ADMIN"));

    const subsetList = ["Ascenseur / Pistonnage", "Cavité", "Centreur", "Centreur à ventouses", "Châssis",
        "Convoyeur coiffe", "Convoyeur principal", "Encollage", "Fourniture", "Habillage", "Insonorisation",
        "Magasin TE-LP", "Margeur", "Marquage", "Plaquage", "Pliage", "Pneumatique", "Pré-pliage",
        "Taqueur", "Transfert"];

    useEffect(() => {
        //Get settings
        CheckConnDao.getSettings("close_request").then(
            (response) => {
                if (response !== "") {
                    var split = response.split("to");

                    var start = new Date(split[0].trim().replace("T", " "));
                    var end = new Date(split[1].trim().replace("T", " "));
                    var today = new Date();
                    setClosed(today >= start && today <= end);
                }


            },
            () => { }
        )

        ArticlesAutocompletion.getArtCode().then(
            (response) => {
                setAutocompletion(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

        var arr = [];
        arr.push(getEmptyRow());
        setRow(arr);
    }, []);

    function getEmptyRow() {
        return {
            business: null,
            searchBiz: false,
            businessList: null,
            machine: null,
            searchMach: false,
            machineList: null,
            article: null,
            searcAert: false,
            reference: null,
            qty: 0,
            stock: 0,
            location: null,
            cause: null,
            se: null
        };
    }

    const addRow = () => {
        var arr = [...row];
        arr.push(getEmptyRow());
        setRow(arr);
    }

    const removeRow = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...row];
        arr = arrayRemoveElt(arr, key);
        setRow(arr);
    }

    const onInputChange = (e, upper = true) => {
        var key = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;
        var arr = [...row];

        arr[key][field] = upper ? value.toUpperCase() : value;

        setRow(arr)
    }

    /**
     * On business blur get the machines
     * 
     * @param {*} e 
     * @returns 
     */
    const getMachines = (business) => {
        setError();
        var arr = [...row];

        setBusinessSrch();
        arr[businessSrch].business = business;

        if (!ignoreMachineSrch(business)) {
            business = business.substring(0, 7) + "0";
            setLoading(true);
            MachineInfo.getAllMachineInfo(business).then(
                (response) => {
                    var machList = new Array();
                    response.map(v => { if (!machList.includes(v.no_machine)) machList.push(v.no_machine) });
                    arr[businessSrch].machList = machList;
                    arr[businessSrch].oldVal = business;
                    setRow(arr);
                    setLoading(false);
                },
                (error) => {
                    /*setError(errorManagement.handleError(props, error));
                    arr[businessSrch].business = null;
                    delete arr[businessSrch].machList;
                    arr[businessSrch].oldVal = null;
                    setRow(arr);*/
                    setLoading(false);
                    arr[businessSrch].machList = ["000000"];
                    arr[businessSrch].machine = "000000";
                    arr[businessSrch].oldMachine = "000000";
                    setRow(arr);
                }
            );
        } else {
            delete arr[businessSrch].machList;
            arr[businessSrch].machine = "000000";
            arr[businessSrch].oldMachine = "000000";
            setRow(arr);
        }


    }

    function ignoreMachineSrch(business) {
        if (!business) return false;
        return ["AATE", "GATE", "GSCL", "REMB"].includes(business.substring(0, 4))
    }

    /**
     * On choose machine in select
     */
    const onMachineSelect = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...row];
        var machine = e.target.value;

        arr[key].machine = machine;
        setRow(arr);
    }

    /**
     * Check if connected
     */

    /**
     * On blur machine get business
     */
    const getBusiness = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...row];
        var machine = e.target.value;

        if (machine === null || machine === "") {
            delete arr[key].machList;
            setRow(arr);
            return;
        }

        if (arr[key].business && arr[key].business.length > 3 && ignoreMachineSrch(arr[key].business)) return;

        var oldVal = e.target.getAttribute("data-old");
        if (oldVal && oldVal === machine) return;

        setLoading(true);

        if (machine.length < 4) {
            setError("Le code machine comporte 4 caractères !");
            setLoading(false);
            return;
        }

        MachineInfo.getAllMachineInfo(machine).then(
            (response) => {
                var b = response[0].id_aff;
                arr[key].business = b.substring(0, 7) + "H";
                arr[key].oldVal = b.substring(0, 7) + "H";
                arr[key].oldMachine = machine;
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const cancelArticleChoose = () => {
        setMultipleArt();
    }

    const chooseArticle = (e) => {
        var key_list = e.target.getAttribute("data-key");
        var key_row = multipleArt.key;
        var arr = [...row];

        arr[key_row].article = multipleArt.list[key_list].CDARL;
        arr[key_row].oldArticle = multipleArt.list[key_list].CDARL;
        arr[key_row].reference = multipleArt.list[key_list].ZN02PA;
        arr[key_row].stock = multipleArt.list[key_list].QTSTL - multipleArt.list[key_list].QTREL + multipleArt.list[key_list].QTCDL;

        setRow(arr);
        setMultipleArt();
        setLoading(false);

    }

    const submitForm = (e) => {
        e.preventDefault();
        setError();
        setMsg();

        //Check articles
        var err = "";
        for (let i = 0; i < row.length; i++)
            if (!autocompletion.includes(row[i].article)) err += row[i].article + ", "

        if (err !== "") {
            setError("Code(s) article(s) erroné(s): " + err + " veuillez sélectionner dans le menu déroulant "
                + "lors de la saisie.");
            return;
        }

        setLoading(true);
        Movement.saveArticleRequest(row).then(
            (response) => {
                setMsg("Demande envoyé<br><br>"
                    + "<a href=\"#\" onclick=\"window.location.reload()\" className=\"fw-bold\">Nouvelle demande</a>");
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const onArticleChoose = (e, k) => {

        if (e === null) {
            setError("Article non trouvé");
            scrollToTop();
            e = new Object();
            e.CDARL = "";
            e.ZN02PA = "";
            e.QTSTL = "";
        }

        var arr = [...row];
        arr[k].article = e.CDARL.trim();
        arr[k].reference = e.ZN02PA;
        arr[k].stock = parseFloat(e.QTSTL);
        setRow(arr);

    }

    const onArticleChange = (v, k) => {
        var arr = [...row];
        arr[k].article = v;
        setRow(arr);
    }

    return <Fragment>

        <h4 className="text-center">Sortie de pièces comptoir</h4>
        <br></br>
        {
            closed &&
            <div className="text-center"><img src="/img/storehouse/closed_inventory.png"></img></div>
        }

        {!closed &&
            <div className="table-responsive">
                {error && <ErrorMessage error={error} />}
                {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

                <form onSubmit={submitForm}>
                    <table className="table text-center">
                        <thead>
                            <tr className="table-info text-center fw-bold">
                                <th scope="col">Affaire</th>
                                <th scope="col">N° machine</th>
                                <th scope="col">S/E</th>
                                <th scope="col">Article</th>
                                <th scope="col">Référence</th>
                                <th scope="col">Quantité en stock</th>
                                <th scope="col">Quantité demandée</th>
                                <th scope="col">Motif</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                row && row.map(
                                    (v, k) =>
                                        <tr key={"r" + k}>
                                            {
                                                loading && <td colSpan={7} className="text-center"><ButtonWaiting /></td>
                                            }
                                            {
                                                !loading &&
                                                <Fragment>
                                                    <th scope="row">
                                                        <input className="form-control" data-key={k} data-field="business" data-old={v.oldVal}
                                                            onClick={() => setBusinessSrch(k)} value={v.business} required readOnly></input>
                                                    </th>
                                                    <td>
                                                        {
                                                            v.machList &&
                                                            <select className="form-select" data-key={k} onChange={onMachineSelect} required>
                                                                <option value="">Choisissez</option>
                                                                <option value="SAV" selected={v.machine === "SAV"}>SAV</option>
                                                                {
                                                                    v.machList.map((v2, k2) => {
                                                                        return <option value={v2} selected={v.machine === v2}>{v2}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        }
                                                        {
                                                            !v.machList &&
                                                            <input className="form-control" data-key={k} data-field="machine" data-old={v.oldMachine}
                                                                onChange={onInputChange} onBlur={getBusiness} value={v.machine} required
                                                                readOnly={ignoreMachineSrch(v.business)}></input>
                                                        }
                                                    </td>
                                                    <td>
                                                        <select className="form-select" onChange={(e) => onInputChange(e, false)} data-field="se" 
                                                             data-key={k} value={v.se} required >
                                                            <option value="">Choisissez</option>
                                                            {subsetList.map((v, k) => {
                                                                return <option value={v} key={"se" + k}>{v}</option>
                                                            })

                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <ArticleAutoComplete value={v.article} onChooseArt={(e) => onArticleChoose(e, k)}
                                                            setValue={(val) => onArticleChange(val, k)} searchDetail={true} />
                                                    </td>
                                                    <td>{v.reference}</td>
                                                    <td>{v.stock}</td>
                                                    <td>
                                                        <input type="number" data-key={k} className="form-control text-center fw-bold" data-field="qty"
                                                            onChange={onInputChange} min={1} value={v.qty} required></input>
                                                    </td>
                                                    <td>
                                                        <input className="form-control" data-key={k} data-field="cause"
                                                            onChange={onInputChange} value={v.cause} list="causes" required></input>
                                                    </td>
                                                    <td>
                                                        {
                                                            k > 0 && <img className="cursor-pointer" data-key={k} onClick={removeRow}
                                                                src={"/common/remove_icon.png"} style={{ width: "30px" }}></img>
                                                        }
                                                    </td>
                                                </Fragment>
                                            }

                                        </tr>
                                )
                            }

                            {
                                row.length < 5 &&
                                <tr className="text-start">
                                    <td colSpan={8}>
                                        <img className="cursor-pointer" onClick={addRow}
                                            src={"/common/add-logo.png"} style={{ width: "30px" }}></img>
                                    </td>
                                </tr>
                            }

                        </tbody>
                    </table>

                    {
                        !msg &&
                        <div className="text-center">
                            <button type="submit" className="btn btn-success" disabled={loading}>
                                {loading && <ButtonWaiting />}
                                Soumettre
                            </button>
                        </div>
                    }


                </form>

                {
                    multipleArt &&
                    <div className="custom-tooltip text-center">
                        <button type="button" className="btn-close" aria-label="Close" onClick={cancelArticleChoose}></button>
                        <h5>Plusieurs articles ont été retrouvés, veuillez en choisir un.</h5><br></br><br></br>
                        <div style={{ height: "250px", overflowY: "auto" }}>
                            <table className="table">
                                <tr>
                                    <th>Article</th>
                                    <th>Ref</th>
                                    <th>Qté</th>
                                    <td></td>
                                </tr>
                                {
                                    multipleArt.list.map(
                                        (v, k) =>
                                            <tr key={"art" + k}>
                                                <td>{v.CDARL}</td>
                                                <td>{v.ZN02PA}</td>
                                                <td>{v.QTSTL}</td>
                                                <td>
                                                    <button className="btn btn-success"
                                                        data-key={k} onClick={chooseArticle}>Choisir</button>
                                                </td>
                                            </tr>
                                    )
                                }
                            </table>
                        </div>
                    </div>
                }

                <datalist id="causes">
                    <option value="MANQUANT" />
                    <option value="CASSE / PANNE / PIECE HS" />
                    <option value="ERREUR NOMENCLATURE" />
                    <option value="ERREUR SORTIE - COMPTAGE" />
                    <option value="ERREUR SORTIE - MAUVAISE PIECE" />
                </datalist>
            </div>

        }

        {businessSrch !== undefined && <BusinessSearch setBusinessSrch={setBusinessSrch}
            getMachines={getMachines} />}
    </Fragment>
}

export default ArticleRequest;