
const Folder = (p) => {

    function getField(field) {
        return field + (p.k === 2 ? "_2" : "")
    }

    return <div className="table-responsive">
        <table className="table businessCardStyle">
            <thead>
                <tr className="bg-dark bg-gradient text-white text-center rounded">
                    <th scope="col" colSpan={3}>Coiffe {p.k ? p.k : ""}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="border-end text-center" style={{ width: "50%" }}>
                        <div className="form-floating mb-3">
                            <select className="form-select" data-field={getField("cannelure_coiffe")} disabled={p.readOnly}
                                value={p.machineInfo[getField("cannelure_coiffe")]} onChange={p.onSelectChange}>
                                <option value=""></option>
                                {
                                    p.dp && p.dp.map((v, k2) => {
                                        if (v.field === "cannelure")
                                            return <option key={"box1Type" + k2} value={v.label}>{v.label}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="Fabricant">Cannelure</label>
                        </div>

                        <div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo[getField("hauteur_rabat_coiffe")]}
                                    placeholder="xxx" data-field={getField("hauteur_rabat_coiffe")} readOnly={p.readOnly}
                                    onBlur={p.onInputChange} />
                                <label>Rabats</label>
                            </div>
                        </div>

                        <div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" defaultValue={p.machineInfo[getField("bande_arrachement")]}
                                    placeholder="xxx" data-field={getField("bande_arrachement")} readOnly={p.readOnly}
                                    onBlur={p.onInputChange} />
                                <label>Bande d'arrachement</label>
                            </div>
                        </div>

                    </td>

                    <td className="border-end text-center" style={{ width: "50%" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={p.machineInfo[getField("ech_l")]}
                                        placeholder="xxx" data-field={getField("ech_l")} readOnly={p.readOnly}
                                        onBlur={p.onInputChange} />
                                    <label>Echancrure L</label>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <div className="form-floating mb-3" style={{ marginLeft: "10px" }} >
                                    <input type="text" className="form-control" defaultValue={p.machineInfo[getField("ech_h")]}
                                        placeholder="xxx" data-field={getField("ech_h")} readOnly={p.readOnly}
                                        onBlur={p.onInputChange} />
                                    <label>Echancrure H</label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="form-floating mb-3">
                                <textarea type="text" className="form-control" defaultValue={p.machineInfo[getField("ech_com")]}
                                    placeholder="xxx" data-field={getField("ech_com")} readOnly={p.readOnly}
                                    onBlur={p.onInputChange} style={{ minHeight: "100px" }} />
                                <label>Commentaire echancrure</label>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <div>
                            <div className="form-floating mb-3">
                                <textarea type="text" className="form-control" defaultValue={p.machineInfo[getField("autre_particularites")]}
                                    placeholder="xxx" data-field={getField("autre_particularites")} readOnly={p.readOnly}
                                    onBlur={p.onInputChange} style={{ minHeight: "100px" }} />
                                <label>Particularités</label>
                            </div>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>



    </div>

}

export default Folder;