import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getPublicContent = () => {
  return axios.get(API_URL + "test/all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "test/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "test/mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "test/admin", { headers: authHeader() });
};

const getConfirmation = (id) => {
  return axios.get( API_URL + "auth/mail-confirm?param=" + id ).then((response) => {
      return response.data;
    });
};

const referentEmailExists = (mail) => {
  return axios.get( API_URL + "auth/mail-exists?mail=" + mail ).then((response) => {
    return response.data;
  });
};

const testReturnTime = (time) => {
  return axios.get( API_URL + "test/public/test?time=" + time ).then((response) => {
    return response.data;
  });
};

const isInMaintenance = () => {
  return axios
    .get(API_URL + "auth/maintenance-mode", {},
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      return response.data;
    });;

};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getConfirmation,
  referentEmailExists,
  testReturnTime,
  isInMaintenance
};