import { SmallFrame } from "../../../common/smallComponents.js/CustomDivs"
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { ButtonWaiting } from "../../../functions/PageAnimation";
import { getEnFormat } from "../../../functions/StrFunctions";
import errorManagement from "../../../services/errorManagement";
import transport from "../../../services/supplyChain/transport";

const DeliveryComp = ({props, delivery, setDelivery, getCleanedArr, getArray, business, reliquat, picking}) => {

    const createDelivery = (e) => {
        e.preventDefault();
        
        setDelivery( (s) => ({...s, error: undefined}) )

        var nb_palet = parseInt( e.target.nb_palet.value ) || 0;
        var nb_box = parseInt( e.target.nb_box.value || 0 );
        var nb_pack = parseInt( e.target.nb_pack.value || 0 );

        if( nb_palet + nb_box + nb_pack === 0 ){
            setDelivery( (s) => ({...s, error: "Choisissez au moins un type de colisage"}) )
            return;
        }

        var list;

        list = getCleanedArr(getArray(e.target.list.value));
        
        var o = {
            business: business,
            nb_palet: nb_palet,
            nb_box: nb_box,
            nb_pack: nb_pack,
            exp_date: e.target.exp_date.value,
            del_date: e.target.del_date.value,
            coms: e.target.coms.value,
            list: list,
            typeList: e.target.list.value
        }
        //console.log(o.list)

        setDelivery( (s) => ({...s, creating: true}) )
        transport.createCompleteProcessFromPicking(o).then(
            (response) => {
                console.log(response)
                window.location.href = "/supply-chain/tools/expedition?page=shipment-request&shipment_id="
                    + response.exp_id +"&id=" + response.ship_id;
            }, (error) => {
                setDelivery( (s) => ({...s, error: errorManagement.handleError(props, error), creating: false}) )
            }
        )
    }

    return <SmallFrame close={() => { setDelivery((s) => s.open = false) }}
    component={<div>
        <h5 className="text-center mb-3">Demande de transport</h5>
        { delivery.error && <ErrorMessage error={delivery.error}/>

        }

        <form className="text-center fw-bold ms-auto me-auto mb-3" onSubmit={createDelivery}
            style={{ width: "800px" }}>
            <div className="form-check form-check-inline mb-3">
                <input className="form-check-input" type="radio" name="list" value="all" defaultChecked />
                <label className="form-check-label">Transport pour tout le picking</label>
            </div>

            {(reliquat && reliquat.length > 0) &&
                <div className="form-check form-check-inline mb-3">
                    <input className="form-check-input" type="radio" name="list" value="reliquat" />
                    <label className="form-check-label">Transport pour le reliquat</label>
                </div>}

            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Colisage: Palette</span>
                <input type="number" className="form-control" name="nb_palet" defaultValue={0}/>

                <span className="input-group-text" id="basic-addon1">Caisse</span>
                <input type="number" className="form-control" name="nb_box" defaultValue={0} />

                <span className="input-group-text" id="basic-addon1">Colis</span>
                <input type="number" className="form-control" name="nb_pack" defaultValue={0} />
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Date d'expédition</span>
                <input type="date" className="form-control" name="exp_date"
                    defaultValue={getEnFormat(picking[0].delivery, true)} required/>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Date de livraison</span>
                <input type="date" className="form-control" name="del_date"
                    defaultValue={getEnFormat(picking[0].DLIVT, true)} required/>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text">Commentaire</span>
                <textarea className="form-control" name="coms"></textarea>
            </div>

            <div className="text-center">
                <button className="btn btn-success" disabled={delivery.creating}>
                    {delivery.creating && <ButtonWaiting />}
                    Créer la demande</button>
            </div>
        </form>


    </div>} />

}

export default DeliveryComp