const PartOne = (props) => {
    return (
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Général</th>
                        <th scope="col">Contexte</th>
                        <th scope="col">Prestation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/** GENERAL */}
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.resp_med_mep}
                                        onBlur={props.updateBusinessField}
                                        placeholder="Resp. MeS" list="resp_med_mep" data-field="resp_med_mep"
                                        readOnly={props.readOnly} />
                                    <label>Resp. MeS</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.commercial}
                                        onBlur={props.updateBusinessField}
                                        placeholder="Commercial" list="commercial" data-field="commercial"
                                        readOnly={props.readOnly} />
                                    <label>Commercial</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.customer_id}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" data-field="customer_id"
                                        readOnly={props.readOnly} />
                                    <label>Client</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.client_final}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" data-field="client_final"
                                        readOnly={props.readOnly} />
                                    <label>Client final</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.chef_proj}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" list="chef_proj" data-field="chef_proj"
                                        readOnly={props.readOnly} />
                                    <label>Chef de projet</label>
                                </div>
                            </div>
                        </td>

                        {/** CONTEXTE */}
                        <td style={{ width: "33.3%" }} className="border-end">
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.secteur_act}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" list="secteur_act" data-field="secteur_act"
                                        readOnly={props.readOnly} />
                                    <label>Secteur d'activité</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.type_inst}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" list="type_inst" data-field="type_inst"
                                        readOnly={props.readOnly} data-translate="true" />
                                    <label>Type d'installation</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.produit_aff}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" data-field="produit_aff"
                                        readOnly={props.readOnly} />
                                    <label>Produits</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.ensemb_mach}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" list="ensemb_mach" data-field="ensemb_mach"
                                        readOnly={props.readOnly} />
                                    <label>Ensemb. intégrant mach.</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.type_expedit}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" list="type_expedit" data-field="type_expedit"
                                        readOnly={props.readOnly} />
                                    <label>Types d'expéditions</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.antecedent_cli}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" data-field="antecedent_cli"
                                        readOnly={props.readOnly} />
                                    <label>Antécédents chez le client</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.pays}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" list="pays" data-field="pays"
                                        readOnly={props.readOnly} />
                                    <label>Pays</label>
                                </div>
                            </div>
                        </td>

                        {/** PRESTATION */}
                        <td style={{ width: "33.3%" }}>
                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" defaultValue={props.businessInfo.nb_lign_comp}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" data-field="nb_lign_comp"
                                        readOnly={props.readOnly} />
                                    <label>Nb lignes complètes</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" defaultValue={props.businessInfo.nb_mach_occas}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" data-field="nb_mach_occas"
                                        readOnly={props.readOnly} />
                                    <label>Nb machines occasion</label>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" defaultValue={props.businessInfo.nb_mach_neuve}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" data-field="nb_mach_neuve"
                                        readOnly={props.readOnly} />
                                    <label>Nb machines neuves</label>
                                </div>

                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" defaultValue={props.businessInfo.procede_emb}
                                        onBlur={props.updateBusinessField}
                                        placeholder="xxx" list="procede_emb" data-field="procede_emb"
                                        readOnly={props.readOnly} />
                                    <label>Procédé emballage</label>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default PartOne;
