import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomToolTip from "../../common/smallComponents.js/CustomToolTip";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { getFrFormat, updateDrawingUrl } from "../../functions/StrFunctions";
import i18n from "../../i18n";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import Select from "react-select";
import { removeAndReturnArray } from "../../functions/ArrayFunctions";
import { getExpander } from "../../common/smallComponents.js/Filter";
import { AdvancedResearch } from "../../common/smallComponents.js/AdvancedResearch";

const SearchArticle = (props) => {
    const { t } = useTranslation('article');
    const user = JSON.parse(localStorage.getItem("user"));
    const [isInternal] = useState(user && user.roles.includes("ROLE_INTERNAL"));
    const [isPurchase] = useState(user && user.roles.includes("ROLE_PURCHASE"));
    const isSee = useState(user && user.roles.includes("ROLE_SEE"));
    const [searching, setSearching] = useState(false);
    const [error, setError] = useState();
    const [data, setData] = useState([]);
    const token = user && "Bearer " + user.accessToken;
    const [nbResult, setNbResult] = useState(100);
    const [invisible, setInvisble] = useState(false);

    const [advanced, setAdvanced] = useState(false);
    const [metaData, setMetaData] = useState();
    const [filter, setFilter] = useState([{ value: "", label: "", type: "", value1: "", value2: "", condition: "" }]);

    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "articles/info/get-drawing?Authorization=" + token + "&article=";
    var url2 = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    useEffect(() => {
        document.getElementById("searchIpt").focus();

        ArticleInfo.getArticleMetaData().then(
            (response) => {
                var arr = [];
                for (let i = 0; i < response.length; i++)
                    arr.push({
                        value: response[i].columnName,
                        label: response[i].comment,
                        type: response[i].type
                    });

                setMetaData(arr);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
            }
        )
    }, []);

    const search = (e) => {
        e.preventDefault();
        setError();
        setData([]);

        var term = e.target.term.value;

        setSearching(true);
        setNbResult(100);
        ArticleInfo.searchArticle(term, i18n.language, false).then(
            (response) => {
                setData(response)
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        if (data && data.length > 0 && filter.length > 0 && data[0]["_" + filter[0].value] !== undefined) {
            arr.push(getExpander(columnHelper));
        }

        arr.push(columnHelper.accessor('ar', {
            header: t('ar_code'),
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        if (isSee) {
            arr.push(columnHelper.accessor('visual_id', {
                header: "Visual ID",
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('visual_desc', {
                header: "Visual desc.",
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));
        }

        arr.push(columnHelper.accessor('com_ref', {
            header: t('com_lab'),
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('tec_ref', {
            header: t('tec_lab'),
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('plan', {
            header: t('plan'),
            cell: i => <div className={"text-center "}>
                <Fragment>
                    {
                        (i.row.original.pdf_plan !== null && i.row.original.pdf_plan !== 'ignore') &&
                        <a target="_blank" href={url2 + updateDrawingUrl(i.row.original.pdf_plan)}>{i.getValue()}</a>

                    }

                    {
                        (i.row.original.pdf_plan === null || i.row.original.pdf_plan === 'ignore') &&
                        <span>{i.getValue()}</span>
                    }
                </Fragment>
            </div>
        }));

        if (isInternal) {
            arr.push(columnHelper.accessor('qty', {
                header: t('qty_avai'),
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('needs_tot', {
                header: t('qty_reserv'),
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('remain_qty', {
                header: t('qty_at_term'),
                cell: i => <div className={"text-center "}>{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('pxrel', {
                header: "Prix de revient",
                cell: i => <div className={"text-center "}>
                    <div>{i.getValue()} €</div>
                    {i.row.original.last_price !== null &&
                        <div className="fst-italic" style={{ fontSize: "12px" }}>Dernier prix achat: {i.row.original.last_price} €</div>}
                </div>
            }));
        }

        if (isPurchase) {
            arr.push(columnHelper.accessor('xxxx', {
                header: "",
                cell: i => <div className={"text-center "}>
                    <a target="_blank" href={"/article/info/stock/dynamic?article=" + i.row.original.ar.trim()}>Stock dynamique</a>
                </div>
            }));
        }

        arr.push(columnHelper.accessor('DCREL', {
            header: "Date de création",
            cell: i => <div className={"text-center "}>{getFrFormat(i.getValue(), true)}</div>
        }));

        if (isInternal) {
            arr.push(columnHelper.accessor('comments', {
                header: "Observations",
                cell: i => <div className={"text-center "}>
                    <Fragment>
                        {i.getValue() !== null &&
                            <div className="mb-1">
                                <CustomToolTip props={
                                    {
                                        tooltipText: i.getValue(),
                                        originText: i.getValue().substring(0, 31) + (i.getValue().length > 31 ? "..." : "")
                                    }} />
                            </div>
                        }

                        {
                            ((user.roles.includes("ROLE_NC_READ") || user.roles.includes("ROLE_NC_WRITE") || user.roles.includes("ROLE_ADMIN"))
                                && i.row.original.no_nc) && <div>
                                <a href={"/storehouse/quality/nc?id=" + i.row.original.no_nc} target="_blank"
                                    className="btn btn-outline-danger">NC en cours</a>
                            </div>
                        }
                    </Fragment>
                </div>
            }));
        }


        arr.push(columnHelper.accessor('xxx', {
            header: "",
            cell: i => <div className={"text-center "}>
                <a target="_blank" href={"/article/info/get?article=" + i.row.original.ar.trim()}
                    className="btn btn-success">{t('enter')}</a>
            </div>
        }));



        return arr;
    }

    const splittedSearch = (e) => {
        e.preventDefault();
        var ar = e.target.arCode.value;
        var label = e.target.label.value;
        var ref = e.target.ref.value;
        var ref_frn = e.target.ref_frn.value;
        var nbRecords = e.target.nbRecords.value;

        var dates = { start: e.target.start.value, end: e.target.end.value };

        setData([]);
        setError();


        setSearching(true);
        setNbResult(nbRecords);
        ArticleInfo.searchSplittedArticle(ar, label, ref, ref_frn, nbRecords, i18n.language, invisible, dates).then(
            (response) => {
                setData(response)
                setSearching(false);
                document.getElementById('collapseBtn').click();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        )


    }

    //Set columns
    const columns = getColumns();

    const onChangeFilter = (e, k) => {
        var arr = [...filter];
        arr[k].label = e.label;
        arr[k].type = e.type;
        arr[k].value = e.value;
        arr[k].condition = "";
        arr[k].value1 = "";
        arr[k].value2 = "";
        setFilter(arr);
    }

    const onConditionChange = (e, k) => {
        var arr = [...filter];
        arr[k].condition = e.target.value;
        setFilter(arr);
    }

    const onChangeValue = (e, k, field) => {
        var arr = [...filter];
        arr[k][field] = e.target.value;
        setFilter(arr);
    }

    const addFilter = () => {
        var arr = [...filter];
        arr.push({ value: "", label: "", type: "", value1: "", value2: "", condition: "", logical_op: "and" });
        setFilter(arr);
    }

    const onLogicalOperatorChange = (e, k) => {
        var arr = [...filter];
        arr[k].logical_op = e.target.value;
        setFilter(arr);
    }

    const removeFilter = (k) => {
        setFilter(removeAndReturnArray(filter, k))
    }

    const Filter = (props) => {
        return <div className="d-flex mb-3">
            {props.k > 0 && <div style={{ width: "80px" }}>
                <select className="form-select fw-bold" value={filter[props.k].logical_op || ""}
                    onChange={(e) => onLogicalOperatorChange(e, props.k)}>

                    <option value="and">ET</option>
                    <option value="or">OU</option>

                </select>
            </div>
            }

            <div style={{ width: "30%" }} className={props.k > 0 ? "ps-3" : ""}>
                <Select options={metaData} onChange={(e) => onChangeFilter(e, props.k)} value={props.v}
                    className="fillInBlank fw-bold" />
            </div>

            <div style={{ width: "33.33%" }} className=" ps-3">
                <select className="form-select fw-bold" value={filter[props.k].condition || ""}
                    onChange={(e) => onConditionChange(e, props.k)}>
                    <option value="">Condition</option>
                    {props.v.type === "" && <option value="" disabled>Choisissez une colonne</option>}

                    {props.v.type === "text" && <Fragment>
                        <option value="=">Est</option>
                        <option value="<>">N'est pas</option>
                        <option value="like">Contient</option>
                        <option value="not like">Ne contient pas</option>
                        <option value="starts with">Commence par</option>
                        <option value="is null">Est vide</option>
                        <option value="is not null">N'est pas vide</option>
                    </Fragment>}

                    {props.v.type === "date" && <Fragment>
                        <option value="=">Est</option>
                        <option value="between">Est entre</option>
                        <option value=">">Est après</option>
                        <option value="<">Est avant</option>
                        <option value="is null">Est vide</option>
                        <option value="is not null">N'est pas vide</option>
                    </Fragment>}

                    {props.v.type === "number" && <Fragment>
                        <option value="=">Est</option>
                        <option value="<>">N'est pas</option>
                        <option value="between">Est entre</option>
                        <option value=">">Est supérieur à</option>
                        <option value="<">Est inférieur à</option>
                        <option value="is null">Est vide</option>
                        <option value="is not null">N'est pas vide</option>
                    </Fragment>}
                </select>
            </div>

            <div style={{ width: "33.33%" }} className="d-flex ps-3">
                {(filter[props.k].condition && !["is null", "is not null"].includes(filter[props.k].condition)
                    && filter[props.k].type === "text") &&
                    <input type="text" class="form-control" placeholder="Valeur" defaultValue={filter[props.k].value1}
                        onBlur={(e) => onChangeValue(e, props.k, "value1")} />}

                {(filter[props.k].condition && !["is null", "is not null"].includes(filter[props.k].condition)
                    && filter[props.k].type === "date") && <Fragment>
                        <input type="date" class="form-control" defaultValue={filter[props.k].value1}
                            onBlur={(e) => onChangeValue(e, props.k, "value1")} />

                        {filter[props.k].condition === "between" &&
                            <input type="date" class="form-control ms-2" defaultValue={filter[props.k].value2}
                                onBlur={(e) => onChangeValue(e, props.k, "value2")} />
                        }
                    </Fragment>
                }

                {(filter[props.k].condition && !["is null", "is not null"].includes(filter[props.k].condition)
                    && filter[props.k].type === "number") && <Fragment>
                        <input type="number" class="form-control" defaultValue={filter[props.k].value1}
                            onBlur={(e) => onChangeValue(e, props.k, "value1")} />

                        {filter[props.k].condition === "between" &&
                            <input type="number" class="form-control ms-2" defaultValue={filter[props.k].value2}
                                onBlur={(e) => onChangeValue(e, props.k, "value2")} />
                        }
                    </Fragment>
                }
            </div>

            {props.k > 0 &&
                <div>
                    <button type="button" class="btn-close" onClick={() => removeFilter(props.k)}></button>
                </div>
            }
        </div>
    }

    function check(filter, i, k2) {
        var err = "";
        var group = k2 ? "groupe " + (k2 + 1) + " et" : "";

        //Check if column ckecked ( this is value according to react-select spec)
        if (filter.value.trim() === "") {
            err += "Choisissez la colonne au " + group + " filtre " + (i + 1) + "<br>";
        } else if (filter.condition.trim() === "") {
            err += "Choisissez la condition " + group + " au filtre " + (i + 1) + "<br>";
        } else if (!["is null", "is not null"].includes(filter.condition) && filter.value1.trim() === "") {
            err += "Choisissez la valeur du " + group + " filtre " + (i + 1) + "<br>";
        } else if (filter.condition === "between" && filter.value2.trim() === "") {
            err += "Choisissez la seconde valeur du " + group + " filtre " + (i + 1) + "<br>";
        }

        return err;
    }

    const advancedSearch = () => {
        setError();
        setData([])
        var err = "";
        for (let i = 0; i < filter.length; i++) {
            //If there is group check the group
            if (filter[i].group) {
                for (let j = 0; j < filter[i].group.length; j++) err += check(filter[i].group[j], i, j);
            } else {
                err += check(filter[i], i);
            }
        }

        if (err !== "") {
            setError(err);
            scrollToTop();
            return;
        }

        setSearching(true);
        setNbResult(500);
        ArticleInfo.advancedSearchArticle(filter, i18n.language).then(
            (response) => {
                setData(response)
                setSearching(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setSearching(false);
            }
        )
    }

    const renderSubComponent = (row) => {

        if (data && data.length > 0 && filter.length > 0 && data[0]["_" + filter[0].value] !== undefined) {
            return <table className="table">
                <thead>
                    <tr className="table-secondary fw-bold">
                        {filter.map((v, k) => {
                            return <Fragment>
                                {!v.group && <td><div className="p-3">{v.label}</div></td>}
                                {v.group && v.group.map((v2, k2) => {
                                    return <td><div className="p-3">{v2.label}</div></td>

                                })}
                            </Fragment>
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {filter.map((v, k) => {
                            return <Fragment>
                                {!v.group && <td><div className="p-3">{row.original["_" + v.value]}</div></td>}
                                {v.group && v.group.map((v2, k2) => {
                                    return <td><div className="p-3">{row.original["_" + v2.value]}</div></td>
                                })}
                            </Fragment>
                        })}
                    </tr>
                </tbody>
            </table>
        }
        else {
            return "";
        }
    };

    return <Fragment>

        <h6 className="text-center">{t('article search')}</h6>

        <div className="accordion col-10 offset-1 mb-3" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                    <form onSubmit={search}>

                        <div className="col-10 offset-1">
                            <div className="p-2 input-group mb-3">
                                <input type="text" className="form-control text-center fw-bold" id="searchIpt" autoComplete="off"
                                    placeholder={t('ar_code') + ", " + t('com_lab') + ", " + t('tec_lab') + ", " + t('plan')} name="term" />
                                <button className="btn btn-outline-success" disabled={searching}>
                                    {searching && <ButtonWaiting />}
                                    {t('search')}
                                </button>
                            </div>
                        </div>
                    </form>

                    <button className="accordion-button collapsed text-center fw-bold" id="collapseBtn" style={{ height: "0px" }}
                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span style={{ margin: "auto", width: "100%", marginTop: "-10px" }}>More</span>
                    </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    {!advanced && <div className="accordion-body">
                        <form onSubmit={splittedSearch}>
                            <div className="display-flex-wrap mb-3">
                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="arCodeInput"
                                        placeholder="xxx" name="arCode" />
                                    <label htmlFor="arCodeInput">{t('ar_code')}</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="labelInput"
                                        placeholder="xxx" name="label" />
                                    <label htmlFor="labelInput">{t('com_lab')}</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="refInput"
                                        placeholder="xxx" name="ref" />
                                    <label htmlFor="refInput">{t('tec_lab')} / {t('plan')}</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="text" className="form-control" id="refInput"
                                        placeholder="xxx" name="ref_frn" />
                                    <label htmlFor="refInput">Ref. fournisseur (achats)</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="date" className="form-control" id="refInput"
                                        placeholder="xxx" name="start" />
                                    <label htmlFor="refInput">Créé après le</label>
                                </div>

                                <div className="form-floating mb-3" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <input type="date" className="form-control" id="refInput"
                                        placeholder="xxx" name="end" />
                                    <label htmlFor="refInput">Créé avant le</label>
                                </div>

                                <div className="form-floating" style={{ margin: "auto", width: "30%", minWidth: "150px" }}>
                                    <select className="form-select" id="floatingSelect" name="nbRecords">
                                        <option value="50">50</option>
                                        <option value="500">500</option>
                                        <option value="5000">2000</option>
                                    </select>
                                    <label for="floatingSelect">Number of records</label>
                                </div>

                                <div className="form-check d-flex">
                                    <input className="form-check-input mt-auto mb-auto me-1"
                                        type="checkbox" checked={invisible} onChange={() => setInvisble(!invisible)} />
                                    <label className="form-check-label mt-auto mb-auto">
                                        Afficher articles invisibles
                                    </label>
                                </div>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-success" disabled={searching}>
                                    {searching && <ButtonWaiting />}
                                    Search
                                </button>
                            </div>
                        </form>

                        {isInternal &&
                            <div className="border-top mt-3">
                                <a onClick={() => setAdvanced(true)}>Passer aux filtres avancés</a>
                            </div>}
                    </div>

                    }

                    {advanced && <div className="accordion-body">

                        {/**{filter.map((v, k) => {
                            return <Filter v={v} k={k} />
                        }) }

                        <div className="mt-3">
                            <button className="btn btn-outline-secondary"
                                onClick={addFilter}>Ajouter un filtre</button>
                        </div>

                        <div className="mt-3 text-center">
                        <button className="btn btn-success" disabled={searching}
                            onClick={advancedSearch}>{searching && <ButtonWaiting />}Lancer la recherche</button>
                        </div>

                        <div className="border-top mt-3">
                            <a onClick={() => setAdvanced(false)}>Passer aux filtres rapides</a>
                        </div>*/}

                        <AdvancedResearch filter={filter} setFilter={setFilter} advancedSearch={advancedSearch}
                            metaData={metaData} searching={searching} />

                        <div className="border-top mt-3">
                            <a onClick={() => setAdvanced(false)}>Passer aux filtres rapides</a>
                        </div>
                    </div>
                    }



                </div>
            </div>
        </div>

        {/*<div className="h-divider">
            <div className="shadow-divider"></div>
</div>*/}

        {data.length >= 50 && <div className="text-center fw-bold">{nbResult + t('fifty-first')}</div>}

        {error && <ErrorMessage error={error} errorState={setError} />}

        {/*data.length > 0 && <Table columns={columns} data={data} />*/}

        {data.length > 0 && <ReactTable columns={columns}
            data={data} canExpand={true} renderSubComponent={renderSubComponent} sendMyself={true}
            origin="art_search" classes={"no-padding"} top0={true} />}


    </Fragment>

}

export default SearchArticle;