import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Manufacturing = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown text-white">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('prod ctrl')}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {(props.roles && (props.roles.includes('ROLE_ADMIN') || props.roles.includes('ROLE_WORKSHOP_N1'))) &&
                    <li>
                        <a href={"/workshop/check-list/summary"} className="dropdown-item">
                            Liste des contrôles
                        </a>
                    </li>
                }

                {
                    (props.roles && (props.roles.includes('ROLE_ADMIN') || props.roles.includes('ROLE_INTEGRATION'))) &&
                    <li>
                        <a href={"/workshop/check-list/integration"} className="dropdown-item">
                            {t('integration check list')}
                        </a>
                    </li>
                }

                {
                    (props.roles && (props.roles.includes('ROLE_ADMIN') || props.roles.includes('ROLE_ASSEMBLY'))) &&
                    <li>
                        <a href={"/workshop/check-list/assembly"} className="dropdown-item">
                            {t('assembly check list')}
                        </a>
                    </li>
                }

                {
                    (props.roles && (props.roles.includes('ROLE_ADMIN') || props.roles.includes('ROLE_ELECTRICAL'))) &&
                    <li>
                        <a href={"/workshop/check-list/electrical"} className="dropdown-item">
                            {t('assembly check list')} (elec)
                        </a>
                    </li>
                }


                <li><hr className="dropdown-divider" /></li>

                <li>
                    <a href={"/workshop/production/tracking/v2"} className="dropdown-item">
                        Scanner Qr Code de prod.
                    </a>
                </li>

                {(props.roles && (props.roles.includes('ROLE_ADMIN') 
                    || props.roles.includes('ROLE_WORKSHOP_N1'))) &&
                    <li>
                        <a href={"/workshop/production/tracking/list/v2"} className="dropdown-item">
                            Suivi de prod.
                        </a>
                    </li>}


            </ul>
        </li>
    )

}

export default Manufacturing;