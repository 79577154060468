import React, { useState } from 'react';

const SpeechRecognition = ({ props, setError, setMsg }) => {

    const [transcript, setTranscript] = useState('');
    const [isListening, setIsListening] = useState(false);

    const handleListen = () => {
        const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        recognition.lang = 'fr-FR'; // Set language
        recognition.continuous = true; // Keep listening
        recognition.interimResults = true; // Show partial results
        setIsListening(true);

        recognition.onresult = (event) => {
            const newTranscript = Array.from(event.results)
                .map((result) => result[0].transcript)
                .join('');
            setTranscript(newTranscript);
        };

        recognition.onerror = (event) => {
            console.error('Error occurred in recognition: ', event.error);
            setIsListening(false);
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.start();

        return () => recognition.stop(); // Stop listening on cleanup
    };

    return <div>
        <h1 className='text-center'>Speech Recognition</h1>
        <button onClick={handleListen} disabled={isListening}>
            {isListening ? 'Listening...' : 'Start Listening'}
        </button>
        <p>Transcript: {transcript}</p>
    </div>

}

export default SpeechRecognition;