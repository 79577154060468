import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { ArticleAutoComplete, isSideBarOpened, SideNavBarV3 } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import Movement from "../../services/storehouse/Movement";
import { addOrRemoveOnCheck, getKey, getKeyInt, removeAndReturnArray } from "../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";
import { ptToPx } from "../../functions/Styles";

const BpTwoStock = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [value, setValue] = useState("");
    const [pin, setPin] = useState(isSideBarOpened());
    const [tagMode, setTagMode] = useState(false);

    const [obj, setObj] = useState({id: "", ar: "", qty: "", mass: "", obs: "", zone: "", depart: ""});
    const [toAdd, setToAdd] = useState([]);
    const [dangerousUpdate, setDangerousUpdate] = useState(false);

    useEffect(() => {
        Movement.getBplus2stock().then(
            (response) => {
                setList(response)
                const maxId = response.reduce((max, obj) => (obj.id > max ? obj.id : max), -Infinity);
                
                var o = { ...obj };
                o.id = parseInt( maxId ) + 1;
                setObj(o);

                setToAdd([{...o}]);

                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }, []);

    const addRows = (e) => {
        e.preventDefault();
        
        setLoading(true);

        Movement.addBplus2stock(toAdd).then(
            (response) => {
                window.location.reload();
                /*var arr = [...list]
                arr = arr.concat([...toAdd]);

                const maxId = arr.reduce((max, obj) => (obj.id > max ? obj.id : max), -Infinity);
                var o = { ...obj };
                o.id = parseInt( maxId ) + 1;
                setObj(o);

                setToAdd([{...o}]);

                setList(arr);
                setLoading(false);*/
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const addRow = () => {
        var arr = [...toAdd];
        arr.push({...obj});
        setToAdd(arr);
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: '#',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>
                { !dangerousUpdate && i.getValue()}
                { dangerousUpdate && <input className="form-control text-center" type="number" defaultValue={i.getValue()}
                    onChange={(e) => onFieldChange(e, i.row.original.auto_id, "id")} /> }
            </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'AR ou DESCRIPTIF',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                { !dangerousUpdate && i.getValue()}
                { dangerousUpdate && <input className="form-control text-center" type="text" defaultValue={i.getValue()}
                    onChange={(e) => onFieldChange(e, i.row.original.auto_id, "ar")} /> }
            </div>
        }));

        arr.push(columnHelper.accessor('ZN02PA', {
            header: 'PLAN OU REFERENCE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'QTE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="number" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.auto_id, "qty")} />
            </div>
        }));

        arr.push(columnHelper.accessor('mass', {
            header: 'STOCK DE MASSE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-check-input text-center" type="checkbox" defaultChecked={i.getValue()}
                    onChange={(e) => onFieldChange(e, i.row.original.auto_id, "mass")} />
            </div>
        }));

        arr.push(columnHelper.accessor('obs', {
            header: 'OBSERVATIONS',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="text" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.auto_id, "obs")} />
            </div>
        }));

        arr.push(columnHelper.accessor('zone', {
            header: 'ZONE STOCKAGE DE T à Z',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="text" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.auto_id, "zone")} />
            </div>
        }));

        arr.push(columnHelper.accessor('depart', {
            header: 'DATE DEPART PALETTE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="date" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.auto_id, "depart")} />
            </div>
        }));

        arr.push(columnHelper.accessor('back', {
            header: 'DATE RETOUR PALETTE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="date" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.auto_id, "back")} />
            </div>
        }));

        arr.push(columnHelper.accessor('ZN04PA', {
            header: 'RENVOI',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('auto_id', {
            header: 'Actions',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center no-print p-1" + getCss(i.row.original)}>

                <input className="form-check-input big" type="checkbox"
                    onChange={(e) => checkToDo(e, i.getValue())} defaultChecked={checked.indexOf(i.getValue()) !== -1}></input>

            </div>
        }));


        return arr;
    }

    function getCss(v) {
        if (v.back !== null && v.back !== "") return " bg-danger-subtle"
        return "";
    }

    const onFieldChange = (e, id, field) => {
        var value = field === "mass" ? (e.target.checked ? 1 : 0) : e.target.value;

        Movement.updateBplus2stock(field, value, id).then(
            () => { },
            (error) => { errorManagement.handleError(props, error) }
        )
    }

    const columns = getColumns();

    const deleteRows = () => {
        confirmAlert({
            title: "Confirmation de suppression",
            message: "Confirmez-vous la suppression de " + checked.length + " article" + (checked.length > 1 ? "s" : ""),
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        Movement.deleteBplus2stock(checked).then(
                            () => { window.location.reload() },
                            (error) => { errorManagement.handleError(props, error); setLoading(false); }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const requestBack = () => {
        confirmAlert({
            title: "Confirmation de rapatriement",
            message: "Confirmez-vous la demande de rapatriement de " + checked.length + " article" + (checked.length > 1 ? "s" : ""),
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [];
                        for (let i = 0; i < checked.length; i++) arr.push(list[getKeyInt(list, "auto_id", checked[i])]);

                        setLoading(true);
                        Movement.requestBackBplusTwoRow(arr).then(
                            () => { setMsg("Mail envoyé!"); scrollToTop(); setLoading(false); },
                            (error) => { errorManagement.handleError(props, error); setLoading(false); }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const checkToDo = (e, v) => { setChecked(addOrRemoveOnCheck(checked, e, v)) }

    const deleteRow = (k) => { setToAdd( removeAndReturnArray(toAdd, k) ) }

    const updateField = (e, k, field ) => {
        var arr = [...toAdd];
        if( field === "" ){ arr[k][field] = e.target.checked ? 1 : 0 }
        else{ arr[k][field] = e.target.value; }

        setToAdd(arr);
    }

    const onChooseArt = ( v, k ) => {
        var arr = [...toAdd];
        arr[k].ar = v;
        setToAdd(arr);
    }

    const onChangeArt = (v, k) => {
        var arr = [...toAdd];
        arr[k].ar = v;
        setToAdd(arr)
    }

    return <div className="bg-see navbarV3 right-side">
        <SideNavBarV3 setPin={setPin} content={<div className="ps-3 pe-1">
            <div className="mb-3">
                <a href="#" className="text-dark " onClick={() => window.print()}>Imprimer</a>
            </div>

            {checked.length > 0 &&
                <div>
                    {!tagMode && <Fragment>
                        <div className="mb-3">
                            <a href="/settings/contacts-mng?code=BPLUS_2_STOCK" target="_blank" className="text-dark mb-3">Carnet d'adresse du rapatriement</a>
                        </div>

                        <div className="mb-3">
                            <a href="#" className="text-dark mb-3"
                                onClick={requestBack}>Demander le rapatriement</a>
                        </div>

                        <div className="mt-3 mb-3">
                            <a href="#" className="text-dark mb-3"
                                onClick={() => setTagMode(true)}>Imprimer les affiches</a>
                        </div>

                        <div className="mt-3 mb-3">
                            <a href="#" className="text-danger mb-3"
                                onClick={deleteRows}>Supprimer {checked.length} article{checked.length > 1 ? "s" : ""}</a>
                        </div>
                    </Fragment>}


                    {tagMode && <Fragment>
                        <div className="mt-3 mb-3">
                            <a href="#" className="mb-3" onClick={() => setTagMode(false)}>Revenir au tableau</a>
                        </div>
                    </Fragment>}

                </div>
            }

            <br></br>

            Légende:
            <div className="bg-danger-subtle p-1 rounded text-center mb-5">
                Palette retournée
            </div>

            <div>

            <div className="form-check">
                <input className="form-check-input" type="checkbox" 
                    checked={dangerousUpdate} onChange={()=>setDangerousUpdate(!dangerousUpdate)}/>
                <div className="form-check-label ms-1 mt-auto">Modifier tag ou AR</div>
            </div>

                </div>
        </div>} />

        
        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h4 className={"text-center " + (tagMode ? "no-print" : "")}>Stock B+2</h4>

            {loading && <WaitingRoundSnippers />}<br></br>
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {!tagMode &&
                <div>
                    <form onSubmit={addRows}>
                        {toAdd.map((v, k) => {

                            return <div className="input-group mb-3 no-print">
                                <span className="input-group-text" >#</span>
                                <input type="number" className="form-control" name="id"
                                    value={v.id || -1} onChange={(e)=>updateField(e, k, "id")} required />

                                <span className="input-group-text" >Article</span>
                                <ArticleAutoComplete value={v.ar} setValue={(v) => onChangeArt(v, k)} 
                                    onChooseArt={(v) => { onChooseArt(v, k) }} />

                                <span className="input-group-text" >Qté</span>
                                <input type="number" className="form-control" name="qty"
                                    value={v.qty} onChange={(e)=>updateField(e, k, "qty")} required />

                                <div className="input-group-text">
                                    <input className="form-check-input mt-0" type="checkbox" name="mass"
                                        value={v.mass} onChange={(e)=>updateField(e, k, "mass")} />
                                </div>
                                <input type="text" className="form-control" value="Stock de masse" readonly />

                                <span className="input-group-text" >Obs.</span>
                                <input type="text" className="form-control" name="obs"
                                    value={v.obs} onChange={(e)=>updateField(e, k, "obs")} />

                                <span className="input-group-text" >Zone</span>
                                <input type="text" className="form-control" name="zone"
                                    value={v.zone} onChange={(e)=>updateField(e, k, "zone")} />

                                <span className="input-group-text" >Départ palette</span>
                                <input type="date" className="form-control" name="depart"
                                    value={v.depart} onChange={(e)=>updateField(e, k, "depart")} required />

                                { k > 0 && 
                                 <button type="button" className="btn btn-outline-danger" 
                                    disabled={loading} onClick={()=>deleteRow(k)}>
                                    {loading && <ButtonWaiting />}
                                    Supprimer</button>
                                }
                            </div>

                        })}
                        
                        <div>
                            <button type="button" className="btn btn-secondary" disabled={loading} onClick={addRow}>
                            {loading && <ButtonWaiting />}
                                Nouvelle ligne</button>
                        </div>

                        <div className="text-center mb-3">
                            <button type="submit" disabled={loading} className="btn btn-success">
                            {loading && <ButtonWaiting />}
                                Sauvegarder</button>
                        </div>
                    </form>

                    <ReactTable columns={columns} data={list} top0={true} origin="b2-stock"
                        tdStyle={{ padding: "0" }} />

                </div>
            }

            {tagMode && <div>
                <div className="alert alert-info no-print fw-bold text-center">Paramétrez l'impression avec les marges par défaut pour un meilleur affichage</div>

                {list.map((v, k) => {
                    return checked.includes(v.auto_id) && <table key={"print" + k}
                        className={(checked.indexOf(v.id) < checked.length - 1 ? "pagebreak" : "") + " text-center font-calibri"}
                        style={{ width: "100%", borderCollapse: "collapse" }}>
                        <tr>
                            <td className="border border-dark fw-bold" rowSpan={2} style={{ color: "black", fontSize: ptToPx(48) }}>{v.ar}</td>
                            <td className="border border-dark fw-bold" style={{ color: "black", fontSize: ptToPx(24) }}>RENVOI</td>
                        </tr>
                        <tr>
                            <td className="border border-dark fw-bold" style={{ color: "black", fontSize: ptToPx(36) }}>{v.ZN04PA}</td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ height: "10px" }}></td>
                        </tr>

                        <tr>
                            <td colSpan={2} className="border border-dark fw-bold" style={{ color: "black", fontSize: ptToPx(72) }}>QTE: {v.qty}</td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ height: "10px" }}></td>
                        </tr>

                        <tr>
                            <td colSpan={2} className="border border-dark fw-bold" style={{ color: "black", fontSize: ptToPx(200) }}>#{v.id}</td>
                        </tr>
                    </table>
                })}

            </div>}

        </div>
    </div>
}

export default BpTwoStock;