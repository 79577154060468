import { useEffect, useState } from "react";
import { Fragment } from "react";
import Labels from "../../services/storehouse/Labels";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";

const ScanInventory = (props) => {
    const [typeLocation, setTypeLocation] = useState("STOCK");
    const [location, setLocation] = useState("");
    const [nbScan, setNbScan] = useState(0);
    const [lastScan, setLastScan] = useState();
    let timer = null;
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        CheckConnDao.internal().then(() => { }, (error) => { errorManagement.handleError(props, error); });
    }, []);

    const onScan = (e) => {
        var scanned = document.getElementById("scanIpt").value;

        if (timer) clearTimeout(timer);

        if (scanned.length >= 14) {

            timer = setTimeout(() => {
                var scanned = document.getElementById("scanIpt").value;
                Labels.saveArticleToInventory(scanned, typeLocation);

                setLastScan(scanned);
                setNbScan(nbScan + 1);
                document.getElementById("scanIpt").value = "";
            }, 100);

        }

    }

    return <Fragment>
        <h4 className="text-center">Inventaire annuel</h4>
        {/*<div className="input-group mb-3">
            <span className="input-group-text">Emp. </span>
            <select className="form-select" onChange={(e) => setTypeLocation(e.target.value)} 
                value={typeLocation}>
                <option value="">Choisissez...</option>
                <option value="ET-">ET-</option>
                <option value="RA-">RA-</option>
            </select>
            <input type="text" className="form-control" placeholder="N° d'emplacement"
                onChange={(e) => { setLocation(e.target.value); setNbScan(0)} }/>
        </div>*/}

        <br></br>

        { (user && !user.trigram) && <div className="alert alert-danger text-center fw-bold">
            Votre trigramme n'est pas renseigné dans votre profil intranet. Merci de le saisir
            <a href="/profile" className="ms-1">ici</a>
        </div>}

        {(user && user.trigram && user.trigram.trim() !== "") &&
            <div className="col-8 offset-2">
                <input className="form-control text-center mb-3" onChange={onScan}
                    placeholder="Cliquez ici pour commencer à scanner" id="scanIpt" autoFocus></input>
                <p className="text-center fw-bold">Nb AR scannés: {nbScan}</p>
                {
                    lastScan && <p className="text-center fw-bold">Dernier AR scanné: {lastScan}</p>
                }
            </div>}

    </Fragment>

}

export default ScanInventory;