import { Fragment, useEffect, useState } from "react";
import { arrayRemoveElt } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import Miscellaneous, { logObj } from "../../../../services/common/Miscellaneous";
import errorManagement from "../../../../services/errorManagement";
import MachineInfo from "../../../../services/machines/MachineInfo";
const PartSeven = (p) => {
    const [mecaCC, setMecaCC] = useState([]);
    const [meca, setMeca] = useState([]);
    const [cc, SetCc] = useState([]);

    const [mecaCCList, setMecaCCList] = useState([]);
    const [mecaList, setMecaList] = useState([]);
    const [ccList, SetCcList] = useState([]);

    const [creating, setCreating] = useState(false);

    useEffect(() => {
        var mecaCcArr = new Array();
        var ccArr = new Array();
        var mecaArr = new Array();

        //Get dropdown list
        p.dpOptions.map(v => {
            if (v.types_machine && p.machineInfo.group && v.types_machine.indexOf(p.machineInfo.group) !== -1) {
                if (v.type_option === "meca") {
                    mecaArr.push(v.label_option);
                } else if (v.type_option === "cc") {
                    ccArr.push(v.label_option);
                } else if (v.type_option === "meca_cc") {
                    mecaCcArr.push(v.label_option);
                }
            }
        });

        setMecaList(mecaArr);
        SetCcList(ccArr);
        setMecaCCList(mecaCcArr);

        //Get options
        mecaCcArr = new Array();
        ccArr = new Array();
        mecaArr = new Array();

        p.options && p.options.map(v => {
            if (v.type_option === "opt_meca") {
                mecaArr.push(v);
            } else if (v.type_option === "opt_cc") {
                ccArr.push(v);
            } else if (v.type_option === "opt_meca_cc") {
                mecaCcArr.push(v);
            }
        });

        setMeca(mecaArr);
        SetCc(ccArr);
        setMecaCC(mecaCcArr);

    }, []);

    const addOption = (e) => {
        var type = e.target.getAttribute("data-type");

        setCreating(true);
        MachineInfo.addOption(p.machineInfo.no_machine, "opt_" + type).then(
            (response) => {
                var arr;
                if (type === "meca") {
                    arr = [...meca];
                    arr.push({
                        id: response,
                        type_option: "opt_meca",
                        label_option: null,
                        new: true
                    })
                    setMeca(arr);
                } else if (type === "cc") {
                    arr = [...cc];
                    arr.push({
                        id: response,
                        type_option: "opt_cc",
                        label_option: null,
                        new: true
                    })
                    SetCc(arr);
                } else if (type === "meca_cc") {
                    arr = [...mecaCC];
                    arr.push({
                        id: response,
                        type_option: "opt_meca_cc",
                        label_option: null,
                        new: true
                    });
                    setMecaCC(arr);
                }

                setCreating(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setCreating(false);
            }
        )

    }

    const updateOption = (e) => {
        if (p.readOnly) return;
        var k = e.target.getAttribute("data-key");
        var id = e.target.getAttribute("data-id");
        var type = e.target.getAttribute("data-type");
        var value = e.target.value;
        var arr;


        if (type === "meca") {
            arr = [...meca];
            var old_value = meca[k].label_option;

            arr[k].label_option = value;
            setMeca(arr);
        } else if (type === "cc") {
            arr = [...cc];
            var old_value = cc[k].label_option;
            
            arr[k].label_option = value;
            SetCc(arr);
        } else if (type === "meca_cc") {
            arr = [...mecaCC];
            var old_value = mecaCC[k].label_option;
            
            arr[k].label_option = value;
            setMecaCC(arr);
        }

        var log = { ...logObj };
        log.origin = "machine";
        log.id = p.machineInfo.no_machine;
        log.log_text = "Mise à jour de l'option " + type;
        log.old_value = "";
        log.new_value = value;

        MachineInfo.updateOption(id, value).then(
            () => { Miscellaneous.saveLog(log); },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setCreating(false);
            }
        )

    }

    const deleteOption = (e) => {
        var k = e.target.getAttribute("data-key");
        var id = e.target.getAttribute("data-id");
        var type = e.target.getAttribute("data-type");
        var arr;
        var old_value;

        if (type === "opt_meca") {
            arr = [...meca];
            old_value = arr[k].label_option;
            arr = arrayRemoveElt(arr, k);
            setMeca(arr);
        } else if (type === "opt_cc") {
            arr = [...cc];
            old_value = arr[k].label_option;
            arr = arrayRemoveElt(arr, k);
            SetCc(arr);
        } else if (type === "opt_meca_cc") {
            arr = [...mecaCC];
            old_value = arr[k].label_option;
            arr = arrayRemoveElt(arr, k);
            setMecaCC(arr);
        }

        var log = { ...logObj };
        log.origin = "machine";
        log.id = p.machineInfo.no_machine;
        log.log_text = "Suppression de l'option " + old_value;
        log.old_value = "";
        log.new_value = "";

        MachineInfo.deleteOption(id).then(
            () => { Miscellaneous.saveLog(log); },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setCreating(false);
            }
        )
    }


    return <div className="table-responsive">
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col">Options méca / cc</th>
                        <th scope="col">Options méca</th>
                        <th scope="col">Options cc</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            {
                                mecaCC.map((v1, k1) => {
                                    return <div style={{ display: "flex" }}>
                                        <Fragment>
                                            {!v1.new && <input className="form-control mb-3"
                                                value={v1.label_option} title={"Prix: " + v1.price} readOnly></input>}

                                            {v1.new &&
                                                <select className="form-select mb-3" onChange={updateOption}
                                                    data-key={k1} data-id={v1.id} data-type={v1.type_option}
                                                    disabled={p.readOnly} key={"mecaCc_" + k1} value={v1.label_option}>
                                                    <option value=""></option>
                                                    {
                                                        mecaCCList.map((v, k) => {
                                                            return <option key={"mecaCc" + k} value={v}>{v}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                        </Fragment>



                                        {!p.readOnly &&
                                            <img src={"/common/remove_icon.png"} onClick={deleteOption}
                                                style={{ height: "30px", margin: "auto" }}
                                                data-key={k1} data-type={v1.type_option} data-id={v1.id}></img>}
                                    </div>
                                })
                            }

                            {!p.readOnly &&
                                <div className="text-center">
                                    <button className="btn btn-outline-secondary" onClick={addOption}
                                        data-type="meca_cc" disabled={creating}>
                                        {creating && <ButtonWaiting />}
                                        Ajouter une option
                                    </button>
                                </div>
                            }
                        </td>

                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            {
                                meca.map((v1, k1) => {
                                    return <div style={{ display: "flex" }}>
                                        <Fragment>
                                            {!v1.new && <input className="form-control mb-3"
                                                value={v1.label_option} title={"Prix: " + v1.price} readOnly></input>}

                                            {v1.new &&
                                                <select className="form-select mb-3" onChange={updateOption}
                                                    data-key={k1} data-id={v1.id} data-type={v1.type_option}
                                                    disabled={p.readOnly} key={"meca_" + k1} value={v1.label_option}>
                                                    <option value=""></option>
                                                    {
                                                        mecaList.map((v, k) => {
                                                            return <option key={"meca" + k} value={v}>{v}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                        </Fragment>

                                        {!p.readOnly &&
                                            <img src={"/common/remove_icon.png"} onClick={deleteOption}
                                                style={{ height: "30px", margin: "auto" }}
                                                data-key={k1} data-type={v1.type_option} data-id={v1.id}></img>}
                                    </div>
                                })
                            }

                            {!p.readOnly &&
                                <div className="text-center">
                                    <button className="btn btn-outline-secondary" onClick={addOption}
                                        data-type="meca" disabled={creating}>
                                        {creating && <ButtonWaiting />}
                                        Ajouter une option
                                    </button>
                                </div>
                            }
                        </td>

                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            {
                                cc.map((v1, k1) => {
                                    return <div style={{ display: "flex" }}>
                                        <Fragment>
                                            {!v1.new && <input className="form-control mb-3"
                                                value={v1.label_option} title={"Prix: " + v1.price} readOnly></input>}

                                            {v1.new &&
                                                <select className="form-select mb-3" onChange={updateOption}
                                                    data-key={k1} data-id={v1.id} data-type={v1.type_option}
                                                    disabled={p.readOnly} key={"cc_" + k1} value={v1.label_option}>
                                                    <option value=""></option>
                                                    {
                                                        ccList.map((v, k) => {
                                                            return <option key={"cc" + k} value={v}>{v}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                        </Fragment>

                                        {!p.readOnly &&
                                            <img src={"/common/remove_icon.png"} onClick={deleteOption}
                                                style={{ height: "30px", margin: "auto" }}
                                                data-key={k1} data-type={v1.type_option} data-id={v1.id}></img>}
                                    </div>
                                })
                            }

                            {!p.readOnly &&
                                <div className="text-center">
                                    <button className="btn btn-outline-secondary" onClick={addOption}
                                        data-type="cc" disabled={creating}>
                                        {creating && <ButtonWaiting />}
                                        Ajouter une option
                                    </button>
                                </div>
                            }
                        </td>

                    </tr>

                </tbody>
            </table>

        </div>

    </div>
}

export default PartSeven;