import axios from "axios";
import { SelectColumnFilter, SelectEmptyOrNot } from "../../functions/TableFuntions";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"purchase";

const getOrderMachineCheck = (machine, business) => {
    return axios
        .post(API_URL + "/order/machine/check", {
                fake_header: authHeader(),
                machine: machine,
                business: business
            },{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'text/plain'
                }
            })
        .then((response) => {
            return response.data;
        });
}

const getOrdersDetail = (article, orders) => {
    return axios
        .post(API_URL + "/order/machine/check/order-detail", {
                fake_header: authHeader(),
                article: article,
                orders: orders
            }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getColumnsInfo = () => {
    
    return [
        {
            Header: "Machine",
            accessor: "machine"
        },
        {
            Header: "Article",
            accessor: "ar",
            Cell: props => <div><a target={"_blank"}
                            href={"http://10.141.44.140:8080/article-info/basic-info/" + props.value}>{props.value}</a>
                            <br></br>
                            <span>{props.data[props.row.id].zn02pa}</span>
                        </div>
        },
        {
            Header: "Catégorie",
            accessor: "cat"
        },
        {
            Header: "Bloqué",
            accessor: "blocked",
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },
        {
            Header: "Nouveaux indices",
            accessor: "lastIdx",
            Filter: SelectEmptyOrNot,
            filter: 'empty',
            Cell: props => props.value !== "" && 
                    props.value.split(",").map(
                        v => 
                        <div>
                        <a target="_blank" href={"/article/info/get?article="+v.split("#")[0]}>{v.split("#")[1]}</a>
                        <br></br>
                        </div>
                    )
                
                
        },
        {
            Header: "Désignation",
            accessor: "label"
        },
        {
            Header: "Fournisseur principal",
            accessor: "supplier"
        },
        {
            Header: "Qté nomenclature",
            accessor: "qty"
        },
        {
            Header: "Qté en stock",
            accessor: "in_store"
        },
        {
            Header: "Qté en besoin TOTAL",
            accessor: "tot_need",
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },
        {
            Header: "Qté commandée sur affaire",
            accessor: "tot_biz",
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },
        {
            Header: "Qté commandée sur stock livré avant",
            accessor: "order_for_stock",
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },       
        {
            Header: "Qté commandée sur stock livré après",
            accessor: "order_for_stock_delayed",
            Filter: SelectEmptyOrNot,
            filter: 'empty'
        },
        {
            Header: "Commentaires",
            accessor: "comments",
            Filter: SelectColumnFilter
        }
        
    ]
}

const getNeedDatesError = () => {
    return axios
        .post(API_URL + "/machine/need/check-date", {
                fake_header: authHeader()
            }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getScrewsToOrder = () => {
    return axios
        .post(API_URL + "/screws/order", {
                fake_header: authHeader()
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updateScrewsToOrder = (o) => {
    return axios
        .post(API_URL + "/screws/order/update", {
                fake_header: authHeader(),
                ...o
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const sendScrewsToOrder = () => {
    return axios
        .post(API_URL + "/screws/order/send", {
                fake_header: authHeader()
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const generateXlsScrewsToOrder = (list) => {
    return axios
        .post(API_URL + "/screws/order/generate-xls", {
                fake_header: authHeader(),
                list: list
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

export default {
    getOrderMachineCheck,
    getColumnsInfo,
    getOrdersDetail,
    getNeedDatesError,
    getScrewsToOrder,
    generateXlsScrewsToOrder,
    updateScrewsToOrder,
    sendScrewsToOrder
}