import axios from "axios";
import textPlainHeader from "./fake-header";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL +"auth/" //"http://localhost:8080/api/auth/";

const register = (username, email, password, company, managerMail, first_name, last_name) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    company, 
    managerMail,
    first_name: first_name,
    last_name: last_name
  },
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'text/plain'
    }
  });
};

const login = (username, password) => {
  const param = {
    username: username,
    password: password
  }

  return axios
    .post(API_URL + "signin", {
      username,
      password,
    },
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const forgottenPassord = (mail, mail2) => {
  return axios
    .post(API_URL + "forgotten-password", {
      mail: mail,
      mail2: mail2
    },
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      return response.data;
    });;

};

const resetPassord = (id, password) => {
  return axios
    .post(API_URL + "reset-password", {
      id,
      password
    },
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain'
      }
    })
    .then((response) => {
      return response.data;
    });;

};

const logout = () => {
  
  localStorage.removeItem("user");
  
};

const createInterim = (username, last_name, first_name, createdInFocus) => {
  return axios
      .post(API_URL + "signup/interim", {
          fake_header: authHeader(),
          username: username,
          last_name: last_name,
          first_name: first_name,
          createdInFocus: createdInFocus
      }, {
          headers: textPlainHeader()
      })
      .then((response) => {
          return response.data;
      });
}

const getUserByEmailOrUserName = (param) => {
  return axios
      .post(API_URL + "get/by-id", {
          fake_header: authHeader(),
          username: param
      }, {
          headers: textPlainHeader()
      })
      .then((response) => {
          return response.data;
      });
}

const getCurrentProfileBackEnd = () => {
  return axios
      .post(API_URL + "profile", {
          fake_header: authHeader()
      }, {
          headers: textPlainHeader()
      })
      .then((response) => {
          return response.data;
      });
}

export default {
  register,
  login,
  logout,
  forgottenPassord,
  resetPassord,
  createInterim,
  getUserByEmailOrUserName,
  getCurrentProfileBackEnd
};
