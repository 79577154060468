 
/**
 * To avoid preflight request, then OPTIONS HTTP request, send text/plain instead of json/application
 * HTTP OPTIONS request are denied by SEE policy
 * @returns 
 */
export default function textPlainHeader(){
  return {
    'Accept': 'application/json',
    'Content-Type': 'text/plain'
  }
}
