import { Fragment } from "react";
import Select from "react-select";
import { arrayRemoveElt, removeAndReturnArray } from "../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../functions/PageAnimation";



const Filter = (props) => {
    const onLogicalOperatorChange = (e, k, k2) => {
        var arr = [...props.filter];
        if (k2 !== undefined) {
            arr[k].group[k2].logical_op = e.target.value;
        }else{
            arr[k].logical_op = e.target.value;
        }
        props.setFilter(arr);
    }

    const removeFilter = (k, k2) => {
        if (k2 !== undefined) {
            var arr = [...props.filter];
            if (arr[k].group.length === 1) {
                arr = arrayRemoveElt(arr, k)
            } else {
                arr[k].group = arrayRemoveElt(arr[k].group, k2)
            }
            props.setFilter(arr)
        } else {
            props.setFilter(removeAndReturnArray(props.filter, k))
        }

    }

    const onChangeFilter = (e, k, k2) => {
        var arr = [...props.filter];

        if (k2 !== undefined) {
            arr[k].group[k2].label = e.label;
            arr[k].group[k2].type = e.type;
            arr[k].group[k2].value = e.value;
            arr[k].group[k2].condition = "";
            arr[k].group[k2].value1 = "";
            arr[k].group[k2].value2 = "";
        } else {
            arr[k].label = e.label;
            arr[k].type = e.type;
            arr[k].value = e.value;
            arr[k].condition = "";
            arr[k].value1 = "";
            arr[k].value2 = "";
        }
        props.setFilter(arr);
    }

    const onConditionChange = (e, k, k2) => {
        var arr = [...props.filter];
        if (k2 !== undefined) {
            arr[k].group[k2].condition = e.target.value;
        } else {
            arr[k].condition = e.target.value;
        }

        props.setFilter(arr);
    }

    const onChangeValue = (e, k, k2, field) => {
        var arr = [...props.filter];

        if (k2 !== undefined) {
            arr[k].group[k2][field] = e.target.value;
        } else {
            arr[k][field] = e.target.value;
        }

        props.setFilter(arr);
    }

    return <div className="d-flex mb-3">
        <div style={{ width: "80px" }}>
        { ( props.group && props.k2 === 0)  && <div className="text-center fw-bold">Où</div> }
            {((props.group === undefined && props.k > 0) || (props.group !== undefined && props.k2 > 0)) && <Fragment>
                <select className="form-select fw-bold" value={ props.v.logical_op || ""}
                    onChange={(e) => onLogicalOperatorChange(e, props.k, props.k2)}>

                    <option value="and">ET</option>
                    <option value="or">OU</option>

                </select>
            </Fragment>
            }
        </div>

        <div style={{ width: "30%" }} className="ps-3">
            <Select options={props.metaData} onChange={(e) => onChangeFilter(e, props.k, props.k2)} 
                value={props.v} className="fillInBlank fw-bold" />
        </div>

        <div style={{ width: "33.33%" }} className=" ps-3">
            <select className="form-select fw-bold" value={props.v.condition || ""}
                onChange={(e) => onConditionChange(e, props.k, props.k2)}>
                <option value="">Condition</option>
                {props.v.type === "" && <option value="" disabled>Choisissez une colonne</option>}

                {props.v.type === "text" && <Fragment>
                    <option value="=">Est</option>
                    <option value="<>">N'est pas</option>
                    <option value="like">Contient</option>
                    <option value="not like">Ne contient pas</option>
                    <option value="starts with">Commence par</option>
                    <option value="is null">Est vide</option>
                    <option value="is not null">N'est pas vide</option>
                </Fragment>}

                {props.v.type === "date" && <Fragment>
                    <option value="=">Est</option>
                    <option value="between">Est entre</option>
                    <option value=">">Est après</option>
                    <option value="<">Est avant</option>
                    <option value="is null">Est vide</option>
                    <option value="is not null">N'est pas vide</option>
                </Fragment>}

                {props.v.type === "number" && <Fragment>
                    <option value="=">Est</option>
                    <option value="<>">N'est pas</option>
                    <option value="between">Est entre</option>
                    <option value=">">Est supérieur à</option>
                    <option value="<">Est inférieur à</option>
                    <option value="is null">Est vide</option>
                    <option value="is not null">N'est pas vide</option>
                </Fragment>}
            </select>
        </div>

        <div style={{ width: "33.33%" }} className="d-flex ps-3">
            {(props.v.condition && !["is null", "is not null"].includes(props.v.condition)
                && props.v.type === "text") &&
                <input type="text" class="form-control" placeholder="Valeur" defaultValue={props.v.value1}
                    onBlur={(e) => onChangeValue(e, props.k, props.k2, "value1")} />}

            {(props.v.condition && !["is null", "is not null"].includes(props.v.condition)
                && props.v.type === "date") && <Fragment>
                    <input type="date" class="form-control" defaultValue={props.v.value1}
                        onBlur={(e) => onChangeValue(e, props.k, props.k2, "value1")} />

                    {props.v.condition === "between" &&
                        <input type="date" class="form-control ms-2" defaultValue={props.v.value2}
                            onBlur={(e) => onChangeValue(e, props.k, props.k2, "value2")} />
                    }
                </Fragment>
            }

            {(props.v.condition && !["is null", "is not null"].includes(props.v.condition)
                && props.v.type === "number") && <Fragment>
                    <input type="number" class="form-control" defaultValue={props.v.value1}
                        onBlur={(e) => onChangeValue(e, props.k, props.k2, "value1")} />

                    {props.v.condition === "between" &&
                        <input type="number" class="form-control ms-2" defaultValue={props.v.value2}
                            onBlur={(e) => onChangeValue(e, props.k, props.k2, "value2")} />
                    }
                </Fragment>
            }
        </div>

        {props.k > 0 &&
            <div>
                <button type="button" class="btn-close" onClick={() => removeFilter(props.k, props.k2)}></button>
            </div>
        }
    </div>
}

{/**
    expcted: 
    - filter: filter usestate + setFilter
    - advancedSearch: function for search
    - metaData
*/}
export const AdvancedResearch = (p) => {
    

    const onLogicalOperatorChange = (e, k) => {
        var arr = [...p.filter];
        arr[k].logical_op = e.target.value;
        p.setFilter(arr);
    }

    const addFilter = (k2) => {
        var arr = [...p.filter];

        if (k2) {
            arr[k2].group.push({ value: "", label: "", type: "", value1: "", value2: "", condition: "", logical_op: "and" })
        } else {
            arr.push({ value: "", label: "", type: "", value1: "", value2: "", condition: "", logical_op: "and" });
        }

        p.setFilter(arr);
    }

    const addGroup = () => {

        var arr = [...p.filter];
        console.log(...p.filter)
        arr.push({
            logical_op: "and",
            group: [{ value: "", label: "", type: "", value1: "", value2: "", condition: "" }]
        })
        p.setFilter(arr);
    }

    return <div>
        {p.filter.map((v, k) => {
            return <Fragment>
                {v.group &&
                    <div className="d-flex">
                        <div style={{ width: "80px", marginRight: "5px" }}>
                            <select className="form-select fw-bold" value={v.logical_op}
                                onChange={(e) => onLogicalOperatorChange(e, k)}>
                                <option value="and">ET</option>
                                <option value="or">OU</option>
                            </select>
                        </div>

                        <div className="bg-secondary-subtle w-100 rounded p-2 mb-1">
                            {v.group.map((v2, k2) => {
                                return <div className="w-100">
                                    <Filter v={v2} k={k} v2={v2} k2={k2} group={true} filter={p.filter} setFilter={p.setFilter} metaData={p.metaData} />
                                </div>
                            })}

                            <div className="mt-3">
                                <button className="btn btn-outline-secondary"
                                    onClick={() => addFilter(k)}>Ajouter un filtre</button>
                            </div>

                        </div>


                    </div>
                }

                {!v.group && <Filter v={v} k={k} filter={p.filter} setFilter={p.setFilter} metaData={p.metaData} />}
            </Fragment>
        })}

        <div className="mt-3">
            <button className="btn btn-outline-secondary"
                onClick={() => addFilter()}>Ajouter un filtre</button>

            <button className="btn btn-outline-secondary ms-3"
                onClick={addGroup}>Ajouter un groupe</button>
        </div>

        <div className="mt-3 text-center">
            <button className="btn btn-success" disabled={p.searching}
                onClick={p.advancedSearch}>{p.searching && <ButtonWaiting />}Lancer la recherche</button>
        </div>

    </div>

}