import { Fragment, useEffect, useState } from "react";
import { ArticleAutoComplete } from "../../common/smallComponents.js/DesignedIpt";
import CsContracts from "../../services/customClient/CsContracts";
import errorManagement from "../../services/errorManagement";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { numWords } from "../../functions/StrFunctions";
import { downloadTempFile } from "../../services/functions/FilesProcess";

const CustomInvoice = (props) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState();
    //When we update detail inside useeffect ignore the next useeffect change
    const [ignore, setIgnore] = useState(false);
    const [pdf, setPdf] = useState(true);
    const [discount, setDiscount] = useState(0);

    const [billingFactor, setBillingFactor] = useState(1.3);
    const [coeff, setCoeff] = useState("sellPrice");
    const [isBl, setIsBl] = useState(false);

    var o = { art: "", des: "", country: "", hs: "", qty: "", uPrice: "", tPrice: "", cost: "" };
    const [detail, setDetail] = useState([{ ...o }]);

    const [info, setInfo] = useState({
        client: "", address: "", country: "", recipient: "", business: "", object: "",
        billCountry: "", incoterm: "", eori: "FR42925343800056", weight: "", size: "", valNb: "", valLetter: ""
    });
 
    /** */
    const changeBillingFact = (e) => {
        if (e.target.value === "") setBillingFactor(1.3);
    }

    /** */
    const searchQuote = (e) => {
        e.preventDefault();

        setLoading(true);
        CsContracts.getQuote(e.target.quote.value).then(
            (response) => {
                if( response.length === 0 ){
                    setError("BL inconnu !");
                    setLoading(false);
                    return;
                }

                fillInForm(response);
                setIsBl(true);
                setCoeff("delNotePrice");
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    /** */
    useEffect(() => {
        if (ignore) {
            setIgnore(false);
            return;
        }

        var arr = [...detail];

        var tot = parseFloat(0.0);
        arr.map(v => {
            var price = calculatePrice(v);
            v.uPrice = price.toFixed(2);
            v.tPrice = (price * (v.qty || 0)).toFixed(2);

            tot += price * v.qty
        })

        var o = { ...info };

        if( discount > 0 ) tot = tot * ( 1 - (discount/100) );
        o.valNb = tot.toFixed(2);

        var wd = numWords(tot.toFixed(2));
        o.valLetter = wd.charAt(0).toUpperCase() + wd.slice(1);
        setInfo(o);

        setDetail(arr);
        setIgnore(true);
    }, [detail, coeff, billingFactor, discount]);

    /** */
    function fillInForm(res) {
        var o = { ...info };

        o.client = res[0].client;
        o.address = res[0].address;
        o.country = res[0].country;
        o.recipient = res[0].attention;
        o.business = res[0].business;
        o.subject = res[0].subject;
        o.isUs = res[0].country_code === "USA";
        setInfo(o);

        var arr = new Array();
        res.map(v => {
            arr.push({
                art: v.ar,
                des: v.label || "",
                country: v.origin || "",
                hs: v.hs || "",
                cost: v.cost,
                sellPrice: v.price,
                delNotePrice: v.dn_price,
                qty: v.qty,
                hst: v.hst,
                country_code: v.country_code
            })
        });

        setDetail(arr);
    }

    /** */
    const calculatePrice = (row) => {

        if (row.customPrice) {
            return parseFloat(row.uPrice);
        } else if (coeff === "sellPrice") {
            return row.sellPrice ? row.sellPrice : 0;
        } else if (coeff === "costPrice") {
            return row.cost ? row.cost : 0;
        } else if (coeff === "delNotePrice") {
            return row.delNotePrice ? row.delNotePrice : 0;
        } else {
            return row.cost ? (row.cost * billingFactor) : 0;
        }
    }

    /** */
    const onArticleChange = (val, k) => {
        var arr = [...detail];
        arr[k].art = val;
        setDetail(arr);
    }

    /** */
    const onArticleChoose = (val, k) => {
        var arr = [...detail];

        arr[k].art = val.CDARL;
        arr[k].des = val.ZN12PA || "";
        arr[k].country = val.ZN17PA || "";
        arr[k].hs = val.DOUA || "";
        arr[k].cost = val.PXREL;
        arr[k].sellPrice = val.price;

        setDetail(arr);
    }

    /** */
    const onChangeInfo = (e, field) => {
        var o = { ...info };
        o[field] = e.target.value;
        setInfo(o);
    }

    /** */
    const onChangeDetail = (e, k, field) => {
        var o = [...detail];
        o[k][field] = e.target.value;
        if (field === "uPrice") o[k].customPrice = true;
        setDetail(o);
    }

    /** */
    const addRow = () => {
        var arr = [...detail];
        arr.push({ ...o });
        setDetail(arr);
    }

    /** */
    const deleteRow = (k) => {
        var arr = [...detail];
        arr = arrayRemoveElt(arr, k);
        setDetail(arr);
    }

    /** */
    const generateCustomInvoice = () => {
        setLoading(true);
        setError();
        CsContracts.generateCustomInvoice(info, detail, pdf, discount).then(
            (res) => {
                downloadTempFile(res);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    function getHstCode(v){
        var hs = v.hs;
        var hst = v.hst;
        if( info.isUs ){ return hst; }
        else{ return ( hs && hs.trim() !== "" ) ? hs : hst; }        
    }

    return <Fragment>
        <h5 className="text-center mb-3">Facture douanière</h5>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} />}

        <h6 className="text-center">Choisissez le type de prix à appliquer</h6>

        <div className="display-flex mb-3">
            <div className="text-end col-7 mb-2">
                {isBl && <Fragment>
                    <input type="radio" className="btn-check" name="coeff" value="delNotePrice" checked={coeff === "delNotePrice"}
                        autocomplete="off" />
                    <label className="btn btn-outline-secondary me-2" for="costPrice" onClick={() => setCoeff("delNotePrice")}>Prix du BL</label>
                </Fragment>
                }


                <input type="radio" className="btn-check" name="coeff" value="sellPrice" checked={coeff === "sellPrice"}
                    autocomplete="off" />
                <label className="btn btn-outline-secondary me-2" for="costPrice" onClick={() => setCoeff("sellPrice")}>Prix de vente</label>

                <input type="radio" className="btn-check" name="coeff" value="costPrice" checked={coeff === "costPrice"}
                    autocomplete="off" />
                <label className="btn btn-outline-secondary me-2" for="costPrice" onClick={() => setCoeff("costPrice")}>Prix de revient</label>

                <input type="radio" className="btn-check" name="coeff" value="billingFactor" checked={coeff === "billingFactor"}
                    autocomplete="off" />
                <label className="btn btn-outline-secondary me-2" for="costPrice" onClick={() => setCoeff("billingFactor")}>Prix de revient x coeff.</label>

            </div>

            <div>
                {coeff === "billingFactor" &&
                    <div className="input-group mb-3 ms-2">
                        <span className="input-group-text" id="basic-addon1">Coeff.</span>
                        <input type="number" className="form-control" value={billingFactor} style={{ width: "80px" }}
                            onChange={(e) => setBillingFactor(e.target.value)} onBlur={changeBillingFact} />
                    </div>
                }
            </div>

        </div>

        <form className="text-center col-6 offset-3" onSubmit={searchQuote}>
            <div className="input-group">
                <span className="input-group-text" id="basic-addon1">Récupérer les articles d'un BL</span>
                <input type="text" className="form-control" name="quote" placeholder="N° de BL" />
                <button className="btn btn-outline-primary" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Rechercher
                </button>
            </div>
        </form>

        {/** HEADER */}
        <div className="card col-10 offset-1">
            <div className="card-body">
                <h5 className="card-title">En-tête de facture</h5>

                <div className="display-flex mb-3">
                    <div className="input-group">
                        <span className="input-group-text" style={{ width: "150px" }}>Client</span>
                        <input type="text" className="form-control" placeholder="Nom du client"
                            value={info.client} onChange={(e) => onChangeInfo(e, "client")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "150px" }}>Adresse</span>
                        <input type="text" className="form-control" placeholder="Adresse du client"
                            value={info.address} onChange={(e) => onChangeInfo(e, "address")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "150px" }}>Pays/ville/CP</span>
                        <input type="text" className="form-control" placeholder="Pays du client"
                            value={info.country} onChange={(e) => onChangeInfo(e, "country")} />
                    </div>

                </div>

                <div className="display-flex">
                    <div className="input-group">
                        <span className="input-group-text" style={{ width: "150px" }}>A l'attention de</span>
                        <input type="text" className="form-control" placeholder="Nom du destinataire"
                            value={info.recipient} onChange={(e) => onChangeInfo(e, "recipient")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "150px" }}>Code affaire</span>
                        <input type="text" className="form-control" placeholder="Code affaire"
                            value={info.business} onChange={(e) => onChangeInfo(e, "business")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "150px" }}>Objet</span>
                        <input type="text" className="form-control" placeholder="Objet"
                            value={info.subject} onChange={(e) => onChangeInfo(e, "subject")}/>
                    </div>

                </div>

            </div>
        </div>

        {/** DETAIL */}
        <div className="card col-10 offset-1">
            <div className="card-body">
                <h5 className="card-title">Détail de facture</h5>
                <span className="fst-italic">Remarque: sélectionnez l'article dans le menu déroulant pour afficher les informations de l'article</span>
                {
                    detail.map((v, k) => {
                        return <div className="input-group mb-2" key={k}>
                            <ArticleAutoComplete value={v.art} onChooseArt={(e) => onArticleChoose(e, k)}
                                setValue={(val) => onArticleChange(val, k)} searchDetail={true} />
                            <input type="text" className="form-control" placeholder="Désignation anglaise"
                                value={v.des} onChange={(e) => onChangeDetail(e, k, "des")} />

                            <input type="text" className="form-control" placeholder="Pays d'origine"
                                value={v.country} onChange={(e) => onChangeDetail(e, k, "country")} />

                            <input type="text" className="form-control" placeholder="HS code"
                                value={ getHstCode(v) } onChange={(e) => onChangeDetail(e, k, "hs")} />

                            <input type="number" className="form-control" placeholder="Qté livrée"
                                value={v.qty} onChange={(e) => onChangeDetail(e, k, "qty")} />

                            <input type="number" className="form-control" placeholder="Prix unitaire"
                                defaultValue={v.uPrice} onBlur={(e) => onChangeDetail(e, k, "uPrice")} />

                            <input type="number" className="form-control" placeholder="Prix total"
                                value={v.tPrice} onChange={(e) => onChangeDetail(e, k, "tPrice")} readOnly />

                            <button className="btn btn-outline-danger" onClick={() => deleteRow(k)}>Supprimer</button>

                        </div>
                    })
                }

                <button className="btn btn-outline-secondary" onClick={addRow}>Ajouter une ligne</button>
            </div>
        </div>

        {/** FOOTER */}
        <div className="card col-10 offset-1">
            <div className="card-body">
                <h5 className="card-title">Pied de facture</h5>

                <div className="display-flex mb-3">
                    <div className="input-group">
                        <span className="input-group-text" style={{ width: "170px" }}>Pays de destination</span>
                        <input type="text" className="form-control"
                            value={info.billCountry} onChange={(e) => onChangeInfo(e, "billCountry")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "170px" }}>INCOTERM</span>
                        <input type="text" className="form-control"
                            value={info.incoterm} onChange={(e) => onChangeInfo(e, "incoterm")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "170px" }}>EORI</span>
                        <input type="text" className="form-control"
                            value={info.eori} onChange={(e) => onChangeInfo(e, "eori")} />
                    </div>

                </div>

                <div className="display-flex mb-3">
                    <div className="input-group">
                        <span className="input-group-text" style={{ width: "170px" }}>Poids du colis</span>
                        <input type="text" className="form-control"
                            value={info.weight} onChange={(e) => onChangeInfo(e, "weight")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "170px" }}>Dimensions du colis</span>
                        <input type="text" className="form-control"
                            value={info.size} onChange={(e) => onChangeInfo(e, "size")} />
                    </div>
                </div>

                <div className="display-flex">
                    <div className="input-group">
                        <span className="input-group-text" style={{ width: "170px" }}>Remise (en %)</span>
                        <input type="number" className="form-control"
                            value={discount} onChange={(e) => setDiscount(e.target.value || 0)} />
                    </div>

                    <div className="input-group">
                        <span className="input-group-text" style={{ width: "170px" }}>Valeur totale</span>
                        <input type="number" className="form-control" placeholder="Valeur en chiffres"
                            value={info.valNb} onChange={(e) => onChangeInfo(e, "valNb")} />
                    </div>

                    <div className="input-group ms-2">
                        <span className="input-group-text" style={{ width: "170px" }}>Valeur totale</span>
                        <input type="text" className="form-control" placeholder="Valeur en lettres"
                            value={info.valLetter} onChange={(e) => onChangeInfo(e, "valLetter")} />
                    </div>
                </div>
            </div>
        </div>

        <div className="col-4 offset-4 mb-3">
            <div className="form-check">
                <input className="form-check-input ms-auto" type="checkbox" checked={pdf} onChange={() => setPdf(!pdf)} />
                <label className="form-check-label" style={{ maxWidth: "150px" }}>Générer en PDF</label>
            </div>
        </div>

        <div className="text-center mb-3">
            <button className="btn btn-success" onClick={generateCustomInvoice} disabled={loading}>
                {loading && <ButtonWaiting />}
                Générer la facture
            </button>
        </div>

    </Fragment>
}

export default CustomInvoice;