import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "storehouse/movement";


const getMachineInfoAndMes = (machine) => {
    return axios
        .post(API_URL + "/storage/receiption", {
            fake_header: authHeader(),
            machine: machine
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const saveArticleRequest = (list) => {
    return axios
        .post(API_URL + "/articles/request/save", {
            fake_header: authHeader(),
            list: list
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getArticleRequest = (id) => {
    return axios
        .post(API_URL + "/articles/request/get", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const moveArticleRequest = (arr) => {
    return axios
        .post(API_URL + "/articles/request/move", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const notifyAvailableArt = (arr) => {
    return axios
        .post(API_URL + "/articles/request/notify", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const exportLabels = (arr, start, ids) => {
    return axios
        .post(API_URL + "/articles/request/labels", {
            fake_header: authHeader(),
            arr: arr,
            start: start,
            ids: ids
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const moveArticle = (article, business, qty, machine, need) => {
    return axios
        .post(API_URL + "/articles/scan/move", {
            fake_header: authHeader(),
            article: article,
            business: business,
            qty: qty,
            machine: machine,
            need: need
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const moveInArticle = (article, business, qty, machine, comment) => {
    return axios
        .post(API_URL + "/articles/scan/move-in", {
            fake_header: authHeader(),
            article: article,
            business: business,
            qty: qty,
            machine: machine,
            comment: comment
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getArticleRequestList = (limit) => {
    return axios
        .post(API_URL + "/articles/request/list", {
            fake_header: authHeader(),
            limit: limit
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const downloadArticleRequestList = () => {
    return axios
        .post(API_URL + "/articles/request/list/export", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const movePC = (o) => {
    return axios
        .post(API_URL + "/articles/pc/move/" + o.type, {
            fake_header: authHeader(),
            article: o.article,
            business: o.business,
            qty: o.qty,
            machine: o.machine,
            comment: o.comment
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getLocations = (term) => {
    return axios
        .post(API_URL + "/articles/locations/get", {
            fake_header: authHeader(),
            term: term
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getLocationsNames = () => {
    return axios
        .post(API_URL + "/articles/locations/names/get", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const saveLocations = (arr) => {
    return axios
        .post(API_URL + "/articles/locations/save", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateLocations = (field, val, ar) => {
    return axios
        .post(API_URL + "/articles/locations/update", {
            fake_header: authHeader(),
            field: field,
            val: val,
            ar: ar
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const deleteLocation = (ar) => {
    return axios
        .post(API_URL + "/articles/locations/delete", {
            fake_header: authHeader(),
            ar: ar
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const exportLocations = () => {
    return axios
        .post(API_URL + "/articles/locations/export/xls", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const manageMenuLocations = (location, typeChange) => {
    return axios
        .post(API_URL + "/articles/menu/manage", {
            fake_header: authHeader(),
            location: location,
            typeChange: typeChange
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getBom = (machine, consumables) => {
    return axios
        .post(API_URL + "/machine/bom/get", {
            fake_header: authHeader(),
            machine: machine,
            consumables: consumables
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getConsumablesBom = (machine, business) => {
    return axios
        .post(API_URL + "/machine/bom/get/consumables", {
            fake_header: authHeader(),
            machine: machine,
            business: business
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const downloadMachineBomLabels = (list, start) => {
    return axios
        .post(API_URL + "/machine/bom/labels", {
            fake_header: authHeader(),
            list: list,
            start: start
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getStorageControl = (start, end) => {
    return axios
        .post(API_URL + "/storage-control/list", {
            fake_header: authHeader(),
            end: end,
            start: start
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getInventoryLocations = () => {
    return axios
        .post(API_URL + "/inventory/locations/get", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateInventoryField = (field, id, value) => {
    return axios
        .post(API_URL + "/inventory/locations/update", {
            fake_header: authHeader(),
            field: field,
            id: id,
            value: value
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const deleteInventoryLocations = (id) => {
    return axios
        .post(API_URL + "/inventory/locations/delete", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const deleteInventoryAllLocations = (location) => {
    return axios
        .post(API_URL + "/inventory/locations/delete/all", {
            fake_header: authHeader(),
            location: location
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const downloadXls = (location) => {
    return axios
        .post(API_URL + "/inventory/locations/download", {
            fake_header: authHeader(),
            location: location
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const downloadArchive = () => {
    return axios
        .post(API_URL + "/inventory/locations/archive", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getRotatingInventoryList = () => {
    return axios
        .post(API_URL + "/inventory/rotating/get", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getRotatingInventoryListToScan = () => {
    return axios
        .post(API_URL + "/inventory/rotating/get/scan", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateRotatingInventoryList = (field, value, art) => {
    return axios
        .post(API_URL + "/inventory/rotating/update", {
            fake_header: authHeader(),
            field: field,
            value: value,
            ar: art
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateRotatingInventoryCounter = (arr) => {
    return axios
        .post(API_URL + "/inventory/rotating/update/counter", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const validateRotatingInventoryList = (arr) => {
    return axios
        .post(API_URL + "/inventory/rotating/validate", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateArticleLocation = (arr) => {
    return axios
        .post(API_URL + "/inventory/rotating/change-location", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateArticleLocationScan = (ar) => {
    return axios
        .post(API_URL + "/inventory/rotating/change-location/scan", {
            fake_header: authHeader(),
            ar: ar
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const addBplus2stock = (o) => {
    return axios
        .post(API_URL + "/storage/b2/add", {
            fake_header: authHeader(),
            form: o
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getBplus2stock = () => {
    return axios
        .post(API_URL + "/storage/b2/get", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const deleteBplus2stock = (arr) => {
    return axios
        .post(API_URL + "/storage/b2/delete", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateBplus2stock = (field, value, id) => {
    return axios
        .post(API_URL + "/storage/b2/update", {
            fake_header: authHeader(),
            field: field,
            value: value,
            auto_id: id
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const requestBackBplusTwoRow = (arr) => {
    return axios
        .post(API_URL + "/storage/b2/request-back", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const deltaScanAndFocus = () => {
    return axios
        .post(API_URL + "/inventory/locations/delta", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getInventoryEvolution = () => {
    return axios
        .post(API_URL + "/inventory/evolution/get", {
            fake_header: authHeader()
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const deleteScanAndFocus = (ar) => {
    return axios
        .post(API_URL + "/inventory/locations/delta/delete", {
            fake_header: authHeader(),
            ar: ar
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const getFocusInventoryTable = (user = null) => {
    return axios
        .post(API_URL + "/inventory/locations/focus", {
            fake_header: authHeader(),
            user: user
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const updateFocusInventoryTable = (key, field, value) => {
    return axios
        .post(API_URL + "/inventory/locations/focus/update", {
            fake_header: authHeader(),
            key: key, 
            field: field, 
            value: value
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const assignInventoryToUser = (arr, secondCpt = false) => {
    return axios
        .post(API_URL + "/inventory/locations/assign", {
            fake_header: authHeader(),
            arr: arr,
            secondCpt: secondCpt
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const importCountInFocusInventoryTable = (arr) => {
    return axios
        .post(API_URL + "/inventory/locations/focus/import-count", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const setSecondCountInventoryTable = (arr, focusTable) => {
    return axios
        .post(API_URL + "/inventory/locations/focus/second-count", {
            fake_header: authHeader(),
            art: arr,
            focusTable: focusTable
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

const archiveFocusInventoryTable = (arr) => {
    return axios
        .post(API_URL + "/inventory/locations/focus/send-email", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: textPlainHeader()
        })
        .then((response) => {
            return response.data;
        });
}

export default {
    getMachineInfoAndMes,
    saveArticleRequest,
    getArticleRequest,
    moveArticleRequest,
    notifyAvailableArt,
    exportLabels,
    moveArticle,
    getArticleRequestList,
    downloadArticleRequestList,
    moveInArticle,
    movePC,
    getLocations,
    saveLocations,
    deleteLocation,
    manageMenuLocations,
    exportLocations,
    getLocationsNames,
    updateLocations,
    getBom,
    getConsumablesBom,
    downloadMachineBomLabels,
    getStorageControl,
    getInventoryLocations,
    updateInventoryField,
    deleteInventoryLocations,
    deleteInventoryAllLocations,
    downloadXls,
    downloadArchive,
    getRotatingInventoryList,
    getRotatingInventoryListToScan,
    updateRotatingInventoryList,
    updateArticleLocation,
    addBplus2stock,
    getBplus2stock,
    updateBplus2stock,
    deleteBplus2stock,
    requestBackBplusTwoRow,
    validateRotatingInventoryList,
    updateArticleLocationScan,
    updateRotatingInventoryCounter,
    deltaScanAndFocus,
    getFocusInventoryTable,
    updateFocusInventoryTable,
    assignInventoryToUser,
    importCountInFocusInventoryTable,
    setSecondCountInventoryTable,
    archiveFocusInventoryTable,
    deleteScanAndFocus,
    getInventoryEvolution
}