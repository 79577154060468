import { confirmAlert } from "react-confirm-alert";
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../../functions/PageAnimation";
import CheckList from "../../../services/customClient/CheckList";
import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { getFrFormat } from "../../../functions/StrFunctions";

export const ChoiceForm = (p) => {

    Moment.locale('fr');

    {/** Add or remove machine from form */ }
    const updateForm = (e, k, type) => {
        e.preventDefault();
        var arr = [...p.machines];

        if (type === "add") {
            arr.push("");
        } else if (type === "delete") {
            arr = arrayRemoveElt(arr, k);
        } else {
            arr[k] = e.target.value;
        }

        p.setMachines(arr);
    }

    /** */
    const deleteForm = (k) => {
        var id = p.list[k].gp_id.trim();

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir supprimer la check list du " + p.list[k].creation_date
                + " pour la/les machine(s) " + p.list[k].machines + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        CheckList.deleteCheckListMachine("commissioning", id);
                        var arr = [...p.list]
                        arr = arrayRemoveElt(arr, k);
                        p.setList(arr);
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('business', { header: 'Affaire' }));
        arr.push(columnHelper.accessor('machines', { header: 'Machine(s)' }));
        arr.push(columnHelper.accessor('creation_date', {
            header: 'Date de création',
            cell: i => <span>{getFrFormat( i.getValue(), true )}</span>
        }));
        arr.push(columnHelper.accessor('controller', { header: 'Contrôleur' }));

        arr.push(columnHelper.accessor('xx', {
            header: '',
            cell: i => <div>
                <a className="btn btn-outline-success"
                    href={"/customer-client/check-list/commissioning?id=" + i.row.original.gp_id
                        + "&machines=" + i.row.original.machines}>Continuer</a>

                <a className="btn btn-outline-danger ms-3" href="#"
                    onClick={() => deleteForm(i.row.id)}>Supprimer</a>
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <div className="container-fluid d-inline-flex table-responsive">
        <div className="col-3 border-end" style={{ minWidth: "200px" }}>
            <h6 className="text-center">Créer une nouvelle check list</h6>
            <div className="col-10 offset-1">
                <div className="alert alert-info text-center fw-bold">Saisissez toutes les machines
                    sur lesquelles vous allez travailler chez ce client.</div>


                {p.machines.map((v, k) =>
                    <div className="d-flex" key={"f" + k}>
                        <div className="form-floating mb-3">
                            <input type="email" className="form-control"
                                id={"m" + k} placeholder="xxx" defaultValue={v} onChange={(e) => updateForm(e, k, "update")} />
                            <label htmlFor={"m" + k}>Machine {k + 1}</label>
                        </div>
                        <div className="ms-1">
                            <img src="/common/remove_icon.png" style={{ maxWidth: "30px" }}
                                onClick={(e) => updateForm(e, k, "delete")} />
                        </div>
                    </div>
                )}

                <button className="btn btn-secondary mb-3" onClick={(e) => updateForm(e, -1, "add")}>Ajouter une machine</button>

                <div className="text-center">
                    <button className="btn btn-success" onClick={p.createCheckList} disabled={p.loading}>
                        {p.loading && <ButtonWaiting />}
                        Commencer
                    </button>
                </div>

            </div>
        </div>

        <div className="col-9 ">
            <h6 className="text-center">Continuer une check list</h6>
            <div className="col-10 offset-1">
                <ReactTable columns={columns} data={p.list} top0={true} origin="commissioning" />
            </div>
        </div>
    </div>
}
