import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";
import { fileToBase64 } from "../../functions/StrFunctions";

const API_URL = process.env.REACT_APP_API_URL + "needs";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const checkFreeNeeds = (arr) => {
    return axios
        .post(API_URL + "/free/create/check",
            {
                fake_header: authHeader(),
                arr: arr
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const createFreeNeed = (arr, copies, coms, subset, articles, articles_info) => {
    return axios
        .post(API_URL + "/free/create",
            {
                fake_header: authHeader(),
                arr: arr,
                copies: copies,
                coms: coms, 
                subset: subset, 
                articles: articles,
                articles_info: articles_info
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const listFreeNeed = () => {
    return axios
        .post(API_URL + "/free/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getFreeNeed = (id) => {
    return axios
        .post(API_URL + "/free/get",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteFreeNeed = (needs) => {
    return axios
        .post(API_URL + "/free/delete",
            {
                fake_header: authHeader(),
                needs: needs
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const closeFreeNeed = (needs, notifyPurchase) => {
    return axios
        .post(API_URL + "/free/close",
            {
                fake_header: authHeader(),
                needs: needs,
                notifyPurchase: notifyPurchase
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const generateLabel = (arr, start, id, user_request, date_request) => {
    return axios
        .post(API_URL + "/free/labels",
            {
                fake_header: authHeader(),
                arr: arr,
                start: start,
                id: id,
                user_request: user_request,
                date_request: date_request
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const storehouseSendMail = (header, body) => {
    return axios
        .post(API_URL + "/free/storehouse/mail",
            {
                fake_header: authHeader(),
                header: header,
                body: body
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/*******
 * KIT
 ******/
const getKitList = (machine, subset, type, dimL, dimW, dimh1, dimh2, dimh3, kit, displayHidden) => {
    return axios
        .post(API_URL + "/kit/list",
            {
                fake_header: authHeader(),
                machine: machine,
                subset: subset,
                type_machine: type,
                dim_l: dimL,
                dim_w: dimW,
                dim_h_1: dimh1,
                dim_h_2: dimh2,
                dim_h_3: dimh3,
                kit: kit,
                hidden_kit: displayHidden
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getHistoricByMachine = (machine) => {
    return axios
        .post(API_URL + "/kit/list/historic",
            {
                fake_header: authHeader(),
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const readKitBomFile = async (file) => {
    const base64 = await fileToBase64(file);

    return axios
    .post(API_URL + "/kit/draft/save",
        {
            fake_header: authHeader(),
            file: base64
        },
        { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getKit = (ref) => {
    return axios
        .post(API_URL + "/kit/get/infos",
            {
                fake_header: authHeader(),
                ref: ref
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const searchDoc = (ref) => {
    return axios
        .post(API_URL + "/kit/get/notice",
            {
                fake_header: authHeader(),
                ref: ref
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveConditions = (ref, primaryConditions, secondaryConditions) => {
    return axios
        .post(API_URL + "/kit/save/conditions",
            {
                fake_header: authHeader(),
                ref: ref,
                primaryConditions: primaryConditions,
                secondaryConditions: secondaryConditions
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getConditions = (ref,secondary = false) => {
    return axios
        .post(API_URL + "/kit/get/conditions",
            {
                fake_header: authHeader(),
                ref: ref,
                secondary: secondary
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateArticle = (article, old_article, id) => {
    return axios
        .post(API_URL + "/kit/change/article",
            {
                fake_header: authHeader(),
                article: article,
                old_article: old_article,
                id:id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const createKit = (ref) => {
    return axios
        .post(API_URL + "/kit/create",
            {
                fake_header: authHeader(),
                ref: ref
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateFocusKit = (kit_ar, bom) => {
    return axios
        .post(API_URL + "/kit/update/focus",
            {
                fake_header: authHeader(),
                kit_ar: kit_ar,
                bom: bom
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const insertKit = (ref, bom, type_use, machine, business, date, qty, userKit) => {
    return axios
        .post(API_URL + "/kit/insert/needs",
            {
                fake_header: authHeader(),
                ref: ref,
                bom: bom,
                type_use: type_use,
                machine: machine,
                business: business,
                date: date,
                qty: qty,
                userKit: userKit
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const notifyKitInsert = (ref, list) => {
    return axios
        .post(API_URL + "/kit/notify/needs",
            {
                fake_header: authHeader(),
                ref: ref,
                list: list
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getKitNeedList = () => {
    return axios
        .post(API_URL + "/kit/needs/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getKitNeedById = (id) => {
    return axios
        .post(API_URL + "/kit/needs/get",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getKitNeedHistoric = (ref) => {
    return axios
        .post(API_URL + "/kit/needs/historic",
            {
                fake_header: authHeader(),
                ref: ref
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const closeKit = (id) => {
    return axios
        .post(API_URL + "/kit/needs/close",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const generateKitLabel = (arr, start, type, header) => {
    return axios
        .post(API_URL + "/kit/labels",
            {
                fake_header: authHeader(),
                arr: arr,
                start: start,
                type: type,
                ...header
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const storehouseSendKitMail = (header, body) => {
    return axios
        .post(API_URL + "/kit/storehouse/mail",
            {
                fake_header: authHeader(),
                header: header,
                body: body
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const hideOrShowKit = (ref, status) => {
    return axios
        .post(API_URL + "/kit/showHide",
            {
                fake_header: authHeader(),
                ref: ref,
                status: status
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    checkFreeNeeds,
    createFreeNeed,
    listFreeNeed,
    getFreeNeed,
    deleteFreeNeed,
    closeFreeNeed,
    generateLabel,
    storehouseSendMail,
    getKitList,
    readKitBomFile,
    getKit,
    searchDoc,
    saveConditions,
    getConditions,
    createKit,
    insertKit,
    getKitNeedList,
    getKitNeedById,
    getKitNeedHistoric,
    closeKit,
    generateKitLabel,
    storehouseSendKitMail,
    getHistoricByMachine,
    hideOrShowKit,
    updateArticle,
    updateFocusKit,
    notifyKitInsert
}