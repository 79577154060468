import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"bom/v2";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const saveBom = (machineInfo, analyse, bom) => {
    return axios
    .post(API_URL + "/save/temp",
    {
        fake_header: authHeader(),
        machineInfo: machineInfo,
        analyse: analyse,
        bom: bom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getPendingBom = () => {
    return axios
    .post(API_URL + "/get/temp/pending",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getTempBom = (machine, type) => {
    return axios
    .post(API_URL + "/get/temp/machine",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getGenericCreated = (arr) => {
    return axios
    .post(API_URL + "/get/generic/created",
    {
        fake_header: authHeader(),
        arr: arr
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getTempBomOnly = (machine, type) => {
    return axios
    .post(API_URL + "/get/temp-bom",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getSavedBomOnly = (machine, type) => {
    return axios
    .post(API_URL + "/get/saved-bom",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getFocusBomOnly = (machine, type) => {
    return axios
    .post(API_URL + "/get/focus-bom",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateBomPlanning = (field, machine, val, bom_type) => {
    return axios
    .post(API_URL + "/planning/update",
    {
        fake_header: authHeader(),
        field: field,
        machine: machine,
        bom_type: bom_type,
        val: val
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteBom = (machine, type) => {
    return axios
    .post(API_URL + "/delete",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const exportIntranetBom = (machine, type) => {
    return axios
    .post(API_URL + "/export/bom/intranet",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const refreshArticleTable = (machine, type) => {
    return axios
    .post(API_URL + "/get/temp/search-art",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const confirmBom = (machine, type, temp) => {
    return axios
    .post(API_URL + "/get/temp/validate",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type,
        temp: temp
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const requestCreation = (stdToCreate, machine, origin) => {
    return axios
    .post(API_URL + "/temp/standard/request-creation",
    {
        fake_header: authHeader(),
        stdToCreate: stdToCreate,
        machine: machine,
        origin: origin
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}


const getBomList = (term) => {
    return axios
    .post(API_URL + "/list",
    {
        fake_header: authHeader(),
        term: term
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getBom = (machine, type) => {
    return axios
    .post(API_URL + "/get/machine",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getBomExceptSubsets = (machine, type, subsets) => {
    return axios
    .post(API_URL + "/get/bom/expect-subset",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type,
        subsets: subsets
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const refreshArticleTableNoTemp = (machine, type) => {
    return axios
    .post(API_URL + "/get/search-art",
    {
        fake_header: authHeader(),
        machine: machine,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}
/*speToCreate, machine, type*/
const createSpeArt = (o, machine) => {
    return axios
    .post(API_URL + "/create/specific-art",
    {
        fake_header: authHeader(),
        o: o,
        machine: machine
        /*machine: machine,
        type: type,
        speToCreate: speToCreate*/
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getOtherMachineSubset = (othSubset) => {
    return axios
    .post(API_URL + "/get/references/subset",
    {
        fake_header: authHeader(),
        refs: othSubset
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const firstImportFile = (machineInfo, bom, typeBom) => {
    return axios
    .post(API_URL + "/export/first-time",
    {
        fake_header: authHeader(),
        machineInfo: machineInfo,
        bom: bom,
        typeBom: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const setArticle = (ref, ar, table) => {
    return axios
    .post(API_URL + "/update"+table+"/article/set-code",
    {
        fake_header: authHeader(),
        ref: ref,
        ar: ar
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const replaceBlockedArt = (old_ref, newRef, newAR, machine, table, typeBom) => {
    return axios
    .post(API_URL + "/update"+table+"/article/replace-blocked",
    {
        fake_header: authHeader(),
        old_ref: old_ref,
        newRef: newRef,
        newAr: newAR,
        machine: machine,
        table: table,
        typeBom: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const generateUpdateXls = ( machineInfo, bom, typeBom ) => {
    return axios
    .post(API_URL + "/export/update-time",
    {
        fake_header: authHeader(),
        machineInfo: machineInfo,
        bom: bom,
        typeBom: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const replaceBom = (machine, table, typeChange, o, typeBom) => {
    return axios
    .post(API_URL + "/update/article/change-bom",
    {
        fake_header: authHeader(),
        machine: machine,
        table: table,
        typeChange: typeChange,
        o,
        typeBom: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateBomField = (machine, table, field, o, typeBom) => {
    return axios
    .post(API_URL + "/update/article/change-field",
    {
        fake_header: authHeader(),
        machine: machine,
        table: table,
        field: field,
        o,
        typeBom: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateBomFieldV2 = (machine, table, field, o, typeBom, newValue) => {
    return axios
    .post(API_URL + "/v2/update/article/change-field",
    {
        fake_header: authHeader(),
        machine: machine,
        table: table,
        field: field,
        o,
        typeBom: typeBom,
        newValue: newValue
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateRefById = (o, typeBom) => {
    return axios
    .post(API_URL + "/update/reference",
    {
        fake_header: authHeader(),
        ...o,
        typeBom: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getHistoric = (machine, typeBom) => {
    return axios
    .post(API_URL + "/get/bom/historic",
    {
        fake_header: authHeader(),
        machine: machine,
        typeBom: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getCabinets = () => {
    return axios
    .post(API_URL + "/cabinet/get/list",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const refreshCategories = (arr) => {
    return axios
    .post(API_URL + "/refresh/categories",
    {
        fake_header: authHeader(),
        arr: arr
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const notifyPurchaser = (machine, typeBom, inFocus) => {
    return axios
    .post(API_URL + "/validate/to-purchaser",
    {
        fake_header: authHeader(),
        machine: machine,
        type: typeBom,
        inFocus: inFocus
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getBomV2 = (machine, typeBom) => {
    return axios
    .post(API_URL + "/get/machine/bom/v2",
    {
        fake_header: authHeader(),
        machine: machine,
        type: typeBom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveCabinet = (cabinet) => {
    return axios
    .post(API_URL + "/cabinet/get/save",
    {
        fake_header: authHeader(),
        cabinet: cabinet
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteCabinet = (id) => {
    return axios
    .post(API_URL + "/cabinet/delete",
    {
        fake_header: authHeader(),
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    saveBom,
    getPendingBom,
    getTempBom,
    getGenericCreated,
    refreshArticleTable,
    confirmBom,
    requestCreation,
    getBomList,
    getBom,
    refreshArticleTableNoTemp,
    createSpeArt,
    getOtherMachineSubset,
    firstImportFile,
    setArticle,
    replaceBlockedArt,
    generateUpdateXls,
    replaceBom,
    getBomExceptSubsets,
    deleteBom,
    updateRefById,
    getHistoric,
    refreshCategories,
    refreshCategories,
    updateBomField,
    notifyPurchaser,
    getTempBomOnly,
    getSavedBomOnly,
    getFocusBomOnly,
    exportIntranetBom,
    updateBomFieldV2,
    updateBomPlanning,
    getBomV2,
    getCabinets,
    saveCabinet,
    deleteCabinet
}