import {
    flexRender, getCoreRowModel, getExpandedRowModel, getFacetedMinMaxValues, getFacetedRowModel,
    getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { Fragment, useEffect, useRef, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { getHeight, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import { downloadTableInXls, downloadTempFile, removeTempFile } from "../../services/functions/FilesProcess";
import { DesignedChckBox, SingleWaiting } from "./DesignedIpt";
import ErrorMessage from "./ErrorMessage";
import Filter from "./Filter";
import { forceString } from "../../functions/StrFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";


/**
 * 
 * @param {*} p data, columns
 * @returns 
 */
const ReactTable = (p) => {
    const [data, setData] = useState(p.data);
    const [columns, setColumns] = useState(p.columns);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [columnOrder, setColumnOrder] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [openSettings, setOpenSettings] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [tblStg, setTblStg] = useState({ bordered: false, headCol: "table-dark" });
    const [hideFilter, setHideFilter] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const [navClicked, setNavClicked] = useState(false);

    const [error, setError] = useState();

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    useEffect(() => {
        if (p.origin) {
            if (cookies["columns order " + p.origin]) setColumnOrder(cookies["columns order " + p.origin].map(d => d.id))
            if (cookies["visibility " + p.origin]) {
                var o = cookies["visibility " + p.origin];

                table.getAllLeafColumns().map(column => {
                    if (o[column.id] !== undefined && o[column.id] === false) column.toggleVisibility()
                });
            }

            if (cookies["settings " + p.origin]) setTblStg(cookies["settings " + p.origin]);

            if (p.show) setTimeout(() => {
                table.setPageSize(Number(p.show));
            }, 3000);
        }

    }, []);

    useEffect(() => {
        setData(p.data);
        setColumns(p.columns);
    }, [p.data, p.columns]);

    /**
     * 
     */
    document.onclick = function (e) {
        setNavClicked(e.target.classList.contains("nav-link"))
    }

    /**
    * Update order of columns table
    */
    const handleSort = () => {
        var arr = [...table.getAllLeafColumns()];
        var draggedItem = arr.splice(dragItem.current, 1)[0];
        arr.splice(dragOverItem.current, 0, draggedItem);

        dragItem.current = null;
        dragOverItem.current = null;

        setColumnOrder(arr.map(d => d.id));

    }

    const fuzzyFilter = (row, columnId, value, addMeta) => {
        /*const itemRank = rankItem(row.getValue(columnId), value);
        addMeta({ itemRank })
        return itemRank.passed*/
    }

    const selectMultiple = (rows, columnIds, filterValue) => {
        var val = forceString(rows.original[columnIds]);

        if ((filterValue === "" || filterValue === "(Vide)") &&
            (!rows.original[columnIds] || rows.original[columnIds] === null || val.trim() === "")) return true;

        if ((filterValue !== "" && filterValue !== "(Vide)") &&
            (!rows.original[columnIds] || rows.original[columnIds] === null || val.trim() === "")) return false;

        return filterValue.split(",").indexOf(val) !== -1;
    }

    const strWithEmpty = (rows, columnIds, filterValue) => {
        var val = rows.original[columnIds];
        if (filterValue === "") return true;

        if (filterValue === "(Vide)" && (!val || val === null || val === "")) return true;
        return forceString(val).toLowerCase().trim().indexOf(filterValue.toLowerCase().trim()) !== -1;
    }

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
            selectMultiple: selectMultiple,
            strWithEmpty: strWithEmpty
        },
        state: { sorting, columnVisibility, columnOrder, columnFilters, globalFilter, expanded },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        onExpandedChange: setExpanded,

        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,

        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(),

        getRowCanExpand: row => { return p.canExpand !== undefined ? p.canExpand : false }

    })

    useEffect(() => { if (table.getState().pagination.pageSize === 10) table.setPageSize(100) }, table)

    /**
     * 
     */
    const closeSettings = (e) => {
        if (e.target.classList.contains('btn-close')
            || e.target.classList.contains('custom-tooltip-v2')) {
            setOpenSettings(false);

            saveInCookie();
        }

    }

    /**
     * 
     * @param {*} type 
     * @param {*} obj 
     */
    function saveInCookie() {
        if (p.origin) {
            var cookiesExpiration = new Date();
            cookiesExpiration.setFullYear(new Date().getFullYear() + 1);

            var arr = new Array();
            table.getAllLeafColumns().map(v => arr.push({ id: v.id }));
            setCookie("columns order " + p.origin, arr, { expires: cookiesExpiration });

            var o = new Object();
            table.getAllLeafColumns().map(v => o[v.id] = v.getIsVisible());
            setCookie("visibility " + p.origin, o, { expires: cookiesExpiration });

            setCookie("settings " + p.origin, { ...tblStg }, { expires: cookiesExpiration });
        }
    }

    /**
     * Remove cookies
     */
    function resetTableSettings() {
        if (p.origin) {
            removeCookie("columns order " + p.origin, { path: '/' });
            removeCookie("visibility " + p.origin, { path: '/' });
            removeCookie("settings " + p.origin, { path: '/' });
            window.location.reload();
        }
    }

    function getTitle(title){
        //If the title contains an object (custom tooltip) extract the text
        if (typeof title === "object" && title !== null){
            return title.props.children[0].props.children || title.props.children[1];
        } 
            
        
        return title
    }

    const exportTable = (getAll = true) => {
        var header = new Array();

        var v;
        for (let i = 0; i < table.getHeaderGroups()[0].headers.length; i++) {
            v = table.getHeaderGroups()[0].headers[i];
            if( /^x+$/i.test(v.id) === false )
                header.push({ id: v.id, idx: v.index, title: getTitle(v.column.columnDef.header) })
        }

        var body = new Array();

        var arr = getAll ? table.getCoreRowModel() : table.getRowModel();

        for (let i = 0; i < arr.rows.length; i++) {
            v = arr.rows[i].original;
            body.push(v)
        }

        var o = {
            header: header,
            body: body
        }

        setExporting(true);
        CheckConnDao.downloadTableInXls(o).then(
            (r) => {
                downloadTempFile(r);
                removeTempFile(r);
                setExporting(false);
            }, (error) => {
                console.log(error)
                setExporting(false);
                setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )

    }

    const changeTblStg = (e, field) => {
        var o = { ...tblStg };

        if (field === "headCol") {
            o.headCol = e.target.value;
        } else {
            o.bordered = e.target.checked;
        }

        setTblStg(o);
    }

    return <Fragment>


        {false && <div className="alert alert-info display-flex no-print" style={{
            marginBottom: "0",
            paddingTop: "0.5em", paddingBottom: "0.5em", borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px"
        }}>
            Cliquez <a href="#" className="fw-bold ms-1 me-1" onClick={() => { setHideFilter(!hideFilter) }}>ici</a>
            pour {hideFilter ? "filtrer" : "supprimer le filtre"}. Cliquez sur l'en-tête du tableau
            pour trier les données. Cliquez sur le bouton à droite pour paramétrer l'affichage.

            <div className="ms-auto">
                <img src="/common/settings.png" className="cursor-pointer " style={{ width: "20px" }}
                    title="Paramètres des colonnes" onClick={() => setOpenSettings(true)}></img></div>
        </div>}

        {error && <ErrorMessage error={error} errorState={setError} />}

        <div className="table-responsive" id={p.origin} style={{ height: getHeight(p.origin, 50) }}>
            {/**Setting  btn */}
            <div className="position-absolute bg-light rounded-circle cursor-pointer border"
                onClick={() => setOpenSettings(true)}
                style={{ zIndex: "3000", width: "30px", height: "30px", right: "5px", marginTop: "-10px" }}>

                <img src="/common/settings.png" className="center-img"
                    style={{ width: "20px" }} title="Paramètres des colonnes"></img>

            </div>

            <datalist id="number_option_list">
                <option value="> 0" key="> 0" />
                <option value=">= 0" key=">= 0" />
                <option value="< 0" key="< 0" />
                <option value="<= 0" key="<= 0" />
                <option value="= 0" key="= 0" />
            </datalist>

            <table className={"table react-table " + (p.classes || "") + (tblStg.bordered ? " table-bordered" : "")} id={"table_" + p.origin}>
                {/** HEADER 
                 *  + (/*!navClicked ? " sticky-top" : "") 
                */}
                <thead className={tblStg.headCol + " sticky-top " + (p.top0 ? " top-0" : " top-90")}>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={"header_gp_" + headerGroup.id} className="text-center">
                            {headerGroup.headers.map((header, k) => (
                                <th key={"th_" + header.id + "_" + k}
                                    className={(p.classes && p.classes.indexOf("fixFstCol") !== -1 && k === 0) ? "fixFstCol" : ""}>
                                    {header.isPlaceholder
                                        ? null
                                        :
                                        <Fragment>
                                            <div
                                                {...{
                                                    className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{ asc: ' 🔼', desc: ' 🔽', }[header.column.getIsSorted()] ?? null}
                                            </div>
                                            {header.column.getCanFilter() ? (
                                                <div className={hideFilter ? "d-none no-padding-head no-print" : "no-padding-head no-print"} >
                                                    <Filter key={"filter" + header.column.id} column={header.column} table={table} />
                                                </div>
                                            ) : null}

                                        </Fragment>
                                    }
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                {/** BODY */}
                <tbody>
                    {table.getRowModel().rows.map((row, k1) => (
                        !row.original.doNotDisplay && <Fragment>
                            <tr key={"tr_" + row.id + "_" + k1} style={{ backgroundColor: "white" }}
                                className={row.getIsExpanded() ? "border-left-see" : ""}>
                                {row.getVisibleCells().map((cell, k) => (
                                    <td key={"td_" + cell.id + "_" + k + "_" + k1} style={p.tdStyle ? p.tdStyle : {}}
                                        className={((p.classes && p.classes.indexOf("fixFstCol") !== -1 && k === 0) ? "fixFstCol" : "") + ""}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                            {row.getIsExpanded() && (
                                <tr className="border-left-see">
                                    {/* 2nd row is a custom 1 cell row */}
                                    <td colSpan={row.getVisibleCells().length} style={{ padding: 0 }}>
                                        <div className="subRowTable border-start">
                                            {(row.original.subRow && Array.isArray(row.original.subRow)) &&
                                                p.renderSubComponent(row)
                                            }

                                            { p.sendMyself && p.renderSubComponent(row) }
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    ))}
                </tbody>

            </table>
        </div>

        <div className="mt-1 no-print">
            <div className="flex items-center gap-2 display-flex"
                style={{ justifyContent: "center" }}>
                <button
                    className="border rounded p-1"
                    onClick={() => { table.setPageIndex(0); scrollToTop(p.origin) }}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<<'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => { table.previousPage(); scrollToTop(p.origin) }}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => { table.nextPage(); scrollToTop(p.origin) }}
                    disabled={!table.getCanNextPage()}
                >
                    {'>'}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => { table.setPageIndex(table.getPageCount() - 1); scrollToTop(p.origin) }}
                    disabled={!table.getCanNextPage()}
                >
                    {'>>'}
                </button>
                <span className="flex items-center gap-1 display-flex">
                    <div>Page</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount()}
                    </strong>
                </span>
                {<span className="flex items-center gap-1 display-flex">
                    | Go to:
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                        className="form-control" style={{ width: "70px" }}
                    />
                </span>}


                <select className="border rounded"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                >
                    {[50, 100, 500, 1000].map(pageSize => (
                        <option key={"page_" + pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
                <div>{table.getRowModel().rows.length} Rows</div>
            </div>
        </div>

        {/** SETTING PANEL */
            openSettings && <div className="custom-tooltip-v2" onClick={closeSettings}>
                <div className="modal-body card" style={{ width: "650px" }}>
                    <div className="text-end">
                        <button type="button" className="btn-close" aria-label="Close" onClick={closeSettings}></button>
                    </div>

                    <div className="display-flex">
                        {/** Colonnes display */}
                        <div className="col-6 border-end">
                            <h5 className="fw-bold text-center">Affichage de colonnes</h5>

                            <div className="px-1 border-b border-black mb-3">
                                <div className="display-flex mb-2">
                                    <DesignedChckBox checked={table.getIsAllColumnsVisible()} onChange={table.getToggleAllColumnsVisibilityHandler()} />
                                    <span className="ms-2 fw-bold">Tout cocher</span>
                                </div>
                                {table.getAllLeafColumns().map(column => {
                                    return (
                                        <div key={"leaf" + column.id} className="px-3">

                                            {/*<label>
                                    <input
                                        {...{
                                            type: 'checkbox',
                                            checked: column.getIsVisible(),
                                            onChange: column.getToggleVisibilityHandler(),
                                        }}
                                    />{' '}
                                    {column.columnDef.header}
                                    </label>*/}
                                            <div className="display-flex mb-1">
                                                <DesignedChckBox checked={column.getIsVisible()} onChange={column.getToggleVisibilityHandler()} />
                                                <span className="ms-2 fw-bold">{column.columnDef.header}</span>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>

                            <h5 className="fw-bold text-center">Ordre de colonnes</h5>
                            <div>
                                {table.getAllLeafColumns().map((column, k) => {
                                    return (
                                        <div key={"leaf2_" + column.id} className="px-1" style={{ width: "300px" }}>
                                            <div className="draggable display-flex" draggable
                                                onDragStart={(e) => dragItem.current = k}
                                                onDragEnter={(e) => dragOverItem.current = k}
                                                onDragEnd={handleSort}>
                                                <div>
                                                    <img src="/common/drag_vertical.png" style={{ maxWidth: "30px", marginRight: "5px" }}></img>
                                                </div>
                                                <div className="txt text-center">{column.columnDef.header}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {/** Table display */}
                        <div className="col-6 ">
                            <h5 className="fw-bold text-center mb-3">Paramètres d'affichage</h5>

                            <div className="p-2">
                                <h6>Couleur de l'en-tête</h6>
                                <select className="form-select" value={tblStg.headCol} onChange={(e) => changeTblStg(e, "headCol")}>
                                    <option value="">Default</option>
                                    <option style={{ backgroundColor: "#cfe2ff" }} value="table-primary">Primary</option>
                                    <option style={{ backgroundColor: "#e2e3e5" }} value="table-secondary">Secondary</option>
                                    <option style={{ backgroundColor: "#fff3cd" }} value="table-warning">Warning</option>
                                    <option style={{ backgroundColor: "#cff4fc" }} value="table-info">Info</option>
                                    <option style={{ backgroundColor: "#f8f9fa" }} value="table-light">Light</option>
                                    <option style={{ backgroundColor: "#212529" }} className="text-white" value="table-dark">Dark</option>
                                </select>

                                <br></br>

                                <div className="display-flex mb-3">
                                    <DesignedChckBox checked={tblStg.bordered} onChange={(e) => changeTblStg(e, "bordered")} />
                                    <label className="fw-bold ms-2">Afficher les séparations verticales</label>
                                </div>

                                <div className="mb-3">
                                    <button className="btn btn-warning"
                                        onClick={() => resetTableSettings()}>Remettre les colonnes par défaut</button>
                                </div>

                                <div className="text-center">
                                    {!exporting && <div className="ms-auto me-5">
                                        <img src="/common/xls_logo.png" className="cursor-pointer" style={{ maxWidth: "40px" }}
                                            title="Exporter ce tableau en format xlsx" onClick={exportTable}></img></div>}

                                    {exporting && <SingleWaiting classes="me-5" />}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        }
    </Fragment>

}

{/** Documentation:  https://tanstack.com/table/v8/docs/examples/react/basic */ }

export default ReactTable;