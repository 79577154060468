import { Fragment, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

const imageURL = '/common/micro.png';

// Function to show the image
function showImage(element, setListen) {
    // Get the position and dimensions of the input element
    const rect = element.getBoundingClientRect();

    // Calculate if there's enough space on the right
    const imageWidth = 30; // Width of the image in pixels
    const gap = 10; // Gap between the input and the image
    const viewportWidth = window.innerWidth;

    // Determine position: right by default, left if not enough space
    const topPosition = rect.top + window.scrollY;
    let leftPosition = rect.right + gap + window.scrollX; // Default: position to the right

    // If there isn't enough space on the right, position on the left
    if (leftPosition + imageWidth > viewportWidth) {
        leftPosition = rect.left - imageWidth - gap + window.scrollX; // Position to the left
    }

    // Create the image element
    const divWrapper = document.createElement('div');
    divWrapper.style.position = 'absolute';
    divWrapper.style.top = `${topPosition}px`; // Align with the input
    divWrapper.style.left = `${leftPosition}px`; // Adjust dynamically based on space
    divWrapper.style.width = `${imageWidth}px`; // Match the image size
    divWrapper.style.height = `${imageWidth}px`;
    divWrapper.style.zIndex = '10000';
    divWrapper.style.display = 'flex'; // Allows better alignment inside the div
    divWrapper.style.alignItems = 'center';
    divWrapper.style.justifyContent = 'center';
    divWrapper.style.backgroundColor = '#ece6ff'; // Set the background color for the wrapper
    divWrapper.className = 'image-wrapper'; // Add a class for identification
    divWrapper.style.borderRadius = "50%";
    divWrapper.style.borderColor = "black";
    divWrapper.style.borderStyle = "solid";
    divWrapper.style.borderWidth = "thin";
    divWrapper.style.padding = "1px"
    divWrapper.className = 'focus-image'; // Add a class for identification


    const img = document.createElement('img');
    img.src = imageURL;
    img.style.width = '100%'; // Make the image fill the wrapper
    img.style.height = '100%';
    img.style.cursor = 'pointer'; // Make the image clickable

    // Append the image to the wrapper
    divWrapper.appendChild(img);

    // Append the image to the body
    document.body.appendChild(divWrapper);

    // Add click event listener to the image
    img.addEventListener('click', () => {
        setListen(element.getAttribute("data-id"))
    });
}

// Function to remove the image
function removeImage() {
    const img = document.querySelector('.focus-image');
    if (img) {
        img.remove();
    }
}

export const markToListen = (setListen, elt, setElt, includeInput = false) => {
    setTimeout(() => {
        var selector = "textarea:not(.no-speech)";
        if (includeInput) selector += ', input[type="text"]:not(.no-speech)';
        const inputsAndTextareas = document.querySelectorAll(selector);

        var i = 0;
        // Iterate through the NodeList and log them
        inputsAndTextareas.forEach(element => {
            // Add a unique data-id to each element
            element.setAttribute('data-id', `speech${i++}`);
            // Add focus event listener
            element.addEventListener('focus', () => {
                showImage(element, setListen);
                setElt(elt)
            });

            // Add blur (leave focus) event listener
            element.addEventListener('blur', () => {
                // Delay removing the image to allow image click detection
                setTimeout(() => {
                    if (!document.querySelector('.focus-image:hover')) removeImage();
                }, 100);
            });
        });
    }, 1000);

    document.addEventListener('click', (event) => {
        const clickedElement = event.target;

        // Check if the clicked element is NOT an input[type="text"] or has the class "focus-image"
        if (
            !(clickedElement.matches('input[type="text"]') ||
                clickedElement.matches('textarea') ||
                clickedElement.matches('.focus-image'))
        ) {
            var img = document.getElementsByClassName("focus-image");
            if (img && img.length > 0) {
                for (let i = 0; i < img.length; i++) {
                    img[i].remove()
                }
            }
        }
    });
}


{/**
* @param const [listen, setListen] = useState(false);
* @param onValidate
*/}
const CustomSpeechRecognition = ({ listen, setListen, validateScript }) => {
    const [lang, setLang] = useState("fr-FR")
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (listen) {
            resetTranscript();
            startListening()
        } else {
            resetTranscript();
            stopListening()
        }
    }, [listen])

    useEffect(() => {
        if (listen) {
            stopListening();
            setTimeout(() => { startListening(); }, 250);
        }
    }, [lang])

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true, language: lang });
        setTimeout(() => { setReady(true) }, 500);
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
        setReady(false);
    };

    const onValidate = () => {
        var inputElement = document.querySelector('[data-id="' + listen + '"]');
        if (inputElement) {
            inputElement.value += (inputElement.value.length > 0 ? " " : "") + transcript;

            //Simulate onBlur
            inputElement.focus();
            setTimeout(() => { inputElement.blur(); }, 0);

        }
        SpeechRecognition.stopListening();
        resetTranscript();
        setListen();
    }

    return listen && <div className="center-div see-bg p-2 rounded text-white" style={{zIndex: "10000"}}>
        <button type="button" className="btn-close ms-auto"
            onClick={() => { setListen(); resetTranscript() }}></button>

        {ready && <Fragment>
            <h6 className='text-center'>
                {lang === "fr-FR" ? "Je vous écoute" : "I'm listening"}
            </h6>

            <div className="text-center text-decoration-underline cursor-pointer mb-3"
                onClick={() => { setLang(lang === "fr-FR" ? "en-EN" : "fr-FR") }}>
                Passer à {lang === "fr-FR" ? "anglais" : "français"}
            </div>
        </Fragment>}

        {!ready && <Fragment>
            <h6 className='text-center'>
                {lang === "fr-FR" ? "Un instant..." : "Just a moment..."}
            </h6>
        </Fragment>}


        {browserSupportsSpeechRecognition && <Fragment>
            <div className="mb-3">
                <div className="text-center">
                    {listening && <img style={{ width: "60px" }} src="/common/recording.gif" />}
                </div>
                {!listening && <button className="btn btn-secondary me-1" onClick={startListening}>Reprendre</button>}
                {listening &&
                    <button className="btn btn-warning me-1" onClick={stopListening}>Pause</button>}
                <button className="btn btn-danger me" onClick={resetTranscript}>Effacer et recommencer</button>
            </div>
            <h6>Transcription:</h6>
            <p>{transcript}</p>

            {transcript.length > 0 &&
                <div className="text-center mt-3">
                    <button className="btn btn-success" onClick={onValidate}>Valider la saisie</button>
                </div>}
        </Fragment>}

        {!browserSupportsSpeechRecognition && <div className="alert alert-warning">
            Votre navigateur ne supporte pas la reconnaisance vocale
        </div>}



        {/*
        <div className="text-center">
            <button className="btn btn-success"
                onClick={() => onValidate()}>Valider</button>
        </div>*/}
    </div>

}

export default CustomSpeechRecognition;