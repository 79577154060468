import { useEffect, useState } from "react";
import { Fragment } from "react";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import NeedsMngt from "../../services/articles/NeedsMngt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import errorManagement from "../../services/errorManagement";
import getUrlParam, { forceString } from "../../functions/StrFunctions";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { useMemo } from "react";
import { DefaultColumnFilter, SelectEmptyOrNot, filterMethods } from "../../functions/TableFuntions";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import { arrayRemoveElt, getKey } from "../../functions/ArrayFunctions";
import MachineInfo from "../../services/machines/MachineInfo";
import { use } from "i18next";
import { ProgressBar } from "react-bootstrap";

const Kit = (props) => {
    const [ref, setRef] = useState();
    const [reading, setReading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [data, setData] = useState([]);
    const [tab, setTab] = useState(0);
    const [artToCreate, setArtiToCreate] = useState(0);
    const [blocked, setBlocked] = useState(0);
    const [kitCode, setKitCode] = useState();
    const [secondaryConditions, setSecondaryConditions] = useState([]);
    const [document, setDocument] = useState();
    const [docSearch, setDocSearch] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [historic, setHistoric] = useState();
    const [primaryConditions, setPrimaryConditions] = useState({
        machineType: [],
        subset: [],
        extDimL: { v1: "", v2: "", v3: "", v4: "", v5: "", v6: "" },
        extDimW: { v1: "", v2: "", v3: "", v4: "", v5: "", v6: "" },
        extDimH: { v1: "", v2: "", v3: "", v4: "", v5: "", v6: "" },
        extDimHMax: { v1: "", v2: "", v3: "", v4: "", v5: "", v6: "" }
    });
    const typeOfMachine = ["BA_TECMA_18_20_24", "BA_TECMA_30_40", "BA15P", "EC08",
        "EC16", "FORMEUSE_CAISSE_LANTEC", "IK", "IN", "LN", "WN", "POSE_CALE", "POSE_COIFFE"];

    const subsetList = ["Ascenseur / Pistonnage", "Cavité", "Centreur", "Centreur à ventouses", "Châssis",
        "Convoyeur coiffe", "Convoyeur principal", "Encollage", "Fourniture", "Habillage", "Insonorisation",
        "Magasin TE-LP", "Margeur", "Marquage", "Plaquage", "Pliage", "Pneumatique", "Pré-pliage",
        "Taqueur", "Transfert"];
    const [tab1, setTab1] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [tab3, setTab3] = useState(false);
    const [kitUseState, setKitUseState] = useState(0);
    const [kitUseBusinessType, setKitUseBusinessType] = useState();
    const [kitUseBusiness, setKitUseBusiness] = useState();
    const [kitUseMachine, setKitMachine] = useState();
    const [kitUseDate, setKitDate] = useState();
    const [kitUseNb, setKitNb] = useState();
    const [kitUseUser, setKitUser] = useState();
    const [checkingMachine, setCheckingMachine] = useState(false);
    const [hidden, setHidden] = useState();
    const [kitCost, setKitCost] = useState(0);
    const [listToUse, setListToUse] = useState([]);
    const [progress, setProgress] = useState(0);
    const progressInstance = <div className="mb-3"><ProgressBar animated now={progress} label={`${progress}%`} /></div>;
    const [similarKit, setSimilarKit] = useState([])
    const [loading, setLoading] = useState(false);

    const token = user && "Bearer " + user.accessToken;
    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "needs/kit/get/get-instruction?Authorization=" + token + "&ref=";

    //Set columns
    const columns = useMemo(
        () => [
            {
                Header: "Qté",
                accessor: "qty"
            },
            {
                Header: "Référence",
                accessor: "ref"
            },
            {
                Header: "Repère",
                accessor: "rep"
            },
            {
                Header: "Article",
                accessor: "art",
                Cell: (props) =>
                    <Fragment>
                        {(user.roles.includes("ROLE_KIT_MANAGE") || user.roles.includes("ROLE_ADMIN")) &&
                            <div className="display-flex">
                                <input className="form-control" defaultValue={props.value} data-key={props.row.index}
                                    data-id={props.row.original.id} onBlur={updateArticle}
                                    readOnly={props.row.original.ar_changing}></input>
                                {props.row.original.ar_changing && <button className="btn"><ButtonWaiting /></button>}
                            </div>
                        }
                        {(!user.roles.includes("ROLE_KIT_MANAGE") && !user.roles.includes("ROLE_ADMIN")) &&
                            <div>{props.value}</div>
                        }

                    </Fragment>
            },
            {
                Header: "Libellé français",
                accessor: "ZN03PA"
            },
            {
                Header: "Blocage",
                accessor: "BLOCL"
            },
            {
                Header: "Qté dispo en stock",
                accessor: "available"
            },
            {
                Header: "Prix de revient",
                accessor: "PXREL"
            },
            {
                Header: "Historique",
                accessor: "msg"
            }
        ], [data]
    );

    //Set filters for table
    const filterTypes = useMemo(() => (filterMethods()), []);

    //Set default columns of table
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    //Create table
    const tableInstance = useTable({ columns, data, defaultColumn, filterTypes }, useFilters, useGlobalFilter, useSortBy);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    /**
     * 
     */
    useEffect(() => {
        var ref = getUrlParam(props, "ref");

        if (ref !== null) {
            setRef(ref);

            NeedsMngt.getKit(ref).then(
                (response) => {
                    setRef(response.kit_ref);
                    setData(response.bom);
                    setSecondaryConditions(response.secondary_condition);
                    control(response);
                    setTab1(true);
                    setHidden(response.hidden);

                    setSimilarKit(response.similarKit);

                    //Calculate cost
                    var c = 0;
                    var v;
                    for (let i = 0; i < response.bom.length; i++) {
                        v = response.bom[i];
                        if (v.ZN15PA && (v.ZN15PA.toUpperCase().indexOf("CSM") === -1
                            && v.ZN15PA.toUpperCase().indexOf("CMP") === -1)) c += parseFloat(v.PXREL);
                    }
                    setKitCost(c);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                }
            )

            NeedsMngt.getConditions(ref).then(
                (response) => {
                    setPrimaryConditions(response)
                    setTab3(true);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                }
            )

            NeedsMngt.getKitNeedHistoric(ref).then(
                (response) => {
                    setHistoric(response)
                    setTab2(true);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                }
            )


        }
    }, []);

    /**
     * 
     * @param {*} e 
     */
    const updateArticle = (e) => {
        if (e.target.value.trim() === "") return;

        var arr = [...data];
        var k = e.target.getAttribute("data-key");
        if (forceString(arr[k].art).toUpperCase().trim() === e.target.value.toUpperCase().trim()) return;

        var id = e.target.getAttribute("data-id");
        arr[k].ar_changing = true;

        setData(arr);

        NeedsMngt.updateArticle(e.target.value, arr[k].art, id).then(
            (response) => {
                arr = [...data];
                delete arr[k].ar_changing;
                delete arr[k].changed;
                arr[k].ref = response;
                arr[k].art = e.target.value;
                setData(arr)
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                arr = [...data];
                delete arr[k].ar_changing;
                delete arr[k].changed;
                setData(arr)
            }
        );

    }

    /**
     * Controle kit
     * @param {*} kit 
     */
    const control = (kit) => {
        //Check if created in focus and doc
        if (kit.kit_ar !== null) setKitCode(kit.kit_ar);
        if (kit.notice !== null) setDocument(kit.notice_url);

        //Check blocked and not created articles
        var notCreated = 0, blockedA = 0;
        kit.bom.map(v => {
            if (!v.art) notCreated++;
            if (v.BLOCL && v.BLOCL !== "N") blockedA++;
        });

        setArtiToCreate(notCreated);
        setBlocked(blockedA);

    }

    const readFile = (e) => {
        setError();
        e.preventDefault();
        setReading(true);
        NeedsMngt.readKitBomFile(e.target.file.files[0]).then(
            (response) => {
                window.location.href = "/article/kit?ref=" + response.kit_ref;
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setReading(false);
            }
        )
    }

    const searchDoc = () => {
        setDocSearch(true);

        NeedsMngt.searchDoc(ref).then(
            (response) => {
                setDocument(response);
                setDocSearch(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setDocSearch(false);
            }
        )
    }

    /**
     * 
     */
    const onMachineTypeChoose = (e) => {
        var value = e.target.value;
        var arr = [...primaryConditions.machineType];

        if (e.target.checked) {
            arr.push(value);
        } else {
            arr = arrayRemoveElt(arr, arr.indexOf(value));
        }

        var o = { ...primaryConditions };
        o.machineType = arr;
        setPrimaryConditions(o);
    }

    /**
     * 
     */
    const onSubsetTypeChoose = (e) => {
        var value = e.target.value;
        var arr = [...primaryConditions.subset];

        if (e.target.checked) {
            arr.push(value);
        } else {
            arr = arrayRemoveElt(arr, arr.indexOf(value));
        }

        var o = { ...primaryConditions };
        o.subset = arr;
        setPrimaryConditions(o);
    }

    /**
     * 
     */
    const onRemoveSecondaryCondition = (e) => {
        var arr = [...secondaryConditions];
        var key = e.target.getAttribute("data-key");
        arr = arrayRemoveElt(arr, key);
        setSecondaryConditions(arr);
    }

    /**
     * 
     */
    const onSecondaryConditionChange = (e) => {
        var arr = [...secondaryConditions];
        var key = e.target.getAttribute("data-key");
        arr[key] = e.target.value;
        setSecondaryConditions(arr);
    }

    /**
     * 
     */
    const onDimConditionsChange = (e) => {
        var typeDim = e.target.getAttribute("data-type");
        var field = e.target.getAttribute("data-field");
        var o = { ...primaryConditions };
        o[typeDim][field] = e.target.value;
        setPrimaryConditions(o);
    }

    /**
     * 
     */
    const saveConditions = () => {
        setSaving(true);
        NeedsMngt.saveConditions(ref, primaryConditions, secondaryConditions).then(
            (response) => {
                setMsg("Sauvegardé !");
                scrollToTop();
                setSaving(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        )
    }

    /**
     * 
     */
    const createKit = () => {
        setCreating(true);
        NeedsMngt.createKit(ref).then(
            (response) => {
                setKitCode(response);
                scrollToTop();
                setCreating(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setCreating(false);
            }
        )
    }

    /**
     * 
     */
    const updateFocusKit = () => {
        setCreating(true);
        NeedsMngt.updateFocusKit(kitCode, data).then(
            (response) => {
                setMsg(response);
                scrollToTop();
                setCreating(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setCreating(false);
            }
        )
    }

    /**
     * 
     */
    const checkMachine = () => {
        setError();
        setCheckingMachine(true);
        MachineInfo.getAllMachineInfo(kitUseMachine).then(
            (response) => {
                var err = checkCompatibilities(response)

                if (err !== "") {
                    setError(err);
                    scrollToTop();
                    setCheckingMachine(false);
                    setKitUseBusiness("");
                    setKitMachine("");

                    return;
                }

                var business = response[0].affaire;
                business = business.substring(0, business.length - 1) + (kitUseBusinessType === "SAV" ? "G" : "H");

                setKitUseBusiness(business);
                setKitUseState(3);
                setCheckingMachine(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setCheckingMachine(false);
            })
    }

    function checkCompatibilities(machineInfo) {
        /*console.log(primaryConditions)
        console.log( machineInfo )*/

        //Check type of machine
        var machine_type = machineInfo[0].group;
        var mach_typ_compatible = primaryConditions.machineType;

        if (mach_typ_compatible) {
            if (!mach_typ_compatible.includes(machine_type))
                return "Ce kit n'est pas compatible avec cette machine de configuration: "
                    + machine_type + "<br>Veuillez vous référer à l'encadré jaune pour voir les conditions d'utilisation de ce kit.";
        }


        // Check Longueur extérieures (mm): L
        var found_l_dim_ext = false;
        var found_w_dim_ext = false;
        var found_h_dim_ext = false;
        var found_haut_ext_brut = false;
        for (let i = 0; i < machineInfo.length; i++) {
            if (isNumberInRange(machineInfo[i].l_dim_ext, primaryConditions.extDimL)) found_l_dim_ext = true;
            if (isNumberInRange(machineInfo[i].w_dim_ext, primaryConditions.extDimW)) found_w_dim_ext = true;

            if (isNumberInRange(machineInfo[i].h_dim_ext, primaryConditions.extDimH)) found_h_dim_ext = true;
            if (machineInfo[i].haut_ext_brut && isNumberInRange(machineInfo[i].haut_ext_brut, primaryConditions.extDimH)) found_haut_ext_brut = true;
        }

        var err = "";
        if (!found_l_dim_ext) err += "La longueur extérieures (mm): L est de " + machineInfo[0].l_dim_ext + " mm<br>";
        if (!found_w_dim_ext) err += "La largeur extérieures (mm): W est de " + machineInfo[0].w_dim_ext + " mm<br>";
        if (!found_h_dim_ext && !found_haut_ext_brut)
            err += "La hauteur brut min (mm): H est de " + machineInfo[0].haut_ext_brut || machineInfo[0].h_dim_ext + " mm<br>";


        if (err !== "") {
            return "Ce kit n'est pas compatible avec le format de cette machine:<br>" + err
                + "<br>Veuillez vous référer à l'encadré jaune pour voir les conditions d'utilisation de ce kit."
        } else {
            return "";
        }

    }

    function isNumberInRange(num, ranges) {
        const { v1, v2, v3, v4, v5, v6 } = ranges;

        // Convert values to numbers or null if empty
        const range1Start = v1 !== "" ? Number(v1) : null;
        const range1End = v2 !== "" ? Number(v2) : null;
        const range2Start = v3 !== "" ? Number(v3) : null;
        const range2End = v4 !== "" ? Number(v4) : null;
        const range3Start = v5 !== "" ? Number(v5) : null;
        const range3End = v6 !== "" ? Number(v6) : null;

        if (range1Start === null && range2Start === null && range3Start === null) return true;

        // Check if num falls within any range
        if ((range1Start !== null && range1End !== null && num >= range1Start && num <= range1End) ||
            (range2Start !== null && range2End !== null && num >= range2Start && num <= range2End) ||
            (range3Start !== null && range3End !== null && num >= range3Start && num <= range3End)) {
            return true;
        }

        return false;
    }

    /**
     * 
     */
    const insertInBusiness = async () => {
        setError();

        setCreating(true);
        var err = [];
        var arr = [...listToUse];

        for (let i = 0; i < listToUse.length; i++) {
            var o = listToUse[i];

            setProgress(Math.round((i / listToUse.length) * 100))
            var resp = await NeedsMngt.insertKit(ref, data, o.kitUseBusinessType, o.kitUseMachine,
                o.kitUseBusiness, o.kitUseDate, o.kitUseNb, o.kitUseUser).then(
                    (response) => { return response; },
                    (error) => { return error; }
                )

            try {
                if (resp.response && resp.response.data) {
                    err.push(resp.response.data);
                } else {
                    arr[i].id = resp;
                }
            } catch (error) { err.push("Erreur inconnue") }

        }

        if (err.length > 0) {
            setError("Un ou plusieurs erreurs se sont produites:<br>" + err.join("<br>"));
            scrollToTop();
        } else {
            NeedsMngt.notifyKitInsert(ref, arr);
            setMsg("Demande envoyée !");
            scrollToTop();
        }
        setCreating(false);
    }

    const hideShowKit = (e) => {
        var status = !hidden;
        setHidden(status);

        NeedsMngt.hideOrShowKit(ref, status).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setCreating(false);
            }
        )

    }

    const addMachineToUse = (e) => {
        e.preventDefault();

        if (!kitUseBusiness) {
            setError("Vérifiez le code machine pour récupérer le code affaire !")
            scrollToTop();
            return;
        }

        var arr = [...listToUse];
        arr.push({
            kitUseBusinessType: kitUseBusinessType,
            kitUseMachine: kitUseMachine,
            kitUseBusiness: kitUseBusiness,
            kitUseDate: kitUseDate,
            kitUseNb: kitUseNb,
            kitUseUser: kitUseUser
        });

        setKitMachine();
        setKitUseBusiness();
        setCheckingMachine();

        e.target.kitUseMachine.value = "";
        e.target.kitUseBusiness.value = "";

        setListToUse(arr);
    }

    const deleteFromList = (k) => {
        var arr = [...listToUse];
        arr = arrayRemoveElt(arr, k);
        setListToUse(arr);
    }

    const copyConditions = (k) => {
        var ref = similarKit[k].ref;
        setLoading(true);

        NeedsMngt.getConditions(ref, true).then(
            (response) => {
                var o = {
                    extDimH: response.extDimH,
                    extDimHMax: response.extDimHMax,
                    extDimL: response.extDimL,
                    extDimW: response.extDimW,
                    machineType: response.machineType,
                    subset: response.subset
                };
                setPrimaryConditions(o);
                setSecondaryConditions(response.secondary_condition);

                setTab(4)

                setLoading(false);
            },(error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();setLoading(false);
            }
        )
    }

    return <Fragment>
        <h4 className="text-center">Kit {ref}</h4>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {   /** IF NO REF IN URL PARAM READ BOM FILE */
            !ref && <div>
                <br></br>
                <form className="text-center col-4 offset-4" onSubmit={readFile} required>
                    <h5>Choisissez le fichier compilé à traiter</h5>

                    <div className="input-group mb-3">
                        <input type="file" className="form-control" name="file" />
                        <button className="btn btn-success" disabled={reading}>
                            {reading && <ButtonWaiting />}
                            Soumettre
                        </button>
                    </div>
                </form>
            </div>
        }

        {   /** IF THERE IS A REF DISPLAY DETAIL AND ACTIONS */
            ref && <Fragment>

                <ul className="nav nav-tabs">
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 0 ? "active" : "")}
                            href="#" onClick={() => setTab(0)}>
                            {!tab1 && <ButtonWaiting />}
                            Préambule
                        </a>
                    </li>
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 1 ? "active" : "")}
                            href="#" onClick={() => setTab(1)} data-tab="1">
                            {!tab1 && <ButtonWaiting />}
                            Détail kit</a>
                    </li>
                    {
                        (kitCode &&
                            <li className="nav-item fw-bold">
                                <a className={"nav-link " + (tab === 2 ? "active" : "")}
                                    href="#" onClick={() => setTab(2)} data-tab="1">Utiliser le kit</a>
                            </li>
                        )
                    }
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 3 ? "active" : "")}
                            href="#" onClick={() => setTab(3)} data-tab="1">
                            {!tab2 && <ButtonWaiting />}
                            Historique</a>
                    </li>
                    {
                        (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_KIT_MANAGE")) &&
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 4 ? "active" : "")}
                                href="#" onClick={() => setTab(4)} data-tab="1">
                                {!tab3 && <ButtonWaiting />}
                                Conditions</a>
                        </li>
                    }
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 5 ? "active" : "")}
                            href="#" onClick={() => setTab(5)} data-tab="1">
                            {!tab2 && <ButtonWaiting />}
                            Anciens indices</a>
                    </li>

                </ul>

                {
                    (tab1 && tab === 0) && <Fragment>
                        <br></br>
                        <ul className="list-group col-8 offset-2">
                            {kitCode && <Fragment>
                                <li className="list-group-item">
                                    <b>Kit créé dans Focus sous le code:</b> {kitCode}
                                    <button className="btn btn-outline-info ms-3"
                                        onClick={updateFocusKit} disabled={creating}>
                                        {creating && <ButtonWaiting />}
                                        Mettre à jour le kit dans Focus</button>
                                </li>

                            </Fragment>

                            }
                            {
                                !kitCode &&

                                <li className="list-group-item list-group-item-danger">
                                    <b>Kit non créé dans Focus</b>
                                    {
                                        (artToCreate <= 0 && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_KIT_MANAGE"))) &&
                                        <button className="btn btn-outline-success" style={{ marginLeft: "10px" }}
                                            disabled={creating} onClick={createKit}>
                                            {creating && <ButtonWaiting />}
                                            Créer
                                        </button>
                                    }
                                </li>
                            }
                            {
                                artToCreate > 0 &&
                                <li className="list-group-item list-group-item-danger"><b>Article(s) à créer:</b> {artToCreate}</li>
                            }
                            {
                                blocked > 0 &&
                                <li className="list-group-item list-group-item-danger"><b>Article(s) bloqué(s):</b> {blocked}</li>
                            }

                            {
                                secondaryConditions && secondaryConditions.map((v, k) => {
                                    return <li className="list-group-item list-group-item-warning" key={"c" + k}>
                                        <b>Condition secondaire {k + 1}:</b> {v}
                                    </li>;
                                })
                            }
                            {document && <li className="list-group-item">
                                <b>Notice:</b><br></br>
                                <a href={url + ref} target="_blank">{document}</a>
                                <br></br>
                                <button className="btn btn-outline-warning" onClick={searchDoc} disabled={docSearch}>
                                    {docSearch && <ButtonWaiting />}
                                    Cliquez ici si le lien ne fonctionne pas</button>
                            </li>
                            }
                            {(!document && !docSearch) && <li className="list-group-item" onClick={searchDoc}><a href="#">Chercher une notice</a></li>}
                            {docSearch && <li className="list-group-item"><WaitingRoundSnippers /></li>}
                            {kitCost > 0 && <li className="list-group-item">
                                Prix de revient du kit (hors CSM et CMP): <b>{kitCost.toFixed(2)} €</b>
                            </li>}
                            {hidden !== undefined &&
                                <li className="list-group-item">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" checked={hidden} onClick={hideShowKit} />
                                        <label className="form-check-label" for="inlineCheckbox1">Kit masqué</label>
                                    </div>
                                </li>}
                        </ul>
                    </Fragment>
                }

                {
                    tab === 1 &&
                    <table {...getTableProps()} className="table text-center" id="subcontracting_table">
                        <thead>
                            {// Loop over the header rows
                                headerGroups.map(headerGroup => (
                                    // Apply the header row props
                                    <tr className="table-dark fixed-header" {...headerGroup.getHeaderGroupProps()}>
                                        {// Loop over the headers in each row
                                            headerGroup.headers.map(column => (
                                                // Apply the header cell props
                                                <th {...column.getHeaderProps(column.getSortByToggleProps(),)}>
                                                    {column.render('Header')}

                                                    <span>
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? ' 🔽'
                                                                : ' 🔼'
                                                            : ''}
                                                    </span>

                                                </th>

                                            ))}
                                    </tr>



                                ))}
                            {// Loop over the header rows
                                headerGroups.map(headerGroup => (
                                    // Apply the header row props
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {// Loop over the headers in each row
                                            headerGroup.headers.map(column => (
                                                // Apply the header cell props
                                                <th {...column.getHeaderProps()}>
                                                    {/* Render the columns filter UI */}
                                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                </th>
                                            ))}
                                    </tr>
                                ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {// Loop over the table rows
                                rows.map(row => {
                                    // Prepare the row for display
                                    prepareRow(row)
                                    return (
                                        // Apply the row props
                                        <tr {...row.getRowProps()}>
                                            {// Loop over the rows cells
                                                row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                }

                {
                    tab === 2 && <div className="col-10 offset-1">
                        <br></br>
                        <p className="fw-bold">Vous vous apprêtez à insérer un kit dans une machine dans Focus.
                            Concrètement vous allez insérer l'{kitCode} dans une affaire et ainsi générer des besoins.<br></br>
                            Ensuite, un mail automatique sera envoyé au service achats pour passer les commandes si nécessaire ainsi qu'au service logistique pour plannifier les sorties de stock. Veuillez suivre les instructions ci-dessous.</p>

                        <div className="alert alert-warning fw-bold">
                            Rappel des conditions permettant l'utilisation du kit:
                            <ul>
                                <li>Concerne les machines:&nbsp;
                                    {primaryConditions.machineType.length === 0 ? "Tous" : primaryConditions.machineType.join(', ')}
                                </li>
                                <li>Concerne les sous-ensemble:&nbsp;
                                    {primaryConditions.subset.length === 0 ? "Tous" : primaryConditions.subset.join(', ')}
                                </li>
                                {
                                    primaryConditions.extDimL.v1 &&
                                    <li>Longueur extérieures (mm) comprises entre:&nbsp;
                                        {primaryConditions.extDimL.v1} et {primaryConditions.extDimL.v2}&nbsp;
                                        {
                                            primaryConditions.extDimL.v3 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimL.v3} et {primaryConditions.extDimL.v4}&nbsp;
                                            </Fragment>
                                        }
                                        {
                                            primaryConditions.extDimL.v5 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimL.v5} et {primaryConditions.extDimL.v6}&nbsp;
                                            </Fragment>
                                        }
                                    </li>
                                }

                                {
                                    primaryConditions.extDimW.v1 &&
                                    <li>Largeur extérieures (mm) comprises entre:&nbsp;
                                        {primaryConditions.extDimW.v1} et {primaryConditions.extDimW.v2}&nbsp;
                                        {
                                            primaryConditions.extDimW.v3 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimW.v3} et {primaryConditions.extDimW.v4}&nbsp;
                                            </Fragment>
                                        }
                                        {
                                            primaryConditions.extDimW.v5 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimW.v5} et {primaryConditions.extDimW.v6}&nbsp;
                                            </Fragment>
                                        }
                                    </li>
                                }

                                {
                                    primaryConditions.extDimH.v1 &&
                                    <li>Hauteur brut min (mm) comprises entre:&nbsp;
                                        {primaryConditions.extDimH.v1} et {primaryConditions.extDimH.v2}&nbsp;
                                        {
                                            primaryConditions.extDimH.v3 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimH.v3} et {primaryConditions.extDimH.v4}&nbsp;
                                            </Fragment>
                                        }
                                        {
                                            primaryConditions.extDimH.v5 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimH.v5} et {primaryConditions.extDimH.v6}&nbsp;
                                            </Fragment>
                                        }
                                    </li>
                                }

                                {
                                    primaryConditions.extDimHMax.v1 &&
                                    <li>Hauteur brut min (mm) comprises entre:&nbsp;
                                        {primaryConditions.extDimHMax.v1} et {primaryConditions.extDimHMax.v2}&nbsp;
                                        {
                                            primaryConditions.extDimHMax.v3 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimHMax.v3} et {primaryConditions.extDimHMax.v4}&nbsp;
                                            </Fragment>
                                        }
                                        {
                                            primaryConditions.extDimHMax.v5 !== "" &&
                                            <Fragment>
                                                OU&nbsp;
                                                {primaryConditions.extDimHMax.v5} et {primaryConditions.extDimHMax.v6}&nbsp;
                                            </Fragment>
                                        }
                                    </li>
                                }

                                {
                                    secondaryConditions && secondaryConditions.map((v, k) => {
                                        return <li key={"c" + k}>
                                            <b>Condition secondaire {k + 1}:</b> {v}
                                        </li>;
                                    })
                                }

                            </ul>
                        </div>

                        <div className="d-flex">
                            <form className="col-8 border-end pe-3" onSubmit={addMachineToUse}>

                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" checked={kitUseState >= 1}
                                        onClick={(e) => { if (e.target.checked) { setKitUseState(1) } else { setKitUseState(0) } }} />
                                    <label className="form-check-label fw-bold" for="flexCheckDefault">
                                        Je confirme que la machine répondra aux conditions mentionnées
                                    </label>
                                </div>

                                {
                                    kitUseState >= 1 &&
                                    <div>
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" required
                                                    name="typeBusiness" value="SAV" checked={kitUseBusinessType === "SAV"}
                                                    onClick={() => { setKitUseBusinessType("SAV"); setKitUseState(2) }} />
                                                <label className="form-check-label" for="inlineRadio1">Kit en garantie</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" checked={kitUseBusinessType === "Affaire"}
                                                    name="typeBusiness" value="Affaire" required
                                                    onClick={() => { setKitUseBusinessType("Affaire"); setKitUseState(2) }} />
                                                <label className="form-check-label" for="inlineRadio2">Autre</label>
                                            </div>
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "450px" }}>Sur quelle machine doit-on ajouter ce kit ?</span>
                                            <input type="text" className="form-control" onChange={(e) => setKitMachine(e.target.value)}
                                                value={kitUseMachine} id="kitUseMachine" required />
                                            <button type="button" className="btn btn-secondary" onClick={checkMachine} disabled={checkingMachine}>
                                                {checkingMachine && <ButtonWaiting />}
                                                Vérifier
                                            </button>
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "450px" }}>Affaire (non modifiable)</span>
                                            <input type="text" className="form-control" value={kitUseBusiness} id="kitUseBusiness"
                                                readOnly required />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "450px" }}>Pour quelle date devons-nous disposer des pièces ?</span>
                                            <input type="date" className="form-control" value={kitUseDate} required
                                                onChange={(e) => { setKitDate(e.target.value); setKitUseState(5) }} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "450px" }}>Combien, de ce kit, avons-nous besoin pour cette machine ?</span>
                                            <input type="number" className="form-control" value={kitUseNb} required
                                                onChange={(e) => { setKitNb(e.target.value); setKitUseState(6) }} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "450px" }}>A qui, en interne seront destinés les articles de ce kit ?</span>
                                            <input type="text" className="form-control" value={kitUseUser} required
                                                onChange={(e) => { setKitUser(e.target.value); setKitUseState(7) }} />
                                        </div>



                                        <div className="text-center mb-3">
                                            <button type="submit" className="btn btn-primary">Ajouter</button>
                                        </div>
                                    </div>
                                }


                            </form>

                            <div className="ps-3" style={{ width: "100%" }}>
                                <h4 className="text-center">Machine(s) à lancer en kit</h4>

                                {listToUse.length === 0 && <div className="text-center">Pour lancer ce kit en affaire(s)
                                    remplissez le formulaire de gauche, puis cliquez sur ajouter. Vous pouvez ajouter
                                    plusieurs machines via le formulaire de gauche</div>}



                                {listToUse.length > 0 && <div>
                                    {creating && progressInstance}

                                    <ul className="list-group mb-3">
                                        {listToUse.map((v, k) => {
                                            return <li className="list-group-item d-flex" key={"todo" + k}>
                                                <div>#{v.kitUseMachine}</div>
                                                <div className="ms-auto">
                                                    <img src="/common/remove_icon.png" className="see-img w-30"
                                                        onClick={() => deleteFromList(k)} />
                                                </div>
                                            </li>
                                        })}
                                    </ul>

                                    <div className="text-center">
                                        <button className="btn btn-success" onClick={insertInBusiness} disabled={creating}>
                                            {creating && <ButtonWaiting />}
                                            Lancer {listToUse.length} kit en affaire
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>

                        </div>
                    </div>
                }

                {
                    tab === 3 && <Fragment>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Affaire</th>
                                    <th scope="col">Machine</th>
                                    <th scope="col">Qté demandée</th>
                                    <th scope="col">Type d'affaire</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Demandeur</th>
                                    <th scope="col">Clôturé</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    historic.map(
                                        (v, k) => {
                                            return <tr key={"h" + k}>
                                                <th scope="row">{v.business}</th>
                                                <td>{v.machine}</td>
                                                <td>{v.qty}</td>
                                                <td>{v.type_use}</td>
                                                <td>{v.date_use}</td>
                                                <td>{v.author}</td>
                                                <td>{v.closed === 0 ? "Non" : "Oui"}</td>
                                                <td>
                                                    <a target="_blank" href={"/article/kit/business/display?id=" + v.id}>Voir</a>
                                                </td>
                                            </tr>
                                        }
                                    )
                                }

                            </tbody>
                        </table>
                    </Fragment>
                }

                {
                    tab === 4 && <Fragment>
                        <h4 className="text-center">Définition des conditions d'utilisation de ce kit</h4>

                        <div className="alert alert-secondary" role="alert">
                            <h5 className="text-center">Conditions primaires</h5>

                            <div className="accordion mb-3" id="accordionExample">

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            <b>Types de machines concernées:</b>&nbsp; {primaryConditions.machineType.join(', ')}
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample"
                                        style={{ padding: "10px" }}>
                                        {
                                            typeOfMachine.map((v, k) => {
                                                return <div className="form-check" key={"tm" + k}>
                                                    <input className="form-check-input" type="checkbox"
                                                        value={v} onChange={onMachineTypeChoose} checked={primaryConditions.machineType.includes(v)} />
                                                    <label className="form-check-label" for="flexCheckDefault">{v}</label>
                                                </div>
                                            })
                                        }

                                    </div>
                                </div>

                                <div className="accordion-item mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree">
                                            <b>Sous-ensembles concernés:</b>&nbsp; {primaryConditions.subset.join(', ')}
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                                        style={{ padding: "10px" }}>
                                        <div className="accordion-body">
                                            {
                                                subsetList.map((v, k) => {
                                                    return <div className="form-check" key={"tm" + k}>
                                                        <input className="form-check-input" onChange={onSubsetTypeChoose}
                                                            type="checkbox" value={v} checked={primaryConditions.subset.includes(v)} />
                                                        <label className="form-check-label" for="flexCheckDefault">{v}</label>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "250px" }} >Longueur extérieures (mm): L</span>
                                    <span className="input-group-text" id="basic-addon1">entre</span>
                                    <input type="number" className="form-control" data-type="extDimL"
                                        data-field="v1" onChange={onDimConditionsChange} value={primaryConditions.extDimL.v1} />
                                    <span className="input-group-text" id="basic-addon1">et</span>
                                    <input type="number" className="form-control" data-type="extDimL"
                                        data-field="v2" onChange={onDimConditionsChange} value={primaryConditions.extDimL.v2} />

                                    <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                    <span className="input-group-text" id="basic-addon1">entre</span>
                                    <input type="number" className="form-control" data-type="extDimL"
                                        data-field="v3" onChange={onDimConditionsChange} value={primaryConditions.extDimL.v3} />
                                    <span className="input-group-text" id="basic-addon1">et</span>
                                    <input type="number" className="form-control" data-type="extDimL"
                                        data-field="v4" onChange={onDimConditionsChange} value={primaryConditions.extDimL.v4} />

                                    <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                    <span className="input-group-text" id="basic-addon1">entre</span>
                                    <input type="number" className="form-control" data-type="extDimL"
                                        data-field="v5" onChange={onDimConditionsChange} value={primaryConditions.extDimL.v5} />
                                    <span className="input-group-text" id="basic-addon1">et</span>
                                    <input type="number" className="form-control" data-type="extDimL"
                                        data-field="v6" onChange={onDimConditionsChange} value={primaryConditions.extDimL.v6} />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "250px" }}>Largeur extérieures (mm): W</span>
                                    <span className="input-group-text" id="basic-addon1">entre</span>
                                    <input type="number" className="form-control" data-type="extDimW"
                                        data-field="v1" onChange={onDimConditionsChange} value={primaryConditions.extDimW.v1} />
                                    <span className="input-group-text" id="basic-addon1">et</span>
                                    <input type="number" className="form-control" data-type="extDimW"
                                        data-field="v2" onChange={onDimConditionsChange} value={primaryConditions.extDimW.v2} />

                                    <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                    <span className="input-group-text" id="basic-addon1">entre</span>
                                    <input type="number" className="form-control" data-type="extDimW"
                                        data-field="v3" onChange={onDimConditionsChange} value={primaryConditions.extDimW.v3} />
                                    <span className="input-group-text" id="basic-addon1">et</span>
                                    <input type="number" className="form-control" data-type="extDimW"
                                        data-field="v4" onChange={onDimConditionsChange} value={primaryConditions.extDimW.v4} />

                                    <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                    <span className="input-group-text" id="basic-addon1">entre</span>
                                    <input type="number" className="form-control" data-type="extDimW"
                                        data-field="v5" onChange={onDimConditionsChange} value={primaryConditions.extDimW.v5} />
                                    <span className="input-group-text" id="basic-addon1">et</span>
                                    <input type="number" className="form-control" data-type="extDimW"
                                        data-field="v6" onChange={onDimConditionsChange} value={primaryConditions.extDimW.v6} />
                                </div>
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "250px" }}>Hauteur brut min (mm): H</span>
                                <span className="input-group-text" id="basic-addon1">entre</span>
                                <input type="number" className="form-control" data-type="extDimH"
                                    data-field="v1" onChange={onDimConditionsChange} value={primaryConditions.extDimH.v1} />
                                <span className="input-group-text" id="basic-addon1">et</span>
                                <input type="number" className="form-control" data-type="extDimH"
                                    data-field="v2" onChange={onDimConditionsChange} value={primaryConditions.extDimH.v2} />

                                <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                <span className="input-group-text" id="basic-addon1">entre</span>
                                <input type="number" className="form-control" data-type="extDimH"
                                    data-field="v3" onChange={onDimConditionsChange} value={primaryConditions.extDimH.v3} />
                                <span className="input-group-text" id="basic-addon1">et</span>
                                <input type="number" className="form-control" data-type="extDimH"
                                    data-field="v4" onChange={onDimConditionsChange} value={primaryConditions.extDimH.v4} />

                                <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                <span className="input-group-text" id="basic-addon1">entre</span>
                                <input type="number" className="form-control" data-type="extDimH"
                                    data-field="v5" onChange={onDimConditionsChange} value={primaryConditions.extDimH.v5} />
                                <span className="input-group-text" id="basic-addon1">et</span>
                                <input type="number" className="form-control" data-type="extDimH"
                                    data-field="v6" onChange={onDimConditionsChange} value={primaryConditions.extDimH.v6} />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: "250px" }}>Hauteur brut max (mm): H</span>
                                <span className="input-group-text" id="basic-addon1">entre</span>
                                <input type="number" className="form-control" data-type="extDimHMax"
                                    data-field="v1" onChange={onDimConditionsChange} value={primaryConditions.extDimHMax.v1} />
                                <span className="input-group-text" id="basic-addon1">et</span>
                                <input type="number" className="form-control" data-type="extDimHMax"
                                    data-field="v2" onChange={onDimConditionsChange} value={primaryConditions.extDimHMax.v2} />

                                <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                <span className="input-group-text" id="basic-addon1">entre</span>
                                <input type="number" className="form-control" data-type="extDimHMax"
                                    data-field="v3" onChange={onDimConditionsChange} value={primaryConditions.extDimHMax.v3} />
                                <span className="input-group-text" id="basic-addon1">et</span>
                                <input type="number" className="form-control" data-type="extDimHMax"
                                    data-field="v4" onChange={onDimConditionsChange} value={primaryConditions.extDimHMax.v4} />

                                <span className="input-group-text bg-danger bg-gradient text-white fw-bold" id="basic-addon1">OU</span>

                                <span className="input-group-text" id="basic-addon1">entre</span>
                                <input type="number" className="form-control" data-type="extDimHMax"
                                    data-field="v5" onChange={onDimConditionsChange} value={primaryConditions.extDimHMax.v5} />
                                <span className="input-group-text" id="basic-addon1">et</span>
                                <input type="number" className="form-control" data-type="extDimHMax"
                                    data-field="v6" onChange={onDimConditionsChange} value={primaryConditions.extDimHMax.v6} />
                            </div>

                        </div>

                        <div className="alert alert-info" role="alert">
                            <h5 className="text-center">Conditions secondaires</h5>

                            {
                                secondaryConditions.map((v, k) => {
                                    return <div style={{ display: "flex" }}>
                                        <div className="form-floating mb-3" key={"sc" + k} style={{ width: "100%" }}>
                                            <textarea className="form-control" onChange={onSecondaryConditionChange}
                                                placeholder="xxx" data-key={k} value={v}></textarea>
                                            <label for="floatingTextarea">Condition {k + 1}</label>
                                        </div>
                                        <img src={"/common/remove_icon.png"} data-key={k} onClick={onRemoveSecondaryCondition}
                                            style={{ height: "40px", marginTop: "auto", marginBottom: "auto" }}></img>
                                    </div>
                                })
                            }

                            <img src={"/common/add-logo.png"} onClick={() => { var arr = [...secondaryConditions]; arr.push(""); setSecondaryConditions(arr); }}></img>
                        </div>

                        <div className="text-center">
                            <button className="btn btn-success" onClick={saveConditions} disabled={saving}>
                                {saving && <ButtonWaiting />}
                                Sauvegarder
                            </button>
                        </div>

                    </Fragment>
                }

                {tab === 5 && <div>
                    {similarKit.length === 0 && <h6 className="text-center">Aucun ancien indice trouvé</h6>}
                    {similarKit.length > 0 &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Code AV</th>
                                    <th scope="col">Ref</th>
                                    <th scope="col">Désignation</th>
                                    <th scope="col">Masqué ?</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    similarKit.map(
                                        (v, k) => {
                                            return <tr key={"h" + k}>
                                                <th scope="row">
                                                    <a target="_blank" href={"/article/kit?ref=" + v.ref}>{v.ar}</a>
                                                </th>
                                                <td>{v.ref}</td>
                                                <td>{v.label}</td>
                                                <td>{v.hide === "O" ? "Non" : "Oui"}</td>
                                                <td>
                                                    <button className="btn btn-outline-secondary" disabled={loading}
                                                        onClick={()=>copyConditions(k)}>
                                                            {loading && <ButtonWaiting />}
                                                            Rapatrier les conditions</button>
                                                </td>
                                            </tr>
                                        }
                                    )
                                }

                            </tbody>
                        </table>

                    }
                </div>}


            </Fragment>
        }
    </Fragment>

}

export default Kit;