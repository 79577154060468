import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import { SmallFrame } from "../../../../common/smallComponents.js/CustomDivs";
import { BtnWithCheck } from "../../../../common/smallComponents.js/DesignedIpt";
import ReactTable from "../../../../common/smallComponents.js/ReactTable";
import { arrayRemoveElt, getKeyInt, removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../../../functions/PageAnimation";
import { forceString } from "../../../../functions/StrFunctions";

const OtherPacking = (p) => {
    const [search, setSearch] = useState();
    const [tab, setTab] = useState("");
    const [data, setData] = useState();
    const [selectedArt, setSelectedArt] = useState([]);
    const [added, setAdded] = useState();

    const packingType = [{ ar: "Palette", label: "Palette" },
    { ar: "Caisse", label: "Caisse" },
    { ar: "Colis", label: "Colis" }];

    //,{ article: "Enveloppe", fr_label: "Enveloppe" }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id',
            {

                enableSorting: false,
                enableColumnFilter: false,
                header: <div><input type="checkbox" className="form-check-input big" /></div>,
                cell: (i) => <div className="text-center">
                    {tab === "packing" && <BtnWithCheck label={"Ajouter"}
                        onClick={() => addPacking(i.row.id)} id={i.row.id} />}

                    {(tab === "articles" && !hideButton(i.row.original)) &&
                        <input type="checkbox" className="form-check-input big"
                            onChange={(e) => selectArticle(e, i.row.id)}
                            checked={selectedArt.find((v) => { return v.data_id === i.row.id })} />
                    }
                </div>
            }));

        arr.push(columnHelper.accessor('ar',
            { header: 'Article', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('label',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            { header: 'Ref.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('fonction',
            { header: 'Machine', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        return arr;
    }

    function hideButton(v) {
        if (!v) return;

        var this_id = forceString(v.source).trim() + v.id;

        for (let i = 0; i < p.packing_list.length; i++) {
            var content = p.packing_list[i].content;
            if (content && content.find((a) => { return a.picking_id === this_id }) !== undefined) return true;
        }


        return false;
    }

    const selectArticle = (e, id) => {
        var arr = [...selectedArt];
        var o = { ...data[id] };
        if (e.target.checked) {
            o.data_id = id;
            arr.push(o)
        } else {
            arr = arrayRemoveElt(arr, getKeyInt(arr, "data_id", id));
        }
        setSelectedArt(arr)
    }

    const changeTab = (tab) => {
        setTab(tab);
        setData(tab === "articles" ? [...p.picking.currentPicking] : [...packingType])
    }

    /**
     * ADD PACKING INSIDE PALET - PACK - OTHERS
     * @param {*} k 
     */
    const addPacking = (k) => {
        var packing = [...p.packing_list];
        var choice = data[k];

        var id = 1;
        var rank = 1;

        var insertedPacking = [];
        //Detect packing and if exists ask where to insert the packing
        for (let i = 0; i < packing.length; i++) {
            var c = packing[i].content;
            //Get this lastestID to increment
            var this_id = parseInt(packing[i].id);
            if (this_id > id) id = this_id;

            //Add to list of packing when we can insert the articles
            if (packing[i].type.toLowerCase() !== "article" && i === search) insertedPacking.push(packing[i]);

            if (c) {
                for (let j = 0; j < c.length; j++) {
                    this_id = parseInt(c[j].id);
                    if (this_id > id) id = this_id;
                    if (c[j].type.toLowerCase() !== "article" && i === search) insertedPacking.push(c[j]);
                }
            }

        }

        id += 1;

        var o = {
            "dim1":  "",
            "dim2": "",
            "dim3": "",
            "weight": "",
            "level": 1,
            "display": 1,
            "label": choice.type,
            "shipment_id": p.picking.shipment.id,
            "type": choice.type || choice.label,
            "ar": null,
            "picking_id": p.pickingId,
            "av": null,
            "machine": p.packing_list[search].machine,
            "rank": rank,
            "id": id,
            qty: 1
        }

        setAdded({
            insertedPacking: insertedPacking,
            toAdd: [o],
            previousTab: "packing"
        });

        setTab("choose");
    }

    const addArticle = () => {
        var packing = [...p.packing_list];


        var level = 1;
        var rank = 1;

        var insertedPacking = [];
        //Detect packing and if exists ask where to insert the packing
        for (let i = 0; i < packing.length; i++) {
            var c = packing[i].content;
            if (packing[i].type.toLowerCase() !== "article" && i === search) insertedPacking.push(packing[i]);

            if (c) {
                for (let j = 0; j < c.length; j++)
                    if (c[j].type.toLowerCase() !== "article" && i === search) insertedPacking.push(c[j]);

            }

        }


        var toAdd = []
        for (let i = 0; i < selectedArt.length; i++) {

            toAdd.push({
                ...selectedArt[i],
                "level": level,
                "display": 1,
                "shipment_id": p.picking.shipment.id,
                "type": "Article",
                "picking_id": p.pickingId,
                "av": null,
                "machine": p.packing_list[search].machine,
                "rank": rank,
                "id": -1,
                "picking_id": forceString( selectedArt[i].source ).trim() + '' + selectedArt[i].id,
                qty: selectedArt[i].qte_initiale
            })

        }
        console.log(toAdd)

        setAdded({
            insertedPacking: insertedPacking,
            toAdd: toAdd,
            previousTab: "articles"
        });

        setTab("choose");
    }


    function getEndOfTree(arr, k, targetId) {
        if (k >= arr.length) return k;
        let found = false;
        //console.log("***")

        //Get the start of tree
        for (let i = k; i < arr.length; i++) {
            //console.log( "current ID: "+arr[i].id, "current father: " + parseInt( arr[i].father ), "target ID: "+ targetId, "found: " + found )
            if (parseInt(arr[i].id) === parseInt(targetId)) {
                k = i + 1;
                found = true;
                return getEndOfTree(arr, k, arr[i].id);
            } else if (parseInt(arr[i].id) !== -1 && parseInt(arr[i].father) === parseInt(targetId)) {
                k = i + 1;
                found = true;
                return getEndOfTree(arr, k, arr[i].id);
            } else if (parseInt(arr[i].id) === -1 && parseInt(arr[i].father) === parseInt(targetId)) {
                k = i + 1;
                found = true;
                for (let j = k; j < arr.length; j++) {
                    k += 1;
                    if (parseInt(arr[j].id) !== -1) return k - 1;
                }
            } else if (found && parseInt(arr[i].id) === -1) {
                // -1 is an article and not a tree  
                found = true;
                k = k + 1;
                for (let j = k; j < arr.length; j++) {
                    k += 1;
                    if (parseInt(arr[j].id) !== -1) return k;
                }
            } else if (found) {
                return k;
            }
        }

        return k;
    }

    const setElement = (v) => {
        var packing = [...p.packing_list];
        var content = packing[search].content ? [...packing[search].content] : [];
        var toAdd = added.toAdd;

        var level = parseInt(v.level) + 1;

        //If the level is -1 so add to root: simple case
        if (parseInt(v.level) === -1) {
            //If multiple articles to add loop it
            for (let i = 0; i < toAdd.length; i++) {
                var o = toAdd[i];
                o.level = 1; //Always level 1 and not 0
                o.father = v.id; //Save the father to link it
                content.push(o);
                packing[search].content = content;
                p.setPackingList(packing);
            }
        } else {
            //Get the key after what we'll add the new element
            var k = getEndOfTree(content, 0, v.id);
            //return

            var newContent = [...content.slice(0, k)];

            for (let i = 0; i < toAdd.length; i++) {
                var o = toAdd[i];
                o.father = v.id;
                o.level = level;
                newContent.push(o);
            }

            newContent = newContent.concat([...content.slice(k, content.length)]);

            packing[search].content = resetRank(newContent);
            p.setPackingList(packing);
        }

        setTab(added.previousTab);
        setAdded();
        setSelectedArt([]);
    }

    function resetRank(arr) {
        for (let i = 0; i < arr.length; i++) arr[i].rank = i
        return arr;
    }


    const removeArticle = (father, k) => {
        var packing = [...p.packing_list];

        //Remove entire tree
        var content = packing[father].content;
        var end = getEndOfTree(content, k, content[k].id) - 1;
        //console.log( k +"->"+ end + ": "  )

        // If one element is to remove or it's an article remove juste one row
        if (k === end || content[k].id === -1) {
            packing[father].content = removeAndReturnArray(packing[father].content, k);
        } else {
            packing[father].content = [...content.slice(0, k), ...content.slice(end + 1)]
        }

        p.setPackingList(packing);
    }

    const columns = getColumns();

    function getTag(v, k, currentLevel) {
        if (currentLevel === v.level - 1) {
            return "↳"
        } else if (currentLevel === v.level) {
            if (v.id === -1) return "";
            return "#" + v.id
        } else {
            return "";
        }
    }

    const Table = (param) => {
        var v = param.v;

        return <table className="table">
            <thead className="text-center">
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Qté</th>
                    <th scope="col">Article</th>
                    <th scope="col">Désignation</th>
                    <th scope="col">Référence</th>
                    <th scope="col">Type de colisage</th>
                    <th scope="col">Longueur</th>
                    <th scope="col">Largeur</th>
                    <th scope="col">Hauteur</th>
                    <th scope="col">Poids</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {v && v.content && v.content.map((v, k) => {
                    return <tr key={k}>

                        <td className="fw-bold">{getTag(v, k, 1)}</td>
                        <td className="fw-bold">{getTag(v, k, 2)}</td>
                        <td className="fw-bold">{getTag(v, k, 3)}</td>
                        <td>
                            <input className="form-control text-center" type="number" value={v.qty}
                                style={{ width: "80px" }} />
                        </td>
                        <td>{v.ar}</td>
                        <td>{v.label}</td>
                        <td>{v.ref}</td>
                        <td>{v.type}</td>
                        <td>
                            <input className="form-control text-center" type="number" defaultValue={v.dim1 || ""}
                            />
                        </td>
                        <td>
                            <input className="form-control text-center" type="number" defaultValue={v.dim2 || ""}
                            />
                        </td>
                        <td>
                            <input className="form-control text-center" type="number" defaultValue={v.dim3 || ""}
                            />
                        </td>
                        <td>
                            <input className="form-control text-center" type="number" defaultValue={v.weight || ""}
                            />
                        </td>
                        <td>
                            <img className="see-img w-30" src="/common/remove.png"
                                onClick={() => removeArticle(param.k, k)} />
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    const addElement = (k) => {
        setSearch(k);
    }

    return <Fragment>
        <h5 className="text-center">Liste de colisage {p.pickingId ? "n°" + p.pickingId : ""}</h5>

        {p.packing_list && p.packing_list.map((v, k) => {
            return <div className="card" key={"pack" + k}>
                <h5 className="fw-bold">{"#" + (k + 1) + ": " + v.type} (Dim: {v.pack_width} x {v.pack_height} x {v.truck_height} - Poids: {v.pack_weight})</h5>
                <Table v={v} k={k} />
                <div>
                    <button className="btn btn-secondary mt-3" onClick={() => addElement(k)}>Ajouter des éléments</button>
                </div>
            </div>
        })
        }



        <div className="text-center mt-3">
            <button className="btn btn-success" disabled={p.saving} onClick={() => p.saveOtherPicking(false)}>
                {p.saving && <ButtonWaiting />}
                Sauvegarder
            </button>
        </div>

        {search !== undefined && <SmallFrame close={() => { setSearch(); setAdded(); setSelectedArt([]) }} component={<div>
            <h5 className="text-center">Ajouter</h5>

            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class={"nav-link fw-bold text-dark " + (tab === "packing" ? "active" : "")}
                        onClick={() => changeTab("packing")}>Des emballages</a>
                </li>

                <li class="nav-item">
                    <a class={"nav-link fw-bold text-dark " + (tab === "articles" ? "active" : "")}
                        onClick={() => changeTab("articles")}>Des articles</a>
                </li>

                {added !== undefined &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "choose" ? "active" : "")}
                            onClick={() => changeTab("choose")}>Emplacement</a>
                    </li>
                }
            </ul>

            {(data !== undefined && data.length > 0 && tab !== "choose") && <Fragment>
                {selectedArt !== undefined && selectedArt.length > 0 &&
                    <div className="text-center mb-3">
                        <button className="btn btn-success" onClick={addArticle}>Insérer {selectedArt.length > 1 ? "ces articles" : "cet article"}</button>
                    </div>}
                <ReactTable columns={columns} data={data} origin="picking_ship_other" classes={"no-padding fixFstCol "} top0={true} />
            </Fragment>
            }

            {(tab === "choose" && added !== undefined) && <div>
                <h5 className="text-center">Où souhaitez-vous placer {added.toAdd.length > 1 ? "ces éléments" : "cet élément"} ?</h5>

                {added.insertedPacking.map((v, k) => {
                    return <div className="mb-3" style={{ marginLeft: (1 + (Math.max(parseInt(v.level), 1) - 1) * 50) + "px" }}>
                        <button className="btn btn-success text-start" style={{ width: "250px" }}
                            onClick={() => setElement(v)}>#{v.id} - {v.label}</button>
                    </div>
                })}
            </div>}

        </div>} />
        }
    </Fragment>

}

export default OtherPacking;