import Moment from "moment";
import { Fragment, useState } from "react";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";
import { ButtonWaiting, getDeviceType } from "../../../functions/PageAnimation";
import { forceString } from "../../../functions/StrFunctions";
import ShareFileDao from "../../../services/common/ShareFileDao";
import fahDao from "../../../services/common/fahDaoV2";
import { downloadTempFile } from "../../../services/functions/FilesProcess";
import { BusinessSearch, FahHeader } from "./FahHeader";
import { Absences, InstallerDay, InterimCharges, Location, RestaurantTicket, SubTotal, TotHoursClockedInDay, TotHoursWorkdInDay, TotHoursWorkdOrAssimilatedInDay, TotalSurcharges, TotalTotalSurcharges, YardBonus } from "./InstallerCalculation";
import { CustomToolTipInfo } from "../../../common/smallComponents.js/CustomToolTip";

const InstallerPC = (p) => {

    Moment.locale("fr");
    const [businessSearch, setBusinessSearch] = useState();
    const [minimalDisplay, setMinimalDisplay] = useState(true);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const emptyRow = {
        "no_row": 0,
        "registration": 0,
        "week": 0,
        "year": 0,
        "business": null,
        "activity": null,
        "function": null,
        "label": null,
        "monday_d_tee": null,
        "monday_n_tee": null,
        "monday_d_tsc": null,
        "monday_n_tsc": null,
        "monday_dat": null,
        "monday_dnt": null,
        "monday_dat_n": null,
        "monday_dnt_n": null,
        "tuesday_d_tee": null,
        "tuesday_n_tee": null,
        "tuesday_d_tsc": null,
        "tuesday_n_tsc": null,
        "tuesday_dat": null,
        "tuesday_dnt": null,
        "tuesday_dat_n": null,
        "tuesday_dnt_n": null,
        "wednesday_d_tee": null,
        "wednesday_n_tee": null,
        "wednesday_d_tsc": null,
        "wednesday_n_tsc": null,
        "wednesday_dat": null,
        "wednesday_dnt": null,
        "wednesday_dat_n": null,
        "wednesday_dnt_n": null,
        "thursday_d_tee": null,
        "thursday_n_tee": null,
        "thursday_d_tsc": null,
        "thursday_n_tsc": null,
        "thursday_dat": null,
        "thursday_dnt": null,
        "thursday_dat_n": null,
        "thursday_dnt_n": null,
        "friday_d_tee": null,
        "friday_n_tee": null,
        "friday_d_tsc": null,
        "friday_n_tsc": null,
        "friday_dat": null,
        "friday_dnt": null,
        "friday_dat_n": null,
        "friday_dnt_n": null,
        "saturday_d_tee": null,
        "saturday_n_tee": null,
        "saturday_d_tsc": null,
        "saturday_n_tsc": null,
        "saturday_dat": null,
        "saturday_dnt": null,
        "saturday_dat_n": null,
        "saturday_dnt_n": null,
        "sunday_d_tee": null,
        "sunday_n_tee": null,
        "sunday_d_tsc": null,
        "sunday_n_tsc": null,
        "sunday_dat": null,
        "sunday_dnt": null,
        "sunday_dat_n": null,
        "sunday_dnt_n": null,
        "total_d_tee": null,
        "total_n_tee": null,
        "total_d_tsc": null,
        "total_n_tsc": null,
        "total_dat": null,
        "total_dnt": null,
        "total_dat_n": null,
        "total_dnt_n": null,
        "machineList": null
    };

    const actCodes = [
        { value: '901', text: 'Gestion sur affaire' },
        { value: '903', text: 'Interface Client' },
        { value: '905', text: 'Protection industrielle' },
        { value: '906', text: 'Veille technologique' },
        { value: '910', text: 'Pré-étude / Av. Projet / Spéci' },
        { value: '912', text: 'Etudes générales/Architecture' },
        { value: '913', text: 'Etudes détaillées/Dossier/Prog' },
        { value: '914', text: 'Contrôle d\'études' },
        { value: '916', text: 'Mise à jour et notices' },
        { value: '925', text: 'Consultat/Négo./Achat/Suivi' },
        { value: '927', text: 'Contrôle de sous-traitance' },
        { value: '935', text: 'Fab/Reprises sur mach. outils' },
        { value: '940', text: 'Montage usine' },
        { value: '941', text: 'Câblage usine' },
        { value: '942', text: 'Essais / MàP unitaire usine' },
        { value: '943', text: 'Essais MàP ensemble usine' },
        { value: '945', text: 'Emballage/Manutention' },
        { value: '950', text: 'Montage/Câblage site' },
        { value: '951', text: 'Essais MàP site' },
        { value: '952', text: 'Maintenance' },
        { value: '953', text: 'Formation client' },
        { value: '954', text: 'Assistance/Dépannage sur site' },
        { value: '955', text: 'Support technique à distance' },
        { value: '956', text: 'Formation externe' },
        { value: '957', text: 'Formation scolaire' },
        { value: '958', text: 'Auto-formation' },
        { value: '959', text: 'Rédaction mémoire/rapport' },
        { value: '960', text: 'Formation interne' },
        { value: '961', text: 'Comptabilité' },
        { value: '962', text: 'Contrôle de gestion' },
        { value: '963', text: 'Juridique' },
        { value: '964', text: 'Administration' },
        { value: '965', text: 'Gestion du personnel' },
        { value: '966', text: 'Secrétariat' },
        { value: '967', text: 'Représentation du personnel' },
        { value: '975', text: 'Assurance qualité' },
        { value: '980', text: 'Commerce prospection siège' },
        { value: '981', text: 'Commerce prosp. en déplacement' },
        { value: '982', text: 'Commerce élaboration propale' },
        { value: '983', text: 'Commerce divers' },
        { value: '984', text: 'Commerce export' },
        { value: '985', text: 'Communication' },
        { value: '991', text: 'Maintenance informatique' },
        { value: '992', text: 'Entretien des locaux' },
        { value: '993', text: 'Rangement/entretien atelier' },
        { value: '994', text: 'Rangement stock général' }
    ]

    const addRow = () => {
        var arr = [...p.hours];
        arr.push({ ...p.emptyObj });
        p.setHours(arr);
    }

    const onBusinessClick = (e) => {
        setBusinessSearch({ row: e.target.getAttribute("data-key") });
    }

    const getFunction = (e) => {
        if (e.indexOf("/") !== -1) return e.split("/")[1].replace("}", "").trim();
        return e.trim();
    }

    /**
     * On hours element change
     * @param {*} e 
     * @returns 
     */
    const onElementChange = (e) => {
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("id");

        if (field.startsWith("total")) return;

        var arr = [...p.hours];
        arr[k][field] = e.target.value;

        //If change made on hours do calculation
        if (field !== "business" && field !== "activity" && field !== "function") {
            var f = field.substring(field.indexOf("_"), field.lenght);
            var tot = 0;
            for (const [key, value] of Object.entries(arr[k]))
                if (key.endsWith(f) && !key.startsWith("total_") && !isNaN(parseFloat(value)))
                    tot += parseFloat(value);

            arr[k]["total" + f] = tot;
        }

        p.setHours(arr);
    }

    /**
     * On absence element change
     */
    const onAbsChange = (e) => {
        var field = e.target.getAttribute("id");
        var o = { ...p.abs };
        o[field] = e.target.value;
        p.setAbs(o);
    }

    const addJustification = (e) => {
        var field = e.target.getAttribute("data-field");
        var o = { ...p.abs };
        o[field] = e.target.value;
        p.setAbs(o);
    }

    const removeRow = (e) => {
        var k = e.target.getAttribute("data-row");
        var arr = [...p.hours];

        if( parseInt(k) === 0 ){
            arr[0] = {...emptyRow};
        }else{
            arr = arrayRemoveElt(arr, k);
        }
        
        p.setHours(arr);
    }

    /**
     * 
     */
    const addFile = (e) => {
        if (e.target.files.length === 0) return;

        setUploading(true);
        ShareFileDao.uploadAnyWhere("fah", e.target.files).then(
            (response) => {
                setUploading(false);
                var o = { ...p.abs };
                var arr = [...o.files].concat(response.split(";"));
                o.files = arr;
                p.setAbs(o);
            }, (error) => {
                setUploading(false);
            });
    }

    /**
     * 
     */
    const downloadFiles = () => {
        
        setUploading(true);
        fahDao.downloadAttachments(p.abs.files, "Pièces jointes FAH").then(
            (response) => {
                setUploading(false);
                downloadTempFile(response)
            }, (error) => {
                setUploading(false);
            }
        )
    }

    const deleteFile = (k) => {
        //removeFile( "fah", p.abs.files[k] );

        var o = { ...p.abs };
        var arr = [...o.files];
        arr = arrayRemoveElt(arr, k);
        o.files = arr;
        p.setAbs(o);
    }

    return <Fragment>
        <div className="display-flex installer-fah" style={{ marginTop: p.ignoreTop ? "" : "-30px" }}>
            {(getDeviceType() !== "mobile" && !minimalDisplay) &&
                <div className="scroll-left cursorPointer"
                    onClick={() => document.getElementById("toScroll").scrollLeft -= 500}>
                    <div className="sign">&#60;</div>
                </div>
            }
 
            <div className="table-responsive" id="toScroll">
                <table className="fah_table table">
                    <thead>

                        <FahHeader week={p.week} year={p.year} weekDays={p.weekDays}
                            colSpan={minimalDisplay ? 1 : 7} rowSpan={2} summary={p.summary}
                            recalculateSchedule={p.recalculateSchedule} minimalDisplay={minimalDisplay}
                            setMinimalDisplay={setMinimalDisplay} userInfo={p.userInfo} />

                        <tr className="text-center no-change-bg">
                            {[...Array(8)].map((v, k) => {
                                return <Fragment key={"tee" + k}>
                                    {minimalDisplay && <td className="big-separator">TT</td>}
                                    {!minimalDisplay && <Fragment>
                                        <td>TeE<CustomToolTipInfo props={{ tooltipText: "Temps de travail en Entreprise" }}/></td>
                                        <td colSpan={2}>TsC<CustomToolTipInfo props={{ tooltipText: "Temps de travail sur chantier" }}/></td>
                                        <td colSpan={2}>DaT<CustomToolTipInfo props={{ tooltipText: "Temps de déplacement assimilé à du travail " }}/></td>
                                        <td colSpan={2} className="big-separator">DnT<CustomToolTipInfo props={{ tooltipText: "Temps de déplacement non assimilé à du travail" }}/></td>
                                    </Fragment>
                                    }

                                </Fragment>
                            })}
                        </tr>

                        {
                            p.hours.map((v, k) => {
                                return <tr key={"hours" + k}>

                                    <td className="big-separator">
                                        <div className="container" style={{ minWidth: "400px" }}>
                                            <div className="row align-items-start">
                                                {/** BUSINESS */}
                                                <div className="col-4" style={{ maxWidth: "110px" }}>
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control" id="business" placeholder="xxx"
                                                            style={{ minWidth: "110px" }} data-key={k} onClick={onBusinessClick}
                                                            value={v.business || ""} readOnly />
                                                        <label htmlFor="business">Code affaire</label>
                                                    </div>
                                                </div>

                                                {/** ACTIVITY */}
                                                <div className="col">
                                                    <div className="form-floating" style={{ marginLeft: "5px", width: "100%" }}>
                                                        <select className="form-select" id="activity" placeholder="xxx" onChange={onElementChange}
                                                            style={{ minWidth: "110px" }} data-key={k}
                                                            title={v.activity} value={forceString(v.activity).trim() || ""}>
                                                            <option value=""></option>
                                                            {
                                                                actCodes.map((v2, k2) => {
                                                                    return <option key={"actCode" + k + "_" + k2} value={v2.value}>
                                                                        {v2.value + ": " + v2.text}</option>
                                                                })
                                                            }
                                                        </select>

                                                        <label htmlFor="activity">Code act.</label>
                                                    </div>
                                                </div>

                                                {/** MACHINE */}
                                                <div className="col-4" style={{ maxWidth: "110px", paddingLeft: 0 }}>
                                                    {v.machineSearch && <div style={{ margin: "auto" }}><ButtonWaiting /></div>}

                                                    {
                                                        v.machineList &&
                                                        <div className="form-floating">
                                                            <select className="form-select" id="function" style={{ minWidth: "110px" }}
                                                                data-key={k} value={v.function || ""} onChange={onElementChange}>
                                                                <option value="">Choisis</option>
                                                                {
                                                                    v.machineList.map((v2, k2) => {
                                                                        return <option key={"mach" + k + "_" + k2}
                                                                            value={getFunction(v2.no_machine)}>{v2.no_machine}</option>
                                                                    })
                                                                }
                                                                <option value="211000">SAV</option>
                                                                <option value="000000">Divers</option>
                                                            </select>
                                                            <label htmlFor="function">Code fonct.</label>
                                                        </div>
                                                    }

                                                    {
                                                        (!v.machineList && v.function !== null) &&
                                                        <div className="form-floating mb-3" style={{ marginLeft: "5px" }}>
                                                            <input className="form-control" value={v.function} readOnly placeholder="xxx"></input>
                                                            <label>Code fonct.</label>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {!minimalDisplay && <div className="text-center">{v.label}</div>}
                                    </td>

                                    {[...Array(8)].map((v2, k2) => {
                                        return <InstallerDay key={"installDay" + k2} onElementChange={onElementChange} hours={p.hours} row={k}
                                            col={k2} weekDays={p.weekDays} removeRow={removeRow} minimalDisplay={minimalDisplay} />
                                    })}
                                </tr>
                            })
                        }

                        {
                            p.hours.length > 0 &&
                            <tr className="no-change-bg big-separator">
                                <td colSpan={59}>
                                    <button className="btn btn-outline-secondary"
                                        onClick={addRow}>Ajouter une ligne</button>
                                </td>
                            </tr>
                        }

                        <tr className="text-center no-change-bg">
                            <td className="big-separator text-end">Sous-total heures pointées</td>
                            {[...Array(8)].map((v, k) => {
                                return <SubTotal key={"a" + k} col={k} hours={p.hours} minimalDisplay={minimalDisplay} />
                            })}
                        </tr>

                        <tr className="text-center no-change-bg">
                            <td className="big-separator text-end">Total heures pointées / jour</td>
                            {[...Array(8)].map((v, k) => {
                                return <TotHoursClockedInDay key={"b" + k} col={k} hours={p.hours} minimalDisplay={minimalDisplay} />
                            })}
                        </tr>

                        <tr className="text-center no-change-bg">
                            <td className="big-separator text-end">Total heures travaillées / jour</td>
                            {[...Array(8)].map((v, k) => {
                                return <TotHoursWorkdInDay key={"c" + k} col={k} hours={p.hours} minimalDisplay={minimalDisplay} />
                            })}
                        </tr>

                        <tr className="text-center no-change-bg">
                            <td className="big-separator text-end" style={{ minWidth: "320px" }}>Total heures travaillées ou assimilées / jour</td>
                            {[...Array(8)].map((v, k) => {
                                return <TotHoursWorkdOrAssimilatedInDay key={"d" + k} col={k} hours={p.hours} err={p.err}
                                    minimalDisplay={minimalDisplay} />
                            })}
                        </tr>

                        <tr className="text-center no-change-bg">
                            <td className="big-separator text-end">Total majorations dimanches et fériés</td>
                            {[...Array(7)].map((v, k) => {
                                return <TotalSurcharges key={"e" + k} col={k} hours={p.hours}
                                    weekDays={p.weekDays} minimalDisplay={minimalDisplay} />
                            })}

                            <TotalTotalSurcharges hours={p.hours} weekDays={p.weekDays} minimalDisplay={minimalDisplay} />

                        </tr>

                        <tr className="text-center no-change-bg">
                            <td className="big-separator text-end">Motif absence</td>
                            {[...Array(8)].map((v, k) => {
                                return <Absences key={"f" + k} col={k} abs={p.abs} onAbsChange={onAbsChange} minimalDisplay={minimalDisplay} />
                            })}
                        </tr>

                        <tr className="text-center no-change-bg">
                            <td className="big-separator text-end">Lieux de travail</td>
                            {[...Array(8)].map((v, k) => {
                                return <Location key={"g" + k} col={k} abs={p.abs} onAbsChange={onAbsChange} minimalDisplay={minimalDisplay} />
                            })}
                        </tr>

                        {/** DELETED: SEE EMAIL CAROLINE ON 2024-01-26
                        <tr className="text-center yard">
                            <td className="big-separator text-end yard">Chantier France / Etranger ?</td>
                            {[...Array(8)].map((v, k) => {
                                return <Yard key={"h" + k} col={k} abs={p.abs} onAbsChange={onAbsChange} minimalDisplay={minimalDisplay} />
                            })}
                        </tr>

                         
                        <tr className="text-center yard">
                            <td className="big-separator text-end">Prime Eloignement ?</td>
                            {[...Array(8)].map((v, k) => {
                                return <Distance key={"i" + k} col={k} abs={p.abs} onAbsChange={onAbsChange} minimalDisplay={minimalDisplay} />
                            })}
                        </tr>
                        */}

                        <tr className={"text-center " + (minimalDisplay ? "d-none" : "")}>
                            <td className="big-separator text-end yard">Total Primes Chantier</td>
                            {[...Array(8)].map((v, k) => {
                                return <YardBonus key={"j" + k} col={k} abs={p.abs} onAbsChange={onAbsChange}
                                    hours={p.hours} weekDays={p.weekDays} />
                            })}
                        </tr>

                        <tr className={"text-center yard " + (minimalDisplay ? "d-none" : "")}>
                            <td className="big-separator text-end ticket">Droit Nb de Ticket restaurant</td>
                            {[...Array(8)].map((v, k) => {
                                return <RestaurantTicket key={"j" + k} col={k} abs={p.abs}
                                    onAbsChange={onAbsChange} hours={p.hours} />
                            })}
                        </tr>

                        {(p.userInfo && p.userInfo.focusInfo && p.userInfo.focusInfo.CDINT === "O") &&
                            <InterimCharges abs={p.abs} onAbsChange={onAbsChange} minimalDisplay={minimalDisplay} />
                        }

                        {/** DELETED: SEE EMAIL CAROLINE ON 2024-01-26 
                        <tr className="text-center exceptional-bonus">
                            <td className="big-separator text-end">Prime Exeptionnelle ?</td>
                            <ExceptionalBonus abs={p.abs} onAbsChange={onAbsChange} minimalDisplay={minimalDisplay} />
                        </tr>
                        */}
                    </thead>

                </table>
            </div>

            {(getDeviceType() !== "mobile" && !minimalDisplay) &&
                <div className="scroll-right cursorPointer"
                    onClick={() => document.getElementById("toScroll").scrollLeft += 500}>
                    <div className="sign">&#62;</div>
                </div>
            }

        </div>

        <br></br>

        {/** SUMMARY & ABSENCES */}
        <div className="table-responsive installer-fah">
            <table className="fah_table table" style={{ borderStyle: "solid", borderColor: "black" }}>
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={5} className="fw-bold" style={{ verticalAlign: "middle" }}>RESUME</td>
                        <td>Temps de travail effectif</td>
                        <td className={"text-center " + (p.err.work_time !== undefined && "error")} style={{ minWidth: "80px" }}>{p.summary.work_time.toFixed(1)} h</td>
                        <td rowSpan={5} style={{ verticalAlign: "middle" }}>
                            <div className="fw-bold">ABSENCES</div>
                            <div className="fw-bold fst-italic">Absences en récup à déduire pour la semaine</div>
                        </td>
                        <td>Total congés</td>
                        <td className="text-center" style={{ minWidth: "80px" }}>{p.summary.abs_tot_h.toFixed(1)} h / {p.summary.abs_tot_d.toFixed(1)} j</td>
                    </tr>

                    <tr>
                        <td>Temps assimilé</td>
                        <td className="text-center">{p.summary.wrk_or_ass.toFixed(1)} h</td>
                        <td className="text-end">Dont maladie</td>
                        <td className="text-center">{p.summary.abs_disease.toFixed(1)} j</td>
                    </tr>

                    <tr>
                        <td>Temps considéré comme du trajet</td>
                        <td className="text-center">{p.summary.move.toFixed(1)} h</td>
                        <td className="text-end">Dont Récup C2</td>
                        <td className="text-center">{p.summary.abs_c2.toFixed(1)} h (solde: {p.soldeC2 && p.soldeC2.toFixed(1)}h)</td>
                    </tr>

                    <tr>
                        <td>Heures excédentaires (***)</td>
                        <td className="text-center">{p.summary.exc_hours.toFixed(1)} h</td>
                        <td className="text-end" colSpan={2}></td>
                    </tr>
                </tbody>
            </table>
        </div>

        {/** JUSTIFICATIONS */}
        {
            (p.err.hasNight || p.err.hasExc || p.err.hasMove) &&
            <div className="table-responsive installer-fah">
                {
                    p.err.hasNight &&
                    <div className="form-floating mb-3">
                        <input type="text" className={"form-control " + ((p.abs.comment_night && p.abs.comment_night.trim()) === "" && "danger")} id="night"
                            placeholder="xx" data-field="comment_night" onBlur={addJustification} defaultValue={p.abs.comment_night} />
                        <label htmlFor="night">Préciser les horaires des heures de nuit pour chaque jour concerné :</label>
                    </div>
                }

                {
                    p.err.hasExc &&
                    <div className="form-floating mb-3">
                        <input type="text" className={"form-control " + ((p.abs.comment_exceed && p.abs.comment_exceed.trim()) === "" && "danger")} id="night"
                            placeholder="xx" data-field="comment_exceed" onBlur={addJustification} defaultValue={p.abs.comment_exceed} />
                        <label htmlFor="night">Préciser qui a donné l'accord et la date d'obtention de cet accord :</label>
                    </div>
                }

                {
                    p.err.hasMove &&
                    <div className="form-floating mb-3">
                        <input type="text" className={"form-control " + ((p.abs.comment_journey && p.abs.comment_journey.trim()) === "" && "danger")} id="night"
                            placeholder="xx" data-field="comment_journey" onBlur={addJustification} defaultValue={p.abs.comment_journey} />
                        <label htmlFor="night"> Justifier pourquoi le déplacement est assimilable à du travail effectif.
                         (Déplacement entre deux clients, déplacement après passage usine Gémenos, Rédaction RI,FAH,...) :</label>
                    </div>
                }

                {
                    p.err.hasOthCharges &&
                    <div className="form-floating mb-3">
                        <input type="text" className={"form-control " + ((p.abs.comment_oth_charges && p.abs.comment_oth_charges.trim()) === "" && "danger")} id="night"
                            placeholder="xx" data-field="comment_oth_charges" onBlur={addJustification} defaultValue={p.abs.comment_oth_charges} />
                        <label htmlFor="night">Détaillez la nature des autres frais mentionnés (précisez à quel pièce joint cela fait référence si possible) :</label>
                    </div>
                }


            </div>
        }


        {/** COUNTERS */}
        <div className="accordion mb-3">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        A PAYER OU CREDITER SUR COMPTEUR
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="table-responsive installer-fah">
                        <table className="table fah_table">
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>Heures excédentaires sur chantier à payer (à 100%)</td>
                                    <td className="big-separator text-center" style={{ minWidth: "80px" }}>{p.summary.p100.toFixed(1)} h</td>
                                    {/*<td>Heures excédentaires en entreprise à passer en récup. compteur C1 (à 100%)</td>
                                    <td className="big-separator text-center" style={{ minWidth: "80px" }}>{p.summary.c1_100.toFixed(1)} h</td>*/}
                                    <td colSpan={2} rowSpan={2} className="no-change-bg big-separator">
                                        TeE: Temps de travail en Entreprise / TsC: Temps de travail sur chantier<br></br>
                                        DaT: Temps de déplacement assimilé à du travail / DnT: Temps de déplacement non assimilé à du travail
                                    </td>
                                </tr>

                                <tr>
                                    <td>Heures excédentaires sur chantier à payer (à 125%)</td>
                                    <td className="big-separator text-center">{p.summary.p125.toFixed(1)} h</td>
                                    {/*<td>Heures supplémentaires en entreprise à passer en récup. compteur C1 (à 125%)</td>
                                    <td className="big-separator text-center">{p.summary.c1_125.toFixed(1)} h</td>*/}
                                </tr>

                                <tr>
                                    <td>Heures excédentaires sur chantier à payer (à 150%)</td>
                                    <td className="big-separator text-center">{p.summary.p150.toFixed(1)} h</td>
                                    {/*<td>Heures supplémentaires en entreprise à passer en récup. compteur C1 (à 150%)</td>
                                    <td className="big-separator text-center">{p.summary.c1_150.toFixed(1)} h</td>*/}
                                    <td>Heures de trajet à passer en récup. compteur C2 (à 100%)</td>
                                    <td className="big-separator text-center" style={{ minWidth: "80px" }}>{p.summary.c2_100.toFixed(1)} h</td>
                                </tr>

                                <tr>
                                    <td>Majorations heures de nuit sur chantier à payer (à 50%)</td>
                                    <td className="big-separator text-center">{p.summary.p50.toFixed(1)} h</td>
                                    {/*<td>Majorations heures de D et fériés en entreprise à passer en récup. compteur C1 (à 100%)</td>
                                    <td className="big-separator text-center">{p.summary.majD.toFixed(1)} h</td>*/}
                                    <td>Majorations heures de nuit en DnT à passer en récup. compteur C2 (à 25%)</td>
                                    <td className="big-separator text-center">{p.summary.c2_25.toFixed(1)} h</td>
                                </tr>

                                <tr>
                                    <td>Majorations heures de D et fériés sur chantier à payer (à 100%)</td>
                                    <td className="big-separator text-center">{p.summary.p_d_100.toFixed(1)} h</td>
                                    {/*<td className="no-change-bg big-separator" colSpan={2}></td>*/}
                                    <td>Majorations heures de trajet de D et fériés à passer en récup. compteur C2 (à 50%)</td>
                                    <td className="big-separator text-center">{p.summary.c2_50.toFixed(1)} h</td>
                                </tr>

                                <tr>
                                    <td className="text-end fw-bold">BRUT A PAYER</td>
                                    <td className="big-separator text-center">{p.summary.tot_p.toFixed(1)} h</td>
                                    {/*<td className="text-end fw-bold">TOTAL A CREDITER SUR COMPTEUR C1</td>
                                    <td className="big-separator text-center">{p.summary.tot_c1.toFixed(1)} h</td>*/}
                                    <td className="text-end fw-bold">TEMPS DE TRAJET A CREDITER SUR COMPTEUR RECUP</td>
                                    <td className="big-separator text-center">{p.summary.tot_c2.toFixed(1)} h</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        {/** ATTACHMENTS */}
        <div className="mb-3">
            <h6>Pièces jointes</h6>
            <div className="fst-italic">Partagez vos notes de frais, rapports d'interventions ou autre.
                Seuls les PDF sont acceptés. Vous pouvez sélectionner plusieurs fichiers à la fois.</div>
            {
                (!p.isAccountant && !p.isManager) &&
                <div className="input-group">
                    {!uploading &&
                        <input type="file" className="form-control" accept=".pdf" onChange={addFile} multiple />}

                    {uploading && <WaitingRoundSnippers />}
                </div>
            }

            {p.abs.files && p.abs.files.length > 0 &&
                <div>
                    <h6>Fichiers partagés</h6>
                    <ul className="list-group">
                        {p.abs.files.map((v, k) => {
                            return <li className="list-group-item" key={"file" + k}>
                                {v.substring(v.indexOf("_")+1, v.length)}
                                <button className="btn btn-outline-danger ms-3"
                                    onClick={()=>deleteFile(k)}>Supprimer</button>
                            </li>
                        })}
                        <li className="list-group-item">
                            <button className="btn btn-outline-secondary" onClick={downloadFiles}>Tout télécharger</button>
                        </li>
                    </ul>
                </div>}
        </div>


        {/** SEND BUTTONS */
            (!p.isAccountant ) && <Fragment>
                <div className="text-center mb-3">
                    {
                        !p.isManager &&
                        <button className="btn btn-outline-secondary" data-send="false" onClick={p.save} disabled={p.saving}>
                            {p.saving && <ButtonWaiting />}
                            Sauvegarder sans envoyer
                        </button>
                    }

                    <button className="btn btn-outline-success" data-send="true" style={{ marginLeft: "5px" }}
                        onClick={p.save} disabled={p.saving}>
                        {p.saving && <ButtonWaiting />}
                        {p.isManager ? "Valider" : "Envoyer"}
                    </button>
                </div>
            </Fragment>
        }




        {
            businessSearch && <BusinessSearch businessSearch={businessSearch}
                setBusinessSearch={setBusinessSearch} year={p.year} hours={p.hours} setHours={p.setHours} />
        }

    </Fragment>

}

export default InstallerPC;