import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import MachineInfo from "../../services/machines/MachineInfo";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting } from "../../functions/PageAnimation";
import { getCurrentDateTime, getFrFormat, hasRole } from "../../functions/StrFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SpeechRecognition from "../../common/smallComponents.js/SpeechRecognition";
import CustomSpeechRecognition from "../../common/smallComponents.js/SpeechRecognition";

const MachinePwd = (props) => {
    const [error, setError] = useState();
    const [result, setResult] = useState();
    const [counter, setCounter] = useState();
    const [hours, setHours] = useState();
    const { t } = useTranslation("business");
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(hasRole(["ROLE_ADMIN", "ROLE_MACHINE_PWD"]));//have to change
    const [tab, setTab] = useState(0);
    const [list, setList] = useState([]);
    const [mode, setMode] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [listen, setListen] = useState(false);

    useEffect(() => {
        if (hasRole(["ROLE_ADMIN", "ROLE_MACHINE_PWD"])) {
            MachineInfo.getMachinePwdList().then(
                (response) => {
                    setList(response);
                },
                (error) => { }
            );
        }
    }, [])

    const getPwd = (e) => {
        e.preventDefault();
        setError();

        var dwCode = e.target.dwCode.value;

        /*if (isNaN(dwCode)) {
            setError(t("wrong pwd"));
            return;
        }*/

        setLoading(true);
        MachineInfo.getMachinePwd(dwCode, mode).then(
            (response) => {
                setResult(response.code.toString());
                setCounter(response.counter);
                setHours(response.hours);
                setLoading(false);

                var arr = [{
                    machine: "ToDo",
                    counter: response.counter,
                    hours: response.hours,
                    author: user.id,
                    date: getCurrentDateTime('en')
                }]
                MachineInfo.savePwdLogInDataBase(arr)
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('machine', {
            header: 'Machine',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('counter', {
            header: 'Compteur',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('hours', {
            header: 'Nombre d\'heure d\'activité',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('username', {
            header: 'Technicien',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('date', {
            header: 'Date de génération pwd',
            cell: i => <div className="text-center fw-bold">{getFrFormat(i.getValue(), false)}</div>
        }));


        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <Fragment>
        {error && <ErrorMessage error={error} errorState={setError} />}

        {isAdmin && <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={"nav-link " + (tab === 0 ? "active" : "")}
                    onClick={() => setTab(0)} href="#">Génération de code</a>
            </li>
            <li className="nav-item">
                <a className={"nav-link " + (tab === 1 ? "active" : "")}
                    onClick={() => setTab(1)} href="#">Log</a>
            </li>
        </ul>}

        {tab === 0 && <Fragment>
            {!result && <Fragment>

                {!mode && <div className='text-center pt-5'>
                    {hasRole(["ROLE_ADMIN", "ROLE_MACHINE_OP", "ROLE_MACHINE_MAINT", "ROLE_MACHINE_ADMIN", "ROLE_MACHINE_SUP_ADMIN"]) &&
                        <div className='mb-3'>
                            <button className='pushable green mb-3' style={{ width: "230px" }}
                                onClick={() => setMode("OP")}>
                                <span className='front green'>Opérateur</span>
                            </button>
                        </div>
                    }

                    {hasRole(["ROLE_ADMIN", "ROLE_MACHINE_MAINT", "ROLE_MACHINE_ADMIN", "ROLE_MACHINE_SUP_ADMIN"]) &&
                        <div className='mb-3'>
                            <button className='pushable orange mb-3' style={{ width: "230px" }}
                                onClick={() => setMode("MAINT")}>
                                <span className='front orange'>Maintenance</span>
                            </button>
                        </div>
                    }{hasRole(["ROLE_ADMIN", "ROLE_MACHINE_ADMIN", "ROLE_MACHINE_SUP_ADMIN"]) &&
                        <div className='mb-3'>
                            <button className='pushable yellow mb-3' style={{ width: "230px" }}
                                onClick={() => setMode("ADMIN")}>
                                <span className='front yellow'>Admin</span>
                            </button>
                        </div>
                    }{hasRole(["ROLE_ADMIN", "ROLE_MACHINE_SUP_ADMIN"]) &&
                        <div className='mb-3'>
                            <button className='pushable red mb-3' style={{ width: "230px" }}
                                onClick={() => setMode("SUP_ADMIN")}>
                                <span className='front red'>Super Admin</span>
                            </button>
                        </div>
                    }

                </div>}

                {mode && <Fragment>

                    <h4 className="text-center mb-3">{t('type machine code')}</h4>

                    <form className="text-center" onSubmit={getPwd}>
                        <div className="ms-auto">
                            <input type="text" className="form-control ms-auto me-auto text-center fw-bold mb-3"
                                style={{ maxWidth: "500px", fontSize: "30px" }} name="dwCode" autoFocus required />
                        </div>
                        <button type="submit" className="btn btn-success" disabled={loading}>
                            {loading && <ButtonWaiting />}
                            {t('generate')}
                        </button>
                    </form>

                    <div className="text-center mt-3">
                        <button className="btn btn-outline-secondary"
                            onClick={() => setMode()}>Changer de profil</button>
                    </div>
                </Fragment>}

            </Fragment>}

            {result && <Fragment>
                <h4 className="text-center mb-3">{t('type this pwd')}</h4>

                <div className="d-flex flex-wrap">
                    {[...Array(result.length)].map((v, k) => {
                        return <div className={"bg-secondary-subtle me-1 p-2 border fw-bold fs-4 mb-3 "
                            + (k === 0 ? "ms-auto" : k === result.length - 1 ? "me-auto" : "")
                        } key={"nb" + k}>
                            {result.charAt(k)}
                        </div>
                    })
                    }
                </div>

                <div className="text-center fw-bold mb-3" style={{ fontSize: "25px" }}>
                    Compteur: {counter}
                </div>

                <div className="text-center fw-bold mb-3" style={{ fontSize: "25px" }}>
                    Heures: {hours}
                </div>

                <div className="text-center mb-3">
                    <button className="btn btn-outline-secondary" onClick={() => setResult()}>Try again</button>
                </div>

            </Fragment>}

        </Fragment>
        }

        {
            tab === 1 && <ReactTable columns={columns} data={list}
                origin="pwd_log" classes={"no-padding"} top0={true} />
        }



    </Fragment>

}

export default MachinePwd;