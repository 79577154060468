import { Fragment, useState } from "react";
import { fuzzySearch, highlightSubstring, highlightWords } from "../../functions/StrFunctions";

{/**
    contact={contact} setContact={setContact} chooseContact={chooseContact} addressBook={addressBook}
*/}
const AddressChoice = (p) => {
    const [contactRes, setContactRes] = useState([]);
    const [input, setInput] = useState("");

    const searchContact = (e) => {
        setContactRes(fuzzySearch(p.addressBook, e.target.value));
        setInput( e.target.value );
    }

    function highlightWd(word){
        return highlightSubstring(word, input)
    }

    return <Fragment>
        {p.contact && <div className="custom-tooltip-v2">
            <div className="modal-body xl blue-purple-bg">
                <button type="button" className="btn-close" onClick={() => p.setContact()}></button>
                <h5 className="text-center">carnet d'adresse</h5>

                <h6 className="text-center fst-italic">Remarque: cette action ne modifiera pas les données de la fiche affaire</h6>

                <div className="col-6 offset-3 mb-3">
                    <input className="form-control text-center fw-bold" onChange={searchContact}
                        placeholder="Saisissez la raison sociale, adresse ou nom du contact"
                        autoFocus />
                </div>

                <ul className="list-group">
                    {contactRes.length > 0 && <div className="overflow-y-auto" style={{ maxHeight: "500px" }}>

                        <table className="table">
                            <thead>
                                <tr className="table-info text-center fixed-header-zero">
                                    <th>Affaire</th>
                                    <th>Raison sociale</th>
                                    <th>Adresse</th>
                                    <th>Suite</th>
                                    <th>CP</th>
                                    <th>Ville</th>
                                    <th>Pays</th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactRes.map((v, k) => {
                                    return <tr key={"choose_ct" + k} onClick={(e) => p.chooseContact(v.key)}
                                        className="cursor-pointer bg-secondary-hover">
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.business)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.company)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.address)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.add_address)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.postal_code)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.compacityny)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.country)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.project_man_fn)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.project_man_ln)}}></td>
                                        <td dangerouslySetInnerHTML={{__html: highlightWd(v.project_man_ln)}}></td>
                                    </tr>

                                })}
                            </tbody>
                        </table>

                    </div>}

                </ul>

            </div>
        </div>}
    </Fragment>

}

export default AddressChoice;