import { Fragment, useState } from "react";

const Request = (props) => {
    const [hidden, setHidden] = useState(false);
    const [open, setOpen] = useState(true)

    const openMonday = () => {
        window.open("https://forms.monday.com/forms/e528ee6358dde26a08a8b94d362da5bb?r=use1", '_blank')
    }

    return !hidden && <div className="no-print cursor-pointer"
        title="Ticket intranet" id="request-btn" 
        /*onMouseEnter={()=>setOpen(true)} onMouseLeave={()=>setOpen(false)}*/
        style={{ position: "fixed", width: "60px", height: "60px", bottom: "5px", right: "5px" }}>
        
        <button type="button" className="btn-close border rounded-circle bg-secondary-subtle"
            id="close"
            onClick={() => setHidden(true)}></button>

        <div onClick={openMonday} id="ticketMonday" className={open ? "open" : "closed"}>
            { !open && <div className="txt">?</div> }
            { open && <div className="txt">Ticket</div> }
        </div>
    </div>

}

export default Request;