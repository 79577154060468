import { Fragment, useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ODM from "../../services/common/ODM";
import Select from "react-select";
import BusinessDao from "../../services/machines/BusinessDao";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { isValidDateTime } from "../../functions/FormCtrl";
import { confirmAlert } from "react-confirm-alert";
import getUrlParam from "../../functions/StrFunctions";
import { downloadTempFile } from "../../services/functions/FilesProcess";

const ODMRequest = (props) => {
    const [trigram, setTrigram] = useState([]);
    const [changeTrigram, setChangeTrigram] = useState(false);
    const [lastName, setLastName] = useState([]);
    const [firstName, setFirstName] = useState([]);
    const [odm, setOdm] = useState();
    const [pojo, setPojo] = useState();
    const [id, setId] = useState();
    const [downloading, setDownloading] = useState(false);
    const [persons, setPersons] = useState()
    
    const contentBlock = htmlToDraft(getDefaultMail());
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    useEffect( () => {
        if( id && odm ) {
            // Get the person and add it in the array
            var person = new Object;
            person.trigram = trigram.find( a => a.value === odm.trigram );
            person.lastName = lastName.find( a => a.value === odm.lastName );
            person.firstName = firstName.find( a => a.value === odm.firstName );
            setPersons( [person] );
        }
    }, [trigram, lastName, firstName])

    useEffect(() => {
        var id = getUrlParam(props, "id");

        if (id !== null) {
            setId(id);

            ODM.getOdmById(id).then(
                (response) => {                               
                    setOdm({ ...response.odm });
                    setPojo({ ...response.pojo });

                    const contentBlock = htmlToDraft(response.odm.mail);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(() => EditorState.createWithContent(contentState));

                    var t = new Array(); var l = new Array(); var f = new Array();

                    response.salaries.map((v, k) => {
                        t.push({ value: v.trigram, label: v.trigram, k: k });
                        l.push({ value: v.PNOM, label: v.PNOM, k: k });
                        f.push({ value: v.PPRENO, label: v.PPRENO, k: k });
                    })
                    setTrigram(t); setLastName(l); setFirstName(f);

                    setLoading(false);
                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                    scrollToTop();
                }
            );
        } else {
            ODM.getNewOdm().then(
                (response) => {
                    setOdm({ ...response.pojo });
                    setPojo({ ...response.pojo });

                    setPersons([{ trigram: "", firstName: "", lastName: "", done: false }]);

                    var t = new Array(); var l = new Array(); var f = new Array();
                    response.salaries.map((v, k) => {
                        t.push({ value: v.trigram, label: v.trigram, k: k });
                        l.push({ value: v.PNOM, label: v.PNOM, k: k });
                        f.push({ value: v.PPRENO, label: v.PPRENO, k: k });
                    }
                    )
                    setTrigram(t); setLastName(l); setFirstName(f);

                    setLoading(false);
                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                    scrollToTop();
                }
            );
        }


    }, []);

    function getDefaultMail() {
        var user = JSON.parse(localStorage.getItem("user"));
        if (user === null) errorManagement.needConnection(props);

        return "Bonjour,<br><br>"
            + "Veuillez trouver ci-joint une nouvelle demande de réservation liée à l’Ordre de mission numéro :  {ODM}<br>"
            + "Pour Mr :  {PERSON}<br>"
            + "Réservation : HÔTEL + AVION + TRAIN + VOITURE.<br><br>"
            + "Pour plus de détails merci de consulter l’ODM ci-joint.<br><br>"
            + "Merci de nous retourner l'ODM ci-joint avec votre réponse.<br><br>"
            + "Attention: ceci est un mail automatique, merci de ne pas répondre.<br>"
            + "Votre contact est:<br>"
            + user.last_name + " " + user.first_name + "<br>"
            + user.email + "<br><br>"
            + "Nous restons à votre disposition pour tout complément d’information.<br>"
            + "Nous vous remercions et vous souhaitons une très bonne journée.";
    }

    /**
     * On person choose
     */
    const onPersonChoose = (e, index) => {
        var newPersons = [...persons];
        newPersons[index].trigram = trigram[e.k];
        newPersons[index].lastName = lastName[e.k];
        newPersons[index].firstName = firstName[e.k];
        setPersons(newPersons);
    }

    /**
     * Change field
     */
    const updField = (field, val, row) => {
        var o = { ...odm };
        if (row !== undefined) {
            o.rows[row][field] = typeof val == "boolean" ? val : val.trim();
        } else {
            o[field] = val;
        }
        setOdm(o);
    }

    /**
     * Get business info
     */
    const getBusinessInfo = (e, k) => {
        var o = { ...odm };

        setError();
        if (e.target.value.trim() === "") {
            o.rows[k].business = null;
            setOdm(o);
            delete o.rows[k].businessConfirmed;
            delete o.rows[k].unknown;
            return;
        }


        if (o.rows[k].id_aff && o.rows[k].id_aff.toUpperCase() === e.target.value.toUpperCase()) return;

        o.rows[k].searching = true;
        delete o.rows[k].businessConfirmed;
        delete o.rows[k].unknown;
        setOdm(o);

        var basicCode = e.target.value.trim();
        basicCode = basicCode.substring(0, basicCode.length - 1) + "0";

        BusinessDao.getBusinessInfoApproximative(basicCode).then(
            (r) => {
                o = { ...o };
                o.rows[k].searching = false;
                o.rows[k].businessConfirmed = true;
                Object.assign(o.rows[k], r);
                o.rows[k].nom_chef_proj = r.nom_chef_proj + " " + r.prenom_chef_proj
                setOdm(o);
            }, (error) => {
                o = { ...o };
                o.rows[k].searching = false;
                o.rows[k].businessConfirmed = true;
                o.rows[k].unknown = true;
                setOdm(o);
            }
        )
    }

    /**
     * 
     */
    const addRow = () => {
        var o = { ...odm };
        o.rows.push({
            "departure": null,
            "arrival": null,
            "business": null,
            "societe_livrais": null,
            "adress_livrais": null,
            "adress_comp_livrais": null,
            "cp_livrais": null,
            "pays_livrais": null,
            "ville_livrais": null,
            "nom_resp_maint": null,
            "statut_resp_maint": null,
            "societe_resp_maint": null,
            "mobile_resp_maint": null,
            "fixe_resp_maint": null,
            "mail_resp_maint": null,
            "nom_chef_proj": null,
            "statut_chef_proj": null,
            "societe_chef_proj": null,
            "mobile_chef_proj": null,
            "fixe_chef_proj": null,
            "mail_chef_proj": null,
            "updAddress": false,
            "updRespMaint": false,
            "updCdp": false,
            "businessConfirmed": false
        });
        setOdm(o);
    }

    /**
     * 
     */
    const deleteRow = (k) => {
        var o = { ...odm };
        var arr = [...o.rows];
        arr = arrayRemoveElt(arr, k);
        o.rows = arr;
        setOdm(o);
    }



    /**
     * 
     */
    const submit = async (send) => {
        setError();
        setMsg();

        // Check mandatories fields
        var err = "";

        for (let i = 0; i < persons.length; i++) {
            if (!persons[i].trigram || !persons[i].firstName || !persons[i].lastName)
                err += "Veuillez saisir la personne concernée par cet ODM<br>";
        }

        var o;
        for (let i = 0; i < odm.rows.length; i++) {
            o = odm.rows[i];
            if (o.departure === null || o.arrival === null
                || !isValidDateTime(o.departure) || !isValidDateTime(o.arrival))
                err += "Veuillez saisir la date/heure de départ et arrivée à la ligne " + (i + 1) + "<br>";

            if (o.business === null || o.business.trim() === "")
                err += "Veuillez saisir le code affaire à la ligne " + (i + 1) + "<br>";
        }

        if (err !== "") {
            setError(err);
            scrollToTop();
            return;
        }

        var mail = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        o = { ...odm };
        o.mail = mail;

        if (send) {
            confirmAlert({
                title: "Confirmation",
                message: "Etes-vous sûr(e) de vouloir transmettre cet ODM à l'agence ?",
                buttons: [{
                    label: "Oui", onClick: () => {
                        save(o, send);
                    }
                }, { label: "Non" }]
            });
        } else {
            save(o, send);
        }

    }

    /**
     * 
     * @param {*} odm 
     * @param {*} send 
     */
    const save = async (odm, send) => {
        setSaving(true);
        for (let i = 0; i < persons.length; i++) {
            odm.trigram = persons[i].trigram
            odm.firstName = persons[i].firstName
            odm.lastName = persons[i].lastName

            var res = await ODM.saveOdm(odm, send, (!id ? "new" : "update"));
            if (res) {
                var person = [...persons]
                person[i].done = true
                setPersons(person)
            }
        }
        window.location.href = "/odm/list";
    }

    /**
     * Change person
     */
    const onChangePerson = () => {
        var o = { ...odm };
        o.oldTrigram = o.trigram;
        o.trigram = null;
        o.lastName = null;
        o.firstName = null;
        setOdm(o);
        setChangeTrigram(true);
    }




    /**
     * 
     */
    function download() {
        setDownloading(true);

        ODM.downloadOdm(odm).then(
            (r) => {
                downloadTempFile(r);
                setDownloading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setDownloading(false);
                scrollToTop();
            }
        )

    }

    const addPerson = () => {
        setPersons([...persons, { trigram: '', lastName: '', firstName: '', done: false }])

    }

    return <Fragment>
        {!id && <h5 className="text-center fw-bold">Nouvel Ordre De Mission</h5>}
        {id && <h5 className="text-center fw-bold">Ordre De Mission n°{id}</h5>}


        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            (id && odm) && <div className="cursor-pointer" title="Télécharger l'ODM en PDF"
                style={{ position: "absolute", marginBottom: "-10px" }}>
                {!downloading && <img src="/common/pdf_download.png" onClick={download}></img>}
                {downloading && <WaitingRoundSnippers />}
            </div>
        }

        {
            !loading && <Fragment>
                <div className="col-10 offset-1">
                    {persons && persons.map((person, index) =>
                        <div key={index} className="input-group mb-3">
                            { person.done && <img width="30px" src="\common\check.png"></img> }

                            <span className="input-group-text">Personne concernée</span>
                                    <Select options={trigram} className="fillInBlank" placeholder="Trigramme"
                                        onChange={(e) => { onPersonChoose(e, index); }} value={person.trigram} 
                                            isDisabled={ id && !changeTrigram }/>

                                    <Select options={lastName} className="fillInBlank" placeholder="Nom"
                                        onChange={(e) => onPersonChoose(e, index)} value={person.lastName}
                                            isDisabled={ id && !changeTrigram } />

                                    <Select options={firstName} className="fillInBlank" placeholder="Prénom"
                                        onChange={(e) => onPersonChoose(e, index)} value={person.firstName}
                                            isDisabled={ id && !changeTrigram } />
                            
                                { (id && !changeTrigram) && <button className="btn btn-outline-warning" 
                                    onClick={() => setChangeTrigram(true)}>
                                Modifier cette personne</button>}
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" checked={odm.personnalCar}
                                    onChange={() => updField("personnalCar", !odm.personnalCar)} />
                            </div>
                            <input type="text" className="form-control" style={{ minWidth: "250px" }}
                                value="Autoriser le véhicule personnel" readOnly></input>

                            {index > 0 && (
                                <button className="btn btn-danger" onClick={() => {
                                    const newPersons = persons.filter((_, i) => i !== index);
                                    setPersons(newPersons);
                                }}>Supprimer</button>
                            )}
                        </div>
                    )}

                    {!id && <button className="btn btn-primary" onClick={addPerson}>Ajouter une personne</button>}

                </div>

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Départ / arrivée</th>
                            <th scope="col">Affaire</th>
                            <th scope="col">Client / société</th>
                            <th scope="col">Adresse</th>
                            <th scope="col">Coordonnées resp. maintenance</th>
                            <th scope="col">Coordonnées chef de projet</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            odm && odm.rows.map((v, k) => {
                                return <tr key={"odm" + k}>
                                    <td scope="row">
                                        <input className="form-control" type="datetime-local" value={v.departure || ""}
                                            onChange={(e) => updField("departure", e.target.value, k)} /><br></br>
                                        <input className="form-control" type="datetime-local" value={v.arrival || ""}
                                            onChange={(e) => updField("arrival", e.target.value, k)} />
                                    </td>
                                    <td>
                                        <input className="form-control" type="text" value={v.business || ""}
                                            onBlur={(e) => getBusinessInfo(e, k)}
                                            onChange={(e) => updField("business", e.target.value.toUpperCase(), k)} />
                                        {
                                            v.unknown && <div className="text-danger" style={{ maxWidth: "250px" }}>
                                                Affaire {v.business} non trouvée dans les FM.
                                                Vous pouvez poursuivre si vous confirmez ce code.</div>
                                        }
                                    </td>
                                    {
                                        !v.businessConfirmed &&
                                        <td colSpan="4">{v.searching && <ButtonWaiting />}</td>
                                    }
                                    {
                                        v.businessConfirmed && <Fragment>
                                            <td>
                                                <input className="form-control" type="text"
                                                    value={v.societe_livrais || ""} onChange={(e) => updField("societe_livrais", e.target.value, k)} /></td>
                                            <td>
                                                <input className="form-control" type="text" placeholder="Adresse"
                                                    value={v.adress_livrais || ""} onChange={(e) => updField("adress_livrais", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Adress comp."
                                                    value={v.adress_comp_livrais || ""} onChange={(e) => updField("adress_comp_livrais", e.target.value, k)} />

                                                <input className="form-control" type="text" placeholder="Code postal"
                                                    value={v.cp_livrais || ""} onChange={(e) => updField("cp_livrais", e.target.value, k)} />

                                                <input className="form-control" type="text" placeholder="Ville"
                                                    value={v.ville_livrais || ""} onChange={(e) => updField("ville_livrais", e.target.value, k)} />

                                                <input className="form-control" type="text" placeholder="Pays"
                                                    value={v.pays_livrais || ""} onChange={(e) => updField("pays_livrais", e.target.value, k)} />

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                        checked={v.updAddress} onChange={() => updField("updAddress", !v.updAddress, k)} />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Demande MAJ FA
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <input className="form-control" type="text" placeholder="Nom"
                                                    value={v.nom_resp_maint || ""} onChange={(e) => updField("nom_resp_maint", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Statut"
                                                    value={v.statut_resp_maint || ""} onChange={(e) => updField("statut_resp_maint", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Société"
                                                    value={v.societe_resp_maint || ""} onChange={(e) => updField("societe_resp_maint", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Mobile"
                                                    value={v.mobile_resp_maint || ""} onChange={(e) => updField("mobile_resp_maint", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Fixe"
                                                    value={v.fixe_resp_maint || ""} onChange={(e) => updField("fixe_resp_maint", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Mail"
                                                    value={v.mail_resp_maint || ""} onChange={(e) => updField("mail_resp_maint", e.target.value, k)} />

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                        checked={v.updRespMaint} onChange={() => updField("updRespMaint", !v.updRespMaint, k)} />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Demande MAJ FA
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <input className="form-control" type="text" placeholder="Nom"
                                                    value={v.nom_chef_proj || ""} onChange={(e) => updField("nom_chef_proj", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Statut"
                                                    value={v.statut_chef_proj || ""} onChange={(e) => updField("statut_chef_proj", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Société"
                                                    value={v.societe_chef_proj || ""} onChange={(e) => updField("societe_chef_proj", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Mobile"
                                                    value={v.mobile_chef_proj || ""} onChange={(e) => updField("mobile_chef_proj", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Fixe"
                                                    value={v.fixe_chef_proj || ""} onChange={(e) => updField("fixe_chef_proj", e.target.value, k)} />
                                                <input className="form-control" type="text" placeholder="Mail"
                                                    value={v.mail_chef_proj || ""} onChange={(e) => updField("mail_chef_proj", e.target.value, k)} />

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                        checked={v.updCdp} onChange={() => updField("updCdp", !v.updCdp, k)} />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Demande MAJ FA
                                                    </label>
                                                </div>
                                            </td>
                                        </Fragment>
                                    }

                                    <td className="text-center">
                                        {k > 0 && <img src="/common/remove_icon.png" style={{ width: "30px" }} onClick={() => deleteRow(k)} />}
                                    </td>

                                </tr>
                            })
                        }

                    </tbody>
                </table>

                {odm.rows.length < 4 &&
                    <button className="btn btn-outline-secondary mb-3" onClick={addRow}>Ajouter une ligne</button>
                }


                <h6>Corps du mail à envoyer</h6>

                <Editor editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="draftEditor-wrapper" editorClassName="draftEditor-editor" />

                <div className="mt-3 mb-3 text-center">
                    <button className="btn btn-outline-warning me-4" onClick={() => submit(true)} disabled={saving}>
                        {saving && <ButtonWaiting />}
                        Envoyer à l'agence</button>
                    <button className="btn btn-outline-success" onClick={() => submit(false)} disabled={saving}>
                        {saving && <ButtonWaiting />}
                        Sauvegarder sans envoyer</button>
                </div>

            </Fragment>
        }

    </Fragment>

}

export default ODMRequest;