import { useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { useEffect } from "react";
import getUrlParam from "../../functions/StrFunctions";
import ShareFileDao from "../../services/common/ShareFileDao";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import axios from "axios";
import { saveAs } from 'file-saver';
import { ProgressBar } from "react-bootstrap";

const DownloadFiles = (props) => {
    const { t } = useTranslation('shareFiles');
    const [share, setShare] = useState();
    const [error, setError] = useState();
    const [files, setFiles] = useState();
    const [id, setId] = useState();
    const API_URL = process.env.REACT_APP_API_URL + "common/share-file";
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user && "\"Bearer " + user.accessToken + "\"";
    const [downloading, setDownloading] = useState(false);

    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(0)

    useEffect(() => {
        var id = getUrlParam(props, "param");
        ShareFileDao.getShareV2(id).then(
            (response) => {
                setShare(response);
                var arr = response.files.split("</>");
                setFiles(arr.slice(0, arr.length - 1));
                setId(response.id);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }, []);

    const download = async (k) => {
        setDownloading(true);

        var content = share.content[k];
        var url = API_URL + "/public/download/v2?folder=" + content.name + "&file=";
        const blobs = [];
        var extension = content.files[0];
        extension = extension.substring( extension.indexOf(".")+1, extension.length ).replace(/[0-9]/g, '');

        var sortedArr = content.files.sort( sortByNumberSuffix );

        for (let i = 0; i < sortedArr.length; i++) {
            setProgress( Math.round( (i / sortedArr.length ) * 100 ) );
            const response = await axios.get(url + sortedArr[i], {
                responseType: 'blob',
            });
            blobs.push(response.data);
        }

        // Merge the blobs into a single blob
        const mergedBlob = new Blob(blobs);

        // Save the merged blob as a file
        saveAs(mergedBlob, content.name + '.' + extension);
        setDownloading(false);
    }

    function sortByNumberSuffix(a, b) {
        //Extract number between _ and extension
        var numA = a.substring( a.lastIndexOf("_") + 1, a.length )
        numA = parseInt(numA.substring( 0, numA.indexOf(".") )); 
    
        var numB = b.substring( b.lastIndexOf("_") + 1, b.length )
        numB = parseInt(numB.substring( 0, numB.indexOf(".") )); 

        return numA - numB; // Compare the extracted numbers
      }

    return (
        <Fragment>
            {(!share && !error) && <WaitingRoundSnippers />}

            {error && <ErrorMessage error={error} />}

            {
                share &&
                <div className="card col-6 offset-3">
                    <div className="card-body">
                        <h5 className="card-title text-center">{t('download files')}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">{t('shared by') + " " + share.last_name + " " + share.first_name}</h6>
                        <h6 className="card-subtitle mb-2 text-muted">{t('expire on') + " " + share.expiration}</h6>

                        <p className="card-text">{t('shared files')}</p>

                        {/*<ul className="list-group">
                            {   files &&
                                    files.map(
                                        (v, k) => 
                                            <li className="list-group-item" key={"k"+k}>{v}</li>
                                    )
                            }
                        </ul>
                        <br></br>

                        <div className="text-center">
                            <a href={API_URL + "/public/download?param=" + id} target="_blank" className="btn btn-success">{t('download')}</a>
                        </div>*/}

                        { downloading && <div className="mb-3">
                            <h6 className="text-center">Downloading</h6>
                            <ProgressBar animated now={progress} label={`${progress}%`} />
                        </div>}

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('name')}</th>
                                    <th>{t('length')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {share.content.map((v, k) => {
                                    return <tr key={k}>
                                        <td>{v.name}</td>
                                        <td>{Math.round(v.size / 1000)} KB</td>
                                        <td>
                                            <button className="btn btn-success" disabled={downloading}
                                                onClick={() => download(k)}>
                                                    {downloading && <ButtonWaiting />}
                                                    {t('download')}
                                                </button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }


        </Fragment>
    )

}

export default DownloadFiles;