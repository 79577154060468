import { Fragment, useEffect, useState } from "react";
import usersDao from "../../services/settings/usersDao";
import errorManagement from "../../services/errorManagement";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { useTranslation } from "react-i18next";
import { differentPrevious, hasLowercase, hasNumber, hasSpecialChar, hasUppercase, pwdLength, validatePassword } from "../../functions/StrFunctions";

const FocusLicense = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const [list, setList] = useState();
    const [userInfo, setUserInfo] = useState();
    const [isUsing, setIsUsing] = useState(false);
    const [toBook, setToBook] = useState();
    const [isValidatePwd, setIsValidatePwd] = useState(false);
    const [pwdConditions, setPwdConditions] = useState({
        pwdLength: false, upper:false, lower: false, number: false, special: false, differentPrevious: true
    });

    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user && user.roles.includes("ROLE_ADMIN");

    const { t } = useTranslation();

    useEffect(() => {
        usersDao.getFocusLicenses(isAdmin).then(
            (response) => {
                setList(response.list);
                setUserInfo(response.user);
                setLoading(false);

                if (!isAdmin) {
                    for (let i = 0; i < response.list.length; i++) {
                        if (response.user.ZN11PP.trim().toUpperCase() === response.list[i].id.trim().toUpperCase()) {
                            setIsUsing(true);
                            break;
                        }
                    }
                }

            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }, []);

    /**
     * 
     * @param {*} k 
     */
    const liberate = (k) => {
        setLoading(true);

        usersDao.liberateFocusLicenses(list[k].id).then(
            () => {
                setMsg("Compte libéré essayez de vous connecter à nouveau.");
                setLoading(false);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    /**
     * 
     * @param {*} k 
     */
    const book = (e) => {
        e.preventDefault();
        setError()        ;
        var password = e.target.pwd.value;
        var code = list[toBook].code;

        if( !validatePassword(password, list[toBook].previous_pwd) ) return;
        if( userInfo.previous_pwd && userInfo.previous_pwd.toUpperCase() === password.toUpperCase() ){
            setError(t('pwd-diff'));
            return;
        }

        setLoading(true);

        var prev_pwd = [password];
        //Get the last password
        for( let i = 0; i <  Math.min( list[toBook].previous_pwd.length, 3 ); i++ ) 
            prev_pwd.push( list[toBook].previous_pwd[i] );
        
        usersDao.bookFocusLicenses(userInfo, code, password.toUpperCase(), prev_pwd).then(
            () => {
                setMsg("Licence réservée, rendez-vous sur Focus.<br><strong>" + t('password-expiration') + "</strong>");
                setLoading(false);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    /**
     * 
     */
    const block = (k) => {
        setLoading(true);

        usersDao.blockFocusLicenses(list[k].code).then(
            () => {
                setMsg("Licence bloquée.");
                setLoading(false);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    const checkPwd = (pwd) => {
        setIsValidatePwd(validatePassword(pwd, list[toBook].previous_pwd));
        setPwdConditions({
            pwdLength: pwdLength(pwd), 
            upper:hasUppercase(pwd), 
            lower: hasLowercase(pwd), 
            number: hasNumber(pwd), 
            special: hasSpecialChar(pwd),
            differentPrevious: differentPrevious(pwd, list[toBook].previous_pwd)
        })

    }

    const sendTeamsMsg = (email) => {
        var message = 'Bonjour, ';
        message += "Pourrais-tu libérer ton compte Focus afin que je l'utilise stp ?";
        var url = `https://teams.microsoft.com/l/chat/0/0?users=${encodeURIComponent(email.toLowerCase())}&message=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    }

    return <Fragment>
        <h5 className="text-center">Gestion des licences Focus</h5>

        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            (list && !toBook) && <div className="col-8 offset-2">
                <table className="table">
                    <thead>
                        <tr>
                            <th>N° de licence</th>
                            <th>Utilisateur connecté</th>
                            <th>Date de connexion</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            list.map((v, k) => {
                                return v.code !== -1 && <tr key={"l" + k} className={userInfo.ZN11PP && userInfo.ZN11PP.trim().toUpperCase() === v.id ?
                                    "table-success fw-bold" : ""}>
                                    <td>{v.code}</td>
                                    <td>{v.name_}</td>
                                    <td>{v.last_con || "Non connecté(e)"}</td>
                                    <td>
                                        {(!isUsing && !isAdmin && v.last_con === null) &&
                                            <button className="btn btn-outline-success"
                                                onClick={() => setToBook(k)} disabled={loading} >
                                                {loading && <ButtonWaiting />}Réserver</button>}

                                        {userInfo.ZN11PP && userInfo.ZN11PP.trim().toUpperCase() === v.id &&
                                            <div>
                                                <button className="btn btn-outline-secondary me-3"
                                                    onClick={() => liberate(k)} disabled={loading}>
                                                    {loading && <ButtonWaiting />}
                                                    Libérer
                                                </button>

                                                <button className="btn btn-outline-secondary"
                                                    onClick={() => setToBook(k)} disabled={loading}>
                                                    {loading && <ButtonWaiting />}
                                                    Changer mot de passe
                                                </button>
                                            </div>
                                        }

                                        {userInfo.ZN11PP && userInfo.ZN11PP.trim().toUpperCase() !== v.id &&
                                            <div>
                                                <button className="btn btn-outline-secondary me-3"
                                                    onClick={() => sendTeamsMsg(v.mail)} disabled={loading}>
                                                    {loading && <ButtonWaiting />}
                                                    Demander la libération du compte
                                                </button>
                                            </div>

                                        }

                                        {
                                            isAdmin && <button className="btn btn-outline-danger"
                                                onClick={() => block(k)} disabled={loading}>
                                                {loading && <ButtonWaiting />}
                                                Bloquer
                                            </button>
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        }

        {
            toBook !== undefined && <div className="card" style={{width: "1000px"}}>
                <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={() => setToBook()}></button>
                <div className="card-body">
                    <h5 className="text-center">Vous allez réserver la licence n°{list[toBook].code}</h5>

                    <h6 className="text-center">{t('password-choose')}</h6>
                    <p className="fw-bold text-center">{t('password-rule')}</p>

                    <form onSubmit={book}>
                        <input type="password" className="form-control mb-3" name="pwd"
                            onChange={(e) => checkPwd(e.target.value)} required></input>

                        <div className="d-flex">
                            <div className={ pwdConditions.pwdLength ? "text-success" : "text-danger"}>
                                { pwdConditions.pwdLength && <img src="/common/check.png" className="see-img w-30"/> }
                                { !pwdConditions.pwdLength && <img src="/common/cross.png" className="see-img w-30"/> }
                                {t('pwd-12')}
                            </div>
                            
                            <div className={ pwdConditions.upper ? "text-success" : "text-danger"}>
                                { pwdConditions.upper && <img src="/common/check.png" className="see-img w-30"/> }
                                { !pwdConditions.upper && <img src="/common/cross.png" className="see-img w-30"/> }
                                {t('pwd-upper')}
                            </div>
                            
                            <div className={ pwdConditions.lower ? "text-success" : "text-danger"}>
                                { pwdConditions.lower && <img src="/common/check.png" className="see-img w-30"/> }
                                { !pwdConditions.lower && <img src="/common/cross.png" className="see-img w-30"/> }
                                {t('pwd-lower')}
                            </div>
                            
                            <div className={ pwdConditions.number ? "text-success" : "text-danger"}>
                                { pwdConditions.number && <img src="/common/check.png" className="see-img w-30"/> }
                                { !pwdConditions.number && <img src="/common/cross.png" className="see-img w-30"/> }
                                {t('pwd-num')}
                            </div>
                            
                            <div className={ pwdConditions.special ? "text-success" : "text-danger"}>
                                { pwdConditions.special && <img src="/common/check.png" className="see-img w-30"/> }
                                { !pwdConditions.special && <img src="/common/cross.png" className="see-img w-30"/> }
                                {t('pwd-spe')}
                            </div>
                            
                            <div className={ pwdConditions.differentPrevious ? "text-success" : "text-danger"}>
                                { pwdConditions.differentPrevious && <img src="/common/check.png" className="see-img w-30"/> }
                                { !pwdConditions.differentPrevious && <img src="/common/cross.png" className="see-img w-30"/> }
                                {t('pwd-diff')}
                            </div>
                        </div>

                        {isValidatePwd &&
                            <div className="text-center mt-3">
                                <button type="submit" className="btn btn-outline-success" disabled={loading}>
                                    {loading && <ButtonWaiting />}Réserver</button>
                            </div>}
                    </form>
                </div>
            </div>
        }

    </Fragment>

}

export default FocusLicense;