import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt, removeEltArray, updateSimpleArray } from "../../functions/ArrayFunctions";
import { getDeviceType, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { getEnFormat, getFrFormat } from "../../functions/StrFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import ticketsDao from "../../services/qhse/ticketsDao";
import contactsDao from "../../services/settings/contactsDao";


const VisitForm = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [phone, setPhone] = useState();
    const [visitors, setVisitors] = useState([{ name: "", mail: "", company: "", send: false }]);
    const [meetingRoom, setMeetingRoom] = useState();
    const [meetingRoomLoc, setMeetingRoomLoc] = useState();
    const [arrival, setArrival] = useState();
    const [departure, setDeparture] = useState();
    const [company, setCompany] = useState();
    const [meal, setMeal] = useState();
    const [comments, setComments] = useState();
    const [particularMeal, setParticularMeal] = useState();
    const [nbMeal, setNbMeal] = useState();
    const [typeVisitor, setTypeVisitor] = useState();
    const [motif, setMotif] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [ct, setCt] = useState([]);
    const [period, setPeriod] = useState({ active: false, frequency: "", occurrence: 1 });
    const [othersHost, setOthersHosts] = useState([]);

    const [sendOutlook, setSendOutlook] = useState(false);
    const [recipient, setRecipient] = useState([]);
    const [lang, setLang] = useState("fr");
    const [msg, setMsg] = useState(getMsg());

    const [id, setId] = useState(false);

    function getMsg() {
        var html;

        if (lang === "fr") {
            html = 'Bonjour ';

            if (visitors.length === 1) {
                html += visitors[0].name
            } else {
                html += "à tous"
            }

            html += ",\n\nJ'espère que vous allez bien.\n\n";
            html += "Je vous invite à participer à une réunion concernant " + motif + ". Celle-ci se tiendra ";
            html += "le " + getFrFormat(arrival) + ".\n\n";
            html += "N'hésitez pas à me faire part de vos disponibilités si cette date ne vous convient pas ou si vous avez ";
            html += "des points supplémentaires à ajouter à l'ordre du jour.\n\n";
            html += "Merci d'avance pour votre présence.\n\n";
            html += "Cordialement,\n\n";
        } else {
            html = 'Hello ';

            if (visitors.length === 1) {
                html += visitors[0].name
            } else {
                html += "everyone"
            }

            html += ",\n\nI hope this message finds you well.\n\n";
            html += "I would like to invite you to a meeting regarding " + motif + ". It will take place ";
            html += "on " + getEnFormat(arrival) + ".\n\n";
            html += "Please let me know if this date does not suit you or if you have any additional points to add to the agenda.\n\n";
            html += "Thank you in advance for your attendance.\n\n";
            html += "Best regards,\n\n";
        }


        html += user.last_name + " " + user.first_name;

        return html;
    }

    useEffect(() => {
        setMsg(getMsg);
    }, [lang, arrival, motif, visitors]);

    useEffect(() => {
        CheckConnDao.internal().then(
            (response) => { },
            (error) => { errorManagement.handleError(props, error); });

        contactsDao.getAllContacts().then(
            (r) => { setCt(r) },
            (err) => { console.err(err) }
        )

        //get user number if exists
        setPhone(user && (user.phone_nb === null ? "" : user.phone_nb));

        //Check if there is a ID you'll update
        var id = getUrlParam(props, "id");
        setId(id);

        if (id !== null) {
            ticketsDao.getTicket(id).then(
                (response) => {
                    setLoading(false);
                    setPhone(response.tel);
                    setArrival(response.arrival);
                    setDeparture(response.departure);
                    var arr = response.visitors.split(";");
                    var visitors = [];

                    for (let i = 0; i < arr.length; i++) {
                        var o = { name: '', mail: '', send: false }
                        if (arr[i].indexOf("#") === -1) { o.name = arr[i] }
                        else { 
                            var split = arr[i].split("#");
                            o.name = split[0]; 
                            o.mail = split[1];
                            o.company = split.length >= 3 ? split[2] : "";
                        }
                        visitors.push(o);
                    }

                    setVisitors(visitors.slice(0, arr.length - 1));
                    setCompany(response.company);
                    setMeetingRoom(response.meeting);
                    setMeetingRoomLoc(response.meeting_location);
                    setMeal(response.meal);
                    setNbMeal(response.nb_meal);
                    setParticularMeal(response.particular_meal);
                    setComments(response.comments);
                    setTypeVisitor(response.typeVisitor);
                    setMotif(response.motif);
                    setOthersHosts( response.otherHosts.split(",") )
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );
        } else {
            setLoading(false);
        }
    }, []);

    const onDepartureChange = (e) => {
        setDeparture(e.target.value);
    }

    const onArrivalChange = (e) => {
        setArrival(e.target.value);
    }

    const addVisitor = () => {
        var arr = [...visitors];
        arr.push({ name: "", mail: "", company: "", send: false });
        setVisitors(arr);
    }

    const removeVisitor = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var arr = [...visitors];
        arr = arrayRemoveElt(arr, key);
        setVisitors(arr);
    }

    const updateVisitor = (e, field) => {
        var key = parseInt(e.target.getAttribute("data-key"));
        var value = e.target.value;
        var arr = [...visitors];
        arr[key][field] = value;
        setVisitors(arr);

        
    }

    const refreshCompany = (e) => {
        var value = e.target.value;

        if(!company || company.indexOf( value ) === -1 )
            setCompany( !company ? value : company + ", " + value);
    }

    const updateMeetingRoom = (e) => {
        var value = parseInt(e.target.value);
        setMeetingRoom(value)
    }

    const onCompanyChange = (e) => {
        setCompany(e.target.value)
    }

    const onMeetingRoomLoc = (e) => {
        setMeetingRoomLoc(e.target.value)
    }

    const onMealChange = (e) => {
        setMeal(parseInt(e.target.value));
    }

    const onParticularMealChange = (e) => {
        setParticularMeal(e.target.value);
    }

    const onCommentChange = (e) => {
        setComments(e.target.value);
    }

    const onNbMealChange = (e) => {
        setNbMeal(e.target.value);
    }

    const onMotifChange = (e) => {
        setMotif(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError();
        if (!isValidPhoneNumber(phone)) {
            setError("Merci de saisir un numéro de téléphone valide.");
            scrollToTop();
            return;
        }

        var a = new Date(arrival);
        var d = new Date(departure);
        var current = new Date();

        if (d.getTime() < a.getTime()) {
            setError("La date de départ ne peut pas être antérieure à la date d'arrivée.");
            scrollToTop();
            return;
        } else if (d.getTime() < current.getTime()) {
            setError("La date ou l'heure de départ est antérieure à la date du jour.");
            scrollToTop();
            return;
        }

        setLoading(true);

        //Save the phone number
        user.phone_nb = phone;
        localStorage.setItem("user", JSON.stringify(user));

        if (id === null) {
            ticketsDao.saveVisit(phone, arrival, departure, visitors, company,
                meetingRoom, meetingRoomLoc, meal, particularMeal, comments, nbMeal, motif, typeVisitor,
                msg, sendOutlook, period, othersHost).then(

                    (response) => {
                        window.location.href = "/qhse/visit/list";
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        scrollToTop();
                        setLoading(false);
                    }

                );
        } else {
            ticketsDao.saveUpdateVisit(phone, arrival, departure, visitors, company,
                meetingRoom, meetingRoomLoc, meal, particularMeal, comments, nbMeal, motif, typeVisitor, id, othersHost).then(

                    (response) => {
                        window.location.href = "/qhse/visit/list";
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        scrollToTop();
                        setLoading(false);
                    }

                );
        }
    }

    const cancelVisit = (e) => {
        e.preventDefault();

        confirmAlert({
            title: "Annulation de visite",
            message: "Etes-vous sûr de vouloir annuler cette visite ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        ticketsDao.cancelVisit(phone, arrival, departure, visitors, company,
                            meetingRoom, meetingRoomLoc, meal, particularMeal, comments, nbMeal, id).then(

                                (response) => {
                                    window.location.href = "/qhse/visit/list";
                                },
                                (error) => {
                                    setError(errorManagement.handleError(props, error));
                                    scrollToTop();
                                    setLoading(false);
                                }

                            );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const checkToSend = (e, k) => {
        var arr = [...visitors];
        arr[k].send = e.target.checked;
        setVisitors(arr);
    }

    const updatePeriod = (field, value) => {
        var o = { ...period };
        o[field] = value;
        setPeriod(o)
    }

    return (
        <div className={getDeviceType() === "desktop" ? "col-6 offset-3" : ""}>
            <h2 className="text-center">Nouvelle visite</h2>

            {error && <ErrorMessage error={error} />}

            {loading && <WaitingRoundSnippers />}

            {
                !loading &&

                <form onSubmit={onFormSubmit}>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Votre nom et prénom</span>
                        <input type="text" className="form-control" readOnly
                            defaultValue={user && (user.last_name + ' ' + user.first_name)} required />
                    </div>

                    { othersHost.map( (v, k)=>{
                        return <div className="input-group mb-3" key={"othHost"+k}>
                                <span className="input-group-text" id="basic-addon1">Mail de l'hôte {k+1}</span>
                                <input type="email" className="form-control" list="visitors_email"
                                    value={v} required onChange={(e) => setOthersHosts(  updateSimpleArray(othersHost, k, e.target.value) )} />
                                <img src={"/common/remove_icon.png"} onClick={()=>  setOthersHosts( (arr) => arrayRemoveElt(arr, k) )} data-key={k}
                                                style={{ width: "30px", height: "30px", margin: "auto" }}></img>
                            </div>
                    } )}

                    <div className="mb-3">
                        <button className="btn btn-outline-secondary" type="button"
                            onClick={()=>setOthersHosts( (arr) => [...arr, ""] )}>Ajouter un autre hôte à prévenir</button>
                    </div>

                    <div style={{ display: "flex", width: "100%", marginBottom: "1rem" }}>
                        <span className="input-group-text spanPhone" id="basic-addon1">Votre téléphone</span>
                        <PhoneInput
                            international
                            defaultCountry="FR"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={setPhone} required />
                    </div>


                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Date et heure d'arrivée</span>
                        <input type="datetime-local" className="form-control"
                            value={arrival} onChange={onArrivalChange} required />
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Date et heure de départ</span>
                        <input type="datetime-local" className="form-control"
                            value={departure} onChange={onDepartureChange} required />
                    </div>

                    {
                        visitors.map(
                            (v, k) => {
                                return (
                                    <div className="input-group mb-3" key={"visitor" + k}>
                                        <span className="input-group-text" id="basic-addon1">{"Visiteur " + (k + 1)}</span>
                                        <input type="text" className="form-control" value={v.name} list="visitors_name"
                                            onChange={(e) => updateVisitor(e, "name")} data-key={k} required />
                                        <input type="text" className="form-control" value={v.mail} list="visitors_email"
                                            placeholder="Email (facultatif)"
                                            onChange={(e) => updateVisitor(e, "mail")} data-key={k} />
                                        <input type="text" className="form-control" value={v.company} list="visitors_company"
                                            placeholder="Entreprise (facultatif)"
                                            onChange={(e) => updateVisitor(e, "company")} data-key={k} 
                                            onBlur={(e) => refreshCompany(e)}/>
                                        {
                                            k > 0 &&
                                            <img src={"/common/remove_icon.png"} onClick={removeVisitor} data-key={k}
                                                style={{ width: "30px", height: "30px", margin: "auto" }}></img>
                                        }
                                    </div>)
                            }
                        )
                    }
                    <img src={"/common/add-logo.png"} style={{ width: "30px", marginBottom: "1rem" }}
                        title="Ajouter un visiteur" onClick={addVisitor}></img>


                    <datalist id="visitors_name">
                        {ct.map((v, k) => <option key={"v_name" + k} value={v.name + " " + v.surname}></option>)}
                    </datalist>

                    <datalist id="visitors_email">
                        {ct.map((v, k) => <option key={"v_email" + k} value={v.email}></option>)}
                    </datalist>

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Entreprise des visiteurs</span>
                        <input type="text" className="form-control"
                            value={company} onChange={onCompanyChange} required />
                    </div>

                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Type de visiteur</label>
                        <select className="form-select" onChange={(e) => setTypeVisitor(e.target.value)} value={typeVisitor} required>
                            <option value="">Choisir...</option>
                            <option value="Intérimaire & stagiaire">Intérimaire & stagiaire</option>
                            <option value="Client">Client</option>
                            <option value="Employé SEE">Employé SEE</option>
                            <option value="Fournisseur/entreprise extérieure">Fournisseur/entreprise extérieure</option>
                        </select>
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Motif</span>
                        <input type="text" className="form-control"
                            value={motif} onChange={onMotifChange} required />
                    </div>

                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Salle de réunion ?</label>
                        <select className="form-select" onChange={updateMeetingRoom} required>
                            <option value="">Choisir...</option>
                            <option value="1" selected={meetingRoom === 1}>Oui</option>
                            <option value="0" selected={meetingRoom === 0}>Non</option>
                        </select>
                    </div>

                    {
                        (meetingRoom !== null && meetingRoom === 1) &&
                        <div className="col-11 offset-1">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Laquelle ?</span>
                                <select className="form-select" onChange={onMeetingRoomLoc} required={meetingRoom && meetingRoom === 1}>
                                    <option value="">Choisir...</option>
                                    <option value="Calanques" selected={meetingRoomLoc === "Calanques"}>Calanques</option>
                                    <option value="B+ site 2" selected={meetingRoomLoc === "B+ site 2"}>B+ site 2</option>
                                    <option value="Cigale" selected={meetingRoomLoc === "Cigale"}>Cigale</option>
                                    <option value="Restanque" selected={meetingRoomLoc === "Restanque"}>Restanque</option>
                                    <option value="Salle Lavande" selected={meetingRoomLoc === "Salle Lavande"}>Salle Lavande</option>
                                    <option value="Salle Mistral" selected={meetingRoomLoc === "Salle Mistral"}>Salle Mistral</option>
                                    <option value="Salle Provence" selected={meetingRoomLoc === "Salle Provence"}>Salle Provence</option>
                                </select>
                            </div>
                        </div>
                    }

                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Plateau repas ?</label>
                        <select className="form-select" onChange={onMealChange} required>
                            <option value="">Choisir...</option>
                            <option value="1" selected={meal === 1}>Oui</option>
                            <option value="0" selected={meal === 0}>Non</option>
                        </select>
                    </div>

                    {
                        (meal !== null && meal === 1) &&
                        <Fragment>
                            <div className="col-11 offset-1">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Nombre de plateau</span>
                                    <input type="number" className="form-control" required={meal && meal === "1"}
                                        value={nbMeal} onChange={onNbMealChange} />
                                </div>
                            </div>

                            <div className="col-11 offset-1">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Régime particulier</span>
                                    <input type="text" className="form-control"
                                        value={particularMeal} onChange={onParticularMealChange} />
                                </div>
                            </div>
                        </Fragment>

                    }

                    {!id &&
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" value="" onChange={() => setSendOutlook(!sendOutlook)} />
                            <label className="form-check-label">Envoyer une invitation Outlook</label>
                        </div>}

                    {sendOutlook && <div className="card" style={{ marginTop: "0px" }}>
                        <div className="alert alert-info text-center">Remarque: ceci enverra un mail avec le meeting pour 
                            qui sera ajouté dans votre agenda Outlook (et ceux des contacts que vous aurez coché) 
                            MAIS ne permet pas de réserver une salle (vous pouvez transférer l'invitation sur Outlook pour réserver).
                        </div>
                        <h6>Destinataires</h6>
                        <div className="mb-3">
                            <table className="table">
                                <tr>
                                    <td><input class="form-check-input" type="checkbox" checked disabled /></td>
                                    <td>Moi</td>
                                    <td>{user.email}</td>
                                </tr>
                                {visitors.map((v, k) => {
                                    return <tr key={"rec" + k}>
                                        <td>
                                            {isEmail(v.mail) && <input class="form-check-input" type="checkbox"
                                                checked={v.send} onChange={(e) => checkToSend(e, k)} />}
                                        </td>
                                        <td>{v.name}</td>
                                        <td>
                                            {!isEmail(v.mail) && <span>Saisissez une adresse mail valide pour envoyer à cette personne</span>}
                                            {isEmail(v.mail) && v.mail}
                                        </td>
                                    </tr>
                                })}
                            </table>
                        </div>

                        <h6>Corps du mail</h6>
                        <div className="form-check form-check-inline">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="lang"
                                    onChange={() => setLang("fr")} checked={lang === "fr"} />
                                <label className="form-check-label">Français</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="lang"
                                    onChange={() => setLang("en")} checked={lang === "en"} />
                                <label className="form-check-label">Anglais</label>
                            </div>
                        </div>

                        <textarea className="form-control" value={msg} style={{ height: "500px" }}
                            onChange={(e) => setMsg(e.target.value)} />
                    </div>}

                    {!id &&
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox"
                                onChange={(e) => updatePeriod("active", e.target.checked)} />
                            <label className="form-check-label">Rendre périodique</label>
                        </div>}

                    {period.active && <div className="input-group mb-3">
                        <span className="input-group-text">Périodicité</span>
                        <select className="form-select" onChange={(e) => updatePeriod("frequency", e.target.value)} required>
                            <option value="">Choose...</option>
                            <option value="quotidien">Tous les jours</option>
                            <option value="hebdomadaire">Chaque semaine</option>
                            <option value="mensuel">Chaque mois</option>
                        </select>
                        <span className="input-group-text">Nombre d'occurrence (en plus de celle ci-dessus)</span>
                        <input className="form-control" type="number" value={period.occurrence} max={10} min={1}
                            onChange={(e) => updatePeriod("occurrence", e.target.value)} required />
                    </div>}

                    <div className="input-group mb-3">
                        <div className="input-group">
                            <span className="input-group-text">Commentaires</span>
                            <textarea className="form-control" value={comments} onChange={onCommentChange}></textarea>
                        </div>
                    </div>

                    <div className="text-center mb-3">
                        {
                            id !== null &&
                            <button type="buton" className="btn btn-danger" onClick={cancelVisit}>Annuler la visite</button>
                        }

                        <button type="submit" className="btn btn-success" style={{ marginLeft: "20%" }}>Valider</button>
                    </div>

                </form>
            }

        </div>)
}

export default VisitForm;