import { scrollToTop } from "../../../functions/PageAnimation";
import { fileToBase64, getBase64 } from "../../../functions/StrFunctions";
import TestDao from "../../../services/common/TestDao";

const FormData = ({ props, setError, setMsg, setLoading, loading }) => {

    const sendData = async (e) => {
        e.preventDefault();

        setError(); setMsg(); setLoading(true);

        const base64 = await fileToBase64(e.target.file.files[0]);
       
        var o = {
            value: e.target.value.value,
            file: base64
        }

        TestDao.testFormData(o).then(
            (response) => { setMsg("Ok"); setLoading(false) },
            (error) => {
                setError("Erreur");
                scrollToTop();
                setLoading(false);
            }
        )
    }

    return <div>
        <h2 className="text-center">Test envoi formData</h2>

        <form onSubmit={sendData} className="col-6 offset-3">
            <div className="form-floating mb-3">
                <input type="text" className="form-control" name="value" placeholder="name@example.com" required />
                <label>Value</label>
            </div>

            <input type="file" className="form-control" name="file" required />

            <div className="text-center mt-3">
                <button className="btn btn-success">Envoyer</button>
            </div>
        </form>
    </div>

}

export default FormData;