import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasRole } from "../../../functions/StrFunctions";
import authService from "../../../services/auth.service";
import { useState } from "react";

const Admin = (props) => {
    const { t } = useTranslation();
    const [backProfile, setBackProfile] = useState("");

    authService.getCurrentProfileBackEnd().then(
        (res) => {
            setBackProfile(res)
        }, (err) => {
            console.error(err)
            setBackProfile("Error request")
        }
    )

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Admin
            </a>
            <ul className="dropdown-menu " aria-labelledby="navbarDropdown">
                <li>
                    <a href={"/settings/get-roles"} className="dropdown-item">
                        {t('role management')}
                    </a>
                </li>

                <li>
                    <a href={"/settings/user-management"} className="dropdown-item">
                        {t('user management')}
                    </a>
                </li>

                <li>
                    <a href={"/settings/contacts-mng"} className="dropdown-item">
                        {t('contact management')}
                    </a>
                </li>

                <li>
                    <a href={"/settings/form-mng/v1"} className="dropdown-item">
                        Form management
                    </a>
                </li>

                <li>
                    <a href={"/annoucement"} className="dropdown-item">
                        Annonces
                    </a>
                </li>

                <li>
                    <a href={"/navigation/management"} className="dropdown-item">
                        Gestion de la navigation de l'intranet
                    </a>
                </li>

                <li>
                    <a href={"/test/anything"} className="dropdown-item">
                        Tests
                    </a>
                </li>

                <li>
                    <a href={"/night-alerts"} className="dropdown-item">
                        Alertes de nuit
                    </a>
                </li>

                <li><hr className="dropdown-divider" /></li>

                {hasRole(["ROLE_ADMIN"]) && <li>
                    <a href="#" className="dropdown-item disabled">
                        <div>
                            <div className="fw-bold">React:</div>
                            <div className="fst-italic">{process.env.REACT_APP_API_URL}</div>
                        </div>
                        <div><div className="fw-bold">Java:</div>{backProfile}</div>
                    </a>
                </li>}


            </ul>
        </li>
    )

}

export default Admin;