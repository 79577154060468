import Moment from 'moment';
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import ticketsDao from "../../services/qhse/ticketsDao";
import { createColumnHelper } from '@tanstack/react-table';
import { getDateTimeFromDb, getFrFormat } from '../../functions/StrFunctions';
import ReactTable from '../../common/smallComponents.js/ReactTable';
import { sortArrayDate } from '../../functions/ArrayFunctions';
import { downloadTempFile } from '../../services/functions/FilesProcess';

const VisitTable = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isQhse = user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_QHSE"));
    const [tab, setTab] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState();
    const [current, setCurrent] = useState();
    Moment.locale('fr');
    let totMeal = 0;

    useEffect(() => {
        ticketsDao.getVisitList().then(
            (response) => {
                var cur = [];
                var old = [];

                for (let i = 0; i < response.length; i++) {
                    var v = response[i];
                    if (v.isBefore === 0) { cur.push(v) }
                    else { old.push(v) }
                }

                setCurrent(sortArrayDate(cur, "arrival", "desc"));
                setList(old);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    /** */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('arrival', {
            header: 'Date d\'arrivée',
            cell: i => <div className={"text-center "}>{getDateTimeFromDb(i.getValue(), "fr")}</div>
        }));

        arr.push(columnHelper.accessor('departure', {
            header: 'Date de départ',
            cell: i => <div className={"text-center "}>{getDateTimeFromDb(i.getValue(), "fr")}</div>
        }));

        arr.push(columnHelper.accessor('visitors', {
            header: 'Visiteur(s)',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('motif', {
            header: 'Motif',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('company', {
            header: 'Entreprise',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('typeVisitor', {
            header: 'Type visiteur',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('meeting', {
            header: 'Salle de réunion ?',
            cell: i => <div className={"text-center "}>{i.getValue() === 0 ? "Non" : "Oui"}</div>
        }));
        arr.push(columnHelper.accessor('meal', {
            header: 'Plateau repas',
            cell: i => <div className={"text-center "}>{
                i.getValue() === 1 ? "Oui pour " + i.row.original.nb_meal : "Non"}</div>
        }));
        arr.push(columnHelper.accessor('comments', {
            header: 'Commentaires',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('name', {
            header: 'Hôte',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();


    const sendTeamsMsg = (v) => {
        var email = v.user_email;
        var visitors = v.visitors.substring(0, v.visitors.length - 1);
        var message = 'Bonjour, ';
        message += (visitors.split(";").length > 1 ? "tes visiteurs " + visitors.replaceAll(";", ", ") + " sont "
            : "ton visiteur " + visitors.replaceAll(";", ", ") + " est ");
        message += "à l'accueil.";

        if (v.otherHosts && v.otherHosts !== "") email += "," + v.otherHosts;

        var url = `https://teams.microsoft.com/l/chat/0/0?users=${encodeURIComponent(email)}&message=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    }

    function getVisitors(v) {
        var arr = v.split(";");
        var txt = "";

        for (let i = 0; i < arr.length - 1; i++) {
            if (arr[i].indexOf("#") !== -1) {
                var split = arr[i].split("#");
                txt += split[0];
                if (split.length > 2 && split[2] && split[2].length > 0) txt += " (" + split[2] + ")"
            }
            else { txt += arr[i] }
            txt += "<br>";
        }

        return txt;//.substring(0, txt.lastIndexOf(","));
    }

    const generateTags = () => {
        setError();
        var today = new Date();
        var arr = [];

        for (let i = 0; i < current.length; i++) {
            var date = new Date(current[i].arrival)

            if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()) arr.push(current[i])
        }

        if( arr.length === 0 ){
            setError("Rien à imprimer pour aujourd'hui");
            return;
        }

        setLoading(true);
        ticketsDao.createBadges(arr).then(
            (response) => {
               downloadTempFile(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    return (
        <div>
            <h4 className="text-center">Liste des visites</h4>

            {loading && <WaitingRoundSnippers />}

            {error && <ErrorMessage error={error} />}

            {
                (current || list) &&
                <Fragment>
                    <ul className="nav nav-tabs">
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 0 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="0">A venir</a>
                        </li>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="1">Historique</a>
                        </li>
                        {isQhse && <li className="nav-item fw-bold">
                            <a className="nav-link"
                                href="#" onClick={generateTags}>Générer les étiquettes</a>
                        </li>}

                    </ul>

                    {/** Coming soon */}
                    {
                        (current && tab === 0) &&
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Date d'arrivée</th>
                                        <th scope="col">Date de départ</th>
                                        <th scope="col">Visiteur(s)</th>
                                        <th scope="col">Motif</th>
                                        <th scope="col">Entreprise</th>
                                        <th scope="col">Type visiteur</th>
                                        <th scope="col">Salle de réunion ?</th>
                                        <th scope="col">Plateau repas</th>
                                        <th scope="col">Commentaires</th>
                                        <th scope="col">Hôte</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        current.map(
                                            (v, k) => {
                                                return v.isBefore === 0 && (
                                                    <Fragment>
                                                        {list[k - 1] &&
                                                            (new Date(v.arrival).toDateString() !== new Date(list[k - 1].arrival).toDateString()) &&
                                                            <Fragment>
                                                                <span style={{ display: "none" }}>{totMeal = v.nb_meal === null ? 0 : parseInt(v.nb_meal)}</span>
                                                            </Fragment>
                                                        }

                                                        {
                                                            (list[k - 1] &&
                                                                (new Date(v.arrival).toDateString() === new Date(list[k - 1].arrival).toDateString())) &&
                                                            <Fragment>
                                                                <span style={{ display: "none" }}>{totMeal += (v.nb_meal === null ? 0 : parseInt(v.nb_meal))}</span>
                                                            </Fragment>
                                                        }

                                                        <tr key={"comin_" + k}>
                                                            <td>
                                                                {getFrFormat(v.arrival)}
                                                            </td>
                                                            <td>
                                                                {getFrFormat(v.departure)}
                                                            </td>
                                                            <td dangerouslySetInnerHTML={{ __html: getVisitors(v.visitors) }}></td>
                                                            <td>{v.motif}</td>
                                                            <td>{v.company}</td>
                                                            <td className="text-white" style={{
                                                                backgroundColor: "rgb("
                                                                    + (v.typeVisitor === "Intérimaire & stagiaire" ? "184,144,2" :
                                                                        v.typeVisitor === "Client" ? "72,132,81" :
                                                                            v.typeVisitor === "Employé SEE" ? "62,92,135" :
                                                                                v.typeVisitor === "Fournisseur/entreprise extérieure" ? "184,144,2" : "")
                                                                    + ")"
                                                            }}>{v.typeVisitor}</td>
                                                            <td>
                                                                {v.meeting === 0 ? "Non" : "Oui"}
                                                                {
                                                                    v.meeting === 1 && <Fragment><br />{v.meeting_location}</Fragment>
                                                                }
                                                            </td>
                                                            <td>
                                                                {v.meal === 1 ? "Oui pour " + v.nb_meal : "Non"}
                                                                {(v.meal === 1 && v.particular_meal !== null) &&
                                                                    <Fragment><br />{v.particular_meal}</Fragment>}
                                                            </td>
                                                            <td>{v.comments}</td>
                                                            <td>{v.name}</td>
                                                            <td className="text-center">
                                                                {
                                                                    v.user_email === user.email &&
                                                                    <a type="button" className="btn btn-outline-secondary"
                                                                        href={"/qhse/visit/form?id=" + v.id}>Modifier</a>
                                                                }

                                                                {
                                                                    v.user_email !== user.email &&
                                                                    <Fragment>
                                                                        <a href={"tel:" + v.tel} title="Appeler">
                                                                            <img src={"/common/call-logo.png"} style={{ width: "30px" }}></img>
                                                                        </a>

                                                                        <img src='/common/teams_logo.png' className='ms-3 cursor-pointer'
                                                                            title="Envoyer un message Teams" style={{ width: "35px" }}
                                                                            onClick={() => sendTeamsMsg(v)}></img>
                                                                        <br></br>
                                                                        <span>{v.tel}</span>
                                                                    </Fragment>
                                                                }
                                                            </td>
                                                        </tr>
                                                        {
                                                            (list[k + 1] &&
                                                                (new Date(v.arrival).toDateString() !== new Date(list[k + 1].arrival).toDateString())) &&
                                                            <Fragment>
                                                                <tr className="table-secondary fw-bold">
                                                                    <td colSpan={7}></td>
                                                                    <td>Total: {totMeal}</td>
                                                                    <td colSpan={3}></td>
                                                                </tr>
                                                            </Fragment>
                                                        }

                                                    </Fragment>)
                                            }
                                        )


                                    }
                                    {
                                        <Fragment>
                                            <tr className="table-secondary fw-bold">
                                                <td colSpan={7}></td>
                                                <td>Total: {totMeal}</td>
                                                <td colSpan={3}></td>
                                            </tr>
                                        </Fragment>
                                    }
                                </tbody>
                            </table>
                        </div>
                    }

                    {/** Due */}
                    {
                        (list && tab === 1) &&
                        <ReactTable columns={columns} data={list}
                            origin="visits" classes={"no-padding fixFstCol"} top0={true} />
                    }
                </Fragment>
            }





        </div>
    );

}

export default VisitTable;