import { createColumnHelper } from "@tanstack/react-table";
import React, { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { nbFormat, updateDrawingUrl } from "../../functions/StrFunctions";
import DisposableArticles from "../../services/articles/DisposableArticles";
import errorManagement from "../../services/errorManagement";
import AddDisposableXls from "./smallComp/AddDisposableXls";
import { getExpander } from "../../common/smallComponents.js/Filter";

const DisposablesArticlesMgt = (props) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const isMech = user && user.roles.includes("ROLE_MECHANICAL");

    const [list, setList] = useState();
    const [thrown, setThrown] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalThrow, setTotalThrow] = useState(0);
    const [totalNbThrow, setTotalNbThrow] = useState(0);
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);
    const MAX_AMOUNT = 5000;

    const token = user && "Bearer " + user.accessToken;
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        DisposableArticles.getDisposableArtListNotPending().then(
            (response) => {
                setList(response.pending);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

        DisposableArticles.getArticlesThrown().then(
            (response) => {
                console.log(response)
                setThrown(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!list) return;
        var tot = 0, nb = 0;

        list.map(v => { if (v.throw_requested) { tot += v.qty_at_term * v.dol_price; nb++ } });

        setTotalThrow(tot);
        setTotalNbThrow(nb);

    }, [list]);

    /** Open drawing */
    var url2 = API_URL + "articles/info/get-drawing-url?Authorization=" + token + "&url=";

    /** Update info */
    const updateInfo = (k, field, e) => {
        var arr = [...list];

        if (field === "throw_requested") {
            arr[k][field] = e.target.checked;
            arr[k].checked = e.target.checked ? "Oui" : "Non";
        } else {
            if (arr[k].checked !== "Oui") return;
            arr[k][field] = e.target.value;
        }

        setList(arr);

        DisposableArticles.addToThrow(arr[k]).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    /** Create columns */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        if (isMech) {
            arr.push(columnHelper.accessor('checked', {
                header: 'Jeter',
                cell: i => <div className="text-center no-print">
                    {
                        (i.row.original.in_trash === undefined || i.row.original.in_trash === null) &&
                        <input type="checkbox" checked={i.row.original.throw_requested}
                            onChange={(e) => updateInfo(i.row.id, "throw_requested", e)}></input>
                    }
                </div>
            }));
        }

        arr.push(columnHelper.accessor('article', {
            header: 'AR',
            cell: i => <div className={"text-center "}>
                <a href={"/article/info/get?article=" + i.getValue()} target="_blank">{i.getValue()}</a>
            </div>
        }));

        arr.push(columnHelper.accessor('DES2L', {
            header: 'Référence',
            cell: i => <div className={"text-center "}>
                {(i.row.original.pdf_plan && i.row.original.pdf_plan !== 'ignore') &&
                    <a target="_blank" href={url2 + updateDrawingUrl(i.row.original.pdf_plan)}>{i.getValue()}</a>}

                {(!i.row.original.pdf_plan || i.row.original.pdf_plan === 'ignore') &&
                    <span>{i.getValue()}</span>}
            </div>
        }));

        arr.push(columnHelper.accessor('qty_at_term', {
            header: 'Qté à jeter',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('QTREL', {
            header: 'Qté affectée',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('tot_price', {
            header: 'Somme à jeter ($)',
            cell: i => <div className={"text-center "}>
                {parseFloat(i.getValue()).toFixed(2)}
            </div>
        }));

        arr.push(columnHelper.accessor('origin', {
            header: 'Origine',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('com_ipt', {
            header: 'Commentaire',
            cell: i => <div className={"text-center "}>
                {<textarea className="form-control" defaultValue={i.getValue()}
                    onBlur={(e) => updateInfo(i.row.id, "com_ipt", e)}
                    readOnly={!i.row.original.throw_requested} ></textarea>}
            </div>
        }));

        arr.push(columnHelper.accessor('in_trash', {
            header: 'Statut',
            cell: i => <div className={"text-center "}>
                {i.getValue() && (i.getValue() === "E" ? "Jet validé par la hiérarchie" :
                    i.getValue() === "P" ? "Article en cours de palettisation" : "")}
            </div>
        }));

        return arr;
    }

    const getColumns2 = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();


        arr.push(getExpander(columnHelper));

        arr.push(columnHelper.accessor('thrown',
            { header: 'Qté de références jetées', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

            arr.push(columnHelper.accessor('total',
                { header: 'Somme en €', cell: (i) => <div className="text-center">{i.getValue() && i.getValue().toFixed(2)}</div> }));

        arr.push(columnHelper.accessor('throw_date',
            { header: 'Date de destruction', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('public_id',
            {
                header: 'Date de destruction',
                cell: (i) => <div className="text-center">
                    <a target="_blank"
                        href={"/article/disposables/public/validation?statut=destroyed&param=" + i.getValue()}>Voir</a>
                </div>
            }));


        return arr;
    }

    //Set columns
    const columns2 = getColumns2();

    const renderSubComponent = (row) => {
        return <table className="table">
            <thead>
                <tr className="table-secondary text-center fw-bold">
                    <td>Article</td>
                    <td>Ref ou plan</td>
                    <td>Renvoi</td>
                    <td>Catégorie</td>
                    <td>Matière</td>
                    <td>Prix de revient</td>
                    <td>Qté jetée</td>
                    <td>Total</td>
                </tr>
            </thead>
            <tbody>
                {row && row.original.subRow.map((v, k) => {
                    return <tr key={"subRow" + k}>
                        <td>{v.CDARL}</td>
                        <td>{v.ZN02PA}</td>
                        <td>{v.zn04pa}</td>
                        <td>{v.zn15pa}</td>
                        <td>{v.matil}</td>
                        <td>{v.price}</td>
                        <td>{v.qty}</td>
                        <td>{v.total}</td>
                    </tr>
                })}
            </tbody>
        </table>
    };

    //Set columns
    const columns = getColumns();

    return (
        <Fragment>
            <ul className="nav nav-tabs">
                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (tab === 0 ? "active" : "")} href="#" onClick={() => setTab(0)}>Jets prévisionnels</a>
                </li>

                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (tab === 1 ? "active" : "")} href="#" onClick={() => setTab(1)}>Pièces jetées</a>
                </li>

                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (tab === 2 ? "active" : "")} href="#"
                        onClick={() => setTab(2)}>Ajout depuis Excel</a>
                </li>
            </ul>

            <br />

            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {(list && tab === 0) &&
                <Fragment>
                    <h6>Total de {totalNbThrow} article(s) à jeter, soit {nbFormat(totalThrow, "fr-FR", 0)}$, à répartir sur {Math.ceil(totalThrow / MAX_AMOUNT) + ' mois'}</h6>
                    <ReactTable columns={columns} data={list}
                        origin="disposable_prop" classes={"no-padding fixFstCol"} top0={true} />
                </Fragment>}

            {
                (thrown && tab === 1) &&
                <div className="col-10 offset-1">
                    <ReactTable columns={columns2} data={thrown} canExpand={true}
                        renderSubComponent={renderSubComponent}
                        origin="disposable_prop_2" classes={"no-padding fixFstCol "} top0={true} />
                </div>
            }

            {tab === 2 && <AddDisposableXls props={props} />}


        </Fragment >
    )

}

export default DisposablesArticlesMgt;