import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { confirmAlert } from "react-confirm-alert";
import { arrayRemoveElt } from "../../../../functions/ArrayFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../../common/smallComponents.js/ReactTable";
import { hasRole } from "../../../../functions/StrFunctions";

const MachinesCat = (p) => {
    var [loading, setLoading] = useState(false);
    const [group, setGroup] = useState([])

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(p.machCat);
        setGroup([...new Set(p.machCat.map(i => i.group))])

    }, []);

    const saveValue = (e) => {
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        if (value === "") return;

        MachineInfo.updateMachinesCat(field, value, list[k].typ_config).then(
            () => { },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    const deleteTypeConfig = (e) => {
        var k = e.target.getAttribute("data-key");
        var config = list[k].typ_config;

        confirmAlert({
            title: "Suppression de type de configuration",
            message: "Etes-vous sûr de vouloir supprimer le type de configuration " + config + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [...list];

                        arr = arrayRemoveElt(arr, k);

                        setList(arr);

                        MachineInfo.deleteMachinesCat(config).then(
                            () => {
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                            }
                        )

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const addCategory = (e) => {
        e.preventDefault();
        p.setError();

        var o = {
            group: e.target.group.value,
            typ_config: e.target.typ_config.value,
            weight: e.target.weight.value,
            price: e.target.price.value
        }

        if( list.find( a => a.typ_config === o.typ_config ) ){
            p.setError("Ce type de config existe déjà !");
            scrollToTop();
            return
        }

        setLoading(true);
        MachineInfo.addMachinesCat(o).then(
            () => {
                setLoading(false)
                e.target.typ_config.value = "";
                e.target.weight.value = "";
                e.target.price.value = "";
            },
            (error) => {
                setLoading(false)
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('group',
            { header: 'Groupe', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('typ_config',
            { header: 'Type de config.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));


        arr.push(columnHelper.accessor('price', {
            header: 'Prix', cell: (i) => <div className="text-center">
                <input type="number" className="form-control" data-key={i.row.id} data-field="price"
                    defaultValue={i.getValue()} onBlur={saveValue} />
            </div>
        }));


        if (hasRole(["ROLE_ADMIN", "ROLE_WRITE_FM"])) {
            arr.push(columnHelper.accessor('weight', {
                header: 'Poids', cell: (i) => <div className="text-center">
                    <input type="number" className="form-control" data-key={i.row.id} data-field="weight"
                        defaultValue={i.getValue()} onBlur={saveValue} />
                </div>
            }));

            arr.push(columnHelper.accessor('xxx', {
                enableColumnFilter: false,
                header: '', cell: (i) => <div className="text-center">
                    <button className="btn btn-outline-danger" data-key={i.row.id}
                        onClick={deleteTypeConfig} style={{ width: "200px" }}>
                        Supprimer
                    </button>
                </div>
            }));
        }

        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <Fragment>
        {
            (list.length > 0 && hasRole(["ROLE_ADMIN", "ROLE_WRITE_FM"])) && <form className="mt-3 mb-3" onSubmit={addCategory}>
                <div className="input-group">
                    <span className="input-group-text">Nouveau type de config</span>
                    <select className="form-select" name="group" required>
                        <option value="">Choisissez le groupe</option>
                        {group.map((v, k) => {
                            return <option value={v} key={"gp" + k}>{v}</option>
                        })}
                    </select>
                    <input className="form-control" name="typ_config" placeholder="Type de configuration" required />
                    <input type="number" className="form-control" name="weight" placeholder="Poids" required />
                    <input type="number" className="form-control" name="price" placeholder="Prix" required />
                    <button className="btn btn-outline-success" disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Ajouter</button>
                </div>
            </form>
        }

        <ReactTable columns={columns} data={list}
            origin="machine_config" classes={"no-padding fixFstCol "} top0={true} />

    </Fragment>

}

export default MachinesCat;