import axios from "axios";
import { dataURLtoFile } from "../../functions/StrFunctions";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"customer-client/check-list";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getCheckListList = ( machine ) => {
    return axios
    .post(API_URL + "/maintenance/form/list",
    {
        fake_header: authHeader(),
        machine: machine
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const createForm = ( machineInfo ) => {
    return axios
    .post(API_URL + "/maintenance/new",
    {
        fake_header: authHeader(),
        machineInfo: machineInfo
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getCheckListForm = ( machine, lang, date ) => {
    return axios
    .post(API_URL + "/maintenance/form",
    {
        fake_header: authHeader(),
        machine: machine,
        lang: lang,
        date: date,
        user: user.username
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const compileCheckListMachine = ( machines, form, lang ) => {
    return axios
    .post(API_URL + "/form/compile/machine",
    {
        fake_header: authHeader(),
        machines: machines, 
        form: form,
        lang: lang
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const singleCheckListMachine = ( machine, form, lang, create, id = null ) => {
    return axios
    .post(API_URL + "/form/single/machine",
    {
        fake_header: authHeader(),
        machine: machine, 
        form: form,
        lang: lang,
        create: create,
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteCheckListMachine = ( form, id ) => {
    return axios
    .post(API_URL + "/form/list/delete/byId",
    {
        fake_header: authHeader(),
        form: form,
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getChkCompilaitonById = ( machines, lang, id ) => {
    return axios
    .post(API_URL + "/form/compilation/get/byId",
    {
        fake_header: authHeader(),
        machines: machines,
        lang: lang,
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getCommissioningCheckList = ( machine, lang, date ) => {
    return axios
    .post(API_URL + "/commissioning/form/list",
    {
        fake_header: authHeader(),
        machine: machine,
        lang: lang,
        date: date,
        user: user.username
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getCheckListFormById = ( public_id, lang ) => {
    return axios
    .post(API_URL + "/public/maintenance/form",
    {
        fake_header: authHeader(),
        public_id: public_id,
        lang: lang
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveForm = (machine, date, form, value1) => {
   
    return axios
    .post(API_URL + "/maintenance/form/save",
    {
        fake_header: authHeader(),
        machine: machine,
        date: date,
        form: form,
        value1: value1
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveAnyForm = (machine, date, form, typeForm, value1) => {
   
    return axios
    .post(API_URL + "/form/save",
    {
        fake_header: authHeader(),
        machine: machine,
        date: date,
        form: form,
        typeForm: typeForm,
        value1: value1
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteForm = (machine, date, form = "maintenance") => {
    return axios
    .post(API_URL + "/maintenance/form/delete",
    {
        fake_header: authHeader(),
        form: form,
        machine: machine,
        date: date
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteFormById = (chk_gp_id) => {
    return axios
    .post(API_URL + "/form/delete/by-group-id",
    {
        fake_header: authHeader(),
        chk_gp_id: chk_gp_id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getPendingForms = () => {
    return axios
    .post(API_URL + "/maintenance/list",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getFatList = () => {
    return axios
    .post(API_URL + "/fat/list",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getPendingFormsById = (form) => {
    return axios
    .post(API_URL + "/form/list/byId",
    {
        fake_header: authHeader(),
        form: form
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const validateChckList = (machines, signatures) => {
    return axios
    .post(API_URL + "/maintenance/form/validation",
    {
        fake_header: authHeader(),
        machines: machines,
        signatures: signatures
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const addReport = (machine, date, form, category, closed, chk_gp_id) => {
   
    return axios
    .post(API_URL + "/report/add",
    {
        fake_header: authHeader(),
        machine: machine,
        date: date,
        form: form,
        category: category,
        closed: closed, 
        chk_gp_id: chk_gp_id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateReport = (field, value, id) => {
   
    return axios
    .post(API_URL + "/report/update",
    {
        fake_header: authHeader(),
        field: field,
        value: value,
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteReport = (id) => {
   
    return axios
    .post(API_URL + "/report/delete",
    {
        fake_header: authHeader(),
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateHeader = (machine, date, form, chapter, field, value) => {
   
    return axios
    .post(API_URL + "/header/update",
    {
        fake_header: authHeader(),
        machine: machine,
        date: date,
        form: form,
        chapter: chapter,
        field: field, 
        value: value
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateHeaderById = (form, id, field, value) => {
   
    return axios
    .post(API_URL + "/header/update/byId",
    {
        fake_header: authHeader(),
        id: id,
        form: form,
        field: field, 
        value: value
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const addAttachment = (form, machine, date, question, img, gpId) => {
   
    return axios
    .post(API_URL + "/attachments/save",
    {
        fake_header: authHeader(),
        machine: machine,
        form: form,
        date: date, 
        question: question,
        img: img,
        gpId: gpId
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteAttachment = (id) => {
   
    return axios
    .post(API_URL + "/attachments/delete",
    {
        fake_header: authHeader(),
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const generateCommissioningReport = (machinesInfo, compilation, interventionReport, internalSignature, 
            clientSignature, lang) => {
   
    return axios
    .post(API_URL + "/report/generate/commissioning",
    {
        fake_header: authHeader(),
        machinesInfo: machinesInfo,
        compilation: compilation,
        interventionReport: interventionReport,
        internalSignature: internalSignature, 
        clientSignature: clientSignature,
        lang: lang
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const generateFatReport = (machineInfo, form) => {
   
    return axios
    .post(API_URL + "/fat/export",
    {
        fake_header: authHeader(),
        machineInfo: machineInfo,
        form: form
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const validateFAT = (machineInfo, form, id, type) => {
   
    return axios
    .post(API_URL + "/fat/validate",
    {
        fake_header: authHeader(),
        machineInfo: machineInfo,
        form: form,
        id: id,
        type: type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    getCommissioningCheckList,
    getCheckListForm,
    compileCheckListMachine,
    saveAnyForm,
    saveForm,
    getCheckListList,
    createForm,
    deleteForm,
    deleteFormById,
    getPendingForms,
    validateChckList,
    getCheckListFormById,
    getChkCompilaitonById,
    addReport,
    updateReport,
    deleteReport,
    getPendingFormsById,
    deleteCheckListMachine,
    updateHeader,
    updateHeaderById,
    addAttachment,
    deleteAttachment,
    generateCommissioningReport,
    singleCheckListMachine,
    getFatList,
    generateFatReport,
    validateFAT
}