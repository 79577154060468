import React, { useState, useRef, useEffect, Fragment } from "react";


import checkList from "../../../services/workshop/checkList";


import "../../../css/workshop/checkList.css"
import "../../../css/workshop/integrationForms.scss"
import errorManagement from "../../../services/errorManagement.js";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage.js";
import { getDeviceType, scrollToTop } from "../../../functions/PageAnimation";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import DownloadFiles from "../../common/DownloadFiles";
import { downloadFile } from "../../../functions/FormCtrl";
import { getKey } from "../../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";
import getUrlParam from "../../../functions/StrFunctions.js";

const DoCheckList = (props) => {
    const [machine, setMachine] = useState();
    const [machineType, setMachineType] = useState();
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [machineInfo, setMachineInfo] = useState(false);

    const [subset, setSubset] = useState();
    const [curSubset, setCurSubset] = useState();
    const [margerSense, setMargerSense] = useState();
    const [list, setList] = useState();
    const [form, setForm] = useState();
    const [sense, setSense] = useState();
    const [response, setResponse] = useState();
    const [controller, setController] = useState();
    const [ctrlDate, setCtrlDate] = useState();
    const [status, setStatus] = useState();
    const [deviceType] = useState(getDeviceType());
    const [changeDone, setChangeDone] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        var machine = getUrlParam(props, "machine");
        if( machine !== null ){
            setMachine(machine);
           getFormRequest(machine);
        }
    }, []);

    /**
     * Change state of machine input
     * @param {*} e 
     */
    const onChangeMachine = (e) => {
        setMachine(e.target.value);
    };

    /**
     * Set subset choosen
     * @param {*} e 
     */
    const onSubsetChoose = (e) => {
        if (changeDone) {
            confirmAlert({
                title: "Confirmation",
                message: "Des changements n'ont pas été sauvegardés, voulez-vous quitter sans enregistrer ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => { setChangeDone(false); changeSubset(e) }
                    },
                    {
                        label: "Non",
                        onClick: () => { document.getElementById("subsetChoice").value = curSubset; return; }
                    }
                ]
            });
        } else {
            changeSubset(e);
        }
    };

    const changeSubset = (e) => {
        var se = e.target.value;
        setCurSubset(se);

        if (se === "") {
            setForm();
            return;
        }

        var arr = new Array();
        var found = false;


        list.map(v => {
            if (v.chapter_label.trim() === se.trim()) {
                setMargerSense(v.value2)
                arr.push(v);
            } else if (found) {
                return;
            }
        });

        setController(arr[0].ctrl_name);
        setCtrlDate(arr[0].control_date);

        setForm(arr);
    }

    /**
     * Search the machine number and get the form
     * @param {*} e 
     */
    const getForm = (e) => {
        e.preventDefault();

        if (changeDone) {
            confirmAlert({
                title: "Confirmation",
                message: "Des changements n'ont pas été sauvegardés, voulez-vous quitter sans enregistrer ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => { setChangeDone(false); getFormRequest() }
                    },
                    {
                        label: "Non",
                        onClick: () => { return; }
                    }
                ]
            });
        } else {
            getFormRequest();
        }



    };

    const getFormRequest = (machineParam) => {
        var mach;
        if( machineParam ){mach = machineParam; } 
        else{mach = machine} 

        //Remove list
        setList();
        setSubset();
        setMachineType();
        setSense();
        setError();
        setForm();
        setMsg();

        //Set loading, so disable button search
        setLoading(true);

        checkList.getAssemblyChkListForm(mach).then(
            (response) => {
                setMachine(response.machine);
                setMachineType(response.machine_type);
                getDistinctSubset(response.form);
                setList(response.form);
                setSense(response.sense);
                setMachineInfo(response.machineInfo);
                setResponse(response);
                setLoading(false);
                setGlobalStatus(response.form);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const setGlobalStatus = (arr) => {
        var status = "Terminé";
        arr.map(v => {
            if (parseInt(v.conform) === 0) {
                status = "Non conforme";
                return;
            } else if (v.conform === null && status !== "Non conforme") {
                status = "En cours";
            }
        });

        setStatus(status);
    }

    function getDistinctSubset(se) {
        var arr = new Array();
        var o, key;
        var label;

        se.map(v => {
            key = getKey(arr, "chapter", v.chapter);
            if (key === null) {

                o = {
                    "chapter": v.chapter,
                    "label": v.chapter_label,
                    "conform": v.conform === 1 ? 1 : 0,
                    "no_conform": v.conform === 0 ? 1 : 0,
                    "cpt": 1
                }
                arr.push(o);
            } else {
                arr[key].cpt++;
                if (v.conform === 1) arr[key].conform++;
                if (v.conform === 0) arr[key].no_conform++;
            }
        })

        setSubset(arr);
    }

    const downloadReport = (e) => {
        e.preventDefault();
        downloadFile(process.env.REACT_APP_API_URL + "workshop/check-list/public/download-checkList?mach_nb=" + machine);
    };

    const onClickConform = (e) => {
        var k = e.target.getAttribute("data-key");
        var conform = e.target.getAttribute("data-conform");
        var arr = [...form];
        arr[k].conform = !conform ? null : conform === "true" ? 1 : 0;
        setForm(arr);
        setChangeDone(true);
    }

    const onSubmit = (e) => {
        setLoading(true);

        checkList.saveAssemblyChkListForm(machine, form).then(
            (response) => {
                //setMsg("Sauvegardé !");
                setForm();
                document.getElementById("subsetChoice").value = "";
                setLoading(false);
                setChangeDone(false);
                scrollToTop();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    const onBuilderChange = (e) => {
        var arr = [...form];
        arr[0].builder = e.target.value;
        setForm(arr);
    }

    const onMrMlSelect = (e) => {
        var side = e.target.value;
        var chap;

        subset.map(v => { if (v.label === curSubset) chap = v.chapter; })

        checkList.setAssemblyMargerSense(machine, chap, side).then(
            (response) => {},
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    return (
        <div>

            {error && <ErrorMessage error={error} />}
            {msg && <SuccessMessage msg={msg} />}

            <form onSubmit={getForm}>

                <div>
                    <div className="input-group mb-3">
                        {
                            status &&
                            <span style={{ maxWidth: "10px" }} className={"form-select text-center fw-bold "
                                + (status === "Non conforme" ? "bg-danger bg-gradient text-white" :
                                    status === "Terminé" ? "bg-success bg-gradient" : "bg-warning bg-gradient")}></span>
                        }
                        {machineInfo && <input type="text" className="form-control text-center fw-bold" value={machineInfo.affaire} readOnly />}
                        {!machineInfo && <span className="input-group-text">N° de machine</span>}
                        <input type="text" className="form-control text-center fw-bold"
                            name="mach_nb" value={machine} onChange={onChangeMachine} required />
                        {machineInfo && <input type="text" className="form-control text-center fw-bold" value={machineInfo.typ_config} readOnly />}
                        {sense && <input type="text" className="form-control text-center fw-bold" value={sense} readOnly />}
                        <button className="btn btn-success" disabled={loading}>
                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                            <span>Search</span>
                        </button>
                        {machineInfo && <button className="btn btn-outline-danger" onClick={(e) => { e.preventDefault(); window.location.reload() }}>Réinit.</button>}
                    </div>

                    {
                        subset && <Fragment>
                            <div className="input-group mb-3">
                                <select className="form-select text-center fw-bold " id="subsetChoice" onChange={onSubsetChoose}>
                                    <option value="">Choisissez le sous-ensemble</option>
                                    {
                                        subset.map((v, k) =>
                                            <option key={"se" + k}
                                                className={v.no_conform > 0 ? "bg-danger bg-gradient text-white" :
                                                    v.conform === v.cpt ? "bg-success bg-gradient text-white" :
                                                        v.conform > 0 ? "bg-warning bg-gradient" : ""}
                                                value={v.label.trim()}>{v.label}</option>)
                                    }
                                </select>

                                {
                                    curSubset && curSubset.startsWith("Margeur") &&
                                    <select className="form-select text-center fw-bold " onChange={onMrMlSelect}>
                                        <option value="">Config.</option>
                                        <option value="MR" selected={margerSense && margerSense === "MR"}>MR</option>
                                        <option value="ML" selected={margerSense && margerSense === "ML"}>ML</option>
                                    </select>
                                }

                                <span className="input-group-text">Contrôle</span>
                                {
                                    response && <input type="date" className="form-control text-center fw-bold"
                                        value={ctrlDate} readOnly />
                                }



                                {
                                    controller &&
                                    <Fragment>
                                        <span className="input-group-text">Contrôleur</span>
                                        <input type="text" className="form-control text-center fw-bold"
                                            value={controller} readOnly />
                                    </Fragment>
                                }

                                {
                                    form &&
                                    <Fragment>
                                        <span className="input-group-text">Monteur</span>
                                        <input type="text" className="form-control text-center fw-bold"
                                            value={form[0].builder === null ? "" : form[0].builder.trim()} onChange={onBuilderChange} />
                                    </Fragment>
                                }

                                <select className="form-select text-center fw-bold">
                                    <option>Légende</option>
                                    <option className="bg-danger bg-gradient text-white">Au moins une non conformité signalée</option>
                                    <option className="bg-warning bg-gradient">En cours de contrôle sans non conformité</option>
                                    <option className="bg-success bg-gradient text-white">Tout est conforme</option>
                                    <option>Non commencé</option>
                                    <option style={{ color: "red" }}>Libellé contrôle en rouge: a été non-conforme autrefois</option>
                                </select>

                            </div>


                        </Fragment>}
                </div>

            </form>

            {
                form &&
                <Fragment>
                    <ul className="list-group" id="integrationForm">
                        {
                            form.map(
                                (v, k) => {

                                    return <Fragment>
                                        {(k === 0 && v.rubric_label !== '') &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }

                                        {
                                            (k > 0 && v.rubric_label !== '' && form[k - 1].rubric_label !== v.rubric_label) &&
                                            <li key={"rq" + k} className="list-group-item fw-bold bg-secondary bg-gradient text-white">
                                                {v.rubric_label}
                                            </li>
                                        }
                                        <li key={"q" + k} className="list-group-item">
                                            <div className="question">
                                                <div className="left-side" style={deviceType === "mobile" ? { display: "inline-flex" } : {}} >
                                                    {
                                                        v.conform !== null &&
                                                        <button type="button" className="btn-close" onClick={onClickConform}
                                                            data-key={k} aria-label="Close"></button>
                                                    }
                                                    <input type="radio" className="btn-check"
                                                        data-key={k} data-conform="true"
                                                        checked={v.conform !== null && v.conform}
                                                        onClick={onClickConform} />
                                                    <label className="btn btn-outline-success"
                                                        htmlFor={"q_" + v.question + "_c"} data-conform="true"
                                                        data-key={k} onClick={onClickConform}>Conform</label>

                                                    <input type="radio" className="btn-check"
                                                        data-key={k} data-conform="false"
                                                        checked={v.conform !== null && !v.conform}
                                                        onClick={onClickConform} />
                                                    <label className="btn btn-outline-danger" data-conform="false"
                                                        data-key={k} htmlFor={"q_" + v.question + "_nc"} onClick={onClickConform}>Non conforme</label>
                                                </div>

                                                <div className="right-side">
                                                    <span style={{ color: (v.conform && v.conform === 1 && v.hasBeenNC === 1) ? "red" : "black" }}>{v.question_label}</span>
                                                </div>
                                            </div>
                                        </li>
                                        
                                    </Fragment>
                                }
                            )
                        }
                    </ul>

                    <br></br>

                    <div className="text-center">
                        <button className="btn btn-success" onClick={onSubmit} disabled={loading}>
                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                            Enregistrer
                        </button>
                    </div>
                </Fragment>

            }


            <br></br>
        </div>

    );

};

export default DoCheckList;