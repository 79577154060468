import parse from "paste-from-excel";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { cleanRef } from "../../functions/StrFunctions";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import { ProgressBar } from "react-bootstrap";

const MassiveArtCreation = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [contacts, setContacts] = useState();

    const [articleInfo, setArticleInfo] = useState();
    const [currency, setCurrency] = useState();
    const [unit, setUnit] = useState();

    const [chapter, setchapter] = useState();
    const [typeProd, settypeProd] = useState();
    const [family, setfamily] = useState();
    const [progress, setProgress] = useState(-1);
    const [defaultSupplier, setDefaultSupplier] = useState(true);

    const [label, setlabel] = useState({
        labels: [
            "Ref fournisseur*", "Fabricant*", "Catégorie*", "Libellé francais*", "Libellé anglais",
            "Libellé allemand", "Chapitre*", "Type de produit*", "Famille d'article*", "Unité de nomenclature*",
            "Pays d'origine*", "Stock mini*"]
    });
    const [inputvalue, setinputvalue] = useState({
        inputs: []
    });

    /**
     * Convert Excel copy to json
     * 
     * @param {*} index 
     * @param {*} elm 
     * @param {*} e 
     * @param {*} i 
     * @returns 
     */
    const handlePaste = (index, elm, e, i) => {
        return parse(e);
    };

    /**
     * Copy from values in input
     * @param {*} index 
     * @param {*} elm 
     * @param {*} e 
     * @param {*} i 
     */
    const handlePaste1 = (index, elm, e, i) => {

        setinputvalue((inputvalue) => ({
            ...inputvalue,
            inputs: inputvalue.inputs.map((item, i) =>
                index === i
                    ? {
                        ...item,
                        [elm]: e.target.value
                    }
                    : item
            )
        }));
    };

    useEffect(() => {

        ArticleInfo.getArticleToCreate(null).then(
            (response) => {
                setArticleInfo(response.article);

                setCurrency(response.currency);
                setUnit(response.unit);
                setchapter(response.chapter);
                settypeProd(response.typeProd);
                setfamily(response.family);

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Set 1000 rows
        resetTable();
    }, []);

    /**
     * Clear table
     */
    const resetTable = () => {
        var arr = new Array();
        var o = label.labels.reduce((acc, curr) => {
            acc[curr] = "";
            return acc;
        }, {})

        for (let i = 0; i < 1000; i++) {
            arr.push({ ...o });
        }
        setinputvalue({ inputs: arr })
    }

    /**
     * Get type of field for input
     * @param {*} elm 
     * @returns 
     */
    const typeOfField = (elm) => {
        elm = elm.toUpperCase();

        if (elm.indexOf("DATE") !== -1) {
            return "date";
        } else if (elm.indexOf("QTÉ") !== -1) {
            return "number";
        } else {
            return "text";
        }
    }

    /**
     * Extends values until last row
     */
    const extendValues = (row, elm, e, column) => {
        var value = e.target.value;
        if (value.trim() === "") return;

        var maxRow = 0;
        inputvalue.inputs.map((res, index) => {
            for (const key in res) {
                if (res[key].trim() !== "") {
                    maxRow++;
                    break;
                }
            }
        })

        var arr = [...inputvalue.inputs];
        for (let i = row; i < maxRow; i++)
            arr[i][elm] = value;


        setinputvalue({ inputs: arr })
    }

    /**
     * Remove one row
     * @param {*} e 
     */
    const removeRow = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...inputvalue.inputs];
        arr = arrayRemoveElt(arr, key);
        setinputvalue({ inputs: arr })
    }

    /**
     * Control and send
     */
    const validate = () => {
        setLoading(true);
        setError();
        setMsg();
        var arr = [...inputvalue.inputs];
        var err = "";

        var maxRow = 0;
        inputvalue.inputs.map((res, index) => {
            for (const key in res) {
                if (res[key].trim() !== "") {
                    maxRow++;
                    break;
                }
            }
        })

        //Check at least one row is completed
        if (maxRow === 0) err = "Vous devez compléter au moins une ligne !";

        // Check if mandatories fields are filled in
        var err = "";
        for (let i = 0; i < maxRow; i++)
            for (let key in arr[i]) err = checkField(key, arr[i][key], err, (i + 1));


        if (err !== "") {
            setLoading(false);
            setError(err);
            setMsg();
            scrollToTop();
            return;
        }

        arr = arr.slice(0, maxRow);



        // Check before insertion
        setMsg("Vérifications des doublons");
        scrollToTop();

        ArticleInfo.articlesExistsFromList(arr, "Ref fournisseur*").then(
            (response) => {
                if (response.length > 0) {
                    var err = response.length + " article(s) existe(nt) déjà, merci de le(s) retirer:<ul>";
                    for (let i = 0; i < response.length; i++) err += "<li>" + response[i].ZN02PA + ": " + response[i].CDARPA + "</li>"
                    err += "</ul>"
                    setLoading(false);
                    setError(err);
                    setMsg();
                    scrollToTop();
                    return;
                } else {
                    setMsg("Ok pas d'erreur détecté, création en cours...");
                    save(arr);
                }
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setMsg();
                scrollToTop();
                setLoading(false);
            }
        )

    }

    function checkField(field, value, err, row) {
        var msg = "";

        if (field.indexOf("*") !== -1 && value.trim() === "") {
            msg = "Le champ " + field + " est obligatoire<br>";
            if (err.indexOf(msg) === -1) err += msg;
        }

        if (field === "Chapitre*") {
            if (!chapter.some(item => item.CODCS === value))
                err += "Le chapitre " + value + " est incorrect, ligne " + row + "<br>"
        }

        if (field === "Type de produit*") {
            if (!typeProd.some(item => item.CODCS === value))
                err += "Le Type de produit " + value + " est incorrect, ligne " + row + "<br>"
        }

        if (field === "Famille d'article*") {
            if (!family.some(item => item.CODCS === value))
                err += "La famille d'article " + value + " est incorrect, ligne " + row + "<br>"
        }

        if (field === "Unité de nomenclature*") {
            if (!unit.some(item => item.CODCS === value))
                err += "L'unité de nomenclature " + value + " est incorrect, ligne " + row + "<br>"
        }

        return err;
    }

    const save = async (arr) => {

        for (let i = 0; i < arr.length; i++) {
            var arr2 = [...inputvalue.inputs];
            var res = await ArticleInfo.createArticleInFocus(getArtObject(arr[i]), undefined, false, defaultSupplier);
            arr2[i].ar = res;
            setinputvalue({ inputs: arr2 });

            setProgress(((i / arr.length) * 100).toFixed(0))
        }

        setProgress(100)
        setLoading(false);
    }

    const getArtObject = (art) => {
        var o = { ...articleInfo };

        var label = art["Libellé francais*"];
        o.des1l = label.substring(label, Math.min(30, label.length));
        o.dim1l = 0;
        o.dim2l = 0;
        o.dim3l = 0;
        o.cdunl = art["Unité de nomenclature*"];
        o.poidl = 0;
        o.stmil = art["Stock mini*"];
        o.qreal = 0;
        o.des2l = getMaker(art["Fabricant*"]) + "/" + cleanRef(art["Ref fournisseur*"]);
        o.chapl = art["Chapitre*"];
        o.cfaml = art["Famille d'article*"];
        o.typrl = art["Type de produit*"];
        o.catrl = "";

        o.zn01pa = art["Fabricant*"];
        o.zn02pa = art["Ref fournisseur*"];
        o.zn17pa = art["Pays d'origine*"];
        o.zn03pa = art["Libellé francais*"];
        o.zn12pa = art["Libellé anglais"];
        o.deu_lab = art["Libellé allemand"];
        o.zn15pa = art["Catégorie*"];

        return o;
    }

    /**
     * Get maker trigram
     * @param {*} value 
     * @returns 
     */
    const getMaker = (value) => {
        if (value && value.toUpperCase().startsWith("B+")) {
            return "BPLUS";
        } else {
            return value.toUpperCase().substring(0, Math.min(5, value.length)).padEnd(5, "_");
        }
    }


    function getStyle(i) {
        var o = {};

        if (i === 0) return { width: "250px" }

        return o
    }

    return (
        <Fragment>
            <h3 className="text-center">Création d'articles</h3>

            <div className="accordion col-6 offset-3" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header text-center" id="headingOne">
                        <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Indications
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <ul>
                                <li>Vous pouvez copier/coller les cellules d'un fichier Excel sur ce tableau.</li>
                                <li>Vous pouvez copier plusieurs colonnes si elles sont côte à côte ou une seule colonne à la fois.</li>
                                <li><i><b>Attention:</b> pour copier les dates sur ce tableau, elles devront être au format américain (yyyy-mm-dd) sur Excel</i></li>
                                <li>En double-cliquant sur une case vous dupliquez la valeur de celle-ci jusqu'à la dernière ligne remplie.</li>
                                <li> Le bouton Réinitialiser le tableau efface toutes les valeurs du tableau.</li>
                                <li>Les colonnes en fond jaunes doivent être remplies</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>

            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {progress >= 0 && <div className="col-8 offset-2 mb-3">
                <ProgressBar animated now={progress} label={`${progress}%`} />
            </div>
            }

            <div className="text-center">
                <button type="button" className="btn btn-outline-danger"
                    onClick={resetTable} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Réinitialiser le tableau
                </button>
                <button type="button" className="btn btn-outline-success" style={{ marginLeft: "20px" }}
                    onClick={validate} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Valider la création
                </button>
            </div>
            <br></br>

            <div className="form-check">
                <input className="form-check-input" type="checkbox" checked={defaultSupplier}
                    onChange={() => setDefaultSupplier(!defaultSupplier)} />
                <label className="form-check-label">Créer le fournisseur ADEFINIR</label>
            </div>

            <table className="table table-striped">
                <thead>
                    <tr className="text-center table-info fixed-header">
                        <th>#</th>
                        {label.labels.map((elm, ind) => {
                            return (
                                <th>
                                    {elm}
                                </th>
                            );
                        })}
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {inputvalue.inputs.map((res, index) => {
                        return (
                            <tr key={index}>
                                <th scope="row" className="text-center">{index + 1}</th>
                                {label.labels.map((elm, i) => {
                                    return (
                                        <Fragment>
                                            <td>
                                                <input style={getStyle(i)}
                                                    onInput={(e) => {
                                                        handlePaste1(index, elm, e, i);
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePaste(index, elm, e, i);
                                                    }}
                                                    onDoubleClick={(e) => {
                                                        extendValues(index, elm, e, i);
                                                    }}
                                                    type={typeOfField(elm)}
                                                    className="form-control"
                                                    value={res[elm]}
                                                />
                                            </td>
                                        </Fragment>
                                    );

                                })}
                                <td className={res.ar ? "bg-success text-white" : ""}>
                                    <a target="_blank" className="text-white" href={"/article/info/get?article=" + res.ar}>
                                        {res.ar}</a>
                                </td>
                                <td>
                                    <img src={"/common/remove_icon.png"} onClick={removeRow}
                                        style={{ width: "30px" }} data-key={index}></img>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {
                contacts &&
                <datalist id="contacts">
                    {contacts.map((v, k) => <option key={"c" + k} value={v} />)}
                </datalist>
            }


        </Fragment>
    )

}

export default MassiveArtCreation;