import axios from "axios";
import { SelectColumnFilter, SelectEmptyOrNot } from "../../functions/TableFuntions";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "qhse";

const arrToXls = (arr) => {
    return axios
        .post(API_URL + "/export/array", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const saveVisit = (phone, arrival, departure, visitors, company, meetingRoom,
    meetingRoomLoc, meal, particularMeal, comments, nbMeal, motif, typeVisitor, msg, sendOutlook, period, othersHost) => {
    return axios
        .post(API_URL + "/visit/new", {
            fake_header: authHeader(),
            phone: phone,
            arrival: arrival,
            departure: departure,
            visitors: visitors,
            company: company,
            meetingRoom: meetingRoom,
            meetingRoomLoc: meetingRoomLoc,
            meal: meal,
            particularMeal: particularMeal,
            comments: comments,
            nbMeal: nbMeal,
            motif: motif,
            typeVisitor: typeVisitor,
            msg: msg,
            sendOutlook: sendOutlook,
            period: period, 
            othersHost: othersHost
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const saveUpdateVisit = (phone, arrival, departure, visitors, company, meetingRoom,
    meetingRoomLoc, meal, particularMeal, comments, nbMeal, motif, typeVisitor, id, othersHost) => {
    return axios
        .post(API_URL + "/visit/update", {
            fake_header: authHeader(),
            phone: phone,
            arrival: arrival,
            departure: departure,
            visitors: visitors,
            company: company,
            meetingRoom: meetingRoom,
            meetingRoomLoc: meetingRoomLoc,
            meal: meal,
            particularMeal: particularMeal,
            comments: comments,
            nbMeal: nbMeal,
            motif: motif,
            typeVisitor: typeVisitor,
            id: id,
            othersHost: othersHost
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const cancelVisit = (phone, arrival, departure, visitors, company, meetingRoom, meetingRoomLoc, meal, particularMeal, comments, nbMeal, id) => {
    return axios
        .post(API_URL + "/visit/cancel", {
            fake_header: authHeader(),
            phone: phone,
            arrival: arrival,
            departure: departure,
            visitors: visitors,
            company: company,
            meetingRoom: meetingRoom,
            meetingRoomLoc: meetingRoomLoc,
            meal: meal,
            particularMeal: particularMeal,
            comments: comments,
            nbMeal: nbMeal,
            id: id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getVisitList = () => {
    return axios
        .post(API_URL + "/visit/list", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const createBadges = (arr) => {
    return axios
        .post(API_URL + "/visit/badge", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getTicket = (id) => {
    return axios
        .post(API_URL + "/visit/get", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const saveBbsoForm = (o, type) => {
    var url_end = type === "new" ? "/bbso/form/save" : "/bbso/form/update";
    return axios
        .post(API_URL + url_end, {
            fake_header: authHeader(),
            o: o
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getBbso = (id) => {
    return axios
        .post(API_URL + "/bbso/form/get", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getBbsoList = () => {
    return axios
        .post(API_URL + "/bbso/form/list", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const searchBbso = (term, start, end) => {
    return axios
        .post(API_URL + "/bbso/search/list", {
            fake_header: authHeader(),
            term: term,
            start: start,
            end: end
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getBbsoGoalsList = () => {
    return axios
        .post(API_URL + "/bbso/goals/list", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updateBbsoField = (id, field, value) => {
    return axios
        .post(API_URL + "/bbso/update/field", {
            fake_header: authHeader(),
            id: id,
            field: field,
            value: value
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const addBbsoGoalsList = (goal, trigram, last_name, first_name, globalId, contract, mail) => {
    return axios
        .post(API_URL + "/bbso/goals/add", {
            fake_header: authHeader(),
            goal: goal,
            trigram: trigram,
            last_name: last_name,
            first_name: first_name,
            globalId: globalId,
            contract: contract,
            mail: mail
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const deleteBbsoGoalsList = (globalId) => {
    return axios
        .post(API_URL + "/bbso/goals/delete", {
            fake_header: authHeader(),
            globalId: globalId
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updateBbsoGoalsList = (field, value, globalId) => {
    return axios
        .post(API_URL + "/bbso/goals/update", {
            fake_header: authHeader(),
            field: field,
            value: value,
            globalId: globalId
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const searchQualityFb = (term, start, end) => {
    return axios
        .post(API_URL + "/quality-feedback/search/list", {
            fake_header: authHeader(),
            term: term,
            start: start,
            end: end
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const saveQualityFeedBack = (business, machine, machineType, description, problem, proposal, dataPhoto, criticality) => {
    return axios
        .post(API_URL + "/quality-feedback/new", {
            fake_header: authHeader(),
            business: business,
            machine: machine,
            machineType: machineType,
            description: description,
            problem: problem,
            proposal: proposal,
            dataPhoto: dataPhoto,
            criticality: criticality
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updateQualityFeedBack = (id, business, machine, machineType, description, problem, proposal,
    dataPhoto, qhseComment, statut, criticality) => {
    return axios
        .post(API_URL + "/quality-feedback/update", {
            fake_header: authHeader(),
            id: id,
            business: business,
            machine: machine,
            machineType: machineType,
            description: description,
            problem: problem,
            proposal: proposal,
            dataPhoto: dataPhoto,
            qhseComment: qhseComment,
            statut: statut,
            criticality: criticality
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getQualityFeedBackList = () => {
    return axios
        .post(API_URL + "/quality-feedback/list", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getQualityFeedBack = (id) => {
    return axios
        .post(API_URL + "/quality-feedback/get", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updateQualityFeedBackField = (id, field, value) => {
    return axios
        .post(API_URL + "/quality-feedback/update/field", {
            fake_header: authHeader(),
            id: id,
            field: field,
            value: value
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const createBuildingTicket = (o) => {
    return axios
        .post(API_URL + "/building/new", {
            fake_header: authHeader(),
            ...o
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getBuildingTicket = (id) => {
    return axios
        .post(API_URL + "/building/get", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updateBuildingTicket = (o) => {
    return axios
        .post(API_URL + "/building/update", {
            fake_header: authHeader(),
            ...o
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getBuildingList = () => {
    return axios
        .post(API_URL + "/building/list", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updateBuildingField = (id, field, value) => {
    return axios
        .post(API_URL + "/building/update/field", {
            fake_header: authHeader(),
            id: id,
            field: field,
            value: value
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const statutChange = (id, field, value) => {
    return axios
        .post(API_URL + "/building/update/statut", {
            fake_header: authHeader(),
            id: id,
            field: field,
            value: value
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getEquipmentList = () => {
    return axios
        .post(API_URL + "/equipment/list/get", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const saveEquipmentList = (list) => {
    return axios
        .post(API_URL + "/equipment/list/save", {
            fake_header: authHeader(),
            list: list
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const deleteEquipmentList = (id) => {
    return axios
        .post(API_URL + "/equipment/list/delete", {
            fake_header: authHeader(),
            id: id
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const newEquipmentRequest = (list, finalUser) => {
    return axios
        .post(API_URL + "/equipment/request/save", {
            fake_header: authHeader(),
            list: list,
            finalUser: finalUser

        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const equipmentRequestList = (inProgressOnly) => {
    return axios
        .post(API_URL + "/equipment/request/list", {
            fake_header: authHeader(),
            inProgressOnly: inProgressOnly
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const equipmentRequestNotify = (arr) => {
    return axios
        .post(API_URL + "/equipment/request/notify", {
            fake_header: authHeader(),
            arr: arr
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const equipmentRequestSignature = (arr, clientSignature, qhseSignature) => {
    return axios
        .post(API_URL + "/equipment/request/sign", {
            fake_header: authHeader(),
            arr: arr,
            clientSignature: clientSignature,
            qhseSignature: qhseSignature
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const saveNewChemicalProduct = (id, form, infos) => {
    return axios
        .post(API_URL + "/chimical-risk/new-product/save", {
            fake_header: authHeader(),
            form: form,
            id: id,
            infos: infos
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getNewChemicalProduct = () => {
    return axios
        .post(API_URL + "/chimical-risk/new-product/get", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getPPM = () => {
    return axios
        .post(API_URL + "/ppm/get", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updatePPM = (o) => {
    return axios
        .post(API_URL + "/ppm/update", {
            fake_header: authHeader(),
            ...o
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const addPPM = (o) => {
    return axios
        .post(API_URL + "/ppm/add", {
            fake_header: authHeader(),
            ...o
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

export default {
    saveVisit,
    getVisitList,
    getTicket,
    saveUpdateVisit,
    cancelVisit,
    saveBbsoForm,
    getBbso,
    getBbsoList,
    getBbsoGoalsList,
    addBbsoGoalsList,
    deleteBbsoGoalsList,
    updateBbsoGoalsList,
    saveQualityFeedBack,
    getQualityFeedBackList,
    getQualityFeedBack,
    updateQualityFeedBack,
    updateQualityFeedBackField,
    updateBbsoField,
    createBuildingTicket,
    getBuildingTicket,
    updateBuildingTicket,
    getBuildingList,
    updateBuildingField,
    statutChange,
    searchBbso,
    searchQualityFb,
    arrToXls,
    saveEquipmentList,
    getEquipmentList,
    deleteEquipmentList,
    newEquipmentRequest,
    equipmentRequestList,
    equipmentRequestNotify,
    equipmentRequestSignature,
    saveNewChemicalProduct,
    getNewChemicalProduct,
    createBadges,
    getPPM,
    updatePPM,
    addPPM
}