import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import "../../css/machines/businessCard.scss";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { compareTwoDates, fileToBase64, forceString, getBase64, getFrFormat, getLabelFromInput } from "../../functions/StrFunctions";
import ShareFileDao from "../../services/common/ShareFileDao";
import errorManagement from "../../services/errorManagement";
import BusinessDao from "../../services/machines/BusinessDao";
import BAContract from "./smallCom/business/BAContract";
import CustomerInvoice from "./smallCom/business/CustomerInvoice";
import DeliveryNote from "./smallCom/business/DeliveryNote";
import DropDownMenu from "./smallCom/business/dropDownMenu";
import PartEight from "./smallCom/business/PartEight";
import PartFive from "./smallCom/business/PartFive";
import PartFour from "./smallCom/business/PartFour";
import PartOne from "./smallCom/business/PartOne";
import PartSeven from "./smallCom/business/PartSeven";
import PartSix from "./smallCom/business/PartSix";
import PartThree from "./smallCom/business/PartThree";
import PartTwo from "./smallCom/business/PartTwo";
import Miscellaneous, { logObj } from "../../services/common/Miscellaneous";
import History from "../common/History";

const BusinessCard = (props) => {

    const [business, setBusiness] = useState();
    const [businessInfo, setBusinessInfo] = useState();
    const [businessDates, setBusinessDates] = useState();
    const [machines, setMachines] = useState();
    const [machinesCreationInfo, setMachinesCreationInfo] = useState();
    const [revisions, setRevisions] = useState();
    const [BAfiles, setBAFiles] = useState();
    const [configs, setConfigs] = useState();
    const [dp, setDp] = useState();
    const [annexes, setAnnexes] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);
    const [imgDisplay, setImgDisplay] = useState();
    const [readOnly, setReadonly] = useState(true);
    const [BAmachines, setBAmachines] = useState([]);
    const [deliveryNote, setDeliveryNote] = useState([]);
    const [customerInvoice, setCustomerInvoice] = useState([]);
    const [businessInfoFocus, setBusinessInfoFocus] = useState();
    const [lastDelivery, setLastDelivery] = useState("");
    const [history, setHistory] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        var business = getUrlParam(props, "code");
        setBusiness(business);

        if (getUrlParam(props, "tab") === "tecma") {
            setTab(3);
        }

        var readOnly = !user.roles.includes("ROLE_WRITE_FM");
        setReadonly(readOnly)

        //Business info
        BusinessDao.getBusinessInfo(business).then(
            (response) => {
                setBusinessInfo(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

         //Business dates
         BusinessDao.getBusinessDates(business).then(
            (response) => {
                setBusinessDates(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Dropdown menu
        BusinessDao.getDp().then(
            (response) => {
                setDp(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Machines
        BusinessDao.getMachines(business).then(
            (response) => {
                setMachines(response);
                var arr = new Array();
                response.map(v => { if (v.group.startsWith("BA_TECMA")) arr.push(v); })
                setBAmachines(arr);
                getLastDelivery(response);

                //Get tecma files
                if (arr.length > 0) {
                    BusinessDao.getTecmaFiles(business, arr).then(
                        (response) => {
                            for (let i = 0; i < arr.length; i++)
                                arr[i].url = getFm(arr[i].no_machine, response)

                            setBAmachines(arr);
                            setBAFiles(response);
                        },
                        (error) => {
                            setError(errorManagement.handleError(props, error));
                            scrollToTop();
                            setLoading(false);
                        }
                    );
                }
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        function getLastDelivery(arr) {
            var lastDelivery = "1900-01-01";
            var lastMachine = "";
            var missing = "";

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].delivery_date) {
                    if (compareTwoDates(lastDelivery, arr[i].delivery_date) > 0) {
                        lastDelivery = arr[i].delivery_date;
                        lastMachine = arr[i].no_machine;
                    }
                } else {
                    missing += "#" + arr[i].no_machine + ", "
                }
            }

            if (lastDelivery !== "1900-01-01") {
                var txt = "Dernière machine livrée: #" + lastMachine + " le " +
                    getFrFormat(lastDelivery, true);

                if (missing !== "") {
                    missing = missing.substring(0, missing.lastIndexOf(","));
                    txt += "\nLivraison(s) à venir: " + missing;
                }

                setLastDelivery(txt);
            }
        }

        function getFm(machine, files) {
            if (!machine) return null;
            if (machine.indexOf("/")) machine = machine.split("/")[1];

            for (let i = 0; i < files.length; i++) {
                if (files[i].type === "fm" && files[i].name.indexOf(machine) !== -1)
                    return files[i].url;
            }

            return null;
        }

        //Configs
        BusinessDao.getConfigs().then(
            (response) => {
                setConfigs(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Annexes
        BusinessDao.getAnnexes(business).then(
            (response) => {
                setAnnexes(response);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        //Revisions
        BusinessDao.getRevisions(business).then(
            (response) => {
                var rev = new Array();
                var revBA = new Array();

                response.map(v => {
                    if (v.no_business.startsWith("BA")) {
                        revBA.push(v);
                    } else {
                        rev.push(v);
                    }
                })

                setRevisions(rev);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        // Delivery note
        if (!readOnly) {
            BusinessDao.getDeliveryNote(business).then(
                (response) => {
                    setDeliveryNote(response);
                },
                (error) => {

                }
            );

            BusinessDao.getCustomerInvoice(business).then(
                (response) => {
                    setCustomerInvoice(response);
                },
                (error) => {

                }
            );
        }

        BusinessDao.getBusinessInfoFocus(business).then(
            (response) => {
                setBusinessInfoFocus(response);
            },
            (error) => {

            }
        );


        if (user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_WRITE_FM"))) {
            BusinessDao.getMachineCreationInfo().then(
                (response) => {
                    setMachinesCreationInfo(response)
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            )
        }


        // History
        Miscellaneous.getLog({ id: business, origin: "business" }).then(
            (response) => { setHistory(response) },
            (error) => { console.error(error) }
        )

    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    /**
     * Update business fields
     * @param {*} e 
     */
    const updateBusinessField = (e) => {
        if (readOnly) return;

        var field = e.target.getAttribute("data-field");
        var translate = e.target.getAttribute("data-translate") !== null;
        var value = e.target.value;
        var typeField = e.target.type;
        if (typeField === "textarea" || typeField === "select-one") typeField = "text";
        setError();

        var old_value = businessInfo[field];

        if (forceString(old_value) === forceString(value) || old_value === null && value === "") return;

        var log = { ...logObj };
        log.origin = "business";
        log.id = business;
        log.log_text = "Mise à jour du champ " + (getLabelFromInput(e.target) || field);
        log.old_value = old_value === null ? "" : old_value;
        log.new_value = value;

        BusinessDao.updateBusinessField(business, field, value, typeField, translate).then(
            (response) => {
                onBusinessChange(e);
                Miscellaneous.saveLog(log);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    /**
     * Change state of field
     * @param {*} e 
     */
    const onBusinessChange = (e) => {
        if (readOnly) return;

        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...businessInfo };
        o[field] = value;
        setBusinessInfo(o);
    }

    /**
     * Add logo client
     * @param {*} e 
     */
    const addLogo = async (e) => {
        if (readOnly) return;

        var field = e.target.getAttribute("data-field");
        var o = { ...businessInfo };

        
        const b64 = await fileToBase64( e.target.files[0] );

        o[field] = b64.fullBase64;

        var log = { ...logObj };
        log.id = business;
        log.origin = "business";
        log.log_text = "Modification de logo client" + (field === "logo_client" ? "" : " final");
        log.old_value = "";
        log.new_value = b64.name;


        o[field] = b64.fullBase64;
        setBusinessInfo(o);
        e.target.value = "";

        BusinessDao.uploadFile(business, field, b64, "/upload-file/v2").then(
            (response) => { Miscellaneous.saveLog(log); },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const addAnnexe = async (e) => {
        if (readOnly) return;

        var arr = [...annexes];

        const base64 = await fileToBase64(e.target.files[0]);
        arr.push({
            name: base64.name,
            img: base64.base64,
            new: true
        });
        setAnnexes(arr);
        e.target.value = "";

        var log = { ...logObj };
        log.id = business;
        log.origin = "business";
        log.log_text = "Ajout d'annexe";
        log.old_value = "";
        log.new_value = base64.name;


        BusinessDao.uploadFile(business, "annexe", base64, "/upload-annexe").then(
            (response) => { Miscellaneous.saveLog(log); },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

    }

    /**
     * Display the image annexe
     * @param {*} e 
     */
    const displayAnnexe = (e) => {
        setImgDisplay(e.target.getAttribute("data-img"));
    }

    const deleteAnnexe = (e) => {
        if (readOnly) return;

        var key = e.target.getAttribute("data-key");
        var arr = [...annexes];

        var log = { ...logObj };
        log.id = business;
        log.origin = "business";
        log.log_text = "Suppression d'annexe";
        log.old_value = arr[key].name;
        log.new_value = "";

        BusinessDao.deleteAnnexe(business, arr[key].name).then(
            (response) => {
                Miscellaneous.saveLog(log);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

        arr = arrayRemoveElt(arr, key);

        setAnnexes(arr);

    }

    return (
        <Fragment>

            <h4 className="text-center">{business}</h4>
            { (businessInfo && businessInfo.date_clot_intr) && 
            <div className="text-center">
                <img src="/common/closed.png" style={{width: "80px", marginTop: "-25px"}}/>
            </div> }

            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
            {loading && <WaitingRoundSnippers />}



            <Fragment>
                <ul className="nav nav-tabs">
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 0 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="0">Fiche affaire</a>
                    </li>
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 2 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="2">Autres infos & actions</a>
                    </li>
                    {
                        !readOnly &&
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 1 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="1">Exporter</a>
                        </li>
                    }
                    {
                        (BAmachines.length > 0 && !readOnly) &&
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 3 ? "active" : "")}
                                href="#" onClick={changeTab} data-tab="3">Commande TECMA</a>
                        </li>
                    }

                    {
                        (businessInfo && businessInfo.schengen === 0) && <Fragment>
                            <li className="nav-item fw-bold">
                                <a className={"nav-link " + (tab === 4 ? "active" : "")}
                                    href="#" onClick={changeTab} data-tab="4">Bordereau d'exp.</a>
                            </li>

                            <li className="nav-item fw-bold">
                                <a className={"nav-link " + (tab === 5 ? "active" : "")}
                                    href="#" onClick={changeTab} data-tab="5">Edit. facture</a>
                            </li>

                        </Fragment>
                    }
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 6 ? "active" : "")}
                            href="#" onClick={changeTab} data-tab="6">Historique</a>
                    </li>
                </ul>

                {
                    (tab === 0 && businessInfo && configs && annexes && dp) &&
                    <Fragment>
                        {/** GENERAL + CONTEXTE + PRESTATION */}
                        <PartOne businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} />
                        <br></br>

                        {/** MACHINES */}
                        <PartTwo machines={machines} configs={configs} setMachines={setMachines} business={businessInfo}
                            machinesCreationInfo={machinesCreationInfo} setMachinesCreationInfo={setMachinesCreationInfo}
                            readOnly={readOnly} />
                        <br></br>

                        {/** DELIVERY ADDRESS - PROJECT MANAGER - YARD MANAGER - MAINTENANCE MANAGER - PURCHASER */}
                        <PartThree businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} dp={dp} />
                        <br></br>

                        {/** DOCUMENTS - PLANNING - LIMITS */}
                        <PartEight businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} />
                        <br></br>

                        {/** CUSTOMER DPT - OTHERS - PARTICULARITIES */}
                        <PartFour businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} readOnly={readOnly} />
                        <br></br>

                        {/** LOGOS + ANNEXES */}
                        <PartFive businessInfo={businessInfo} addLogo={addLogo} imgDisplay={imgDisplay}
                            annexes={annexes} addAnnexe={addAnnexe} displayAnnexe={displayAnnexe}
                            deleteAnnexe={deleteAnnexe} readOnly={readOnly} />
                        <br></br>
                    </Fragment>
                }

                {   
                    (tab === 2 && businessInfo && machines && businessDates && businessInfoFocus) &&
                    <Fragment>
                        {/** OTHERS ACTION & ANNEXES */}
                        <PartSix businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} machines={machines} lastDelivery={lastDelivery} 
                            businessDates={businessDates} businessInfoFocus={businessInfoFocus}/>
                        <br></br>
                    </Fragment>
                }

                {
                    (machines && tab === 1 && revisions && machines) &&
                    <Fragment>
                        {/** EXPORTS DOCS */}
                        <PartSeven businessInfo={businessInfo} updateBusinessField={updateBusinessField}
                            onBusinessChange={onBusinessChange} revisions={revisions} machines={machines}
                            annexes={annexes} />
                    </Fragment>
                }

                {
                    (tab === 3 && BAmachines && BAfiles) &&
                    <BAContract BAmachines={BAmachines} setBAmachines={setBAmachines}
                        BAfiles={BAfiles} setBAFiles={setBAFiles} setError={setError} setMsg={setMsg}
                        readOnly={readOnly} />
                }

                {
                    (tab === 4 && businessInfo && machines) &&
                    <DeliveryNote businessInfo={businessInfo} machines={machines} setError={setError}
                        deliveryNote={deliveryNote} setMsg={setMsg} />
                }

                {
                    (tab === 5 && businessInfo && machines && businessInfoFocus) &&
                    <CustomerInvoice businessInfo={businessInfo} machines={machines} setError={setError}
                        customerInvoice={customerInvoice} businessInfoFocus={businessInfoFocus} setMsg={setMsg} />
                }

                { tab === 6 && <History history={history}/> }


            </Fragment>

            {dp && <DropDownMenu list={dp} />}

        </Fragment >
    );

}

export default BusinessCard;