import { useEffect, useRef, useState } from "react"
import Autocomplete from "react-autocomplete"
import { ButtonWaiting, getHeight } from "../../functions/PageAnimation"
import ArticleInfo from "../../services/articles/ArticleInfo"
import { Cookies, useCookies } from "react-cookie"

/**
 * checked + onChange
 * @param {*} p 
 * @returns 
 */
export const DesignedChckBox = (p) => {
    const getStyle = () => {
        if (!p.style) return {};
        return p.style;
    }

    return <div className="checkbox-wrapper-12 text-center">
        <div className="cbx">
            <input id="cbx-12" type="checkbox" checked={p.checked} onChange={p.onChange}
                className={p.className} />
            <label for="cbx-12"></label>
            <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
                <path d="M2 8.36364L6.23077 12L13 2"></path>
            </svg>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                <filter id="goo-12">
                    <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur>
                    <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></fecolormatrix>
                    <feblend in="SourceGraphic" in2="goo-12"></feblend>
                </filter>
            </defs>
        </svg>
    </div>
}

export const SingleWaiting = (p) => {
    return <div className={"spinner-border " + (p.classes || "")} role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
}

export const BtnWithCheck = ({ onClick, label, id }) => {
    
    function onClickInside() {
        const button = document.getElementById(id);
        const checkmark = document.getElementById('checkmark' + id);
        button.style.display = 'none';
        checkmark.style.display = 'inline-block';

        onClick();

        // Show the checkmark for 2 seconds
        setTimeout(() => {
            checkmark.style.display = 'none';
            button.style.display = 'inline-block';
        }, 1000);
    }

    return <div>
        <button className="btn btn-outline-success" id={id}
            onClick={onClickInside}>{label}</button>
        <span class="checkmark" id={"checkmark" + id} style={{display: "none"}}>✔</span>
    </div>

}

/**
 * Need: value, setValue, onChooseArt
 * @param {*} p 
 * @returns 
 */
export const ArticleAutoComplete = (p) => {
    const [items, setItems] = useState([]);
    const [search, setSearch] = useState(false);
    const [articleCodes, setArticles] = useState();

    const [found, setFound] = useState(false);
    const [selectedArt, setSelectedArt] = useState("");

    useEffect(() => {
        /*fetch('/files/articles.txt')
            .then((r) => r.text())
            .then(text => {
                setArticles(text.split(","));
            })*/

        ArticleInfo.getAutocompletionTxt().then(r => setArticles(r))
    }, []);

    const onChangeIpt = (e) => {
        setItems([]);
        var val = e.target.value;
        p.setValue(val);
        var arr = new Array();
        getSuggestions(e).slice(0, 20).map(v => arr.push({ label: v }));
        setItems(arr);
    }

    // Search the article in file
    const getSuggestions = (e) => {
        const inputValueLowerCase = e.target.value.toLowerCase();
        return articleCodes.filter(
            (code) => code.toLowerCase().includes(inputValueLowerCase)
        );
    };

    //Return the choosen article
    const onArticleChoose = (val) => {
        p.setValue(val);

        if (selectedArt === val && found) return;

        setSelectedArt(val);

        if (p.searchDetail) {


            setSearch(true);
            ArticleInfo.getAllArtInfoStrictSearchLight(val).then(
                (r) => {
                    p.onChooseArt(r[0]);
                    setSearch(false);
                    setFound(true);
                }, () => {
                    p.onChooseArt(null);
                    setSearch(false);
                }
            )
        } else {
            p.onChooseArt(val);
        }
    }

    function menuCss() {
        return {
            zIndex: "2000", position: "fixed", borderBottomStyle: "solid", borderLeftStyle: "solid",
            borderRightStyle: "solid", borderWidth: "thin", borderColor: "#dee2e6", borderBottomLeftRadius: "0.5em",
            borderBottomRightRadius: "0.5em", maxHeight: "300px", overflowY: "auto"
        }
    }

    return <Autocomplete
        getItemValue={(item) => item.label}
        items={items}
        menuStyle={menuCss()}
        selectOnBlur={true}
        renderItem={(item, isHighlighted) =>
            <div className={"cursor-pointer p-1 text-center " + (isHighlighted ? "fw-bold" : "")}
                style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.label}
            </div>
        }
        renderInput={function (props) {
            return <div className="display-flex input-group">
                {
                    search &&
                    <div className="form-control" style={{ maxWidth: "50px" }}>
                        <ButtonWaiting />
                    </div>
                }
                <input className="form-control" name="ar" {...props} placeholder="Code article"
                    required={p.required} />
            </div>
        }}
        value={p.value} onChange={onChangeIpt} onSelect={onArticleChoose}
    />
}

/**
 * 
 * @param {*} setPin={setPin} const [pin, setPin] = useState(isSideBarOpened());
 * @param {*} content Any content
 * 
 * Wrap complete content: <div className="bg-see navbarV3 right-side">
 * Wrap right side: <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
 * @returns Side navbar
 */
export const SideNavBarV3 = (p) => {
    var isPined = isSideBarOpened();

    const [mini, setMini] = useState(isPined ? "expanded" : "mini");
    const [pin, setPin] = useState(isPined);
    const [cookies, setCookie] = useCookies(['name']);

    const onChangePin = () => {
        var newStatus = !pin;

        setPin(newStatus);
        p.setPin(newStatus);
        setMini(newStatus ? "expanded" : "mini");

        //Save cookie
        var cookiesExpiration = new Date();
        cookiesExpiration.setFullYear(new Date().getFullYear() + 1);
        setCookie("side-bar", newStatus, { expires: cookiesExpiration });
    }

    return <div className={"sideNavBarV3 " + (mini)} id="sideNavBarV3"
        onMouseOver={() => !pin && setMini("expanded")} onMouseOut={() => !pin && setMini("mini")} >

        {
            p.topElement &&
            <div className={"ps-2 mt-2 navContentInside " + mini}
                style={{ position: "absolute", top: "0px" }}>
                {p.topElement}
            </div>
        }

        <img src="/common/right_arrow_icon.png" className={"expand-logo " + mini}
            onClick={onChangePin} />

        <div className={"navContent overflow-x-hidden "
            + (mini !== "expanded" ? "overflow-y-hidden" : "overflow-y-auto")}
            id="navContent" style={{ height: getHeight("navContent") }}>
            <div className={"navContentInside " + mini}>
                {p.content}
            </div>
        </div>
    </div>
}

export const isSideBarOpened = () => {
    const cookies = new Cookies();
    return cookies.get("side-bar") === undefined ? false : cookies.get("side-bar");
}

export const MultiSelectCheckbox = ({ options, selectedOptions, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCheckboxChange = (option) => {
        const newSelectedOptions = option === "all" ?
            [...options] :
            option === "nothing" ? [] :
                selectedOptions.includes(option)
                    ? selectedOptions.filter((item) => item !== option)
                    : [...selectedOptions, option];
        onChange(newSelectedOptions);
    };

    function getTitle(val) {
        if (val.length > 10) return val.substring(0, 10) + "...";
        return val
    }

    return (
        <div className="multi-select-checkbox" ref={dropdownRef}>
            <div className="select-box" onClick={() => setIsOpen(!isOpen)}>
                {selectedOptions.length > 0 ? getTitle(selectedOptions.join(', ')) : 'Select options'}
            </div>

            {isOpen && (
                <div className="multi-select-dropdown">

                    <div key="all" className="option">
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedOptions.length === options.length}
                                onChange={() => handleCheckboxChange(selectedOptions.length === options.length ? "nothing" : "all")}
                            />
                            All
                        </label>
                    </div>


                    {options.map((option) => (
                        <div key={option} className="option">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedOptions.includes(option)}
                                    onChange={() => handleCheckboxChange(option)}
                                />
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelectCheckbox;
