import React, { Component }  from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { isEmail } from "validator";

const user = JSON.parse(localStorage.getItem("user"));
const token = user && "Bearer " + user.accessToken;

const required = (value) => {
if (!value) {
    return (
    <div className="alert alert-danger" role="alert">
        This field is required!
    </div>
    );
}
};

const validEmail = (value) => {
if (!isEmail(value)) {
    return (
    <div className="alert alert-danger" role="alert">
        This is not a valid email.
    </div>
    );
}
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
        <div className="alert alert-danger" role="alert">
            The password must be between 6 and 40 characters.
        </div>
        );
    }
};

const downloadFile = (url) => {
    if( url.indexOf("?") === -1 ){
        url += "?";
    }else{
        url += "&";
    }
    window.open("", "_blank").location.href = url + "Authorization=" + token;
}

export function setAllReadOnly(){
    var f = document.getElementsByClassName("bodyContent")[0].getElementsByTagName('input');
    for(var i=0;i<f.length;i++) f[i].readOnly = true;

    var f2 = document.getElementsByClassName("bodyContent")[0].getElementsByTagName('select');
    for(var i=0;i<f2.length;i++) f2[i].disabled = true;
        
}

const getTxtAreaValue = (value) => {
    if(value === undefined || value === null || !value.startsWith("<txt")) return "";

    var val = value.substring( value.indexOf(">") +1 );
    val = val.substring(0, val.indexOf('</txt>'));
    return val;
}

const getTxtAreaName = (value) => {
    if(value === undefined || value === null || value.indexOf("name") === -1 ) return "";

    var val = value.substring( value.indexOf("name=\"") +6 );
    val = val.substring(0, val.indexOf("\""));
    return val;
}

const writeTxtAreaValue = (name, value) => {
    if(value === undefined || value === null) value = "";
    return "<txt name=\""+name+"\" >"+value+"</txt>";
}

const isValidDateTime = (val) => {
    if( val === null || val === "" ) return false;

    var date = new Date( val );
    return !isNaN(date);
}

const confirmDialog = (options) => {
    return new Promise((resolve) => {
      confirmAlert({
        ...options,
        buttons: [
          {
            label: 'Yes',
            onClick: () => resolve(true),
          },
          {
            label: 'No',
            onClick: () => resolve(false),
          },
        ],
      });
    });
  };

export {
    required,
    validEmail,
    vpassword,
    downloadFile,
    getTxtAreaValue,
    getTxtAreaName,
    writeTxtAreaValue,
    isValidDateTime,
    confirmDialog
};