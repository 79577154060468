import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import FormMgtDao from "../../services/settings/FormMgtDao";
import { addAfter, arrayRemoveElt } from "../../functions/ArrayFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import Miscellaneous from "../../services/common/Miscellaneous";

const MiscellaneousCpt = (props) => {

    const [list, setList] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState();

    const updateValue = (type, value) => {
        Miscellaneous.updateState(type, value)
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: '#',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('type', {
            header: 'Type',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('value1', {
            header: 'Valeur 1',
            cell: i => <div className="text-center">
                <input className="form-control" defaultValue={i.getValue()}
                    onBlur={(e) => updateValue(i.row.original.type, e.target.value)} />
            </div>
        }));


        return arr;
    }

    const columns = getColumns();

    useEffect(() => {
        setLoading(true);

        Miscellaneous.getAllState().then(
            (response) => {
                setList(response)
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }, []);

    return <div><h5 className="text-center">Gestion de divers paramètres</h5>
        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        <form onSubmit={(e) => {e.preventDefault(); updateValue( e.target.type.value, e.target.value.value )}}>
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">Ajouter</span>
                <input type="text" name="type" className="form-control" placeholder="Type" required/>
                <input type="text" name="value" className="form-control" placeholder="Valeur" required/>
                <button className="btn btn-success">Ajouter</button>
            </div>
        </form>

        <ReactTable columns={columns} data={list}
            origin="misc_mngt" classes={"no-padding fixFstCol"} top0={true} />
    </div>

}

export default MiscellaneousCpt;