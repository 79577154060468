import { useEffect, useMemo, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import getUrlParam from "../../functions/StrFunctions";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import Moment from "moment";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { Fragment } from "react";
import { arrayRemoveElt, removeEltArray } from "../../functions/ArrayFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";

const ArticleStock = (props) => {
    const [data, setData] = useState([]);
    const [article, setArticle] = useState();

    const [loading, setLoading] = useState(false);
    const [closing, setClosing] = useState(false);

    const [error, setError] = useState();
    const [lastIndex, setLastIndex] = useState();

    const [replacement, setReplacement] = useState("");
    const [toReplace, setToReplace] = useState([]);

    const [toClose, setToClose] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));

    Moment.locale("fr");
 
    useEffect(() => {
        var ar = getUrlParam(props, "article");
        setArticle(ar);

        if (ar !== null) {
            setLoading(true);
            getTable(ar);
        }

    }, []);

    function getBg(status){
        if( status === "Lancement en cours" ) return { color: "white", backgroundColor: "#C4C4C4" }
        if( status === "Mise en service" ) return { color: "white", backgroundColor: "#91D8FD" }
        if( status === "En réserve" ) return { color: "white", backgroundColor: "#9AADBD" }
        if( status === "BE" ) return { color: "black", backgroundColor: "#FFDD2D" }
        if( status === "Livraison en cours" ) return { color: "white", backgroundColor: "#3C3C3C" }
        if( status === "Clôturée" ) return { color: "white", backgroundColor: "#56188D" }
        if( status === "En attente achats" ) return { color: "white", backgroundColor: "#00C336" }
        if( status === "En attente MeS" ) return { color: "white", backgroundColor: "#469DFF" }
        if( status === "En cours ST" ) return { color: "black", backgroundColor: "#FFB4F1" }
        if( status === "Achats" ) return { color: "white", backgroundColor: "#90D04F" }
        if( status === "MeS site" ) return { color: "white", backgroundColor: "#C91E46" }
        if( status === "Logistique" ) return { color: "white", backgroundColor: "#009145" }
        if( status === "En attente MeP" ) return { color: "black", backgroundColor: "#CFBB47" }
        if( status === "Montage" ) return { color: "white", backgroundColor: "#AD3FE8" }
        if( status === "MeP site" ) return { color: "white", backgroundColor: "#7F5045" }

    }

    const getColumn = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: '#',
            cell: i => <div className="text-center p-1">{parseInt(i.row.id) + 1}</div>
        }));

        arr.push(columnHelper.accessor('business', { header: 'Affaire',
            cell: i => <div className="text-center p-1">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('machine', { header: 'Machine',
            cell: i => <div className="text-center p-1">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('date', {
            header: 'Date du besoin',
            cell: i => <div className={i.row.original.in !== null && i.row.original.confirmed_date === null ?
                "bg-danger text-white" : ""}>{i.getValue() && Moment(i.getValue()).format("DD/MM/YYYY")}</div>
        }));

        arr.push(columnHelper.accessor('need_creation', {
            header: 'Date création besoin',
            cell: i => <div className="text-center p-1">
                {i.getValue() && Moment(i.getValue()).format("DD/MM/YYYY")}</div>
        }));

        arr.push(columnHelper.accessor('supplier', { header: 'Fournisseur',
            cell: i => <div className="text-center p-1">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('in', { header: 'Entrée',
            cell: i => <div className="text-center p-1">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('out', { header: 'Sortie',
            cell: i => <div className="text-center p-1">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('available', { header: 'Disponible',
            cell: i => <div className="text-center p-1">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('affected', { header: 'Qté affectée',
            cell: i => <div className="text-center p-1">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('status', { header: 'Statut Monday',
            cell: i => <div className="text-center p-1" style={getBg(i.getValue())}>{i.getValue()}</div>
         }));

        if (user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_NEED_MANAGE"))) {
            arr.push(columnHelper.accessor('xxx', {
                header: 'Solder le besoin',
                cell: i => i.row.original.need && <div className="text-center"><input type="checkbox"
                    checked={toClose && toClose.includes(i.row.original.need)}
                    onChange={(e) => onCloseChange(e, i.row.original.need)} ></input></div>
            }));

            arr.push(columnHelper.accessor('xxxx', {
                header: 'A transférer',
                cell: i => i.row.original.need && <div className="text-center"><input type="checkbox"
                    checked={toReplace && toReplace.includes(i.row.original.need)}
                    onChange={(e) => addToReplace(e, i.row.original.need)}></input></div>
            }));
        }

        return arr;
    }

    const [columns, setColumns] = useState(getColumn());

    useEffect(() => { setColumns(getColumn()); }, [toReplace, toClose, data]);

    /**
     * Articles to replace
     * @param {*} e 
     * @param {*} need 
     */
    const addToReplace = (e, need) => {
        var arr = [...toReplace];

        if (e.target.checked) { arr.push(need); }
        else { arr = removeEltArray(arr, need); }
        setToReplace(arr);
    }

    /**
     * Article to close
     * @param {*} e 
     */
    const onCloseChange = (e, need) => {
        var arr = [...toClose];
        if (e.target.checked) { arr.push(need); }
        else { arr = arrayRemoveElt(arr, arr.indexOf(need)) }
        setToClose(arr);
    }

    /**
     * Get list of dynamic stock
     * @param {*} ar 
     */
    const getTable = (ar) => {
        ArticleInfo.getDynamicStockInfo(ar).then(
            (response) => {
                console.log(response)
                setColumns(getColumn());
                setData(response.list);
                if (response.lastIndex.CDARL.trim() !== ar) setLastIndex(response.lastIndex);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    /**
     * Upgrade article index
     * @param {*} e 
     */
    const changeIndex = (e) => {
        setClosing(true);
        setError();
        ArticleInfo.changeIndexNeed(replacement, toReplace).then(
            () => {
                var arr = new Array();
                data.map( (v) => { if( !toReplace.includes( v.need ) ) arr.push(v) } );

                setData(arr);
                setToReplace([]);
                setClosing(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setClosing(false);
            }
        );
    }

    /**
     * Close Needs
     */
    const closeNeeds = () => {
        setClosing(true);
        setError();
        ArticleInfo.closeNeeds(article, toClose).then(
            () => {
                var arr = new Array();
                data.map( (v) => { if( !toClose.includes( v.need ) ) arr.push(v) } );

                setData(arr);
                setToClose([]);
                setClosing(false);
            },
            (error) => {
                setClosing(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    return (
        <div>

            <form className="col-6 offset-3">
                <div className="input-group mb-3">
                    <span className="input-group-text">Article</span>
                    <input type="text" className="form-control text-center fw-bold"
                        name="article" defaultValue={article} />
                    <button type="submit" className="btn btn-outline-success">Chercher</button>
                </div>
            </form>

            {loading && <WaitingRoundSnippers />}

            {error && <ErrorMessage error={error} errorState={setError}/>}

            {data &&
                <div className="bg-danger text-white fw-bold" style={{ position: "absolute", padding: "5px", borderRadius: "12px" }}>
                    Date non confirmée<br />par le fournisseur
                </div>
            }



            {
                !loading &&
                <div className="col-10 offset-1">

                    <div className="alert alert-primary text-center fw-bold" role="alert">
                        {(lastIndex && lastIndex.CDARL.trim() !== article.trim() && !lastIndex.DES2L.toUpperCase().endsWith("DOUB")) &&
                            <span>Un nouvel indice existe: <a href={"/article/info/get?article=" + lastIndex.CDARL}
                                target="_blank">{lastIndex.CDARL}</a> en quantité {lastIndex.QTSTL}. </span>
                        }

                        {
                            (user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_NEED_MANAGE"))) &&
                            <Fragment>
                                <span>Transférer ces besoins vers un nouvel article:</span><br></br>
                                <div className="col-8 offset-2">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Article de remplacement</span>
                                        <input type="text" className="form-control" placeholder="Saisissez le code AR et cochez les besoins à transférer"
                                            value={replacement} onChange={(e) => setReplacement(e.target.value)} />
                                        {
                                            toReplace.length > 0 && replacement !== "" &&
                                            <button className="btn btn-outline-success" disabled={closing}
                                                onClick={changeIndex}>
                                                {closing && <ButtonWaiting />}
                                                Transférer
                                            </button>
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        }

                        {
                            toClose.length > 0 && <div className="text-center">
                                <button className="btn btn-warning" onClick={closeNeeds} disabled={closing}>
                                    {closing && <ButtonWaiting />}
                                    {toClose.length > 1 && <span>Clôturer les {toClose.length} articles cochés</span>}
                                    {toClose.length === 1 && <span>Clôturer l'article cochés</span>}
                                </button>
                            </div>
                        }

                    </div>


                    <ReactTable columns={columns} data={data} top0={true} origin="dynamic_stock" tdStyle={{padding: "0px"}}/>
                </div>
            }

        </div>
    )
}

export default ArticleStock;