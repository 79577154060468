import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";
import { fileToBase64 } from "../../functions/StrFunctions";

const API_URL = process.env.REACT_APP_API_URL + "transport";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";


const getTransportList = () => {

    return axios
        .post(API_URL + "/list", {
            fake_header: authHeader()
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const getTransportListPeriod = (date) => {

    return axios
        .post(API_URL + "/list", {
            fake_header: authHeader(),
            date: date
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const setOrderToTransport = (id, order) => {

    return axios
        .post(API_URL + "/update-order", {
            fake_header: authHeader(),
            id: id,
            order: order
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}

const updateTransportField = (machine, field, value, typeField) => {

    return axios
        .post(API_URL + "/update-transport", {
            fake_header: authHeader(),
            machine: machine,
            field: field,
            value: value,
            typeField: typeField
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            }
        })
        .then((response) => {
            return response.data;
        });
}
 
const notifyExpedition = (business, machines, delivery, comments, detailId, expId) => {
    return axios
    .post(API_URL + "/notify-expedition", {
        fake_header: authHeader(),
        business: business,
        machines: machines,
        delivery: delivery,
        comments: comments,
        detailId: detailId,
        expId: expId

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const getForm = (id) => {
    return axios
    .post(API_URL + "/v2/form/get", {
        fake_header: authHeader(),
        id: id

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const saveForm = (form, send, formOriginChange, businessInfo, lessThan48h) => {
    return axios
    .post(API_URL + "/v2/form/save", {
        fake_header: authHeader(),
        form: form,
        send: send,
        formOriginChange: formOriginChange,
        businessInfo: businessInfo,
        lessThan48h: lessThan48h
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}


const getList = (getAll) => {
    return axios
    .post(API_URL + "/v2/form/list", {
        fake_header: authHeader(),
        getAll: getAll
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const shipmentExists = (id) => {
    return axios
    .post(API_URL + "/v2/form/shipment/exists", {
        fake_header: authHeader(),
        id: id

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const getShipmentForm = (id, shipment_id) => {
    return axios
    .post(API_URL + "/v2/form/shipment/get", {
        fake_header: authHeader(),
        id: id,
        shipment_id: shipment_id

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const saveShipmentForm = (o) => {
    return axios
    .post(API_URL + "/v2/form/shipment/save", {
        fake_header: authHeader(),
        ...o

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const deleteDetailById = (id, type, id_exp) => {
    return axios
    .post(API_URL + "/v2/form/shipment/detail/delete", {
        fake_header: authHeader(),
        id: id,
        type: type, 
        id_exp: id_exp

    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const syncOrderNb = () => {
    return axios
    .post(API_URL + "/v2/form/shipment/list/sync", {
        fake_header: authHeader()
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const searchDrawing = (business) => {
    return axios
    .post(API_URL + "/v2/form/shipment/drawing/search", {
        fake_header: authHeader(),
        business: business
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const sendRequestPrice = (business, msg, recipients, exp_id) => {
    return axios
    .post(API_URL + "/v2/form/shipment/price-request", {
        fake_header: authHeader(),
        business: business,
        msg: msg,
        recipients: recipients,
        exp_id: exp_id
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const uploadFile = async (detail_id, file, field) => {
    
    const b64 = await fileToBase64(file);

    return axios
    .post(API_URL + "/v2/form/shipment/upload", {
        fake_header: authHeader(),
        detail_id: parseInt(detail_id),
        field: field,
        file: b64
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
    
    let formData = new FormData();

    formData.append("detail_id", parseInt(detail_id));
    formData.append("file", file);
    formData.append("field", field);
    formData.append("fake_header", token);

    return axios({
        method: "post",
        url: API_URL + "/v2/form/shipment/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        return response.data;
    });

}

const compressFiles = (files) => {
    return axios
    .post(API_URL + "/v2/form/shipment/download", {
        fake_header: authHeader(),
        files: files
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const cancelReqest = (form) => {
    return axios
    .post(API_URL + "/v2/form/shipment/cancel", {
        fake_header: authHeader(),
        form: form
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const reactivateRequest = (form) => {
    return axios
    .post(API_URL + "/v2/form/shipment/reactivate", {
        fake_header: authHeader(),
        form: form
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const updateShipField = (shipmentId, field, value) => {
    return axios
    .post(API_URL + "/v2/form/shipment/field/update", {
        fake_header: authHeader(),
        shipmentId: shipmentId,
        field: field,
        value: value
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const updateShipDetailField = (shipmentId, field, value) => {
    return axios
    .post(API_URL + "/v2/form/shipment-detail/field/update", {
        fake_header: authHeader(),
        shipmentId: shipmentId,
        field: field,
        value: value
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain'
        }
    })
    .then((response) => {
        return response.data;
    });
}

const getHistoric = (id) => {
    return axios
        .post(API_URL + "/v2/form/shipment/get/historic",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPicking = (shipment_id, picking_id) => {
    return axios
        .post(API_URL + "/v2/form/shipment/machines/get/picking",
            {
                fake_header: authHeader(),
                shipment_id: shipment_id,
                picking_id: picking_id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getOtherPicking = (shipment_id, id, picking_id) => {
    return axios
        .post(API_URL + "/v2/form/shipment/other/get/picking",
            {
                fake_header: authHeader(),
                shipment_id: shipment_id,
                id:id,
                picking_id: picking_id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const savePicking = (shipment_id, list, picking_head, send) => {
    return axios
        .post(API_URL + "/v2/form/shipment/machines/save/picking",
            {
                fake_header: authHeader(),
                shipment_id: shipment_id,
                list: list,
                picking_head: picking_head,
                send: send
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveOtherPicking = (shipment_id, list, picking_head, send, currentPicking) => {
    return axios
        .post(API_URL + "/v2/form/shipment/other/save/picking",
            {
                fake_header: authHeader(),
                shipment_id: shipment_id,
                list: list,
                picking_head: picking_head,
                send: send,
                currentPicking: currentPicking
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deletePicking = (pickingId) => {
    return axios
        .post(API_URL + "/v2/form/shipment/machines/delete/picking",
            {
                fake_header: authHeader(),
                pickingId: pickingId
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadHashTag = (list) => {
    return axios
        .post(API_URL + "/v2/form/shipment/machines/print/hash-tag",
            {
                fake_header: authHeader(),
                list: list
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadPacking = (picking, tab) => {
    return axios
        .post(API_URL + "/v2/form/shipment/machines/print/packing",
            {
                fake_header: authHeader(),
                picking: {...picking},
                tab: tab
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPossibleShipOrigin = (business) => {
    return axios
        .post(API_URL + "/v2/get/type",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getFullExpeditionInfo = (id) => {
    return axios
        .post(API_URL + "/v2/expedition/get/full-info",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const notifyExpeditionPack = (arr, recipient, type, exp_id) => {
    return axios
        .post(API_URL + "/v2/expedition/notify/pack",
            {
                fake_header: authHeader(),
                arr: arr,
                recipient: recipient,
                type: type,
                exp_id: exp_id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const notifyDelivery = (o) => {
    return axios
        .post(API_URL + "/notify-delivery",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const createCompleteProcessFromPicking = (o) => {
    return axios
        .post(API_URL + "/v2/form/shipment/picking/create-all",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getTransportList,
    setOrderToTransport,
    getTransportListPeriod,
    updateTransportField,
    notifyExpedition,
    getForm,
    saveForm,
    getList,
    getShipmentForm,
    shipmentExists,
    saveShipmentForm,
    deleteDetailById,
    syncOrderNb,
    searchDrawing,
    sendRequestPrice,
    uploadFile,
    compressFiles,
    cancelReqest,
    reactivateRequest,
    updateShipField,
    getHistoric,
    getPicking,
    savePicking,
    saveOtherPicking,
    downloadHashTag,
    downloadPacking,
    deletePicking,
    getPossibleShipOrigin,
    getOtherPicking,
    updateShipDetailField,
    getFullExpeditionInfo,
    notifyExpeditionPack,
    notifyDelivery,
    createCompleteProcessFromPicking
}