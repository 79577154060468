import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const MachineDraggableRow = ({ deleteRow, index, moveRow, v2, k, k2, updateField, betweenTwo, cursor, 
        setCursor, position, picking }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'row',
        hover(item) {
            var elt = ref.current
            var k2 = elt.getAttribute("data-k2");
            if (item.index !== index) setCursor( 
                {
                    k: parseInt(k), 
                    k2: parseInt(k2),
                    position: elt.getAttribute("data-position")
                } )
                console.log()
        },
        drop(item) {
            var elt = ref.current
            var k = parseInt (elt.getAttribute("data-k"))                     
            var k2 = parseInt( elt.getAttribute("data-k2") ); // Where you drop
            var level = elt.getAttribute("data-level")
            var position = elt.getAttribute("data-position")

            if (item.index !== index) {
                moveRow(k, k2, item.index, level, position);
                item.index = index;
            }
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'row',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                setCursor(-1)
            } else {
                setCursor(-1)
            }
        },
    });

    if( betweenTwo ){
        drop(ref);
    }else{
        drag(drop(ref));
    }

    // Add the event listener to capture Escape key press
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            setCursor(-1)
        }
    });    

    function getBg(){
        var res =  {backgroundColor: "", borderTop: "", borderBottom: ""};

        if( index < picking.length - 1 ){
            var next = picking[index + 1];
            if(next.level === 2) res = {backgroundColor: "#ece6ff", borderBottom: "none"}
        }

        if( picking[index].level === 2 ) res = {backgroundColor: "#ece6ff", borderTop: "none"}

        return res;
    }

    return (
        <Fragment>
            {!betweenTwo &&
                <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} data-k={k} data-k2={k2} data-position={"inside"}
                    data-level={v2.level} className={'cursor-pointer' 
                        + (cursor === k2 ? " fw-bold " : " " )} >
                    
                    { ( cursor.k2 === k2 && cursor.k === k ) && 
                        <td colSpan={12} className={'fw-bold '
                        + (cursor.position === "inside" ? " ps-3 bg-body-secondary" : "bg-body-tertiary")}>
                            <div className=''>Placer dans #{v2.id}: {v2.label}</div>
                            </td>}

                    { ( (cursor.k === k && cursor.k2 !== k2) || cursor.k !== k ) && <Fragment>
                        
                    {/*<td>
                        <img style={{ width: "20px" }} src='/common/drag_vertical_icon.png' />
                    </td>*/}
                    <th scope="row" style={getBg()}>
                        <div>{ v2.level === 1 && <div>#{v2.id}</div> }</div>
                    </th>
                    <th scope="row" style={getBg()}>
                        <div>{ v2.level === 2 && <div>#{v2.id}</div> }</div>
                    </th>
                    <th scope="row" style={getBg()}>
                        <div>{ v2.level === 3 && <div>#{v2.id}</div> }</div>
                    </th>
                    <td style={getBg()}>
                        <input className="form-control text-center" type="number" defaultValue={v2.qty || ""}
                            onBlur={(e) => updateField(e, k, k2, "qty")} />
                    </td>
                    <td style={getBg()}>{v2.ar}</td>
                    <td style={getBg()}>{v2.label}</td>
                    <td style={getBg()}>{v2.type}</td>
                    <td style={getBg()}>
                        <input className="form-control text-center" type="number" defaultValue={v2.dim1 || ""}
                            onBlur={(e) => updateField(e, k, k2, "dim1")} />
                    </td>
                    <td style={getBg()}>
                        <input className="form-control text-center" type="number" defaultValue={v2.dim2 || ""}
                            onBlur={(e) => updateField(e, k, k2, "dim2")} />
                    </td>
                    <td style={getBg()}>
                        <input className="form-control text-center" type="number" defaultValue={v2.dim3 || ""}
                            onBlur={(e) => updateField(e, k, k2, "dim3")} />
                    </td>
                    <td style={getBg()}>
                        <input className="form-control text-center" type="number" defaultValue={v2.weight || ""}
                            onBlur={(e) => updateField(e, k, k2, "weight")} />
                    </td>

                    </Fragment>}
                </tr >
            }

            {betweenTwo &&
                <tr ref={ref} data-level={0} data-k={k} data-k2={k2} data-position={position}
                    className='cursor-pointer no-print'>
                        <td colSpan={12} 
                            className={ ( ( cursor.k2 === k2 && cursor.k === k ) ? "" : "d-none") 
                                    + " fw-bold td-height-50" + (cursor.position === position ? " ps-3 bg-body-secondary" : " bg-body-tertiary") }>
                                <div>
                                    Placer {position === "after" ? "après " : "avant "} #{v2.id}: {v2.label}
                                </div>
                            </td>
                </tr>
            }
        </Fragment>
    );
}

export default MachineDraggableRow;