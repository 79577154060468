import { useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ticketsDao from "../../services/qhse/ticketsDao";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { getFrFormat, hasRole } from "../../functions/StrFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const PPM = (props) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [error, setError] = useState(null);
    const [nextId, setNextId] = useState();

    useEffect(() => {
        ticketsDao.getPPM().then(
            (response) => {
                var manual = response.filter(a => a.row_id.startsWith("manual_input"))
                var maxId = manual.map(obj => {
                    const match = obj.row_id.match(/\d+/);
                    return match ? parseInt(match[0], 10) : 0;
                })
                    .reduce((max, num) => Math.max(max, num), 0);

                setNextId(maxId + 1);

                setList(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }, []);

    const fields = { "date": "m_date", "business": "m_business", "machines": "m_machine", 
        "description": "m_description", "cause": "m_cause" } 

    const onUpdate = (e, k, field, id) => {
        var arr = [...list];
        var val = e.target.value;

        if (arr[k][field] !== val) {
            var o = {
                field: fields[field] || field,
                value: val,
                id: id
            }
            arr[k][field] = val;

            if( field.startsWith("m_") ) arr[k][field.slice(2)] = val;
           
            if (field.endsWith("_cost")) {
                var labor_cost = arr[k].labor_cost || 0;
                var material_cost = arr[k].material_cost || 0;
                var ship_cost = arr[k].ship_cost || 0;
                arr[k].total_cost = parseFloat(labor_cost) + parseFloat(material_cost) + parseFloat(ship_cost)
            }

            setList(arr);

            ticketsDao.updatePPM(o);
        }
    }

    const inputOrTxt = (i) => {
        const col = i.column.id;
        const row = i.row;

        if( row.original.row_id.startsWith( "manual_input" ) ){
            return <input type={ col.indexOf("date") !== -1 ? "date" : "text" } 
                className="form-control" defaultValue={row.original[col]}
                        onBlur={(e) => onUpdate(e, row.id, col, row.original.row_id)} />
        }else{
            if( col.indexOf("date") !== -1 ){
                return getFrFormat(row.original[col], true);
            }else{
                return row.original[col];
            }
        }
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('date',
            { header: 'Date', cell: (i) => <div className="text-center">
                 {inputOrTxt(i)}
            </div> }));


        arr.push(columnHelper.accessor('business',
            { header: 'Affaire', cell: (i) => <div className="text-center">{inputOrTxt(i)}</div> }));


        arr.push(columnHelper.accessor('machines',
            {
                header: 'Machine', cell: (i) => <div className="text-center">
                    {inputOrTxt(i)} {i.row.original.first_name}</div>
            }));

        arr.push(columnHelper.accessor('chef_proj',
            { header: 'Chef de projet', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('resp_med_mep',
            { header: 'Resp. MeS', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('description',
            {
                header: 'Description', cell: (i) => <div className="text-center">{inputOrTxt(i)}</div>
            }));

        arr.push(columnHelper.accessor('cause',
            { header: 'Cause', cell: (i) => <div className="text-center">{inputOrTxt(i)}</div> }));

        arr.push(columnHelper.accessor('labor_cost',
            {
                header: 'Coût humain', cell: (i) => <div className="text-center">
                    <input type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onUpdate(e, i.row.id, "labor_cost", i.row.original.row_id)} />
                </div>
            }));

        arr.push(columnHelper.accessor('material_cost',
            {
                header: 'Coût matériel', cell: (i) => <div className="text-center">
                    <input type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onUpdate(e, i.row.id, "material_cost", i.row.original.row_id)} />
                </div>
            }));

        arr.push(columnHelper.accessor('ship_cost',
            {
                header: 'Coût transport', cell: (i) => <div className="text-center">
                    <input type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onUpdate(e, i.row.id, "ship_cost", i.row.original.row_id)} />
                </div>
            }));


        arr.push(columnHelper.accessor('total_cost',
            {
                header: 'Coût total', cell: (i) => <div className="text-center">
                    {i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('status',
            {
                header: 'Statut', cell: (i) => <div className="text-center">
                    <select className="form-select" defaultValue={i.getValue()} style={{ minWidth: "130px" }}
                        onChange={(e) => onUpdate(e, i.row.id, "status", i.row.original.row_id)}>
                        <option value="0">Non traité</option>
                        <option value="1">En cours</option>
                        <option value="2">Traité</option>
                    </select>
                </div>
            }));

        arr.push(columnHelper.accessor('coms',
            {
                header: 'Commentaires', cell: (i) => <div className="text-center">
                    <textarea type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onUpdate(e, i.row.id, "coms", i.row.original.row_id)} />
                </div>
            }));



        return arr;
    }

    //Set columns
    const columns = getColumns();

    const addRow = (e) => {
        e.preventDefault();
        setError();

        const formData = new FormData(e.target);
        var o = new Object();

        for (const [key, value] of formData) { o[key] = value }
        o.id = "manual_input_" + nextId;

        var newObj = Object.fromEntries(
            Object.entries(o).map(([key, value]) => {
                if (key.startsWith('m_')) {
                    var newK = key.slice(2);
                    if( newK.startsWith("machine") ) newK = "machines"
                    return [newK, value]; // Supprime "m_" du début de la clé
                }
                return [key, value]; // Garde la clé telle quelle si elle ne commence pas par "m_"
            })
        );

        setLoading(true);
        ticketsDao.addPPM(o).then(
            (res) => {
                setNextId(nextId + 1);
                setList((state) => [...state, newObj]);
                e.target.reset();
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    return <div>
        <h5 className="text-center">Log. PPM</h5>

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        {hasRole(["ROLE_ADMIN"]) &&
            <div className="accordion mb-3" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed text-center fw-bold" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <div className="ms-auto">Ajouter une ligne</div>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <form className="ms-auto me-auto" onSubmit={addRow}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "130px" }}>Date</span>
                                    <input type="date" className="form-control" name="m_date" required />

                                    <span className="input-group-text" style={{ width: "130px" }}>Affaire</span>
                                    <input type="text" className="form-control" name="m_business"
                                        onChange={e => e.target.value = e.target.value.toUpperCase()} required />

                                    <span className="input-group-text" style={{ width: "130px" }}>Machine</span>
                                    <input type="text" className="form-control" name="m_machine" required />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "130px" }}>Description</span>
                                    <input type="text" className="form-control" name="m_description" required />

                                    <span className="input-group-text" style={{ width: "130px" }}>Cause</span>
                                    <input type="text" className="form-control" name="m_cause" required />

                                    <span className="input-group-text" style={{ width: "130px" }}>Commentaires</span>
                                    <input type="text" className="form-control" name="coms" />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "130px" }}>Coût humain</span>
                                    <input type="number" className="form-control" name="labor_cost" />

                                    <span className="input-group-text" style={{ width: "130px" }}>Coût matériel</span>
                                    <input type="number" className="form-control" name="material_cost" />

                                    <span className="input-group-text" style={{ width: "130px" }}>Coût transport</span>
                                    <input type="number" className="form-control" name="ship_cost" />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "130px" }}>Coût transport</span>
                                    <select className="form-select" defaultValue={"0"} name="status">
                                        <option value="0">Non traité</option>
                                        <option value="1">En cours</option>
                                        <option value="2">Traité</option>
                                    </select>
                                </div>

                                <div className="text-center">
                                    <button className="btn btn-success" disabled={loading}>
                                        {loading && <ButtonWaiting />}
                                        Enregistrer</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        }




        <ReactTable columns={columns} data={list}
            origin="ppm_list" classes={"no-padding fixFstCol "} top0={true} />

    </div>

}

export default PPM;