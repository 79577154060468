import { Fragment, useEffect, useRef, useState } from "react";
import addNotification from "react-push-notification";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { getKeyInt, removeEltArray } from "../../functions/ArrayFunctions";
import { ButtonWaiting, getHeight } from "../../functions/PageAnimation";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import { downloadSingleFile } from "../../services/functions/FilesProcess";
import Movement from "../../services/storehouse/Movement";
import { Client, Stomp } from "@stomp/stompjs";
import { connectSocket } from "../../services/common/Socket";

const ArticleRequestList = (props) => {
    const LIMIT = 50;
    const [list, setList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [lastId, setLastId] = useState();
    const [downloading, setDownloading] = useState(false);
    const URL = process.env.REACT_APP_URL;
    const user = JSON.parse(localStorage.getItem("user"));
    const [closed, setClosed] = useState({start: "", end: ""});
    const [closing, setClosing] = useState(false);
    const [group, setGroup] = useState([]);

    const clientRef = useRef(null);
    const listRef = useRef( list );

const token = user && "\"Bearer " + user.accessToken + "\"";

    useEffect(() => {
        //Get settings
        CheckConnDao.getSettings("close_request").then(
            (response) => {
                if(response !== ""){
                    var split = response.split("to");
                    setClosed( { start: split[0].trim(), end: split[1].trim() })
                }
            }, 
            () => {}
        )

        getResults();
    }, []);

    const onClosedChange = (e, period) => {
        var o = {...closed};
        o[period] = e.target.value;
        setClosed(o);
    }

    useEffect(() => {
        listRef.current = list;
      }, [list]);
    
    const getResults = () => {
        Movement.getArticleRequestList(LIMIT).then(
            (response) => {
                var lId = parseInt( response[0].id );
                if( list && ( lId > parseInt( list[0].id ) ) ){
                    setLastId(lId);
                    notify(response[0]);
                }
                startSocket(response)
                                
                setList(response);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const onConnected = (client) => {
        console.log("Connected!!")

        //Remark: do not write the top level URL prefix /api/test
        client.subscribe('/topic/new-request', function (msg) {
            if (msg.body) {
                var jsonBody = JSON.parse(msg.body);
                if( jsonBody.new ){
                    setList( (arr) => [jsonBody, ...arr] );
                }else{
                    var ids = jsonBody.id.split(",");
                    var arr = [...listRef.current];

                    for( let i=0; i<ids.length; i++ ){
                        var key = getKeyInt(arr, "id", parseInt(ids[i]));
                        arr[key].closed = 1;
                        delete arr[key].new;
                    }

                    setList(arr);
                }
                
            }
        });
    }

    const startSocket = () => {
        if (clientRef.current && clientRef.current.connected) return;

        const client = new Client({
            brokerURL: process.env.REACT_APP_SOCKET_URL,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => onConnected(client),
            onDisconnect: () => {}
        });
    
        client.activate();
        clientRef.current = client;
        

        clientRef.current = client;

    }

    const notify = (o) => {
        document.getElementById("soundPlay").play()

        addNotification({
            title: 'Nouvelle demande sortie comptoire',
            message: o.requester + ' demande un ou plusieurs sorties',
            duration: 10000,
            native: true,
            onClick: () => window.location.href = URL + "storehouse/articles/request/get?id=" + o.id
        });
    }

    const downloadList = () => {
        setDownloading(true);
        Movement.downloadArticleRequestList().then(
            (response) => {
                var file = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", file);
                setDownloading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setDownloading(false);
            }
        )
    }

    const closeFutureRequest = (e) => {
        e.preventDefault();
        var start = e.target.start.value;
        var end = e.target.end.value;
        var param = "<close_request>" + start + " to " + end + "</close_request>";
        setClosing(true);
        CheckConnDao.writeSettings(param).then(
            (response) => {setClosing(false)},
            (error) => {setClosing(false)}
        );
    }

    const openGroup = (e, id) => {
        var arr = [...group];

        if( e.target.checked ){ arr.push( id ); }
        else{ arr = removeEltArray(arr, id); }

        setGroup(arr);
    }

    const redirectGroup = () => {
        window.open( "/storehouse/articles/request/get?multiple=yes&id=" + group.join(','), '_blank');
    }

    return <Fragment>
        <h4 className="text-center">Liste des sorties comptoir</h4>
        {list && <div className="text-center">
            {downloading && <WaitingRoundSnippers />}
            {!downloading && <a href="#" onClick={downloadList}>Télécharger la liste complète</a>} 
        </div>}

        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            ( user && user.roles.includes("ROLE_STOREHOUSE_MNGT") ) && <div className="text-center fw-bold">
                <p>Fermer le comptoire pour inventaire</p>
                <form className="col-6 offset-3" onSubmit={closeFutureRequest}>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Du</span>
                        <input type="datetime-local" className="form-control" name="start" value={closed.start} 
                           onChange={(e) =>onClosedChange(e, "start")} required/>
                        <span className="input-group-text" id="basic-addon1">Au</span>
                        <input type="datetime-local" className="form-control" name="end" value={closed.end} 
                           onChange={(e) =>onClosedChange(e, "end")} required/>
                        <button className="btn btn-secondary" disabled={closing}>
                            {closing && <ButtonWaiting />}
                            Sauvegarder</button>
                    </div>
                </form>
            </div>
        }    

        {group.length > 0 && <div className="text-center mb-2">
            <button className="btn btn-success" onClick={redirectGroup}>Ouverture groupée</button>
        </div>}
        
        {
            list &&
            <div className="table-responsive" id="list_art_req" style={{height: getHeight("list_art_req")}}>
                <table className="table">
                    <thead>
                        <tr className="table-info fixed-header-zero">
                            <th scope="col"></th>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Demandeur</th>
                            <th scope="col">Affaire(s)</th>
                            <th scope="col">Machine(s)</th>
                            <th scope="col">Cause(s)</th>
                            <th>Clôturé</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.map((v, k) =>
                                <tr key={k}>
                                    <td>
                                        {v.closed !== 1 && <input type="checkbox" 
                                            onChange={(e)=>openGroup(e, v.id)}/>}
                                        {v.new && <img src="/common/new_icon.png" style={{width: "30px"}} className="ms-1"/>}
                                    </td>
                                    <th scope="row">{v.id}</th>
                                    <td>{v.date}</td>
                                    <td>{v.requester}</td>
                                    <td>{v.business}</td>
                                    <td>{v.machine}</td>
                                    <td>{v.cause}</td>
                                    <td>
                                        {v.closed === 1 &&
                                            <img src={"/common/check.png"} style={{ width: "40px" }}></img>
                                        }
                                    </td>
                                    <td className="text-center">
                                        <a className="btn btn-outline-secondary"
                                            href={"/storehouse/articles/request/get?id=" + v.id}
                                            target="_blank">Entrer</a>
                                    </td>
                                </tr>)
                        }

                    </tbody>
                </table>
            </div>
        }

    </Fragment>

}

export default ArticleRequestList;