import Moment from 'moment/moment';
import { Fragment, useEffect, useState } from "react";
import { arrayRemoveElt, getKeyInt } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { confirmAlert } from 'react-confirm-alert';
import BusinessDao from '../../../../services/machines/BusinessDao';
import errorManagement from '../../../../services/errorManagement';
import MachineInfo from '../../../../services/machines/MachineInfo';
import { isDate } from 'validator';

const BAContract = (p) => {
    const [comments, setComments] = useState("");
    const [date, setDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [machineToOrder, setMachineToOrder] = useState([]);
    const [files, setFiles] = useState([]);


    const user = JSON.parse(localStorage.getItem("user"));
    const isPurchase = user && user.roles.includes("ROLE_PURCHASE");

    Moment.locale("fr");

    const onCheckMachine = (e, k) => {
        var arr = [...machineToOrder];

        if (e.target.checked) { arr.push(k) }
        else { arr = arrayRemoveElt(arr, k) }

        setMachineToOrder(arr);
    }

    const onCheckFiles = (e, v) => {
        var arr = [...files];

        if (e.target.checked) { arr.push(v) }
        else { arr = arrayRemoveElt(arr, getKeyInt(arr, "id", v.id)) }

        setFiles(arr);
    }

    const send = () => {
        p.setError();

        if (machineToOrder.length === 0) {
            p.setError("Choisissez au moins une machine");
            scrollToTop();
            return;
        }

        var c = "";
        for (let i = 0; i < files.length; i++) c += files[i].type;
        if (c.indexOf("implantation") === -1) {
            p.setError("Choisissez le plan d'implantation à envoyer");
            scrollToTop();
            return;
        }

        if( !isDate(date) ){
            p.setError("Saisissez la date de mise à disposition");
            scrollToTop();
            return;
        }

        var machines = [];
        var machNb = [];
        for (let i = 0; i < machineToOrder.length; i++) {
            var o = p.BAmachines[machineToOrder[i]];
            machines.push(o);
            machNb.push(o.no_machine);
        }

        var o = {
            machines: machines,
            files: files,
            comment: comments,
            date: date
        }

        confirmAlert({
            title: "Confirmation",
            message: "Confirmez-vous la commande de: " + machNb.join(','),
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);

                        BusinessDao.sendTecmaFiles(o).then(
                            (response) => {
                                p.setMsg("Demande envoyée");
                                scrollToTop();
                                setLoading(false);
                            },
                            (error) => {
                                p.setError(errorManagement.handleError(p, error));
                                scrollToTop();
                                setLoading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    function getMachineStateBg(v) {
        if (v.order_requested === 1 && !v.order_nb) return "bg-warning";
        if (v.order_nb) return "bg-success text-white";
    }

    const onOrderChange = (e, k) => {
        MachineInfo.updateMachineFieldPurchase(p.BAmachines[k].no_machine, "order_nb", e.target.value, "text").then(
            (response) => {
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    return <div style={{ padding: "10px" }}>

        <div className="card">
            <div className="card-header mb-3">
                <h4>Commande TECMA</h4>
            </div>

            {!p.readOnly && <Fragment>

                <div className="d-flex">

                    <div>
                        <h5>Machine(s) à commander</h5>
                        <ul className="list-group mb-3 me-3" style={{ width: "300px" }}>
                            {p.BAmachines && p.BAmachines.map((v, k) => {
                                return <li className={"list-group-item " + getMachineStateBg(v)} k={"ba" + k}>
                                    <div className="form-check ">
                                        {v.url &&
                                            <input className="form-check-input" type="checkbox" value={v}
                                                onChange={(e) => onCheckMachine(e, k)} />
                                        }
                                        <label className="form-check-label" >
                                            {v.no_machine} ({v.typ_config})
                                        </label>
                                        {!v.url && <label className='text-danger'>FM non trouvée</label>}

                                        {(v.order_requested === 1 && !v.order_nb) && <label>Demande d'achat envoyée</label>}
                                        { v.order_nb && <label>Commande 
                                                <a className='text-white ms-1' href={"/purchase/order/edition?order="+v.order_nb} target='_blank'>#{v.order_nb}</a></label> }
                                    </div>
                                </li>
                            })}

                        </ul>
                    </div>

                    <div style={{ width: "100%" }}>
                        <h5>Commentaire</h5>
                        <div className="form-floating mb-3">
                            <textarea className="form-control" placeholder="Leave a comment here"
                                onChange={(e) => setComments(e.target.value)} style={{ height: "100px" }}></textarea>
                            <label>Informations complémentaires à ajouter au mail</label>
                        </div>

                        <div className="input-group mb-3" style={{width: "400px"}}>
                            <span className="input-group-text">Date de mise à disposition</span>
                            <input type="date" className="form-control"
                                onChange={(e) => setDate(e.target.value)} />
                        </div>
                    </div>

                </div>


                <div className="d-flex">
                    <div className="me-3">
                        <h5>Plan de cartons à envoyer</h5>
                        <ul className="list-group mb-3" style={{ width: "300px" }}>
                            {p.BAfiles && p.BAfiles.map((v, k) => {
                                return (v.type === "carton" && v.name.indexOf("CONS-3") === -1) && <li className="list-group-item" k={"ba" + k}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={v}
                                            onChange={(e) => onCheckFiles(e, v)} />
                                        <label className="form-check-label" >
                                            {v.name}
                                        </label>
                                    </div>
                                </li>
                            })}

                        </ul>
                    </div>

                    <div>
                        <h5>Plan d'implantation</h5>
                        <ul className="list-group mb-3" style={{ width: "300px" }}>
                            {p.BAfiles && p.BAfiles.map((v, k) => {
                                return v.type === "implantation" && <li className="list-group-item" k={"ba" + k}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={v}
                                            onChange={(e) => onCheckFiles(e, v)} />
                                        <label className="form-check-label" >
                                            {v.name}
                                        </label>
                                    </div>
                                </li>
                            })}

                        </ul>
                    </div>
                </div>

                <div className="text-center">
                    <button className="btn btn-success" onClick={send} disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Envoyer</button>
                </div>

            </Fragment>}

            {isPurchase && <Fragment>
                
                <div>
                        <h5>Machine(s) à commander</h5>
                        <ul className="list-group mb-3 me-3" style={{ width: "300px" }}>
                            {p.BAmachines && p.BAmachines.map((v, k) => {
                                return v.order_requested === 1 && <li className="list-group-item " k={"ba" + k}>
                                    <div>
                                        <div className="text-center fw-bold" >
                                            {v.no_machine} ({v.typ_config})
                                        </div>
                                        <div>
                                            <input className='form-control mt-1 fw-bold text-center' defaultValue={v.order_nb}
                                                placeholder='Saisir le n° de commande' onBlur={(e)=>onOrderChange(e, k)}/>
                                        </div>
                                    </div>
                                </li>
                            })}

                        </ul>
                    </div>

            </Fragment>}


        </div>


        <br></br>

    </div>

}

export default BAContract;