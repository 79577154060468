import { useEffect, useState } from "react"
import getUrlParam, { currentEnDate } from "../../functions/StrFunctions";
import { Fragment } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ArticleInfo from "../../services/articles/ArticleInfo";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { useTranslation } from "react-i18next";
import { arrayRemoveElt, getKey } from "../../functions/ArrayFunctions";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import Moment from "moment";
import BasicInfo from "./smallComp/BasicInfo";
import ChangeResume from "./smallComp/ChangeResume";
import OtherInfo from "./smallComp/OtherInfo";
import Supplier from "./smallComp/Supplier";
import Prices from "./smallComp/Prices";
import Moves from "./smallComp/Moves";
import Historic from "./smallComp/Historic";
import OldIndex from "./smallComp/OldIndex";
import CategoriesGuide from "./smallComp/CategoriesGuide";

const Article = (props) => {
    const { t } = useTranslation('article');
    const [articleCode, setArticleCode] = useState();
    const [articleInfo, setArticleInfo] = useState();
    const [chapter, setchapter] = useState();
    const [supplier, setsupplier] = useState();
    const [newSupplier, setNewsupplier] = useState([]);
    const [supplierToRemove, setSupplierToRemove] = useState([]);
    const [unit, setunit] = useState();
    const [currency, setcurrency] = useState();
    const [prices, setprices] = useState();
    const [orders, setOrders] = useState();
    const [moves, setMoves] = useState();
    const [histChange, setHistChange] = useState();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [err, setErr] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);
    const [change, setChange] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    const [readonly] = useState(user && !user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_ART_CHANGE"));
    const [purchase] = useState(user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_PURCHASE")));
    const [isSee] = useState(user && user.roles.includes("ROLE_SEE"));
    const [isInternal] = useState(user && user.roles.includes("ROLE_INTERNAL"));
    const [oldRef, setOldRef] = useState();
    const [oldRefChange, setOldRefChange] = useState(false);

    const token = user && "Bearer " + user.accessToken;

    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL + "articles/info/get-drawing?Authorization=" + token + "&article=";

    Moment.locale("fr");

    useEffect(() => {
        var art = getUrlParam(props, "article");

        if (art !== null) {
            setArticleCode(art);

            ArticleInfo.getAllArtInfo(art).then(
                (response) => {
                    setArticleInfo(response.article);
                    setchapter(response.chapter);
                    setcurrency(response.currency);
                    setunit(response.unit);

                    ArticleInfo.getOldRef(response.article.ZN02PA).then(
                        (response) => {
                            setOldRef(response);
                        },
                        (error) => {
                            setOldRef([]);
                        }
                    );
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                }
            );

            ArticleInfo.getOrders(art).then(
                (response) => {
                    setOrders(response);
                },
                (error) => {
                    scrollToTop();
                }
            );

            ArticleInfo.getSupplier(art).then(
                (response) => {
                    setsupplier(response);
                },
                (error) => {
                    scrollToTop();
                }
            );

            ArticleInfo.getPrices(art).then(
                (response) => {
                    setprices(response);
                },
                (error) => {
                    scrollToTop();
                }
            );

            ArticleInfo.getMoves(art).then(
                (response) => {
                    setMoves(response);
                },
                (error) => {
                    scrollToTop();
                }
            );

            ArticleInfo.getHistoric(art).then(
                (response) => {
                    setHistChange(response);
                },
                (error) => {
                    scrollToTop();
                }
            );

        }

    }, []);

    useEffect(() => {
        oldRef && oldRef.map(v => {
            if (v.mode) {
                setOldRefChange(true);
            }
        })
    }, [oldRef]);

    /**
     * Change tab
     * @param {*} e 
     */
    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")));
    }

    /**
     * Update basic info
     * @param {*} e 
     */
    const updateArticle = (e) => {
        var table = e.target.getAttribute("data-table");
        var field = e.target.getAttribute("data-field");
        var label = document.querySelector("label[for='" + e.target.id + "']").innerHTML;
        var value;

        if ((field === "VISIL" || field === "GEBEL")) {
            value = e.target.checked ? "O" : "N";
        } else {
            value = e.target.value;
        }

        ctrlAndUpdateArticle(table, field, value, label);
    }

    const ctrlAndUpdateArticle = (table, field, value, label, ov) => {

        var old_value = articleInfo[field];
        if (old_value === undefined) old_value = ov;
        var arr = [...change];

        var key = getKey(arr, "field", field);
        if (key === null) {
            var o = {
                table: table,
                field: field,
                old_value: old_value,
                new_value: value,
                label: label,
                cause: ""
            }

            arr.push(o);
        } else {
            if (parseFloat(arr[key].old_value) === parseFloat(value)
                || arr[key].old_value === value
                || (arr[key].old_value === null && value === "")) {
                arr = arrayRemoveElt(arr, key);
            } else {
                arr[key].new_value = value;
            }
        }

        setChange(arr);

        var o = { ...articleInfo };
        o[field] = value;
        setArticleInfo(o);

        control(o, arr);
    }

    /**
     * Cancel change 
     * @param {*} e 
     */
    const cancelChange = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"))
        var arr = [...change];

        var o = { ...articleInfo };
        o[arr[key].field] = arr[key][arr[key].field];

        setArticleInfo(o);

        arr = arrayRemoveElt(arr, key);
        setChange(arr);

        control(o, arr);
    }



    /**
     * Check if there is no error
     * @param {*} o 
     * @param {*} change 
     * @returns 
     */
    const control = (o, change) => {
        setErr();

        var err = "";

        if (o.ZN15PA === null || o.ZN15PA.trim() === "")
            err += "Veuillez remplir la catégorie avant de poursuivre<br>";

        if (o.ZN12PA === null || o.ZN12PA.trim() === "")
            err += "Veuillez remplir le libellé anglais<br>";

        if( o.CDARL.startsWith("GE_") && o.ZN15PA !== "Spé" ){
            err += "Un article générique doit être de catégorie \"Spé\"";
            setErr(err);
            scrollToTop();
            return;
        }

        change.map(
            (v) => {
                if (v.label.indexOf("*") !== -1 && (v.new_value === null || v.new_value.trim() === "")) {
                    err += "Veuillez remplir tous les champs marqués par un astérisque (*)";
                    return;
                }
            }
        )

        if (o.ZN15PA === "Hst" || o.ZN15PA === "Hst Pneu" || o.ZN15PA === "Spé" || o.ZN15PA === "Peint" || o.ZN15PA === "OLD") {
            if (o.STMIL === null || o.STMIL === "" || parseInt(o.STMIL) > -9999) 
                err += "Le stock mini doit être de -9999<br>";
            
            if (o.QREAL === null || o.QREAL === "" || parseInt(o.QREAL) !== 0 ) 
                err += "La quantité de réappro doit être de 0<br>";
            
            if (o.GEBEL !== "O") 
                err += "Le besoin doit être coché à \"Oui\"<br>";
            
        } else if ( o.ZN15PA === "Std old") {
            if (o.STMIL === null || o.STMIL === "" || parseInt(o.STMIL) <= 0) 
                err += "Le stock mini doit être supérieur à 0<br>";
            
            if (o.QREAL === null || o.QREAL === "" || parseInt(o.QREAL) <= 0 ) 
                err += "La quantité de réappro doit être supérieur à 0<br>";
            
            if (o.GEBEL !== "O") 
                err += "Le besoin doit être coché à \"Oui\"<br>";
            
        } else if (o.ZN15PA && (o.ZN15PA.startsWith("CSM") || o.ZN15PA.startsWith("CMP"))) {
            if (o.STMIL === null || o.STMIL === "" || parseInt(o.STMIL) > -9999 ) 
                err += "Le stock mini doit être de -9999<br>";
            
            if (o.QREAL === null || o.QREAL === "" || parseInt(o.QREAL) !== 0) 
                err += "La quantité de réappro doit être de 0<br>";
            
            if (o.GEBEL !== "N") 
                err += "Le besoin doit être coché à \"Non\"<br>";
            
        } else if (o.ZN15PA.toUpperCase().startsWith("STD")) {
            if (o.STMIL === null || o.STMIL === "" || (parseInt(o.STMIL) <= 0 && o.BLOCL === 'N')) 
                err += "Le stock mini doit être supérieur à 0 <b>SI</b> l'article n'est pas bloqué<br>";
            
            if (o.QREAL === null || o.QREAL === "" || (parseInt(o.QREAL) <= 0 && o.BLOCL === 'N')) 
                err += "La quantité de réappro doit être supérieur à 0 <b>SI</b> l'article n'est pas bloqué<br>";
            
            if (o.GEBEL !== "O") 
                err += "Le besoin doit être coché à \"Oui\"<br>";
        }

        if (err !== "") setErr(err);

    }

    /**
     * Save the changes
     */
    const save = () => {
        setSaving(true);
        setError();
        setMsg();

        ArticleInfo.updateArtInfo(articleCode, articleInfo.ZN02PA, change, newSupplier,
            supplierToRemove, oldRef).then(
                (response) => {
                    setMsg("Sauvegardé !");
                    setChange([]);
                    setNewsupplier([]);
                    setSupplierToRemove([]);
                    setTab(0);
                    scrollToTop();
                    setSaving(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setSaving(false);
                }
            )
    }

    /**
     * Set change justification
     * @param {*} e 
     */
    const setChangeCause = (e) => {
        var key = parseInt(e.target.getAttribute("data-key"));

        var arr = [...change];
        arr[key].cause = e.target.value;
        setChange(arr);
    }

    return (
        <div>
            <h4 className="text-center">Fiche {articleCode}</h4>

            {err && <ErrorMessage error={err} />}

            {loading && <WaitingRoundSnippers />}

            {error && <ErrorMessage error={error} />}

            {msg && <SuccessMessage msg={msg} />}



            <ul className="nav nav-tabs fixed-header" style={{ backgroundColor: "white" }}>
                <li className="nav-item fw-bold">
                    <a className={"nav-link " + (tab === 0 ? "active" : "")}
                        style={{ backgroundColor: "white" }}
                        href="#" onClick={changeTab} data-tab="0">
                        {!articleInfo && <ButtonWaiting />}
                        Info de base
                    </a>
                </li>
                {
                    isInternal &&
                    <li className="nav-item fw-bold">
                        <a className={"nav-link " + (tab === 1 ? "active" : "")}
                            style={{ backgroundColor: "white" }}
                            href="#" onClick={changeTab} data-tab="1">
                            {(!articleInfo || !orders) && <ButtonWaiting />}
                            Autres infos
                        </a>
                    </li>
                }

                {
                    isInternal &&
                    <Fragment>
                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 2 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="2">
                                {(!articleInfo || !supplier) && <ButtonWaiting />}
                                Fournisseurs
                            </a>
                        </li>


                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 3 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="3">
                                {!prices && <ButtonWaiting />}
                                Prix de vente</a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 5 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="5">
                                {!moves && <ButtonWaiting />}
                                Mouvements
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 6 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="6">
                                {!histChange && <ButtonWaiting />}
                                Hist. modifs
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 7 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="7">
                                {!oldRef && <ButtonWaiting />}
                                Autres indices
                            </a>
                        </li>

                        <li className="nav-item fw-bold">
                            <a className={"nav-link " + (tab === 8 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="8">
                                Guide des catégories
                            </a>
                        </li>

                        <li className="nav-item fw-bold"
                            style={{
                                display: ((change.length > 0 || newSupplier.length > 0
                                    || supplierToRemove.length > 0 || oldRefChange) ? "" : "none")
                            }}>
                            <a className={"nav-link gradient-green " + (tab === 4 ? "active" : "")}
                                style={{ backgroundColor: "white" }}
                                href="#" onClick={changeTab} data-tab="4">Valider les modifications</a>
                        </li>
                    </Fragment>
                }
            </ul>
            <br></br>
            {
                (tab === 0 && articleInfo) && <BasicInfo articleInfo={articleInfo} ctrlAndUpdateArticle={ctrlAndUpdateArticle}
                    updateArticle={updateArticle} setError={setError} setArticleInfo={setArticleInfo}
                    props={props} readonly={readonly} chapter={chapter} purchase={purchase} change={change}
                    setChange={setChange} control={control} articleCode={articleCode} unit={unit}/>
            }

            {
                (articleInfo && orders && tab === 1 && isInternal) &&
                <OtherInfo articleInfo={articleInfo} isInternal={isInternal} setArticleInfo={setArticleInfo}
                    articleCode={articleCode} orders={orders} moves={moves} ctrlAndUpdateArticle={ctrlAndUpdateArticle} />
            }

            {
                (tab == 2 && articleInfo && supplier) &&
                <Supplier articleInfo={articleInfo} supplier={supplier} setsupplier={setsupplier}
                    purchase={purchase} setNewsupplier={setNewsupplier} newSupplier={newSupplier}
                    change={change} setChange={setChange} control={control} articleCode={articleCode}
                    ctrlAndUpdateArticle={ctrlAndUpdateArticle} setError={setError} props={props}
                    supplierToRemove={supplierToRemove} setSupplierToRemove={setSupplierToRemove}
                    currency={currency} unit={unit} />
            }

            {(tab === 3 && articleInfo && prices) && <Prices articleInfo={articleInfo}
                prices={prices} setprices={setprices} change={change} setChange={setChange} 
                setError={setError}/>}

            {(tab === 5 && moves) && <Moves moves={moves} />}

            {(tab === 6 && histChange) && <Historic histChange={histChange} />}

            {
                tab === 4 &&
                <ChangeResume change={change} setChangeCause={setChangeCause} cancelChange={cancelChange}
                    newSupplier={newSupplier} supplierToRemove={supplierToRemove} err={err} saving={saving}
                    save={save} oldRef={oldRef} />
            }

            {(tab === 7 && oldRef) && <OldIndex oldRef={oldRef} setOldRef={setOldRef} articleInfo={articleInfo}
                                        props={props}/>}

            {tab === 8 && <CategoriesGuide />}
        </div>
    )
}

export default Article