import { useState } from "react";
import { Fragment } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import MachineInfo from "../../services/machines/MachineInfo";
import errorManagement from "../../services/errorManagement";
import NeedsMngt from "../../services/articles/NeedsMngt";
import CustomToolTip from "../../common/smallComponents.js/CustomToolTip";

const KitList = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [machine, setMachine] = useState("");
    const [subset, setSubset] = useState("");
    const [type, setType] = useState("");
    const [dimL, setDimL] = useState("");
    const [dimW, setDimW] = useState("");
    const [dimh1, setDimH1] = useState("");
    const [dimh2, setDimH2] = useState("");
    const [dimh3, setDimH3] = useState("");
    const [kit, setKit] = useState("");
    const [displayHidden, setDisplayHidden] = useState(false);
    const [list, setList] = useState();
    const [historic, setHistoric] = useState([]);

   
    /**
     * Get machine info
     * @param {*} e 
     * @returns 
     */
    const getMachineInfo = (e) => {
        e.preventDefault();
        setError();
        setHistoric([]);
        if (!machine || machine.trim() === "") {
            setError("Saisissez le numéro de machine");
            scrollToTop();
            return;
        }

        setLoading(true);
        MachineInfo.getAllMachineInfo(machine).then(
            (response) => {
                setType(response[0].type_machine);
                setDimL(response[0].l_dim_ext);
                setDimW(response[0].w_dim_ext);
                setDimH1((response[0].h_dim_ext || response[0].haut_ext_brut || ""));
                setDimH2(((response[1] && response[1].h_dim_ext) || ""));
                setDimH3(((response[2] && response[2].h_dim_ext) || ""));
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

        NeedsMngt.getHistoricByMachine(machine).then(
            (response) => {
                setHistoric(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );


    }

    /**
     * Search kit
     */
    const onSubmit = (e) => {
        e.preventDefault();
        setError();

        if (kit.trim() === ""
            && (type === "" || dimL === "" || dimW === "" || dimh1 === "")) {
            setError("Vous devez renseigner les champs obligatoires (marqués par un astérisque) ou la référence du kit");
            scrollToTop();
            return;
        }

        setLoading(true);
        NeedsMngt.getKitList(machine, subset, type, dimL, dimW, dimh1, dimh2, dimh3, kit, displayHidden).then(
            (response) => {
                if (response.length === 0) {
                    setError("La recherche n'a pas abouti, veuillez ré-essayer.");
                    scrollToTop();
                } else {
                    setList(response);
                }
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    return <Fragment>
        <h4 className="text-center">Liste des kits</h4>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <div className="alert alert-info text-center fw-bold">
            Cherchez soit par machine, soit par attributs de machine, soit par référence du kit
        </div>

        <form onSubmit={onSubmit}>
            <div className="mb-3" style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Machine</span>
                        <input type="text" className="form-control" value={machine} onChange={(e) => setMachine(e.target.value)}
                            placeholder="Kits compatibles avec cette machine" />
                        <button type="button" className="btn btn-outline-secondary" onClick={getMachineInfo} disabled={loading}>
                            {loading && <ButtonWaiting />}
                            Vérifier
                        </button>
                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Sous-ensemble</span>
                        <select className="form-select" value={subset} onChange={(e) => setSubset(e.target.value)}>
                            <option value="">( facultatif )</option>
                            <option value="Ascenseur / Pistonnage">Ascenseur / Pistonnage</option>
                            <option value="Cavité">Cavité</option>
                            <option value="Centreur">Centreur</option>
                            <option value="Centreur à ventouses">Centreur à ventouses</option>
                            <option value="Châssis">Châssis</option>
                            <option value="Convoyeur coiffe">Convoyeur coiffe</option>
                            <option value="Convoyeur principal">Convoyeur principal</option>
                            <option value="Encollage">Encollage</option>
                            <option value="Fourniture">Fourniture</option>
                            <option value="Habillage">Habillage</option>
                            <option value="Insonorisation">Insonorisation</option>
                            <option value="Margeur">Margeur</option>
                            <option value="Marquage">Marquage</option>
                            <option value="Plaquage">Plaquage</option>
                            <option value="Pliage">Pliage</option>
                        </select>
                    </div>

                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header text-center" id="headingOne">
                                <button className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Voir plus
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Type de machine*</span>
                                        <select className="form-select" value={type} onChange={(e) => setType(e.target.value)}>
                                            <option value="">Choose...</option>
                                            <option value="BA_TECMA_18_20_24">BA_TECMA_18_20_24</option>
                                            <option value="BA_TECMA_30_40">BA_TECMA_30_40</option>
                                            <option value="BA15P">BA15P</option>
                                            <option value="EC08">EC08</option>
                                            <option value="EC16">EC16</option>
                                            <option value="FORMEUSE_CAISSE_LANTEC">FORMEUSE_CAISSE_LANTEC</option>
                                            <option value="IK">IK</option>
                                            <option value="IN">IN</option>
                                            <option value="LN">LN</option>
                                            <option value="POSE_CALE">POSE_CALE</option>
                                            <option value="POSE_COIFFE">POSE_COIFFE</option>
                                            <option value="WN">WN</option>
                                        </select>
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Longueur extérieures (mm): L*</span>
                                        <input type="number" className="form-control" value={dimL} onChange={(e) => setDimL(e.target.value)} />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Largeur extérieures (mm): W*</span>
                                        <input type="number" className="form-control" value={dimW} onChange={(e) => setDimW(e.target.value)} />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Hauteur brut (mm): H*</span>
                                        <input type="number" placeholder="Hauteur 1*" value={dimh1} onChange={(e) => setDimH1(e.target.value)} className="form-control" />
                                        <input type="number" placeholder="Hauteur 2" value={dimh2} onChange={(e) => setDimH2(e.target.value)} className="form-control" />
                                        <input type="number" placeholder="Hauteur 3" value={dimh3} onChange={(e) => setDimH3(e.target.value)} className="form-control" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "50%", paddingLeft: "10px" }}>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Kit</span>
                        <input type="text" className="form-control" value={kit} onChange={(e) => setKit(e.target.value)}
                            placeholder="Rechercher par référence ou libellé de kit" />
                    </div>

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={displayHidden}
                            onChange={(e) => setDisplayHidden(e.target.checked)} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Afficher les kits masqués
                        </label>
                    </div>
                </div>
            </div>

            <div className="text-center mb-3">
                <button type="submit" className="btn btn-success" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Rechercher
                </button>
            </div>
        </form>
        <br></br>

        {
            historic.length > 0 &&
            <Fragment>
                <h5 className="text-center">Kits déjà utilisés sur cette machine</h5>
                <table className="table">
                    <thead>
                        <tr className="table-info">
                            <th scope="col">Kit</th>
                            <th scope="col">Libellé</th>
                            <th scope="col">Affaire</th>
                            <th scope="col">Date d'utilisation</th>
                            <th scope="col">Clôturé</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            historic.map((v, k) => {
                                return <tr key={k}>
                                    <th scope="row">{v.kit}</th>
                                    <td>{v.DESCT}</td>
                                    <td>{v.business}</td>
                                    <td>{v.date_use}</td>
                                    <td>{v.closed === 0 ? "Non" : "Oui"}</td>
                                </tr>
                            })
                        }

                    </tbody>
                </table>
                <br></br>
            </Fragment>
        }

        {
            list &&
            <table className="table">
                <thead>
                    <tr className="table-info">
                        <th scope="col">Ref</th>
                        <th scope="col">Libellé</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col" className="text-center">Kit complet ?</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((v, k) => {
                            return <tr key={k}>
                                <th scope="row">{v.ref}</th>
                                <td>{v.label}</td>
                                <td>{v.secondary_cond !== null &&
                                    <CustomToolTip props={
                                        { tooltipText: v.secondary_cond, originText: '<img src="/common/tooltip.png"></img>' }} />
                                }</td>
                                <td>
                                    {(v.hide === true || v.hide === "O") &&
                                        <span className="fw-bold" style={{ color: "red" }}>Cet AV a été masqué !</span>}
                                </td>
                                <td className="text-center">
                                    {
                                        (v.notice_url != null && v.condition_nb > 0 && v.ar) &&
                                        <img src={"/common/check.png"} style={{ width: "40px" }}></img>
                                    }
                                </td>
                                <td>
                                    <a href={"/article/kit?ref=" + v.ref}
                                        className="btn btn-outline-success">Entrer</a>
                                </td>
                            </tr>
                        })
                    }

                </tbody>
            </table>
        }
    </Fragment>

}

export default KitList;