import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import BusinessDao from "../../services/machines/BusinessDao";
import MachineInfo from "../../services/machines/MachineInfo";
import { getFrFormat } from "../../functions/StrFunctions";
import { CustomToolTipInfo } from "../../common/smallComponents.js/CustomToolTip";

const TecmaTracking = (props) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const user = JSON.parse(localStorage.getItem("user"));
    const isPurchase = user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_PURCHASE"));

    useEffect(() => {

        BusinessDao.getTecmaList().then(
            (r) => {
                console.log(r)
                setList(r);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }, []);

    const onOrderChange = (e, k) => {
        MachineInfo.updateMachineFieldPurchase(list[k].no_machine, "order_nb", e.target.value, "text").then(
            (response) => {
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('affaire', {
            header: 'Affaire',
            cell: i => <div className="td-bg-grey text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('proj_man', {
            header: 'CDP',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('no_machine', {
            header: 'Machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('order_nb', {
            header: 'N° de cmd',
            cell: i => <div className="text-center">
                {!isPurchase && i.getValue()}
                {isPurchase &&
                    <input type="number" className="form-control text-center ms-auto me-auto"
                        style={{ width: "200px" }} defaultValue={i.getValue()}
                        onChange={(e) => onOrderChange(e, i.row.id)} />}
            </div>
        }));

        arr.push(columnHelper.accessor('DTHCRE', {
            header: 'Date de cmd',
            cell: i => <div className="text-center">
                {i.getValue() && getFrFormat(i.getValue(), true)}
            </div>
        }));

        arr.push(columnHelper.accessor('PUNTE', {
            header: 'Prix unitaire',
            cell: i => <div className="text-center">
                {i.getValue() && i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('last_date', {
            header: <div className="d-flex">
                <div className="ms-auto me-3">
                    Date de livraison
                </div>
                <div className="me-auto">
                    <CustomToolTipInfo props={{ tooltipText: "Dernière date de mise à jour ou date confirmée par le fournisseur ou délai demandé" }} /></div>
                </div>,
            cell: i => <div className="text-center">
                {i.getValue() && getFrFormat(i.getValue(), true)}
            </div>
        }));

        arr.push(columnHelper.accessor('SLDQE', {
            header: <div className="d-flex">
            <div className="ms-auto me-3">
            Statut en livraison
            </div>
            <div className="me-auto">
                <CustomToolTipInfo props={{ tooltipText: "N = Non livré, E = En cours de livraison, S = Livré" }} /></div>
            </div>,
            cell: i => <div className="text-center">
                {i.getValue() && ( i.getValue() === "N" ? "Non livré" : i.getValue() === "E" ? "En cours de livraison" :"Livré")}
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <Fragment>

        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <h6 className="text-center">Suivi de commandes TECMA</h6>

        <ReactTable columns={columns} data={list}
            origin="tecma_track" classes={"no-padding fixFstCol"} top0={true} />

    </Fragment>

}

export default TecmaTracking;