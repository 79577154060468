import { useState } from "react";
import { InfoMsg, InfoMsgObj } from "../../../common/smallComponents.js/ErrorMessage";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { removeAndReturnArray } from "../../../functions/ArrayFunctions";
import Movement from "../../../services/storehouse/Movement";

const DeltaScan = (p) => {

    const [info, setInfo] = useState(<div>Cette page affiche:
        <ul>
            <li>Les articles scannées en emplacements mais n'apparaissant pas dans la liste des pièces à inventorier</li>
            <li>Les articles apparaissant dans la liste des pièces à inventorier mais qui n'ont pas été scannés</li>
        </ul>
        Il convient de vérifier ce qu'il en est.
    </div>);

    const deleteRow = (k) => {
        var ar = p.deltaScanFocus[k].article;
        Movement.deleteScanAndFocus(ar);
        p.setDeltaScanFocus( removeAndReturnArray(p.deltaScanFocus, k) );
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('article', 
            { header: 'Article', cell: (i) => <div className="text-center">
                <a target="_blank" href={"/article/info/get?article="+i.getValue()}>{i.getValue()}</a>
            </div> }));
                          
        arr.push(columnHelper.accessor('drawing', {
            header: 'Plan / ref', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
                          
        arr.push(columnHelper.accessor('alert', {
            header: 'Delta', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
                
                          
        arr.push(columnHelper.accessor('id', {
            header: '', 
            cell: (i) => <div className="text-center">
                <button className="btn btn-outline-danger"
                    onClick={()=>deleteRow(i.row.id)}>Supprimer</button>
            </div>
        }));
                        
        return arr;
    }

    //Set columns
    const columns = getColumns();
    
    return <div>
        <h5 className="text-center">Divergence entre scan et Focus</h5>

        {info &&<InfoMsgObj info={info}  infoState={setInfo}/>}
        
        {p.deltaScanFocus && <ReactTable columns={columns} data={p.deltaScanFocus} top0={true} origin="delta_scan_focus" />}

    </div>

}

export default DeltaScan;