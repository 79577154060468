import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { isValidDateTime } from "../../functions/FormCtrl";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import { downloadSingleFile } from "../../services/functions/FilesProcess";
import BomDaoV2 from "../../services/machines/BomDaoV2";
import { hasRole } from "../../functions/StrFunctions";

const BomList = (props) => {
    Moment.locale("fr");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataSave, setDataSave] = useState([]);
    const [error, setError] = useState();
    const [downloading, setDownloading] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user && user.roles.includes("ROLE_ADMIN");
    const isPurchaser = user && user.roles.includes("ROLE_PURCHASE");
    const isValidator = user && (user.roles.includes("ROLE_BOM_VALIDATION"));

    const be = hasRole(["ROLE_MECHANICAL", "ROLE_DESIGNER"]);
    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
        BomDaoV2.getBomList().then(
            (response) => {
                setData(getDataAccordingRole(response))
                setDataSave(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            })
    }, []);

    function getBg(val) {
        if (val === "En attente Achat") {
            return "td-bg-green"
        } else if (val.indexOf("téléchargé") !== -1) {
            return "td-bg-yellow";
        } else {
            return "td-bg-blue";
        }
    }

    function getDataAccordingRole(response, getAll = false) {
        var arr = [];

        if (getAll || isAdmin) {
            setFiltered(false);
            return response;
        }

        if (be) {
            for (let i = 0; i < response.length; i++) if (response[i].status === "En attente de validation BE") arr.push(response[i]);
            setFiltered(true);
            return arr;
        } else if (isPurchaser) {
            for (let i = 0; i < response.length; i++) if (response[i].status === "En attente Achat") arr.push(response[i]);
            setFiltered(true);
            return arr;
        } else {
            return response;
        }
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('business', {
            header: 'Affaire',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('machine', {
            header: 'Machine',
            cell: i => <div className="text-center fw-bold">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('mach_type', {
            header: 'Type de machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('bom_type', {
            header: 'Type de NM',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('creation', {
            header: 'Date d\'import intranet',
            cell: i => <div className="text-center">
                {Moment(i.getValue()).format("DD/MM/yyyy")}</div>
        }));

        arr.push(columnHelper.accessor('lastUpdate', {
            header: 'Date dernier import intranet',
            cell: i => <div className="text-center">{
                Moment(i.getValue()).format("DD/MM/yyyy")}</div>
        }));

        if (isAdmin) {
            arr.push(columnHelper.accessor('status', {
                header: 'Statut',
                cell: i => <div className={"text-center fw-bold " + getBg(i.getValue())} style={{ width: "300px" }}>
                    <select className="form-select text-center" defaultValue={i.getValue()}
                        onChange={(e) => updatePlanningField(e, i.row.id, "status", i.row.original.bom_type)}>
                        <option value="En attente de validation BE">En attente de validation BE</option>
                        <option value="En attente Achat">En attente Achat</option>
                        <option value="Fichier d'import téléchargé">Fichier d'import téléchargé</option>
                    </select>
                </div>
            }));
        } else {
            arr.push(columnHelper.accessor('status', {
                header: 'Statut',
                cell: i => <div className={"text-center fw-bold " + getBg(i.getValue())}>{i.getValue()}</div>
            }));
        }


        if (isAdmin || isPurchaser) {
            arr.push(columnHelper.accessor('need_date', {
                header: 'Date de besoins',
                cell: i => <div className="text-center fw-bold">
                    <input type="date" className="form-control" value={i.getValue()}
                        onChange={(e) => changeData(e, i.row.id, "need_date")}
                        onBlur={(e) => updatePlanningField(e, i.row.id, "need_date", i.row.original.bom_type)}></input>
                </div>
            }));
        }

        if (isAdmin) {
            arr.push(columnHelper.accessor('version', {
                header: 'Version',
                cell: i => <div className="text-center fw-bold">
                    <select className="form-select text-center" defaultValue={i.getValue()}
                        onChange={(e) => updatePlanningField(e, i.row.id, "version", i.row.original.bom_type)}>
                        <option value="">...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                </div>
            }));
        }

        arr.push(columnHelper.accessor('xxx', {
            header: 'Télécharger NM',
            cell: i => <div className="text-center cursor-pointer" onClick={() => exportXls(i.row.id)}>
                {downloading && <ButtonWaiting />}
                {!downloading && <img src="/common/xls_logo.png" style={{ width: "20px" }}></img>}
            </div>
        }));

        arr.push(columnHelper.accessor('xxxx', {
            header: '',
            cell: i => <div className="text-center cursor-pointer">
                <a className="btn btn-outline-success" target="_blank"
                    href={"/bom" + (i.row.original.version === 1 ? "" : "/v2")
                        + "/get?machine=" + i.row.original.machine
                        + "&type=" + i.row.original.bom_type
                        + "&status=" + i.row.original.status}>Entrer</a>
            </div>
        }));

        if (isAdmin || isValidator) {
            arr.push(columnHelper.accessor('xxxxx', {
                header: '',
                cell: i => <div className="text-center cursor-pointer">
                    <button className="btn btn-outline-danger" onClick={() => deleteRow(i.row.id)}>Supprimer</button>
                </div>
            }));
        }

        return arr;
    }

    //Set columns
    const columns = getColumns();

    const deleteRow = (k) => {

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr de vouloir supprimer la " + data[k].machine
                + " des nomenclatures " + data[k].bom_type + " à valider ? ",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [...data];
                        arr = arrayRemoveElt(arr, k);
                        setData(arr);

                        BomDaoV2.deleteBom(data[k].machine, data[k].bom_type).then(
                            () => { },
                            (error) => {
                                setLoading(false);
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const exportXls = (k) => {
        var machine = data[k].machine;
        var type_machine = data[k].typ_config;

        confirmAlert({
            title: "Confirmation",
            message: "Vous êtes sur le point de télécharger " +
                "la nomenclature de la machine " + machine + " (" + type_machine + ").",
            buttons: [
                { label: "MECA + ELEC", onClick: () => { downloadBom(machine, "ALL"); } },
                { label: "MECA", onClick: () => { downloadBom(machine, "MECA"); } },
                { label: "ELEC", onClick: () => { downloadBom(machine, "ELEC"); } }
            ]
        });

    }

    const downloadBom = (machine, type) => {
        setDownloading(true);
        BomDaoV2.exportIntranetBom(machine, type).then(
            (response) => {
                var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", fileName);
                setDownloading(false);
            },
            (error) => {
                setDownloading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );

    }

    const changeData = (e, k, field) => {
        var arr = [...data];
        arr[k][field] = e.target.value;
        setData(arr);
    }

    const updatePlanningField = (e, k, field, bom_type) => {
        var val = e.target.value;

        if (field === "need_date" && !isValidDateTime(val)) {
            setError("Saisissez une date valide !");
            scrollToTop();
            return;
        }

        BomDaoV2.updateBomPlanning(field, data[k].machine, val, bom_type).then(
            () => { },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        );
    }

    return <Fragment>
        <h4 className="text-center">Nomenclatures en achat</h4>

        {error && <ErrorMessage error={error} errorState={setError} />}

        {loading && <div>
            <WaitingRoundSnippers />
            <div className="text-center fw-bold">
                Vous pourrez rechercher votre machine directement dans le filtre du tableau une fois
                le chargement terminé</div>
        </div>}


        {filtered && <div className="alert alert-info text-center fw-bold">
            Ne sont affichées que les lignes pour lesquelles le statut concerne votre service.
            Pour tout afficher cliquez <a href="#" onClick={() => setData(getDataAccordingRole(dataSave, true))}>ici</a>
        </div>}
        {!filtered && <div className="alert alert-info text-center fw-bold">
            Pour n'afficher que les lignes pour lesquelles le statut concerne votre service cliquez
            &nbsp;<a href="#" onClick={() => setData(getDataAccordingRole(dataSave, false))}>ici</a>
        </div>
        }
        <ReactTable columns={columns} data={data} origin="bom_list" classes={"no-padding"} top0={true} />






    </Fragment>
}

export default BomList;