import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import Moment from 'moment/moment';
import { ButtonWaiting, scrollToTop } from '../../../../functions/PageAnimation';
import ErrorMessage from '../../../../common/smallComponents.js/ErrorMessage';
import BusinessDao from '../../../../services/machines/BusinessDao';
import errorManagement from '../../../../services/errorManagement';
import { downloadSingleFile } from '../../../../services/functions/FilesProcess';
import { Fragment } from 'react';
import WaitingRoundSnippers from '../../../../common/smallComponents.js/WaitingRoundSnippers';
import { confirmAlert } from 'react-confirm-alert';
import Miscellaneous, { logObj } from '../../../../services/common/Miscellaneous';
const user = JSON.parse(localStorage.getItem("user"));

const PartSeven = (props) => {

    const [typeExport, setTypeExport] = useState();
    const [typeRevision, setTypeRevision] = useState(2);
    const [comments, setComments] = useState("");
    const [saveInNetwork, setSaveInNetwork] = useState(true);
    const [sendMail, setSendMail] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const html = getHtml();

    Moment.locale("fr");

    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    function getHtml() {
        var html = 'Bonjour,<br><br>'
            + '<div id="logo" style="font-style: italic"><i>Les logos seront insérés ici dans le mail, pas effacer</i></div><br><br>'
            + 'La société <strong>' + props.businessInfo.customer_id + '</strong> nous commande '
            + '<strong>';

        if (props.businessInfo.nb_lign_comp && props.businessInfo.nb_lign_comp > 0) {
            html += "" + props.businessInfo.nb_lign_comp + " ligne(s) "
        } else {
            html += "" + props.machines.length + " machine(s) "
        }

        html += '</strong> pour l’entreprise <strong>' + props.businessInfo.client_final + '</strong> ';

        if (props.businessInfo.pays_livrais) html += 'à ' + props.businessInfo.pays_livrais + ": ";

        html += "<br><br>";
        for (let i = 0; i < props.machines.length; i++)
            html += "   - " + props.machines[i].no_machine + " (" + props.machines[i].typ_config + ")<br>"

        html += "<br><br>Livraison prévue le <strong>" + Moment(props.businessInfo.date_livraison).format("DD/MM/yyyy") + "</strong>.<br><br>";

        html += "Vous trouverez les infos relatives au projet ";
        if (props.businessInfo.folder) {
            html += "à l’adresse suivante: " + props.businessInfo.folder;
        } else {
            html += "sur le serveur";
        }

        html += "<br>";
        html += "<div style='font-family: Arial; font-size: 10px; color: rgb(0, 154, 169); font-weight: bold'>"
        html += "Cordialement<br>Technical department<br>" + user.first_name + " " + user.last_name
        html += "</div>";

        return html;
    }

    const onExport = () => {
        var err = "";
        setError();

        if (typeExport === undefined) err += "Choisissez si vous voulez générer uniquement la fiche affaire, ou vous voulez également les fiches machines.<br>";
        if (typeRevision === undefined) err += "Choisissez s'il s'agit d'une première diffusion, un duplicata ou une nouvelle révision.<br>";
        if (typeRevision !== undefined && typeRevision === 2 && comments.trim() === "") err += "Saisissez le suivi de révision ou \"duplicata\".<br>";

        if (err.length > 0) {
            setError(err);
            scrollToTop();
            return;
        }

        var o = {
            business: props.businessInfo.id_aff,
            typeExport: typeExport,
            typeRevision: typeRevision,
            sendMail: sendMail,
            saveInNetwork: saveInNetwork,
            comments: comments,
            business: props.businessInfo,
            business_revision: props.revisions,
            annexes: props.annexes,
            machine_info: props.machines
        }

        //If notify email, convert to html
        if (sendMail) {
            var mail = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            o.mailBody = mail;
        }

        var log = { ...logObj };
        log.id = props.businessInfo.id_aff;
        log.origin = "business";
        log.log_text = "Export de " + ( typeRevision === 0 ? "première diffusion" : typeRevision === 1 ? "duplicata" : "nouvelle révision" ) 
            + " de la " + (typeExport === 0 ? "fiche affaire seule" : "fiche affaire + toutes les fiches machines") 
            + (saveInNetwork ? " sauvegardé sur le serveur" : " sauvegardé en local") 
            + " " + ( sendMail ? ", mail envoyé" : "mail non envoyé");
        log.old_value = "";
        log.new_value = comments;

        setLoading(true);
        BusinessDao.exportBusinessCard(o).then(
            (response) => {
                if (!saveInNetwork) {
                    Miscellaneous.saveLog(log)
                    var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                    downloadSingleFile("temp", fileName);
                }
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )

    }

    const exportBusinessGuideLine = (e) => {
        var language = e.target.getAttribute("data-language");
        var version = "A";
        setError();

        confirmAlert({
            title: "Version",
            message: "Quelle version désirez-vous ? ",
            buttons: [
                {
                    label: "Ancienne version",
                    onClick: () => { performExport(language, "A") }
                },
                {
                    label: "Nouvelle version",
                    onClick: () => { performExport(language, "B") }
                }
            ]
        });
       

        
    }

    function performExport(language, version){
        setLoading(true);
        BusinessDao.exportBusinessGuideLine(props.businessInfo.id_aff, language, version).then(
            (response) => {
                var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", fileName);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    return (
        <div className="table-responsive">
            <br></br>

            {
                error && <ErrorMessage error={error} />
            }

            <div style={{ display: "flex" }}>
                <h4>Télécharger le guide d'affaire: </h4>

                {
                    !loading && <Fragment>
                        <img src={"/common/fr_lang.png"} style={{ width: "50px" }}
                            data-language="FR" onClick={exportBusinessGuideLine}></img>

                        <img src={"/common/en_lang.png"} style={{ width: "50px", marginLeft: "10px" }}
                            data-language="EN" onClick={exportBusinessGuideLine}></img>
                    </Fragment>
                }

                {
                    loading && <WaitingRoundSnippers />
                }

            </div>
            <br></br>



            <div className="card">
                <div className="card-header mb-3">
                    <h4>Suivi de révision</h4>
                </div>
                <div className="mb-3">
                    <div className="form-check form-check-inline">
                        <label className="form-check-label fw-bold" style={{ width: "100px" }}>Générer</label>
                    </div>

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" checked={typeExport === 0} onClick={() => { setTypeExport(0) }} />
                        <label className="form-check-label" for="inlineRadio1">Une fiche affaire</label>
                    </div>

                    {
                        (!props.revisions || (props.revisions && props.revisions.length === 0)) &&
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" checked={typeExport === 1} onClick={() => { setTypeExport(1) }} />
                            <label className="form-check-label" for="inlineRadio1">Une fiche affaire + toutes les fiches machines</label>
                        </div>
                    }
                </div>

                <div className="mb-3">
                    <div className="form-check form-check-inline">
                        <label className="form-check-label fw-bold" style={{ width: "100px" }}>Il s'agit de</label>
                    </div>

                    {
                        (props.revisions && props.revisions.length <= 0) &&
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" checked={typeRevision === 0}
                                onClick={() => setTypeRevision(0)} />
                            <label className="form-check-label" for="inlineRadio1">Une première diffusion</label>
                        </div>
                    }

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" checked={typeRevision === 1}
                            onClick={() => setTypeRevision(1)} />
                        <label className="form-check-label" for="inlineRadio1">Un duplicata</label>
                    </div>

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" checked={typeRevision === 2}
                            onClick={() => setTypeRevision(2)} />
                        <label className="form-check-label" for="inlineRadio1">Une nouvelle révision</label>
                    </div>
                </div>

                {
                    typeRevision === 2 &&
                    <div className="form-floating mb-3">
                        <textarea className="form-control" placeholder="Leave a comment here"
                            value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                        <label for="floatingTextarea">Saisissez un suivi de révision</label>
                    </div>
                }

                <div className="mb-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" checked={saveInNetwork} onClick={(e) => setSaveInNetwork(e.target.checked)} />
                        <label className="form-check-label" for="inlineCheckbox1">Enregister directement sur le serveur</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" checked={sendMail} onClick={(e) => setSendMail(e.target.checked)} />
                        <label className="form-check-label" for="inlineCheckbox2">Notifier par mail</label>
                    </div>
                </div>

                {
                    sendMail &&
                    <Editor editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="draftEditor-wrapper" editorClassName="draftEditor-editor" />
                }


                <br></br>

                <div className='alert alert-info col-8 offset-2'>
                    <h5>Révision(s) déjà saisie(s)</h5>
                    {
                        props.revisions &&
                        <Fragment>
                            <ul>
                                {
                                    props.revisions.map((v, k) => {
                                        return <li key={"rev" + k}>{v.date_suivi}: {v.commentaire_suivi}</li>
                                    })
                                }
                            </ul>
                        </Fragment>
                    }
                </div>

                <br></br>

                <div className='text-center'>
                    <button className='btn btn-success' onClick={onExport} disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Exporter
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PartSeven;