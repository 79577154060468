import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { CustomToolTipInfo } from "../../common/smallComponents.js/CustomToolTip";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { addOrRemoveOnCheck, arrayRemoveElt } from "../../functions/ArrayFunctions";
import NeedsMngt from "../../services/articles/NeedsMngt";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting } from "../../functions/PageAnimation";

const ListNeeds = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState([]);
    const [toClose, setToClose] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        NeedsMngt.listFreeNeed().then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }, []);

    const closeNeeds = (e) => {
        
        confirmAlert({
            title: "Confirmation",
            message: "Voulez-vous prévenir également les achats ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => { closeNeed(true) }
                },
                {
                    label: "Non",
                    onClick: () => { closeNeed(false) }
                },
                {
                    label: "Annuler"
                }
            ]
        });
    }

    function closeNeed( notifyPurchase ){
        setLoading(true);

        var arr = [];
        for( let i = 0 ; i<toClose.length; i++ ) arr.push( list[ toClose[i] ] );

        NeedsMngt.closeFreeNeed(arr, notifyPurchase).then(
            (response) => {
                window.location.reload();
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    const onCheckCloseNeed = (e, k) => {
        setToClose( addOrRemoveOnCheck(toClose, e, k) );
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('business',
            { header: 'Affaire', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('machine',
            { header: 'Machine', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('subset',
            { header: 'S/E', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('requester',
            { header: 'Auteur', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('rep',
            { header: 'Rep.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('qty', {
            header: <div className="d-flex">
                <div className="ms-auto me-2">Qté du besoin</div>
                <div>
                    <CustomToolTipInfo props={{ tooltipText: "Ceci est la quantité à commander/sortir du stock, en tenant compte de la qté du S/E de la NM générale" }} />
                </div>
            </div>,
            cell: (i) => <div className="text-center ms-auto me-auto">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('article', {
            header: 'Article',
            cell: (i) => <div className="text-center">
                <a href={"/article/info/get?article="+i.getValue()}>{i.getValue()}</a>
            </div>
        }));

        arr.push(columnHelper.accessor('ref', {
            header: 'Plan / Ref.',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('order_need', {
            header: <div className="d-flex">
            <div className="ms-auto me-2">Cmd sur besoin</div>
            <div>
                <CustomToolTipInfo props={{ tooltipText: "Qté commandée spécifiquement pour le besoin, sur affaire" }} />
            </div>
        </div>,
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('out_qty', {
            header: <div className="d-flex">
            <div className="ms-auto me-2">Qté sortie du stock</div>
            <div>
                <CustomToolTipInfo props={{ tooltipText: "Qté sortie du stock pour le besoin (mouvement manuel non pris en compte)" }} />
            </div>
        </div>,
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('stock_avail', {
            header: <div className="d-flex">
            <div className="ms-auto me-2">Qté dispo</div>
            <div>
                <CustomToolTipInfo props={{ tooltipText: "Qté en stock aujourd'hui - besoins en cours (ceux ci-dessous compris) + les commandes sur stock à venir" }} />
            </div>
        </div>,
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('detail_order', {
            header: <div className="d-flex">
            <div className="ms-auto me-2">Cmd sur stock</div>
            <div>
                <CustomToolTipInfo props={{ tooltipText: "Si commande il y a, vous avez le n° de commande + dernière date de confirmation de livraison" }} />
            </div>
            </div>,
            cell: (i) => <div className="text-center" dangerouslySetInnerHTML={{ __html: i.getValue() }}></div>
        }));        

        arr.push(columnHelper.accessor('need_nb', {
            header: 'Clôturer',
            cell: (i) => <div className="text-center">
                { i.row.original.requester === user.trigram && 
                    <input type="checkbox" onClick={(e)=>onCheckCloseNeed(e, i.row.id)}
                        checked={ toClose.includes( i.row.id ) }/>                
                }
            </div>
        }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    return (
        <Fragment>
            <h6 className="text-center">Liste des besoins lancés hors nomenclature</h6>
            
            <div className="text-center mb-3 d-flex">
                <a className={"ms-auto me-"+(toClose.length > 0?"3":"auto")+" btn btn-outline-secondary"} 
                href={"/article/needs/create"}>
                {loading && <ButtonWaiting />}
                Nouvelle demande</a>
                
                {toClose.length > 0 && 
                    <button className="ms-3 me-auto btn btn-outline-danger" 
                        onClick={closeNeeds} disabled={loading}>
                            {loading && <ButtonWaiting />}
                            Supprimer ces besoins</button>
                }
            </div>
     
            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            <ReactTable columns={columns} data={list} top0={true} origin="need_list" />

        </Fragment>
    )

}

export default ListNeeds;