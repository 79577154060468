import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, getHeight } from "../../../functions/PageAnimation";
import { forceString, getFirstWeekOfMonthAndYear, getPreviousMonth, weeksInYear } from "../../../functions/StrFunctions";
import fahDao from "../../../services/common/fahDaoV2";
import errorManagement from "../../../services/errorManagement";
import { arrayRemoveElt, getKeyString, removeEltArray } from "../../../functions/ArrayFunctions";

const Tracking = (p) => {
    const [start, setStart] = useState(1);
    const [end, setEnd] = useState(54);
    const [contract, setContract] = useState("all");
    const [status, setStatus] = useState("all");
    const [person, setPerson] = useState("");
    const [isAcc, setIsAcc] = useState("");
    const [msg, setMsg] = useState();
    const [c2Hist, setC2Hist] = useState(false);
    const [c2Registration, setc2Registration] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [loading, setLoading] = useState(false);
    const [lastPayed, setLastPayed] = useState();

    useEffect(() => {
        if (p.isAccountant) setIsAcc("cpt_");
    }, []);

    const changeYear = (sense) => {
        if (sense === "next") {
            p.getTracking(p.trackYear + 1);
            p.setTrackYear(p.trackYear + 1);
        } else if (sense === "same") {
            p.getTracking(p.trackYear);
        } else {
            p.getTracking(p.trackYear - 1);
            p.setTrackYear(p.trackYear - 1);
        }
    }

    const changeYearIpt = (e) => {
        var year = e.target.value

        if (isNaN(year) || year === p.trackYear) return;

        p.getTracking(year);
        p.setTrackYear(year);
    }

    const getClass = (i, v) => {
        var status = v.tracking;
        var lastDay = v.lastDay;
        var t;
        var idx;

        if (status === undefined) {
            if (lastDay === null) return isAcc + "N";
            if (v.wk_end !== null && ((v.yr_end < p.trackYear) || (v.wk_end < i && v.yr_end === p.trackYear))) return "NA";
            if (v.wk_end !== null && ((v.yr_start > p.trackYear) || (v.wk_start > i && v.yr_start === p.trackYear))) return "NA";
            return isAcc + "N";
        } else {
            t = "_" + v.tracking;
            idx = t.indexOf("_" + i + ":");
            if (idx !== -1) {
                t = t.substring(idx).substring(0, t.indexOf(","));
                var split = t.split(":");
                if (split[1] === "N") {
                    return split[2] === "true" ? isAcc + "S" : isAcc + "N";
                } else if (split[1] !== isAcc + "N") {
                    return isAcc + split[1];
                } else {
                    return isAcc + "N";
                }
            } else {
                if (v.wk_end !== null && (v.wk_end < i && v.yr_end <= p.trackYear)) return isAcc + "NA";
                if (v.wk_end !== null && (v.wk_start > i && v.yr_start === p.trackYear)) return isAcc + "NA";
                return isAcc + "N";
            }
        }
    }

    /** */
    const relaunch = (k) => {
        var email = p.tracking[k].ZN02PP;

        confirmAlert({
            title: "Confirmation",
            message: "Voulez-vous relancer à l'adresse " + email + " ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setMsg("Envoi en cours, vous serez en copie");
                        fahDao.remindFah(email).then(
                            () => { }, (error) => {
                                p.setError(errorManagement.handleError(p, error));
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const getC2History = (registration, tracking) => {
        setC2Hist([]);
        setLoading(true);
        setc2Registration(registration)

        if( tracking ){
            var segment = tracking.substring(0, tracking.lastIndexOf(":P"));
            segment = segment.substring( segment.lastIndexOf(",") + 1, segment.length ).replace("_", "");
            setLastPayed( segment );
        }else{
            setLastPayed();
        }
        

        fahDao.getC2History(registration).then(
            (r) => {
                setC2Hist(r);
                setLoading(false);
            }, (error) => {
                setLoading(false);
                p.setError(errorManagement.handleError(p, error));
            }
        );
    }

    const correctC2 = (e) => {
        e.preventDefault();
        setLoading(true);

        var month = e.target.month.value
        var semaine = getFirstWeekOfMonthAndYear(month, e.target.year.value)[1] //e.target.week.value;

        var param = {
            matricule: c2Registration,
            semaine: semaine,
            annee: e.target.year.value,
            month: month,
            tot_c2: parseFloat(e.target.tot_c2.value) || 0,
            c2_taken: parseFloat(e.target.c2_taken.value) || 0,
            correction: 1
        }

        if (param.tot_c2 === '' && param.c2_taken === '') {
            alert("Saisissez au moins une récup cumulée et/ou posées");
            return;
        } else {

            setLoading(true);
            fahDao.correctC2(param).then(
                (r) => {
                    var arr = [...c2Hist];
                    param.solde = (parseFloat(param.tot_c2)) - (parseFloat(param.c2_taken));
                    arr.push(param);
                    setC2Hist(arr);

                    //Update planning
                    var arr2 = [...p.tracking];
                    var k = getKeyString(arr2, "focus_id", param.matricule.toString())
                    arr2[k].c2 += param.solde;
                    p.setTracking(arr2);
                    setLoading(false);
                },
                (error) => {
                    alert("Une erreur s'est produite")
                }
            )
        }
    }

    const deleteCorrection = (k) => {
        var param = {
            matricule: c2Hist[k].matricule,
            semaine: c2Hist[k].semaine,
            annee: c2Hist[k].annee,
            solde: c2Hist[k].solde
        }

        setLoading(true);
        fahDao.deleteCorrectC2(param).then(
            (r) => {
                //Update planning
                var arr2 = [...p.tracking];
                var k2 = getKeyString(arr2, "focus_id", param.matricule.toString())
                arr2[k2].c2 -= param.solde;
                p.setTracking(arr2);

                var arr = [...c2Hist];
                arr = arrayRemoveElt(arr, k);
                setC2Hist(arr);
                setLoading(false);
            },
            (error) => {
                alert("Une erreur s'est produite")
            }
        )

    }



    return <Fragment>
        <div className="accordion mb-3" style={{ marginTop: "5px" }}>
            <div className="accordion-item col-8 offset-2">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="fw-bold" style={{ width: "100%" }}>
                            <h6 className="position-absolute">Légende & filtre</h6>
                            <div>
                                <input style={{ maxWidth: "350px" }} className="form-control text-center m-auto" value={person}
                                    placeholder="Rechercher par nom, prénom ou trigramme"
                                    onChange={(e) => { setPerson(e.target.value) }} />
                            </div>
                        </div>
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        {/** LEGEND */}
                        <div className="display-flex text-center mb-3 table-responsive" style={{ width: "100%" }}>
                            <div className={"legend " + isAcc + "L"}>Légende</div>
                            <div className={"legend " + isAcc + "N"}>Non fait par le salarié</div>
                            <div className={"legend " + isAcc + "S"}>Non validé par le responsable</div>
                            <div className={"legend " + isAcc + "H"}>Validé par le responsable</div>
                            <div className={"legend " + isAcc + "C"}>Validé par la comptabilité</div>
                            <div className={"legend " + isAcc + "P"}>Validé en paye</div>
                            <div className={"legend " + isAcc + "NA text-white"}>Hors contrat</div>
                            <div className={"legend " + isAcc + "F"} style={{ borderTopRightRadius: "0.5em", borderBottomRightRadius: "0.5em" }}>Facturé</div>
                        </div>

                        {/** FILTERS */}
                        <div className="table-responsive">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Semaines affichées de</span>
                                <input type="number" className="form-control" value={start} onChange={(e) => { setStart(e.target.value) }} />
                                <span className="input-group-text">jusqu'à</span>
                                <input type="number" className="form-control" value={end} onChange={(e) => { setEnd(e.target.value) }} />
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text">Type de contrat</span>
                                <div className={"input-group-text " + (contract === "all" ? " bg-info" : "")}>
                                    <input className="form-check-input mt-0" type="radio" value="all"
                                        onClick={() => setContract("all")} checked={contract === "all"}></input>
                                </div>
                                <span className={"input-group-text" + (contract === "all" ? " bg-info" : "")}>Tous</span>

                                <div className={"input-group-text " + (contract === "sal" ? " bg-info" : "")}>
                                    <input className="form-check-input mt-0" type="radio" value="sal"
                                        onClick={() => setContract("sal")} checked={contract === "sal"}></input>
                                </div>
                                <span className={"input-group-text" + (contract === "sal" ? " bg-info" : "")}>Salariés</span>

                                <div className={"input-group-text " + (contract === "interim" ? " bg-info" : "")}>
                                    <input className="form-check-input mt-0" type="radio" value="interim"
                                        onClick={() => setContract("interim")} checked={contract === "interim"}></input>
                                </div>
                                <span className={"input-group-text" + (contract === "interim" ? " bg-info" : "")}>Intérimaires</span>
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text">Nom, prénom ou trigramme</span>
                                <input className="form-control" value={person} onChange={(e) => { setPerson(e.target.value) }} />
                            </div>

                            <div className="input-group mb-3">
                            <span className="input-group-text">Statut</span>
                            <select class="form-select" onChange={(e) => setStatus(e.target.value)} >
                                <option value="all">Tous</option>
                                <option value="N" data-class="legend N">Non fait par le salarié</option>
                                <option value="S" class="legend S">Non validé par le responsable</option>
                                <option value="H" class="legend H">Validé par le responsable</option>
                                <option value="C" class="legend C">Validé par la comptabilité</option>
                                <option value="P" class="legend P">Validé en paye</option>
                                <option value="NA" class="legend NA text-white">Hors contrat</option>
                                <option value="F" class="legend F" style={{borderTopRightRadius: "0.5em", borderBottomRightRadius: "0.5em"}}>Facturé</option>
                            </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <img onClick={() => changeYear("same")} title="Mettre à jour le suivi"
            src="/common/refresh.png" className="cursor-pointer"
            style={{ marginLeft: "100px", width: "40px", position: "absolute", zIndex: "9999" }}></img>

        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <div>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th className="cursor-pointer" onClick={(e) => changeYear("previous")}>
                            <img src="/common/prev.png" style={{ width: "20px" }} />
                        </th>
                        <th className="text-center" style={{ width: "60%" }}>
                            <input type="number" className="form-control text-center fw-bold"
                                defaultValue={p.trackYear} onBlur={changeYearIpt}
                                style={{ width: "100px", margin: "auto" }}></input>
                        </th>
                        <th className="cursor-pointer" onClick={(e) => changeYear("next")}>
                            <img src="/common/next.png" style={{ width: "20px" }} />
                        </th>
                    </tr>
                </thead>
            </table>
        </div>

        {/** TABLE */}
        <div className="table-responsive" style={{ width: "100%", height: getHeight("tracking_fah") }}>
            <table className="text-center overflow-y-auto" id="tracking_fah">
                <thead>
                    <tr className="bg-light-subtle sticky-top" >
                        <th style={{ minWidth: "50px", maxWidth: "50px" }}></th>
                        <th style={{ minWidth: "190px", maxWidth: "190px" }}>Nom</th>
                        <th style={{ minWidth: "190px", maxWidth: "190px" }}>Prénom</th>
                        <th style={{ minWidth: "55px", maxWidth: "55px" }}>Trig.</th>
                        <th style={{ minWidth: "55px", maxWidth: "55px" }}>Resp.</th>
                        <th style={{ minWidth: "55px", maxWidth: "55px" }}>Récup</th>
                        {Array.from(Array(weeksInYear(p.trackYear)), (e, i) => {
                            return (start <= i + 1 && end >= i + 1) &&
                                <th key={"track_h_" + i} style={{ minWidth: "30px", maxWidth: "30px" }}>{i + 1}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        p.tracking.map((v, k) => {
                            return ((contract === "all" || (contract === "interim" && v.isInterim === "O")
                                    || (contract === "sal" && v.isInterim === "N")) &&
                                (person === "" || (person !== "" && (v.lastname + v.firstname + v.trigram + v.manager).toLocaleUpperCase().indexOf(person.toLocaleUpperCase()) !== -1))
                                && ( status === "all" || ( forceString( v.tracking ).indexOf( ":" + status ) !== -1 ) )
                            ) &&
                                <tr key={"track" + k}>
                                    {/** Left header */}
                                    <td style={{ minWidth: "50px", maxWidth: "50px" }} className="cursor-pointer"><img src="/common/bell.png" title="Relancer"
                                        onClick={() => relaunch(k)} /></td>
                                    <td style={{ minWidth: "190px", maxWidth: "190px" }}>{v.lastname}</td>
                                    <td style={{ minWidth: "190px", maxWidth: "190px" }}>{v.firstname}</td>
                                    <td style={{ minWidth: "55px", maxWidth: "55px" }}>{v.trigram}</td>
                                    <td style={{ minWidth: "55px", maxWidth: "55px" }}>{v.manager}</td>
                                    <td style={{ minWidth: "55px", maxWidth: "55px" }}>
                                        { ( v.c2 && v.registration )  && <button className="simple-btn" 
                                                title={v.registration} onClick={() => getC2History(v.registration, v.tracking)}>
                                            {v.c2.toFixed(1)}h
                                        </button>}

                                        { ( !v.c2 && v.c2 !== 0 && v.registration )  && <button className="simple-btn" title={v.registration} onClick={() => getC2History(v.registration)}>
                                            0h</button>}
                                    </td>

                                    {/** Tracking */}
                                    {Array.from(Array(weeksInYear(p.trackYear)), (e, i) => {
                                        return (start <= i + 1 && end >= i + 1) &&
                                            <th style={{ minWidth: "30px", maxWidth: "30px" }} className={"legend " + getClass(i + 1, v)} key={"t_status_" + i}
                                                onDoubleClick={(e) => p.doFah(i, k)}></th>
                                    })}
                                </tr>
                        })
                    }
                </tbody>
            </table>
        </div>

        {c2Hist && <div className="custom-tooltip-v2">
            <div className="modal-body xl blue-purple-bg" style={{ maxWidth: "850px" }}>
                <button type="button" className="btn-close" onClick={() => setC2Hist()}></button>

                <h5 className="text-center">Historique des récupérations</h5>
                {loading && <ButtonWaiting />}
                {/*<p className="text-success text-center fw-bold">Remarque: les lignes en vert sont des saisies manuelles faites par la RH</p>*/}
                {lastPayed && <div className="alert alert-warning text-center">
                    La dernière validation en paye a été faite en semaine <strong>{lastPayed}</strong></div>}

                {c2Hist.length > 0 && <div>
                    <div className="overflow-y-auto" style={{ height: getHeight("c2_recap", 200) }}>
                        <table className="table ms-auto me-auto text-center table-striped" id="c2_recap" style={{ maxWidth: "500px" }}>
                            <thead>
                                <tr className="fixed-top">
                                    <th>Période paye</th>
                                    <th>Récup. cumulées</th>
                                    <th>Récup. posées</th>
                                    <th>Cumul</th>
                                    {(user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_FAH_ACCOUNTANT')) &&
                                        <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {c2Hist.map((v, k) => {
                                    return <tr key={"c2" + k} >
                                        <td className={v.correction === 1 ? "text-success" : ""}>{v.semaine} / {v.annee}</td>
                                        <td className={v.correction === 1 ? "text-success" : ""}>{v.tot_c2 > 0 && v.tot_c2.toFixed(1)}</td>
                                        <td className={v.correction === 1 ? "text-success" : ""}>{v.c2_taken > 0 && v.c2_taken.toFixed(1)}</td>
                                        {/*<td className={"fw-bold " + (v.correction === 1 ? "text-success" : "")} >
                                            { v.solde.toFixed(1) }
                                        </td>*/}
                                        <td className={"fw-bold " + (v.correction === 1 ? "text-success" : "")} >
                                            { v.cumulative_solde !== undefined && v.cumulative_solde.toFixed(1) }
                                        </td>
                                        {(user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_FAH_ACCOUNTANT')) &&
                                            <td>
                                                {v.correction === 1 &&
                                                    <button className="btn btn-danger" disabled={loading}
                                                        onClick={() => deleteCorrection(k)}>
                                                            {loading && <ButtonWaiting />}Effacer</button>
                                                }
                                            </td>
                                        }
                                    </tr>
                                })}
                            </tbody>
                        </table>


                    </div>

                </div>}

                {(user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_FAH_ACCOUNTANT')) && <Fragment>
                        <h6 className="text-center fw-bold">Corriger le solde</h6>
                        <form onSubmit={correctC2}>
                            <div className="input-group mb-3">
                                {/*<span className="input-group-text" id="basic-addon1">Semaine</span>
                                <input type="number" className="form-control" name="week" />*/}

                                <span className="input-group-text" id="basic-addon1">Semaine</span>
                                <input type="number" className="form-control" name="month" />

                                <span className="input-group-text" id="basic-addon1">Année</span>
                                <input type="number" className="form-control" name="year" />

                                <span className="input-group-text" id="basic-addon1">Récup. cumulées</span>
                                <input type="number" step="any" className="form-control" name="tot_c2" />

                                <span className="input-group-text" id="basic-addon1">Récup. posées</span>
                                <input type="number" step="any" className="form-control" name="c2_taken" />

                                <button type="submit" className="btn btn-success" disabled={loading}>
                                    {loading && <ButtonWaiting />}Ajouter</button>
                            </div>
                        </form>
                    </Fragment>}
            </div>
        </div>}
    </Fragment>

}

export default Tracking;