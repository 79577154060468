import { Fragment } from "react";
import Moment from "moment";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { getFrFormat } from "../../functions/StrFunctions";


const History = (p) => {
    Moment.locale("fr");

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('datetime', {
            header: 'Date',
            cell: i => <div className="text-center fw-bold">{ getFrFormat( i.getValue(), false)}</div>
        }));

        arr.push(columnHelper.accessor('log_text', {
            header: 'Log',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('old_value', {
            header: 'Ancienne valeur',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('new_value', {
            header: 'Nouvelle valeur',
            cell: i => <div>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('author', {
            header: 'Auteur',
            cell: i => <div>{i.getValue()}</div>
        }));


        return arr;
    }
 
    //Set columns
    const columns = getColumns();

    return <Fragment>

        <ReactTable columns={columns} data={p.history}
                origin="bom_content" classes={"no-padding"} top0={true} />

        
    </Fragment>

}

export default History;