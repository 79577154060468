import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"setting/form";

const getForms = () => {
    return axios
    .post(API_URL + "/get-forms",
    {fake_header: authHeader()},
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getForm = (form, index, machine_type) => {
    return axios
    .post(API_URL + "/get-form",
    {
        fake_header: authHeader(),
        form: form,
        index: index,
        machine_type: machine_type
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

/**
 * 
 * @param {*} form 
 * @param {*} part chapter or rubric or question 
 */
const saveForm = (form, part) => {
    return axios
    .post(API_URL + "/save-form",
    {
        fake_header: authHeader(),
        form: form,
        part: part
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

/**
 * 
 */
const saveFormV2 = (form) => {
    return axios
    .post(API_URL + "/save-form/v2",
    {
        fake_header: authHeader(),
        form: form
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteEltForm = (o) => {
    return axios
    .post(API_URL + "/delete",
    {
        fake_header: authHeader(),
        ...o
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteForm = (o) => {
    return axios
    .post(API_URL + "/delete/form",
    {
        fake_header: authHeader(),
        ...o
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getNightAlertById = (id) => {
    return axios
    .post(API_URL + "/night-alerts/get",
    {
        fake_header: authHeader(),
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveNightAlert = (form) => {
    return axios
    .post(API_URL + "/night-alerts/save",
    {
        fake_header: authHeader(),
        form: form
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getAllNightAlert = () => {
    return axios
    .post(API_URL + "/night-alerts/get/list",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const testNightAlert = (id) => {
    return axios
    .post(API_URL + "/night-alerts/test",
    {
        fake_header: authHeader(),
        id: id
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    getForms,
    saveForm,
    deleteEltForm,
    getForm,
    saveFormV2,
    deleteForm,
    getNightAlertById,
    saveNightAlert,
    getAllNightAlert,
    testNightAlert
};