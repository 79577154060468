import { Fragment, useEffect, useState } from "react";
import contactsDao from "../../services/settings/contactsDao";

export const AutocompleteEmail = ({ value, onChange, placeholder, required=false, onBlur }) => {
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        contactsDao.getAllContacts().then(
            (res) => {
                const emailSet = new Set();
                res.forEach(contact => {
                    if (contact.email) emailSet.add(contact.email.toLowerCase().trim());
                });
                setEmails([...emailSet]);
            }
        )

    }, []);

    return <Fragment>
        <input className="form-control" value={value} onChange={onChange} list="emails"
            placeholder={placeholder} required={required} onBlur={onBlur}/>

        <datalist id="emails">
            { emails.map( (v, k) => <option value={v} key={"email" + k}></option> )}
        </datalist>
    </Fragment>

};

