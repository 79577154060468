import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";
import { fileToBase64 } from "../../functions/StrFunctions";

const API_URL = process.env.REACT_APP_API_URL + "common/share-file";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const canUpload = () => {
    return axios
        .post(API_URL + "/canUpload", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const uploadChunk = (chunk, id) => {
    return axios
    .post(API_URL + "/upload-chunks/v2", {
        file:chunk,
        id: id,
        fake_header: authHeader()
    }, { headers: textPlainHeader() })
    .then((response) => {
        return response;
    });
}

const uploadFilesV2 = (recipients, files, bodyMail) => {
    
    return axios
    .post(API_URL + "/upload/v2", {
        bodyMail:bodyMail,
        files: files,
        recipients: recipients,
        fake_header: authHeader()
    }, { headers: textPlainHeader() })
    .then((response) => {
        return response;
    });

}

const getShare = (id) => {
    return axios
        .post(API_URL + "/public/get-files-to-download", {
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getShareV2 = (id) => {
    return axios
        .post(API_URL + "/public/get-files-to-download/v2", {
            id: id
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const uploadAnyFiles = (url, objects, files) => {
    let formData = new FormData();

    for (const property in objects) formData.append(property, objects[property]);
    for (let i = 0; i < files.length; i++) formData.append("files", files[i]);

    formData.append("fake_header", token);

    //for (let pair of formData.entries()) console.log(pair[0] + ": " + pair[1]);
    
    return axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        return response.data;
    });

}

const uploadAnyWhere = async (folder, files) => {
    var filesArr = [];

    for (let i = 0; i < files.length; i++){
        var b64 = await fileToBase64(files[i]);
        filesArr.push(b64);
    }

    return axios
    .post(API_URL + "/upload/internal", {
        files: filesArr,
        folder: folder,
        fake_header: authHeader()
    }, { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const uploadTemp = async (files) => {
    return uploadAnyWhere("temp", files);
}

const uploadByType = async (type, label, file) => {

    var b64 = await fileToBase64(file);
   
    return axios
    .post(API_URL + "/by-type/upload", {
        type: type,
        label: label,
        file: b64,
        fake_header: authHeader()
    }, { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getDocsByType = (type) => {
    return axios
        .post(API_URL + "/by-type/get", {
            type: type,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteDocsByType = (id, url) => {
    return axios
        .post(API_URL + "/by-type/delete", {
            id: id,
            url: url,
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getUrlToDisplayDocByType = ( id ) => {
    var token = user && "Bearer " + user.accessToken + "";
    return API_URL + "/by-type/display?Authorization=" + token + "&id=" + id;
}


export default {
    canUpload,
    uploadChunk,
    getShare,
    uploadAnyFiles,
    uploadAnyWhere,
    uploadFilesV2,
    getShareV2,
    uploadByType,
    getDocsByType,
    deleteDocsByType,
    getUrlToDisplayDocByType,
    uploadTemp
}