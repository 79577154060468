import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import { getKey } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { getCurrentWeekYear, getDaysOfWeek } from "../../functions/StrFunctions";
import fahDao from "../../services/common/fahDaoV2";
import errorManagement from "../../services/errorManagement";
import CommonRequests from "./smallComponents/CommonRequests";
import { caculateSchedule } from "./smallComponents/FahHeader";
import ImportFocus from "./smallComponents/ImportFocus";
import { calculateSumarry, checkBeforSubmit, checkConstrainsts } from "./smallComponents/InstallerCalculation";
import InstallerPC from "./smallComponents/InstallerPC";
import Schedules from "./smallComponents/Schedules";
import SendToInterim from "./smallComponents/SendToInterim";
import Tracking from "./smallComponents/Tracking";
import Billing from "./Billing";

const Accountant = (props) => {
    const [response, setResponse] = useState();

    const [tab, setTab] = useState(3);
    const [lastTagName, setlastTagName] = useState();
    const [lastTabLoad, setLastTabLoad] = useState(false);
    const [myInterim, setMyInterim] = useState([]);
    const [toSendInterim, setToSendInterim] = useState({ week: null, year: null });

    const [tracking, setTracking] = useState();
    const [schedules, setSchedules] = useState();

    const [error, setError] = useState();

    const [week, setWeek] = useState();
    const [year, setYear] = useState();
    const [trackYear, setTrackYear] = useState();

    const [member, setMember] = useState();
    const [weekDays, setWeekDays] = useState();

    const [toCheck, setToCheck] = useState();
    const [checkingMode, setCheckingMode] = useState(false);
    const [checkCursor, setCheckCursor] = useState(0);

    const [hours, setHours] = useState([]);
    const [abs, setAbs] = useState([]);
    const [emptyObj, setEmptyObj] = useState();
    const [soldeC2, setSoldeC2] = useState(0);

    const [disRejectCause, setDisRejectCause] = useState(false);
    const [err, setErr] = useState(new Object());
    const [trackDblClick, setTrackDblClick] = useState();
    const [trackDblClickSave, setTrackDblClickSave] = useState();
    const [newClockCsv, setNewClockCsv] = useState();
    
    const user = JSON.parse(localStorage.getItem("user"));
    const [isAdmin] = useState(user && user.roles.includes("ROLE_ADMIN"));

    /*const [summaryNonInst, setSumarryNonInst] = useState({
        tpstrav: 0, hrexc: 0,
        conge_ded_jour: 0, conge_ded_heure: 0, hr_exc_100: 0, hr_sup_125: 0,
        hr_sup_150: 0, maj_nuit: 0, maj_dim_ferie: 0, tot_cpt: 0, tpstrav: 0,
        abs_disease: 0, abs_c1: 0,
        night_justif: "", over_justif: "", move_justif: "", comment_except_bonus: ""
    });*/

    const [summaryInst, setSumarryInst] = useState({
        work_time: 0, wrk_or_ass: 0,
        cons_wrk_or_ass: 0, exc_hours: 0, abs_tot_d: 0, abs_tot_h: 0,
        abs_disease: 0, abs_c1: 0, abs_c2: 0, move: 0,
        p100: 0, p125: 0, p150: 0, p50: 0, p_d_100: 0, c1_100: 0, c1_125: 0,
        c1_150: 0, majD: 0, c2_25: 0, c2_100: 0, c2_50: 0,
        tot_p: 0, tot_c1: 0, tot_c2: 0,
        night_justif: "", over_justif: "", move_justif: "", comment_except_bonus: ""
    });

    useEffect(() => {
        var curPeriod = getCurrentWeekYear();
        var w = curPeriod.week === 1 ? 54 : curPeriod.week - 1;
        var y = curPeriod.week === 1 ? curPeriod.year - 1 : curPeriod.year;
        setWeek(w);
        setYear(y);
        setTrackYear(y);
        setToSendInterim({ week: w, year: y });

        // Get tracking
        getTracking(y);

        // Get fah to check
        fahDao.getToCheckAccountant().then(
            (response) => {
                setToCheck(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

        //Get new clocking date to import in focus
        fahDao.getClockToImport(null).then(
            (response) => {
                setNewClockCsv(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

        //Get schedules 
        fahDao.getSchedules().then(
            (response) => {
                setSchedules(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            }
        );

    }, []);

    const doFah = (e) => {
        e.preventDefault();

        var k = getKey(tracking, "trigram", e.target.trigram.value.trim().toUpperCase());

        if (k === null) {
            setError("Introuvable dans le suivi de l'année " + trackYear);
        } else {
            setLastTabLoad(true);
            setMember(tracking[k]);
            doFahCommon(tracking[k], week, year);
        }
    }

    //On abs change calculate the hours
    useEffect(() => {
        //if (abs && hours) setLastTabLoad(false);

        if (member && member.isInstaller) {

            var timeout = setTimeout(() => {
                if (weekDays !== undefined) {
                    clearTimeout(timeout);
                    setWeekDays(caculateSchedule(weekDays, abs));
                    /*if (member.isInstaller === "N") {
                        calculateSumarryNi(abs, hours, summaryNonInst, setSumarryNonInst, weekDays);
                        checkConstrainstsNi(err, setErr, weekDays, hours, summaryNonInst, setError, abs);
                    } else {*/
                    calculateSumarry(abs, hours, summaryInst, setSumarryInst, weekDays);
                    checkConstrainsts(err, setErr, weekDays, hours, summaryInst, setError, abs);
                    //}
                }
            }, 100);
        }
    }, [abs, hours]);

    // On summary non installer change check constraints
    /*useEffect(() => {
        if (weekDays !== undefined && hours !== undefined)
            checkConstrainstsNi(err, setErr, weekDays, hours, summaryNonInst, setError, abs);
    }, [summaryNonInst]);*/

    // On summary installer change check constraints
    useEffect(() => {
        if (weekDays !== undefined && hours !== undefined)
            checkConstrainsts(err, setErr, weekDays, hours, summaryInst, setError, abs);
    }, [summaryInst]);

    /**
     * 
     * @param {*} y 
     */
    const getTracking = (y) => {
        setTracking();

        fahDao.getTrackingAccountant(y).then(
            (response) => {
                setTracking(response);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
            });
    }

    const sortCheckList = (e) => {
        var arr = [...toCheck];

        if (e.target.value === "name") {
            arr.sort(sortByName);
        } else {
            arr.sort(sortByPeriod);
        }

        setToCheck(arr);
    }

    function sortByName(a, b) {
        const nameA = a.lastname.toUpperCase();
        const nameB = b.lastname.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }

    function sortByPeriod(a, b) {
        if (a.year_tc !== b.year_tc) return a.year_tc - b.year_tc;
        return a.week_tc - b.week_tc;
    }

    {/** Start checking */ }
    const startCheck = (k) => {
        setCheckCursor(k);
        setMember();
        //Do FAH
        doFahCommon(toCheck[k], toCheck[k].week_tc, toCheck[k].year_tc);
    }

    /**
     * Get specific FAH to do or check it
     * @param {*} member 
     * @param {*} week 
     * @param {*} year 
     */
    const doFahCommon = async (member, week, year) => {
        setWeek(week);
        setYear(year);
        setAbs([]);
        setHours([]);
        setResponse();

        setLastTabLoad(true);
        setMember(member);
        var arr = getDaysOfWeek(week, year);
        setWeekDays(arr);

        setTab(2);
        setlastTagName("FAH de " + member.trigram);

        var params = {
            focus_id: member.focus_id,
            week: week,
            year: year,
            oldBusiness: false,
            oldWeek: null,
            oldYear: null,
            oldHours: false,
            start: Moment(arr[0].en_date).format("YYYY-MM-DD"),
            end: Moment(arr[6].en_date).format("YYYY-MM-DD"),
            trigram: member.trigram
        }

        CommonRequests.getInstaller(params, setWeekDays, setAbs, setHours, setEmptyObj, member,
            setMember, setLastTabLoad, setResponse, setError, props, setSoldeC2);

        /*if (member.isInstaller === "O") {} else {
            CommonRequests.getNonInstaller(params, setWeekDays, setAbs, setHours, setEmptyObj, member,
                setMember, setLastTabLoad, setResponse, setError, props); }*/
    }


    const onDblClickTrack = (week, k) => {
        setTrackDblClick({ k: k, week: week });
    }

    /**
     * 
     */
    const validate = () => {
        fahDao.checkingResp(member, week, year, "C", "");
        setStatus("Validé");
    }

    const reject = () => {
        setDisRejectCause(true);
    }

    const sendReject = (e) => {
        e.preventDefault();
        var cause = e.target.cause.value;

        if (cause.trim() !== "") {
            fahDao.checkingResp(member, week, year, "N", cause);
            setDisRejectCause(false);
            setStatus("Refusé");
        }
    }

    const skip = () => {
        setStatus("Ignoré");
    }

    function setStatus(msg) {
        setError();
        setTimeout(() => { setMember(); }, 100);

        if (checkCursor >= toCheck.length - 1) {
            var arr = [...toCheck];
            toCheck[checkCursor].msg = msg;
            setToCheck(arr);

            setTab(1);
            setlastTagName();
        } else {
            //Do FAH
            doFahCommon(toCheck[checkCursor + 1], toCheck[checkCursor + 1].week_tc, toCheck[checkCursor + 1].year_tc);

            var arr = [...toCheck];
            toCheck[checkCursor].msg = msg;
            setToCheck(arr);
            setCheckCursor(checkCursor + 1);
        }
    }

    const changeStatus = (status, saved) => {
        var trackDbl = saved ? trackDblClickSave : trackDblClick;

        var k = trackDbl.k;
        var arr = [...tracking];
        var tr = arr[k].tracking;
        var week = trackDbl.week + 1;

        if (!tr) {
            arr[k].tracking = getStatus(week, status);
        } else {
            if (arr[k].tracking.indexOf("_" + week) === -1) {
                arr[k].tracking += getStatus(week, status);
            } else {
                var track = arr[k].tracking;
                var i = track.indexOf("_" + week);
                var newTrack = track.substring(0, i)
                    + getStatus(week, status)
                    + track.substring(i + getStatus(week, status).length - 1, track.length)
                arr[k].tracking = newTrack;
            }
        }

        setTracking(arr);

        if( saved ){
            var o = {...trackDblClickSave};
            o.week = o.week + 1;
            setTrackDblClickSave(o);
            displayFah( week+1, k );
            scrollToTop();
        }else{
            setTrackDblClick();
        }
        

        fahDao.checkingResp(arr[k], week, year, status, "");
    }

    /**
     * 
     * @param {*} week 
     */
    const displayFah = (week, k) => {
        var year = trackYear;
        doFahCommon(tracking[k], week, year);
        if(trackDblClick) setTrackDblClickSave({...trackDblClick});
        setTrackDblClick();
    }

    function getStatus(week, status) {
        return "_" + week + ":" + status + ":" + (status !== "N") + ",";
    }

    const getInterim = (week, year, trigram) => {
        setMyInterim();
        fahDao.getInterimAccountant(week, year, trigram).then(
            (response) => {
                setMyInterim(response);
            }, (error) => {
                setMyInterim([]);
                setError(errorManagement.handleError(props, error));
            }
        )
    }

    return <Fragment>
        {error && <ErrorMessage error={error} errorState={setError} />}

        {/** TABS */}
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={"nav-link " + (tab === 3 && "active")} href="#" onClick={() => { setTab(3); setCheckingMode(false) }}>
                    {!tracking && <ButtonWaiting />}
                    Suivi des FAH
                </a>
            </li>

            <li className="nav-item">
                <a className={"nav-link " + (tab === 0 && "active")} aria-current="page" href="#" onClick={() => { setTab(0); setCheckingMode(false) }}>
                    Voir une FAH
                </a>
            </li>

            <li className="nav-item">
                <a className={"nav-link " + (tab === 1 && "active")} href="#" onClick={() => { setTab(1); setCheckingMode(true) }}>
                    {!toCheck && <ButtonWaiting />}
                    FAH à valider
                </a>
            </li>

            {
                lastTagName &&
                <li className="nav-item">
                    <a className={"nav-link " + (tab === 2 && "active")} href="#" onClick={() => { setTab(2); }}>
                        {lastTabLoad && <ButtonWaiting />}
                        {lastTagName}
                    </a>
                </li>
            }

            <li className="nav-item">
                <a className={"nav-link " + (tab === 6 && "active")} href="#" onClick={() => { setTab(6); setCheckingMode(false) }}>
                    {!myInterim && <ButtonWaiting />}
                    Envoyer FAH aux boites d'interim
                </a>
            </li>

            <li className="nav-item">
                <a className={"nav-link " + (tab === 6 && "active")} href="#" onClick={() => { setTab(4); setCheckingMode(false) }}>
                    {!newClockCsv && <ButtonWaiting />}
                    Fichiers d'import Focus
                </a>
            </li>

            <li className="nav-item">
                <a className={"nav-link " + (tab === 7 && "active")} href="#" onClick={() => { setTab(7); setCheckingMode(false) }}>
                    Facturation intérim. & Paye
                </a>
            </li>

            <li className="nav-item">
                <a className={"nav-link " + (tab === 5 && "active")} href="#" onClick={() => { setTab(5); setCheckingMode(false) }}>
                    {!schedules && <ButtonWaiting />}
                    Heures normales
                </a>
            </li>

        </ul>

        {/** DO FAH */}
        {
            (tab === 0) && <Fragment>
                <div className="card col-6 offset-3 text-center" style={{ minWidth: "300px" }}>

                    <form onSubmit={doFah}>
                        <h5>FAH à visualiser</h5>

                        <div className="input-group mb-3">
                            <span className="input-group-text">Trigramme</span>
                            <input type="text" name="trigram" className="form-control" placeholder="Saisissez le trigramme" />
                        </div>

                        <div className="input-group mb-3">
                            <input type="number" className="form-control text-center fw-bold" onChange={(e) => setWeek(e.target.value)} value={week || ""} />
                            <input type="number" className="form-control text-center fw-bold" onChange={(e) => setYear(e.target.value)} value={year || ""} />
                        </div>

                        <div className="text-center mb-3">
                            <button className="btn btn-outline-success">Voir</button>
                        </div>
                    </form>
                </div>
            </Fragment>
        }

        {/** Error message specific to accountant */}
        {
            (tab === 2 && response && response.contract_chk !== "") && <div className="alert alert-danger">
                {response.contract_chk}
            </div>
        }

        {/** See FAH NON installer */}
        {/*
            (tab === 2 && week && member && member.isInstaller === "N") &&
           <NonInstallerPC weekDays={weekDays} hours={hours} setHours={setHours}
                    emptyObj={emptyObj} week={week} year={year} abs={abs} setAbs={setAbs}
                    summary={summaryNonInst} setSumarry={setSumarryNonInst} loading={lastTabLoad} err={err}
                    error={error} userInfo={member} ignoreTop="true"
                    isAccountant="true" response={response} trigram={member.trigram}/>
        */}

        {/** See FAH installer */}
        {
            (tab === 2 && week && member) &&
            <InstallerPC weekDays={weekDays} hours={hours} setHours={setHours}
                emptyObj={emptyObj} week={week} year={year} abs={abs} setAbs={setAbs}
                summary={summaryInst} setSumarry={setSumarryInst} loading={lastTabLoad} err={err}
                error={error} userInfo={member} ignoreTop="true" soldeC2={soldeC2}
                isAccountant="true"/>
        }

        {/** If is interim let set billed */
            (tab === 2 && response && member.focusInfo.CDINT === "O" ) && <div className="mb-3 text-center">
                <button className="btn btn-secondary"
                   onClick={()=> changeStatus("F", true) } >Passer en facturation</button>
            </div>
        }

        {/** To check */}
        {
            (tab === 1 && toCheck) && <div className="table-responsive">
                <div className="form-check form-check-inline">
                    <label className="form-check-label">Trier par</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="sort" value="name" onClick={sortCheckList} defaultChecked />
                    <label className="form-check-label">Nom</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="sort" onClick={sortCheckList} value="period" />
                    <label className="form-check-label">Période</label>
                </div>

                <table className="table table-striped">
                    <thead>
                        <tr className="text-center">
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Trigramme</th>
                            <th>Intérimaire ?</th>
                            <th>Période à valider</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {
                            toCheck.map((v, k) => {
                                return <tr key={"check_" + k}
                                    className={v.msg && v.msg === "Ignoré" ? "bg-warning" :
                                        v.msg && v.msg === "Validé" ? "bg-success" :
                                            v.msg && v.msg === "Refusé" ? "bg-danger text-white" : ""}>
                                    <td>{v.lastname}</td>
                                    <td>{v.firstname}</td>
                                    <td>{v.trigram}</td>
                                    <td>{v.isInterim === "O" ? "Oui" : ""}</td>
                                    <td>{v.week_tc + "/" + v.year_tc}</td>
                                    <td>{v.msg}</td>
                                    <td>
                                        {
                                            !v.lastname && <span>Rendre visible dans Focus avant de valider</span>
                                        }
                                        {
                                            v.lastname &&
                                            <button className="btn btn-outline-secondary" onClick={() => startCheck(k)}>Commencer la validation ici</button>
                                        }

                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        }

        {/** Tracking */}
        {
            (tab === 3 && tracking) && <Tracking trackYear={trackYear} setTrackYear={setTrackYear}
                tracking={tracking} setTracking={setTracking} getTracking={getTracking} isAccountant="true"
                setYear={setYear} setWeek={setWeek} doFah={onDblClickTrack} />
        }

        {
            (tab === 4 && newClockCsv) && <ImportFocus newClockCsv={newClockCsv} setError={setError} />
        }

        {
            (tab === 5 && schedules) && <Schedules schedules={schedules} setSchedules={setSchedules} />
        }

        {
            (tab === 2 && checkingMode && member) && <div className="text-center mb-3">
                <button className="btn btn-success me-3" onClick={validate}>Valider</button>
                <button className="btn btn-warning me-3" onClick={skip}>Ignorer pour le moment</button>
                <button className="btn btn-danger" onClick={reject}>Refuser</button>
            </div>
        }

        {disRejectCause &&
            <div className="custom-tooltip">
                <button type="button" className="btn-close" onClick={() => setDisRejectCause(false)}></button>
                <h5 className="text-center">Veuillez saisir la raison du refus (obligatoire)</h5>
                <h6 className="text-center">Période: {week}/{year} - {member.lastname}</h6>
                <form onSubmit={sendReject} className="text-center">
                    <textarea className="form-control mb-3" name="cause"></textarea>
                    <button className="btn btn-primary">Envoyer le refus</button>
                </form>
            </div>
        }

        {
            trackDblClick &&
            <div className="custom-tooltip">
                <button type="button" className="btn-close" onClick={() => setTrackDblClick()}></button>
                <h5 className="text-center mb-3">Période: {trackDblClick.week + 1}/{year} - {tracking[trackDblClick.k].lastname}</h5>

                <h6 className="text-center">Changer de statut</h6>
                <div className="legend N rounded mb-2 cursor-pointer" onClick={() => changeStatus("N")}>Non fait par le salarié</div>
                <div className="legend S rounded mb-2 cursor-pointer" onClick={() => changeStatus("S")}>Non validé par le responsable</div>
                <div className="legend H rounded mb-2 cursor-pointer" onClick={() => changeStatus("H")}>Validé par le responsable</div>
                <div className="legend C rounded mb-2 cursor-pointer" onClick={() => changeStatus("C")}>Validé par la comptabilité</div>
                <div className="legend P rounded mb-2 cursor-pointer" onClick={() => changeStatus("P")}>Validé en paye</div>
                <div className="legend F rounded mb-2 cursor-pointer" onClick={() => changeStatus("F")}>Facturé</div>

                <div className="text-center mt-3">
                    <button className="btn btn-secondary" onClick={() => displayFah(trackDblClick.week + 1, trackDblClick.k)}>Voir la FAH</button>
                </div>
            </div>
        }

        {/** To send to interim */}
        {(tab === 6 && myInterim) && <SendToInterim myInterim={myInterim} myStaff={myInterim}
                getInterim={getInterim} toSendInterim={toSendInterim} setToSendInterim={setToSendInterim}
                setMyInterim={setMyInterim} isAccountant={true} props={props}/>}

        { tab === 7 && <Billing /> }
    </Fragment>

}

export default Accountant;