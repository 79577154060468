import React, { useState } from 'react';
import ErrorMessage from '../../common/smallComponents.js/ErrorMessage';
import SuccessMessage from '../../common/smallComponents.js/SuccessMessage';
import SendMail from './TestComponents/SendMail';
import SpeechRecognition from './TestComponents/SpeechRecognition';
import FormData from './TestComponents/FormData';
import WaitingRoundSnippers from '../../common/smallComponents.js/WaitingRoundSnippers';
import TestWindow from './TestComponents/TestWindow';


const Test = (props) => {
  const [tab, setTab] = useState(0);

  const [error, setError] = useState();
  const [msg, setMsg] = useState();
  const [loading, setLoading] = useState();

  return <div>
    {error && <ErrorMessage error={error} errorState={setError}/>}
    {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
    {loading && <WaitingRoundSnippers />}

    <ul className="nav nav-tabs mb-3">
      <li className="nav-item">
        <a className={'nav-link ' + (tab === 0 ? "active" : "")}
          aria-current="page" href="#" onClick={() => setTab(0)}>Mails</a>
      </li>

      <li className="nav-item">
        <a className={'nav-link ' + (tab === 1 ? "active" : "")}
          href="#" onClick={() => setTab(1)}>Speech</a>
      </li>

      <li className="nav-item">
        <a className={'nav-link ' + (tab === 2 ? "active" : "")}
          href="#" onClick={() => setTab(2)}>Form</a>
      </li>
      <li className="nav-item">
        <a className={'nav-link ' + (tab === 3 ? "active" : "")}
          href="#" onClick={() => setTab(3)}>Test Window</a>
      </li>
    </ul>

    {tab === 0 && <SendMail props={props} setError={setError} setMsg={setMsg} />}
    {tab === 1 && <SpeechRecognition props={props} setError={setError} setMsg={setMsg} />}
    {tab === 2 && <FormData props={props} setError={setError} setMsg={setMsg} loading={loading} setLoading={setLoading}/>}
    {tab === 3 && <TestWindow props={props} setError={setError} setMsg={setMsg} loading={loading} setLoading={setLoading}/>}



  </div>
};

export default Test;