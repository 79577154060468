import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import Select from "react-select";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { addOrUpdateElement } from "../../functions/ArrayFunctions";
import { ButtonWaiting, getDeviceType, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { getBase64 } from "../../functions/StrFunctions";
import CheckConnDao from "../../services/common/CheckConnDao";
import errorManagement from "../../services/errorManagement";
import ticketsDao from "../../services/qhse/ticketsDao";
import usersDao from "../../services/settings/usersDao";
import CustomSpeechRecognition, { markToListen } from "../../common/smallComponents.js/SpeechRecognition";

const BbsoForm = (props) => {
    const [takeAPhoto, setTakeAPhoto] = useState(false);
    const [dataPhoto, setDataPhoto] = useState();
    const [id, setId] = useState();
    const [dpt, setDpt] = useState();
    const [incidentDate, setIncidentDate] = useState();
    const [location, setLocation] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [category, setcategory] = useState();
    const [date, setdate] = useState();
    const [description, setdescription] = useState();
    const [email, setemail] = useState();
    const [first_name, setfirst_name] = useState();
    const [last_name, setlast_name] = useState();
    const [myAction, setmyAction] = useState();
    const [risk, setrisk] = useState();
    const [status, setstatus] = useState();
    const [typeObs, settypeObs] = useState();
    const [qhseComment, setQhseComment] = useState(null);
    const [isQhse, setIsQhse] = useState(false);
    const [qhseChange, setQhseChange] = useState([]);
    const [bbsoUsers, setBbsoUsers] = useState();
    const [author, setAuthor] = useState();
    const [authorDpt, setAuthorDpt] = useState();
    const [selecteAuthor, setSelectedAuthor] = useState();
    const [needGlobalId, setNeedGlobalId] = useState(false);
    const [listen, setListen] = useState(false);
    const [elt, setElt] = useState();

    const user = JSON.parse(localStorage.getItem("user"));

    Moment.locale("fr");

    const catOptions = [
        { value: "Choisissez...", label: 'Choisissez...' },
        { value: 'Risque chimique', label: 'Risque chimique' },
        { value: 'Manque de communication', label: 'Manque de communication' },
        { value: 'Risque de coupure', label: 'Risque de coupure' },
        { value: 'Risque électrique (Consignation, …)', label: 'Risque électrique (Consignation, …)' },
        { value: 'Ergonomie', label: 'Ergonomie' },
        { value: 'Sécurité incendie', label: 'Sécurité incendie' },
        { value: 'Outillage (Outils appropriés utilisés)', label: 'Outillage (Outils appropriés utilisés)' },
        { value: 'Risque de manutention mécanique', label: 'Risque de manutention mécanique' },
        { value: 'Risque de manutention manuelle', label: 'Risque de manutention manuelle' },
        { value: 'Travail par point chaud', label: 'Travail par point chaud' },
        { value: 'Environnement de travail', label: 'Environnement de travail' },
        { value: 'Travail en hauteur', label: 'Travail en hauteur' },
        { value: 'Risque sanitaire', label: 'Risque sanitaire' },
        { value: 'Equipement de protection individuel', label: 'Equipement de protection individuel' },
        { value: 'Stockage / Rangement', label: 'Stockage / Rangement' },
        { value: 'Nettoyage et rangement', label: 'Nettoyage et rangement' },
        { value: 'Risque routier', label: 'Risque routier' }
    ]

    const dptOpt = [
        { value: 'QHSE', label: 'QHSE' },
        { value: 'Achats', label: 'Achats' },
        { value: 'Magasin / Logistique', label: 'Magasin / Logistique' },
        { value: 'Commerce', label: 'Commerce' },
        { value: 'Montage', label: 'Montage' },
        { value: 'Comptabilité', label: 'Comptabilité' },
        { value: 'DT', label: 'DT' },
        { value: 'MES / SAV', label: 'MES / SAV' },
        { value: 'BE Meca', label: 'BE Meca' },
        { value: 'BE Autom', label: 'BE Autom' },
        { value: 'RH', label: 'RH' },
        { value: 'Magasin', label: 'Magasin' },
        { value: 'DG', label: 'DG' },
        { value: 'IT', label: 'IT' }
    ]

    useEffect(() => {
        CheckConnDao.internal().then(
            (response) => { },
            (error) => { errorManagement.handleError(props, error); });

        if (user && user.department !== null) setDpt({ value: user.department, label: user.department });

        if (user && (user.global_id === null || user.global_id.trim() === "")) {
            setNeedGlobalId(true);
            setLoading(false);
            return;
        }

        var id = getUrlParam(props, "id");

        if (id !== null) {

            setId(id);
            ticketsDao.getBbso(id).then(
                (response) => {
                    setcategory({ value: response.category, label: response.category });
                    setDataPhoto(response.dataPhoto);
                    setdate(response.date);
                    setdescription(response.description);
                    setemail(response.email);
                    setfirst_name(response.first_name);
                    setlast_name(response.last_name);
                    setmyAction(response.myAction);
                    setrisk(response.risk);
                    setstatus(response.status);
                    settypeObs(response.typeObs);
                    setIncidentDate(response.incidentDate);
                    setLocation(response.location);
                    setQhseComment(response.qhseComment);
                    if (response.author !== null) setAuthor(response.author);
                    setAuthorDpt(response.department || user.department);

                    if (user.id !== response.user_id && user.roles.includes("ROLE_QHSE")) setIsQhse(true);

                    markToListen(setListen, elt, setElt);
                    setLoading(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setLoading(false);
                }
            );
        } else {
            // if qhse get list of users
            if (user && user.roles.includes("ROLE_QHSE")) {
                ticketsDao.getBbsoGoalsList().then(
                    (response) => {
                        var arr = new Array({
                            value: "",
                            label: "C'est le mien !"
                        });
                        response.map(v => {
                            arr.push({
                                value: v.globalId.trim(),
                                label: v.lastName + " " + v.firstName
                            })
                        })

                        setBbsoUsers(arr);
                        markToListen(setListen, elt, setElt);
                    },
                    (error) => {
                        setError(errorManagement.handleError(props, error));
                        scrollToTop();
                        setLoading(false);
                    }
                )
            }

            setemail(user && user.email);
            setAuthorDpt(user.department);
            setLoading(false);
        }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        var elt = e.target;
        var dpt = elt.dpt.value;
        var desc = elt.desc.value;
        var myAction = elt.myAction.value;
        var typeObs = elt.typeObs.value;
        var risk = elt.risk.value;
        var category = elt.category.value;
        var qhseComment = elt.qhseComment === undefined ? null : elt.qhseComment.value;


        if (isNaN(new Date(incidentDate))) {
            setError("La date d'incident est mal écrite: " + incidentDate);
            scrollToTop();
            return;
        }

        setSaving(true);

        var o = {
            dpt: dpt,
            desc: desc,
            myAction: myAction,
            typeObs: typeObs,
            risk: risk,
            category: category,
            dataPhoto: dataPhoto,
            status: status,
            id: id,
            incidentDate: incidentDate,
            location: location,
            qhseChange: qhseChange,
            email: email,
            qhseComment: qhseComment,
            author: author,
            authorDpt: authorDpt
        }
        ticketsDao.saveBbsoForm(o, (id ? "update" : "new")).then(
            (response) => {
                window.location.href = "/qhse/bbso/list?saved=ok";
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setSaving(false);
            }
        );
    }

    /**
     * Open photo device
     * @param {*} e 
     */
    const takePhoto = (e) => {
        setTakeAPhoto(true);
    }

    /**
     * On choose file
     * @param {*} e 
     * @returns 
     */
    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        getBase64(e.target.files[0], (res) => {
            setDataPhoto(res);
        });
    }

    /**
     * Close photo device
     */
    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    /**
     * Save the photo
     * @param {*} data 
     */
    const handlePhoto = (data) => {
        setDataPhoto(data);
        setTakeAPhoto(false);
    }

    /**
     * Delete the taken photo or uploaded img
     */
    const deletePhoto = () => {
        setDataPhoto();
    }

    /**
     * Save department in user profile
     * @param {*} e 
     */
    const onDptChange = (e) => {
        var dpt = e.value;

        if (!selecteAuthor) {
            user.department = dpt;
            localStorage.setItem("user", JSON.stringify(user));
            usersDao.updateMyProfile(user.email, "department", dpt);
            setAuthorDpt(user.department);
        } else {
            setAuthorDpt(e.value);
        }

        setDpt(e);
        onQhseChange(e);

    }

    /**
     * Change the incident date
     * @param {*} e 
     * @returns 
     */
    const onIncidentDateChange = (e) => {
        setError();

        if (new Date(e.target.value).getTime() > new Date().getTime()) {
            setError("Merci de ne pas anticiper un incident. La date doit être antérieure à l'heure actuelle.");
            return;
        }

        setIncidentDate(e.target.value);
        onQhseChange(e);
    }

    /**
     * Change the incident location
     * @param {*} e 
     */
    const onIncidentLocationChange = (e) => {
        setLocation(e.target.value);
        onQhseChange(e);
    }

    const onStatusChange = (e) => {
        setstatus(e.target.value);
        onQhseChange(e);
    }

    const onQhseChange = (e) => {
        if (!isQhse) return;

        var arr = [...qhseChange];

        setQhseChange(addOrUpdateElement(arr, e.target.getAttribute("data-field"), e.target.value));
    }

    const onChooseAuthor = (e) => {
        if (e.value === "") {
            setSelectedAuthor();
            setAuthor();
        } else {
            setSelectedAuthor(e);
            setAuthor(e.value);
        }

    }

    const onGLobalIdChange = (e) => {
        e.preventDefault();
        user.global_id = e.target.globalId.value;
        localStorage.setItem("user", JSON.stringify(user));
        usersDao.updateMyProfileById(user.id, "global_id", e.target.globalId.value);
        window.location.reload();
    }

    return (
        <div className={getDeviceType() === "desktop" ? "col-6 offset-3" : ""}>
            <h3 className="text-center">BBSO - Observation sécurité</h3>

            <div className="alert alert-info text-center">
                Les BBSO ne sont pas nominatifs, seul le nom de celui qui commente les faits est neécessaire pour ensuite faire le suivi des remontées BBSO.
                Le but n'est pas de sanctionner, mais de prendre en compte les bonnes et mauvaises actions de chacun de nous afin de nous améliorer.
            </div>

            {
                (!error && loading) && <WaitingRoundSnippers />
            }

            {error && <ErrorMessage error={error} />}

            {
                needGlobalId &&
                <form onSubmit={onGLobalIdChange}>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Saisissez votre global ID</span>
                        <input type="text" className="form-control" name="globalId" />
                        <button className="btn btn-success">Valider</button>
                    </div>
                </form>
            }

            {
                (id && !loading) && <p>
                    Auteur: <strong>{last_name + " " + first_name}</strong><br></br>
                    Date de création: <strong>{Moment(date).add(7, 'hours').format("DD/MM/yyyy à HH:MM")}</strong><br></br>
                    {!isQhse && <strong>Statut: {status}</strong>}
                    {
                        isQhse &&
                        <div className="input-group mb-3">
                            <label className="input-group-text">Statut</label>
                            <select className="form-select"
                                data-field="Statut" onChange={onStatusChange} required>
                                <option value=""></option>
                                <option value="En cours" selected={status === "En cours"}>En cours</option>
                                <option value="Clôturé" selected={status === "Clôturé"}>Clôturé</option>
                            </select>
                        </div>
                    }
                </p>
            }

            {(!loading && !needGlobalId) &&
                <Fragment>
                    <br></br>
                    <form onSubmit={onSubmit}>

                        {
                            (user && user.roles.includes("ROLE_QHSE") && !id) &&
                            <Fragment>
                                <div className="input-group mb-3">
                                    <label className="input-group-text">Choisissez si vous n'êtes pas l'auteur</label>
                                    {!bbsoUsers && <ButtonWaiting />}
                                    {bbsoUsers &&
                                        <Select options={bbsoUsers} name="dpt" value={selecteAuthor}
                                            className="fillInBlank" data-field="Votre service" onChange={onChooseAuthor} />
                                    }
                                </div>
                            </Fragment>
                        }

                        <div className="input-group mb-3">
                            <label className="input-group-text">Votre service</label>
                            <Select options={dptOpt} name="dpt" value={dpt}
                                className="fillInBlank" data-field="Votre service" onChange={onDptChange} required />
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text">Incident obervé</label>
                            <label className="input-group-text">le</label>
                            <input className="form-control" type="datetime-local" value={incidentDate}
                                onChange={onIncidentDateChange} data-field="Date incident" required></input>
                            <label className="input-group-text">sur</label>
                            <select className="form-select" data-field="Lieu de l'incident" onChange={onIncidentLocationChange} required>
                                <option value=""></option>
                                <option value="B+1 RDC Zone Commune" selected={location === "B+1 RDC Zone Commune"}>B+1 RDC Zone Commune</option>
                                <option value="B+1 RDC Mise En Service" selected={location === "B+1 RDC Mise En Service"}>B+1 RDC Mise En Service</option>
                                <option value="B+1 RDC Atelier Montage" selected={location === "B+1 RDC Atelier Montage"}>B+1 RDC Atelier Montage</option>
                                <option value="B+1 RDC Magasin - Logistique" selected={location === "B+1 RDC Magasin - Logistique"}>B+1 RDC Magasin - Logistique</option>
                                <option value="B+1 Extérieur" selected={location === "B+1 Extérieur"}>B+1 Extérieur</option>
                                <option value="B+1 Etage Bureaux" selected={location === "B+1 Etage Bureaux"}>B+1 Etage Bureaux</option>
                                <option value="B+1 Mezzanine Atelier Mise En Service" selected={location === "B+1 Mezzanine Atelier Mise En Service"}>B+1 Mezzanine Atelier Mise En Service</option>
                                <option value="B+1 Mezzanine Magasin" selected={location === "B+1 Mezzanine Magasin"}>B+1 Mezzanine Magasin</option>
                                <option value="B+1 Mezzanine Bureau Production" selected={location === "B+1 Mezzanine Bureau Production"}>B+1 Mezzanine Bureau Production</option>
                                <option disabled>_________</option>
                                <option value="B+2 Etage" selected={location === "B+2 Etage"}>B+2 Etage</option>
                                <option value="B+2 Rez-de-Chaussée" selected={location === "B+2 Rez-de-Chaussée"}>B+2 Rez-de-Chaussée</option>
                                <option value="B+2 Extérieur" selected={location === "B+2 Extérieur"}>B+2 Extérieur</option>
                            </select>
                        </div>

                        <div className="form-floating mb-3">
                            <textarea className="form-control" name="desc" onBlur={onQhseChange}
                                style={{ height: "150px" }} defaultValue={description} data-field="Description de l'observation sécurité" required></textarea>
                            <label htmlFor="floatingInput">Description de l'observation sécurité</label>
                        </div>

                        <div className="form-floating mb-3">
                            <textarea className="form-control" style={{ height: "150px" }}
                                name="myAction" defaultValue={myAction} data-field="Mes actions" onBlur={onQhseChange} required></textarea>
                            <label htmlFor="floatingInput">Qu'ai-je fait pour corriger ceci
                                OU pour conforter le comportement sécurité</label>
                        </div>

                        <div className="mb-3  text-center">
                            <span className="fw-bold">Type d'observation </span>
                            <br /><br />
                            <input type="radio" className="btn-check" id="positive" defaultChecked={typeObs === "Positive"}
                                name="typeObs" value="Positive" data-field="Type d'observation" onChange={onQhseChange} required />
                            <label className="btn btn-outline-success" htmlFor={"positive"}>Positive</label>

                            <input type="radio" className="btn-check" id="danger-behaviour" defaultChecked={typeObs === "Comportement dangereux"}
                                name="typeObs" data-field="Type d'observation" value="Comportement dangereux" onChange={onQhseChange} />
                            <label className="btn btn-outline-warning" htmlFor="danger-behaviour" style={{ marginLeft: "10px" }}>Comportement dangereux</label>

                            <input type="radio" className="btn-check" name="typeObs" defaultChecked={typeObs === "Environnement dangereux"}
                                id="danger-environment" data-field="Type d'observation" value="Environnement dangereux" onChange={onQhseChange} />
                            <label className="btn btn-outline-warning" htmlFor="danger-environment" style={{ marginLeft: "10px" }}>Environnement dangereux</label>

                            <input type="radio" className="btn-check" name="typeObs" defaultChecked={typeObs === "Presqu'accident"}
                                id="near-accident" data-field="Type d'observation" value="Presqu'accident" onChange={onQhseChange} />
                            <label className="btn btn-outline-danger" htmlFor="near-accident" style={{ marginLeft: "10px" }}>Presqu'accident</label>
                        </div>

                        <div className="mb-3  text-center">
                            <span className="fw-bold">Risque</span>
                            <br /><br />
                            <input type="radio" className="btn-check" id="low-risk" defaultChecked={risk === "Faible"}
                                name="risk" value="Faible" data-field="Risque" onChange={onQhseChange} required />
                            <label className="btn btn-outline-success" htmlFor={"low-risk"}>Faible</label>

                            <input type="radio" className="btn-check" id="middle-risk" defaultChecked={risk === "Moyen"}
                                name="risk" value="Moyen" data-field="Risque" onChange={onQhseChange} />
                            <label className="btn btn-outline-warning" htmlFor="middle-risk" style={{ marginLeft: "10px" }}>Moyen</label>

                            <input type="radio" className="btn-check" defaultChecked={risk === "Elevé"}
                                name="risk" id="high-risk" value="Elevé" data-field="Risque" onChange={onQhseChange} />
                            <label className="btn btn-outline-danger" htmlFor="high-risk" style={{ marginLeft: "10px" }}>Elevé</label>
                        </div>

                        <div className="input-group mb-3">
                            <label className="input-group-text">Catégories de risques</label>
                            <Select options={catOptions} name="category" defaultValue={category}
                                className="reactSelect" required data-field="Catégories de risques" onChange={onQhseChange} />
                        </div>

                        {getDeviceType() === "mobile" &&
                            <div style={{ display: "flex", marginBottom: "10px" }}>
                                {
                                    !takeAPhoto &&
                                    <Fragment  >
                                        <button type="button" className="custom-file-upload photo" style={{ marginLeft: "auto", marginRight: "auto" }}
                                            onClick={takePhoto}></button>

                                        <label className="custom-file-upload folder" style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <input type="file" accept="image/*" onChange={onChooseFile} />
                                        </label>
                                    </Fragment>
                                }

                                {takeAPhoto &&
                                    <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>}

                                <br></br>
                                {takeAPhoto &&
                                    <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} onTakePhoto={(data) => { handlePhoto(data) }} />}
                            </div>
                        }

                        {getDeviceType() !== "mobile" &&
                            <div className="input-group mb-3">
                                <label className="input-group-text" htmlFor="file">Ajouter une image</label>
                                <input type="file" accept="image/*" className="form-control" onChange={onChooseFile} id="file" name="attachment" />
                            </div>
                        }

                        {dataPhoto &&
                            <div className="text-center" style={{ marginBottom: "20px" }}>
                                <img src={dataPhoto} style={{ maxWidth: getDeviceType() === "mobile" ? "300px" : "500px" }} />
                                <br></br>
                                <button type="button" style={{ marginTop: "10px", marginLeft: "10px" }} className="btn btn-danger" onClick={deletePhoto}>Supprimer</button>
                            </div>
                        }

                        {
                            id &&
                            <div className="form-floating mb-3">
                                <textarea className="form-control" style={{ height: "150px" }}
                                    name="qhseComment" defaultValue={qhseComment} data-field="Commentaires QHSE"
                                    onBlur={onQhseChange} readOnly={!user.roles.includes("ROLE_QHSE")}></textarea>
                                <label htmlFor="floatingInput">Commentaires QHSE</label>
                            </div>

                        }

                        <div className="text-center">
                            <button type="submit" className="btn btn-success" disabled={saving}>
                                {saving && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Soumettre
                            </button>
                        </div>



                        <br></br>
                    </form>
                </Fragment>
            }

            <CustomSpeechRecognition listen={listen} setListen={setListen} />
        </div>
    )
}

export default BbsoForm;