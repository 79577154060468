import { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import errorManagement from "../../services/errorManagement";
import usersDao from "../../services/settings/usersDao";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { getHeight } from "../../functions/PageAnimation";
import { hasRole } from "../../functions/StrFunctions";

const FocusAccesses = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [tab, setTab] = useState(0);

    const [list, setList] = useState();

    useEffect(() => {
        usersDao.getFocusAccesses().then(
            (response) => {
                setList(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }, []);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('coduti',
            { header: 'Global ID', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('nomuti',
            { header: 'Username', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('french_label',
            { header: 'French designation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('english_label',
            { header: 'English label', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('canAccess',
            { header: 'Access granted ?', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        
        if( hasRole(["ROLE_ADMIN"]) ){
            arr.push(columnHelper.accessor('xxx',
                { header: 'Access granted ?', cell: (i) => <div className="text-center">
                    { i.row.original.canAccess === "Yes" && 
                        <button className="btn btn-danger"
                            onClick={()=>onRemoveAccess(i.row.id)}>Retirer l'accès</button>}
                </div> }));
        }        

        return arr;
    }

    //Set columns
    const columns = getColumns();

    const onRemoveAccess = (k) => {
        var arr = [...list];
        var o = { CMNADM: arr[k].CMNADM,
        CSOADM :arr[k].CSOADM,
        CUTADM :arr[k].CUTADM}
        
        usersDao.removeFocusAccess(o);

        arr[k].canAccess = 'No';

        setList(arr);
    }

    return <Fragment>
        <h5 className="text-center">Focus Evolution accesses review</h5>

        {loading && <WaitingRoundSnippers />}<br></br>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                    aria-current="page" href="#" onClick={() => setTab(0)}>Introduction</a>
            </li>

            <li className="nav-item">
                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                    href="#" onClick={() => setTab(1)}>Data</a>
            </li>
        </ul>

        { tab === 0 && <div className="overflow-y-auto" id="focus-accesses-exp" style={{fontSize: "20px", height: getHeight("focus-accesses-exp")}}>
            <h5 className="ms-3 mt-3">Focus HIM</h5>
            <div className="d-flex">
                <div><img src="/doc/doc user focus.PNG"/></div>
                <div className="ms-3">
                    <p>We manage user access to this interface of the Focus Evolution software.</p>
                    <ul>
                        <li>If you see a flashlight icon: This means the user can expand the menu to view its contents or sub-options.</li>
                        <li>If you see a red cross: This indicates the user does not have access to the section or functionality.</li>
                        <li>If you see a pencil icon: This means the user has write access to the functionality or section.</li>
                        <li>If there is a flashlight icon and some of its child elements have a red cross: The user can expand the section to view its contents but will not have access to the specific functionalities marked with a red cross.</li>
                    </ul>
                </div>
            </div>

            <h5 className="ms-3 mt-3">Extract the complete data</h5>

            <p>To retrieve the complete list of user rights, click on the “Data” tab, then on the icon 
                <img className="ms-1 me-1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAApCAYAAABZa1t7AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAMQSURBVFhH7ZbPaxNBFMf7h7QHDx6kUjxILrn0UuuhBEppbxU8eBC0/jiIePcgCCJeBA+KeBAMTcA/oWBp015rtT9iWpP0Rxpr2zStEJ75LOwyGSfZ2d0Ec8jAF5adee99dua9N9snXTp6YEFHDyzoiARWOa1KqXwo+Z2S5PI7srlVcMRzfmffmWNNmBEYrFarSfnwyAle2C1J6ddvOT45ldPqmZyd/3HEM++YYw1rscHWdgQCO6oH+/GzKLv7ZTmpVD0QP7EWG2zxYTOswTiW7eKesxOm4DY6Pqk4PvDlN6zA+Nri3oExWBjhC5+thi8YX9dOKFf4bLVzLcHIB7be5LgdwneznGsKRgWRrOSF6qx0UJbdvX2pnp03vA8j8pUYpmptCkZ5kweuE0AWM0syNTUlIyPXZDaVaggSVsQglj763KaoK1v/ErUlTExMyNDQkIyOXpfx8XEZGBiQycnJ0HL9EoNYevy+Cxcvia4bN2/J6lrWM0ZjY2MyODjogfX390sikQgt1fd6dkvu3X8g8XhcpqenJZlMmsFev3nrdG3VmKNcWMx4R5lKpxvmo4hY795/kFgs5skINje/2LSRhkl+1mKDrWmeWF8Wlv3Bvq5tNtx9UcXussvsNruufxSxVtez/mAbuXyDITIlsK0oFPKS/KSAKCTdPzFDgZkS2FYUigtGAVFIun8rsK49yrn5zH9I/iV/sGbtohOdH1m3C1OD7VTnR9/qx3jn7ow/GCLP1CupU52fGHp+tQR7+uy55LYLngOOshOdf6t+T754+coe7PKVq7JS3zW9CNr721NxTmZ4eNgeDN2eeSgr3zeMTtshoB49fvIPlC8YokLXNnNGx1HEH4Veiap8wdDHT6m2wuFrNv3ZCOTKCgyxcxyr/qsdROQrPvBlglFlDYacnKvnBdWqthI/sRYbbPGBLxOMqkBgiGqllZC4NGG6Njuh3q0884451rAWG2xdPyYYVYHBVHFDcCz8WBKcPwTEM/ctc6wx2ZpgVEUCiyITjKoemC4TjKoemC4TjKouBYvJX5cAIJeiS4gQAAAAAElFTkSuQmCC"/>
                 then on the Excel logo</p>.

            <div className="alert alert-warning fw-bold">Remarks: 
                
                <ul>
                    <li>User names containing the word “réf”, such as “SAV réf”, are not users but user templates which will be used to facilitate the assignment of rights. When we create a new customer service user, we'll assign the rights of “SAV réf” in 1 click</li>
                    <li>It's normal to have duplicate section names.</li>
                </ul>
                
                </div>
            
        </div>}

        { (tab === 1 && list) &&
            <ReactTable columns={columns} data={list}
                origin="focus-accesses" classes={"no-padding fixFstCol "} top0={true} />}

    </Fragment>

}

export default FocusAccesses;